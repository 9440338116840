<template>
  <div class="vd-digital-asset-viewer">
    <div class="thumbnail-container resp-container" v-if="type === 'endcard'" @click="showModal">
      <div class="thumbnail" v-if="url.split('.').pop() !== 'zip'">
        <iframe frameborder="0" class="resp-iframe" :src="url"></iframe>
      </div>
    </div>
    <video
      @click="showModal"
      v-if="type === 'video'"
      width="100%"
      height="100%"
      class="fit-square"
      :src="url"
    ></video>
    <el-dialog :title="title" :visible.sync="modalVisible">
      <div class="thumbnail-container resp-container" v-if="type === 'endcard'">
        <div class="thumbnail">
          <iframe frameborder="0" class="resp-iframe fit-square-modal" :src="url"></iframe>
        </div>
      </div>
      <video v-if="type === 'video'" controls class="fit-square-modal" :src="url"></video>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'vd-digital-asset-viewer',
  data() {
    return {
      modalVisible: false,
    };
  },
  props: {
    type: String,
    title: String,
    url: String,
    next: Function,
    prev: Function,
  },
  methods: {
    showModal(e) {
      this.modalVisible = true;
      e.stopPropagation();
    },
  },
};
</script>

<style lang="scss" scoped>
.vd-digital-asset-viewer {
  display: inline-block;
  margin: 8px;
  position: relative;
}

.thumbnail {
  transform: scale(0.08);
  transform-origin: 0 0;
}

.thumbnail iframe {
  width: 1080px;
  height: 1920px;
}

.thumbnail::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.thumbnail-container {
  width: calc(1080px * 0.08);
  height: calc(1920px * 0.08);
  display: inline-block;
  overflow: hidden;
  position: relative;
}

.resp-container {
  position: relative;
  overflow: hidden;
}

.download-icon {
  font-size: 24px;
  position: relative;
  float: left;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fit-square {
  object-fit: contain;
}

.fit-square-modal {
  width: 100%;
  height: 50vh;
  object-fit: contain;
}

/deep/.el-dialog {
  .el-dialog__header {
    margin: 0;
    padding: 15px;
    border-bottom: 1px solid #ebeef5;
  }

  .el-dialog__body {
    padding: 15px;
  }
}
</style>
