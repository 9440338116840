import { parseStringPromise } from 'xml2js';

/**
 * @param {string} toParse
 * @return {Promise} xml object parsed
 */
const stringToXml = async toParse => (parseStringPromise(toParse));

function reverseEscapeHtml(text) {
  return text
    .replaceAll('&amp;', '&')
    .replaceAll('&lt;', '<')
    .replaceAll('&gt;', '>')
    .replaceAll('&quot;', '"')
    .replaceAll('&apos;', '\'');
}

/**
 * @param {string} xml
 * @return {Object} object based on the xml
 */
const xmlToVoodooObject = (xml) => {
  const mainObject = xml['x:xmpmeta']['rdf:RDF'][0]['rdf:Description'][0].$;
  const voodooObject = Object.keys(mainObject)
    .filter(key => key.toLowerCase().startsWith('voodoo'))
    .reduce((acc, cur) => {
      acc[cur.slice(7)] = mainObject[cur];
      return acc;
    }, {});
  return voodooObject;
};


/**
 * @param {string} data xml as string
 * @return {Promise} xml object parsed and converted to JSON
 */
const getVoodooJson = async (data) => {
  const xml = await stringToXml(data);
  const voodooOject = xmlToVoodooObject(xml);
  if (voodooOject.metadata) {
    return reverseEscapeHtml(voodooOject.metadata);
  }
  return null;
};


/**
 * @param {File} file the file to extract data
 * @param {Promise} promise a promise with the size of the file
 */
const getFileSize = file => new Promise((successCallback, failureCallback) => {
  const type = file.raw.type.split('/')[0];
  if (type === 'image') {
    const img = new Image();
    img.onload = () => {
      URL.revokeObjectURL(img.src);
      successCallback({ width: img.width, height: img.height });
    };
    img.onerror = (error) => { failureCallback(error); };
    const imgUrl = URL.createObjectURL(file.raw);
    img.src = imgUrl;
  } else if (type === 'video') {
    const video = document.createElement('video');
    video.addEventListener('loadedmetadata', (e) => {
      successCallback({ width: e.target.videoWidth, height: e.target.videoHeight });
    }, false);
    video.src = URL.createObjectURL(file.raw);
  } else {
    failureCallback();
  }
});

/**
 * @param {File} file the file to extract data
 * @param {Promise} promise a promise with the size and metadata of the file
 */
const getFileInfos = file => new Promise((successCallback, failureCallback) => {
  const infos = { type: file.raw.type.split('/')[0] };
  try {
    const reader = new FileReader();
    reader.onload = async () => {
      await getFileSize(file).then((size) => { infos.size = size; });

      // Extract metadata
      const fileBinary = reader.result;
      const xmlString = fileBinary.match(/<x:xmpmeta(.*)x:xmpmeta>/s);
      if (xmlString) {
        await getVoodooJson(xmlString).then((voodooJson) => {
          infos.metadata = voodooJson ? JSON.parse(unescape(voodooJson)) : null;
        });
      }
      successCallback(infos);
    };
    reader.onerror = (error) => { failureCallback(error); };
    reader.readAsBinaryString(file.raw);
  } catch (error) {
    failureCallback(error);
  }
});

export default getFileInfos;
