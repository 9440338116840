<template>
  <div class="badgeDialog">
    <el-dialog
      :close-on-click-modal="false"
      :before-close="closeBadgeDialog"
      title="Badge"
      :visible.sync="badgeDialogVisible"
      width="50%"
    >
      <el-form
        class="form"
        ref="badgeModel"
        label-width="120px"
        :model="badgeModel"
        @submit.native.prevent="submitBadgeForm"
      >
        <el-form-item v-if="badgeFormErrors.length">
          <el-alert
            v-for="(error, index) in badgeFormErrors"
            :key="index"
            :title="error"
            :closable="false"
            type="error"
            class="error"
          ></el-alert>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="badgeModel.parentId"
            placeholder="Parent"
            @change="onSelectParentBadge"
          >
            <el-option :key="null" label="None" :value="null"></el-option>
            <el-option
              v-for="option in parentBadgeElements"
              :key="option.id"
              :label="option.name"
              :value="option.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="badgeModel.badgeTypeId"
            placeholder="Type"
            :disabled="!!badgeModel.parentId"
          >
            <el-option
              v-for="option in badgeTypeElements"
              :key="option.id"
              :label="option.name"
              :value="option.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="badgeModel.languageId" placeholder="Language">
            <el-option
              v-for="option in languageElements"
              :key="option.id"
              :label="option.name"
              :value="option.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Name">
          <el-input placeholder="Name" v-model="badgeModel.name"></el-input>
        </el-form-item>
        <el-form-item>
          <vd-upload ref="upload" uploadPath="documents/badges/upload"></vd-upload>
        </el-form-item>
        <el-form-item class="submit">
          <el-button @click="closeBadgeDialog">Cancel</el-button>
          <el-button type="primary" native-type="submit" :disabled="badgeFormSubmit">Save</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import VdUpload from '@/components/vd-upload/VdUpload.vue';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'badge-dialog',
  data() {
    return {
      badgeDialogVisible: false,
      badgeFormSubmit: false,
      badgeFormSubmitTimeout: null,
      badgeFormErrors: [],
      badgeModel: {
        id: null,
        parentId: null,
        badgeTypeId: null,
        languageId: null,
        name: null,
        document: null,
      },
    };
  },
  async mounted() {
    await this.init();
  },
  components: {
    VdUpload,
  },
  beforeDestroy() {
    clearTimeout(this.badgeFormSubmitTimeout);
  },
  computed: {
    ...mapState('badge', { parentBadgeElements: 'parentBadges' }),
    ...mapState('badgeType', { badgeTypeElements: 'elements' }),
    ...mapState('language', { languageElements: 'elements' }),
  },
  methods: {
    ...mapActions('badge', ['fetchParentBadges']),
    ...mapActions('badgeType', ['fetchBadgeTypes']),
    ...mapActions('badge', ['addBadge', 'editBadge']),
    ...mapActions('language', ['fetchLanguages']),
    async init() {
      await Promise.all([
        this.fetchParentBadges(),
        this.fetchBadgeTypes(),
        this.fetchLanguages(),
      ]);
    },
    async openBadgeDialog(badge = null) {
      await this.init();
      this.resetBadgeModel(badge);
      if (this.$refs.upload) {
        this.$refs.upload.resetUpload();
      }
      this.badgeDialogVisible = true;
    },
    resetBadgeModel(badge = null) {
      this.badgeFormErrors = [];
      if (badge) {
        this.badgeModel = { ...badge };
      } else {
        this.badgeModel = {
          id: null,
          parentId: null,
          badgeTypeId: null,
          languageId: null,
          name: null,
          document: null,
        };
      }
    },
    closeBadgeDialog() {
      this.resetBadgeModel();
      this.badgeDialogVisible = false;
    },
    getParentBadgeById(parentId) {
      return (
        this.parentBadgeElements.find(elem => elem.id === parentId) || null
      );
    },
    onSelectParentBadge(parentId) {
      const parentBadge = this.getParentBadgeById(parentId);
      if (parentBadge) {
        this.badgeModel.badgeTypeId = parentBadge.badgeTypeId || null;
      } else {
        this.badgeModel.badgeTypeId = null;
      }
    },
    badgeFormValidation() {
      let valid = true;
      this.badgeFormErrors = [];
      if (!this.badgeModel.badgeTypeId) {
        this.badgeFormErrors.push('Badge type required');
        valid = false;
      }
      if (!this.badgeModel.languageId) {
        this.badgeFormErrors.push('Language required');
        valid = false;
      }
      if (!this.badgeModel.name) {
        this.badgeFormErrors.push('Name required');
        valid = false;
      }
      if (!this.badgeModel.document) {
        this.badgeFormErrors.push('IMAGE file required');
        valid = false;
      }
      return valid;
    },
    async submitBadgeForm() {
      this.badgeFormSubmit = true;
      this.badgeFormSubmitTimeout = setTimeout(async () => {
        this.badgeModel.document = this.$refs.upload.document;
        if (this.badgeFormValidation()) {
          if (this.badgeModel.id) {
            await this.editBadge(this.badgeModel);
          } else {
            await this.addBadge(this.badgeModel);
          }
          this.closeBadgeDialog();
        }
        this.badgeFormSubmit = false;
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.badgeDialog {
  display: block;

  .form {
    .error {
      margin-bottom: 10px;
    }

    .submit {
      text-align: right;
      margin: 0;
    }
  }

  /deep/.el-dialog {
    .el-dialog__header {
      margin: 0;
      padding: 15px;
      border-bottom: 1px solid #ebeef5;
    }

    .el-dialog__body {
      padding: 15px;
    }
  }
}
</style>

