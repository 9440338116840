<template>
  <div class="generate">
    <div class="render-buttons">
      <el-select
        class="render-button"
        v-model="renderPresetId"
        placeholder="Preset"
        @change="onSelectRenderPreset"
      >
        <el-option label="None" :value="null"></el-option>
        <el-option
          v-for="option in renderPresetElements"
          :key="option.id"
          :label="option.name"
          :value="option.id"
        ></el-option>
      </el-select>
      <el-button
        class="render-button"
        @click="saveRenderPreset"
        :disabled="!this.selectionDone || this.value.templateType === 'templateType/5'"
      >Save Preset</el-button>
      <el-button class="render-button" @click="reset">Reset</el-button>
      <el-button
        class="render-button"
        @click="genRenders"
        :disabled="!this.selectionDone"
      >GENERATE RENDERS</el-button>
      <el-button
        class="render-button"
        @click="genForceRenders"
        :disabled="!this.selectionDone"
      >GENERATE RENDERS (FORCE)</el-button>
    </div>
    <render-tree
      :tree="renderTree"
      :selectionDone="selectionDone"
      :value="value"
      :onChange="onChange"
      ref="renderTree"
    ></render-tree>
    <renderPreset-dialog ref="renderPresetDialog"></renderPreset-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import RenderTree from '@/views/generate/RenderTree';
import RenderPresetDialog from '@/views/renderPreset/RenderPresetDialog.vue';

export default {
  name: 'generate-view',
  data() {
    return {
      renderPresetId: null,
      renderPreset: null,
      value: this.resetValue(),
      updatedKey: null,
      selectionDone: false,
    };
  },
  async mounted() {
    await Promise.all([
      this.fetchRenderTree(),
      this.fetchRenderPresets(),
    ]);
  },
  components: { RenderTree, RenderPresetDialog },
  computed: {
    ...mapState('renderTree', ['loading', 'renderTree']),
    ...mapState('renderPreset', { renderPresetElements: 'elements' }),
  },
  methods: {
    ...mapActions('renderTree', ['fetchRenderTree']),
    ...mapActions('render', ['generateRenders']),
    ...mapActions('renderPreset', ['fetchRenderPresets']),
    async onChange(newValue, updatedKey) {
      if (!newValue) return;
      this.value[updatedKey] = newValue;
      this.updatedKey = updatedKey;
      if (!newValue || !newValue.length) {
        await this.fetchRenderTree({
          step: this.getPrevKey(updatedKey),
          templateTypeId: this.getTemplateTypeId(),
          gameId: this.getGameId(),
        });
      } else if (updatedKey !== 'language') {
        await this.fetchRenderTree({
          step: updatedKey,
          templateTypeId: this.getTemplateTypeId(),
          gameId: this.getGameId(),
        });
        if (this.renderPreset) {
          this.onSelectRenderPreset(this.renderPreset.id);
        }
      } else {
        this.selectionDone = true;
      }
    },
    async reset() {
      this.renderPresetId = null;
      this.renderPreset = null;
      this.value = this.resetValue();
      this.updatedKey = null;
      this.selectionDone = false;
      await this.fetchRenderTree();
    },
    resetValue() {
      return {};
    },
    saveRenderPreset() {
      this.$refs.renderPresetDialog.openRenderPresetDialog({
        id: null,
        name: null,
        meta: this.getApiValues(),
      });
    },
    getRenderPresetById(renderPresetId) {
      return (
        this.renderPresetElements.find(elem => elem.id === renderPresetId) ||
        null
      );
    },
    onSelectRenderPreset(renderPresetId) {
      if (renderPresetId === null) {
        this.renderPreset = null;
      }
      const renderPreset = this.getRenderPresetById(renderPresetId);
      if (
        renderPreset &&
        renderPreset.meta.templateTypeId !== this.getTemplateTypeId()
      ) {
        this.reset();
      }
      this.renderPresetId = renderPreset ? renderPreset.id : null;
      this.renderPreset = renderPreset;
      this.setUpValue();
      this.setAllCheckedNodes(this.updatedKey);
    },
    setUpValue() {
      if (!this.renderPreset) {
        this.resetValue();
      } else {
        if (this.renderPreset.meta.templateTypeId) {
          this.value.templateType = `templateType/${this.renderPreset.meta.templateTypeId}`;
        }
        if (this.renderPreset.meta.rushIds.length) {
          this.value.rush = [];
          this.renderPreset.meta.rushIds.forEach((rushId) => {
            this.value.rush.push({
              id: `rush/${rushId}`,
            });
          });
        }
        if (this.renderPreset.meta.templateIds.length) {
          this.value.template = [];
          this.renderPreset.meta.templateIds.forEach((templateId) => {
            this.value.template.push({
              id: `template/${templateId}`,
            });
          });
        }
        if (this.renderPreset.meta.wordingIds.length) {
          this.value.wording = [];
          this.renderPreset.meta.wordingIds.forEach((wordingId) => {
            this.value.wording.push({
              id: `wording/${wordingId}`,
            });
          });
        }
        if (this.renderPreset.meta.networkFormatIds.length) {
          this.value.network = [];
          this.renderPreset.meta.networkFormatIds.forEach((networkFormatId) => {
            this.value.network.push({
              id: `network/${networkFormatId.networkId}/format/${networkFormatId.formatId}`,
            });
          });
        }
        if (this.renderPreset.meta.languageIds.length) {
          this.value.language = [];
          this.renderPreset.meta.languageIds.forEach((languageId) => {
            this.value.language.push({
              id: `language/${languageId}`,
            });
          });
        }
      }
    },
    setAllCheckedNodes(key) {
      this.setCheckedNodes('tree', 2, 'rush');
      this.setCheckedNodes('tree', 3, 'template');
      this.setCheckedNodes('tree', 4, 'wording');
      this.setCheckedNodes('tree', 5, 'network');
      this.setCheckedNodes('tree', 6, 'language');
      if (!key) {
        this.onChange(this.value.templateType, 'templateType');
      } else if (key === 'game') {
        this.onChange(this.value.rush, 'rush');
      } else if (key === 'rush') {
        this.onChange(this.value.template, 'template');
      } else if (key === 'template') {
        this.onChange(this.value.wording, 'wording');
      } else if (key === 'wording') {
        this.onChange(this.value.network, 'network');
      } else if (key === 'network') {
        this.onChange(this.value.language, 'language');
      }
    },
    setCheckedNodes(type, index, key) {
      if (
        this.$refs.renderTree &&
        this.$refs.renderTree.$refs.renderInput &&
        this.$refs.renderTree.$refs.renderInput[index] &&
        (type === 'tree' &&
          this.$refs.renderTree.$refs.renderInput[index].$refs[`${type}${key}`])
      ) {
        this.$refs.renderTree.$refs.renderInput[index].$refs[
          `${type}${key}`
        ].setCheckedNodes(this.value[`${key}`] || []);
      }
    },
    getTemplateTypeId() {
      return this.value.templateType ?
        +this.value.templateType.split('/').pop() :
        this.value.templateType;
    },
    getGameId() {
      return this.value.game ?
        +this.value.game.split('/').pop() :
        this.value.game;
    },
    getApiValues() {
      return {
        templateTypeId: this.getTemplateTypeId(),
        gameId: this.getGameId(),
        ...(this.value.rush && {
          rushIds: this.value.rush.map(rush => +rush.id.split('/').pop()),
        }),
        ...(this.value.template && {
          templateIds: this.value.template.map(
            template => +template.id.split('/').pop(),
          ),
        }),
        ...(this.value.concept && {
          conceptIds: this.value.concept.map(
            concept => +concept.id.split('/').pop(),
          ),
        }),
        ...(this.value.wording && {
          wordingIds: this.value.wording.map(
            wording => +wording.id.split('/').pop(),
          ),
        }),
        networkFormatIds: this.value.network.map((network) => {
          const [, networkIdString, , formatIdString] = network.id.split('/');
          return {
            networkId: +networkIdString,
            formatId: +formatIdString,
          };
        }),
        languageIds: this.value.language.map(
          language => +language.id.split('/').pop(),
        ),
      };
    },
    async genRenders() {
      return this.generateRenders({
        ...this.getApiValues(),
        force: false,
      });
    },
    async genForceRenders() {
      return this.generateRenders({
        ...this.getApiValues(),
        force: true,
      });
    },
    getPrevKey(updatedKey) {
      if (!this.renderTree) return null;
      const keys = this.renderTree.map(({ key }) => key);
      return keys[keys.indexOf(updatedKey) - 1];
    },
  },
};
</script>

<style lang="scss" scoped>
.generate {
  display: block;

  .render-buttons {
    margin-bottom: 16px;
    text-align: right;

    .render-button {
      margin: 4px;
    }
  }
}
</style>

