<template>
  <div class="uploadManualRenderDialog">
    <el-dialog
      :close-on-click-modal="false"
      :before-close="closeUploadManualRenderDialog"
      title="Upload render"
      :visible.sync="uploadManualRenderDialogVisible"
      width="90%"
    >
      <el-form
        class="form"
        ref="model"
        :model="manualRenderModel"
        @submit.native.prevent="submitManualRenderForm"
      >
        <el-form-item v-if="manualRenderFormErrors.length">
          <el-alert
            v-for="(error, index) in manualRenderFormErrors"
            :key="index"
            :title="error"
            :closable="false"
            type="error"
            class="error"
          ></el-alert>
        </el-form-item>
        <el-form-item class="setting">
          <h4>Add upload blocs</h4>
          <el-autocomplete
            class="autocomplete"
            v-model="selectedGameName"
            placeholder="Game"
            :fetch-suggestions="onSearchGame"
            :clearable="true"
            @select="onSelectGame"
            size="small"
          ></el-autocomplete>
          <el-autocomplete
            class="autocomplete"
            v-model="selectedNetworkName"
            placeholder="Network"
            :fetch-suggestions="onSearchNetwork"
            :clearable="true"
            @select="onSelectNetwork"
            size="small"
          ></el-autocomplete>
          <el-button @click="addManualRenderElementModel" size="small">+ add</el-button>
        </el-form-item>
        <el-form-item class="setting">
          <h4>Metadata settings</h4>
          <el-select
            class="select"
            v-model="selectedMetadataOrigin"
            placeholder="Origin"
            size="small"
          >
            <el-option :key="null" label="Origin from file" :value="null"></el-option>
            <el-option
              v-for="origin in metadata.creative.origins"
              :key="origin.id"
              :label="origin.name"
              :value="origin.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-for="(element, elementIndex) in manualRenderModel.elements" :key="elementIndex">
          <el-card shadow="hover">
            <div slot="header" class="card-header">
              <b>{{ element.game.name }} - {{ element.network.name }}</b>
              <span class="right">
                <el-button @click="removeManualRenderElementModel(elementIndex)" size="small">
                  <i class="el-icon-close"></i>
                </el-button>
              </span>
            </div>
            <div class="element-formats">
              <b>Valid formats:</b>
              <span
                class="element-formats-item"
                v-for="(format, formatIndex) in element.network.formats" :key="formatIndex">
                {{ format.name }}
              </span>
              <br>
              <b>Valid video name exemple:</b>
              <span class="element-formats-item">
                {{ element.game.code }}_001_A01_{{ element.network.formats[0].name }}_EN.mp4
              </span>
            </div>

            <vd-upload-multiple
              uploadPath="documents/renders/upload"
              @upload="onChangeUpload($event, elementIndex)"
            ></vd-upload-multiple>

            <div v-if="element.items.length" class="element-bloc">
              <div
                v-for="(item, itemIndex) in element.items"
                :key="itemIndex"
                class="element-name"
                :class="{ 'element-success': item.valid, 'element-error': !item.valid }"
              >
                {{ item.document.fileName.replace('.mp4', '') }}
                <i v-if="!item.errors" class="el-icon-check"></i>
                <ul v-if="item.errors || item.warnings" class="error-list">
                  <li
                    v-for="(error, errorIndex) in item.errors"
                    :key="errorIndex"
                    class="element-name element-error"
                  >&#10060; {{ error.message }}</li>
                  <li
                    v-for="(warning, warningIndex) in item.warnings"
                    :key="warningIndex"
                    class="element-name element-warning"
                  >&#9888;&#65039; {{ warning.message }}</li>
                </ul>
              </div>
            </div>
          </el-card>
        </el-form-item>
        <el-form-item class="submit">
          <el-button @click="closeUploadManualRenderDialog">Cancel</el-button>
          <el-button type="primary" native-type="submit" :disabled="manualRenderFormSubmit">Upload</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import VdUploadMultiple from '@/components/vd-upload-multiple/VdUploadMultiple.vue';
import { mapState, mapActions } from 'vuex';
import languageAlias from '../../utils/language-alias';

export default {
  name: 'manualRender-dialog',
  data() {
    return {
      uploadManualRenderDialogVisible: false,
      manualRenderFormSubmit: false,
      manualRenderFormSubmitTimeout: null,
      manualRenderFormErrors: [],
      manualRenderModel: {
        elements: {},
      },
      selectedGameId: null,
      selectedGameName: '',
      selectedNetworkId: null,
      selectedNetworkName: '',
      selectedMetadataOrigin: null,
    };
  },
  async mounted() {
    await this.init();
  },
  beforeDestroy() {
    clearTimeout(this.manualRenderFormSubmitTimeout);
  },
  components: {
    VdUploadMultiple,
  },
  computed: {
    ...mapState('game', { gameElements: 'elements' }),
    ...mapState('network', { networkElements: 'elements' }),
    ...mapState('language', { languageElements: 'elements' }),
    ...mapState('metadata', { metadata: 'metadata' }),
  },
  methods: {
    ...mapActions('game', ['fetchGames']),
    ...mapActions('network', ['fetchNetworks']),
    ...mapActions('language', ['fetchLanguages']),
    ...mapActions('metadata', ['fetchMetadata']),
    ...mapActions('manualRender', ['uploadManualRenders']),
    ...mapActions('rush', ['validateRushesExist']),
    async init() {
      await Promise.all([
        this.fetchGames(),
        this.fetchNetworks(),
        this.fetchLanguages(),
        this.fetchMetadata(),
      ]);
    },
    async openUploadManualRenderDialog({ gameId = null }) {
      await this.init();
      this.resetManualRenderModel({ gameId });
      this.uploadManualRenderDialogVisible = true;
    },
    resetManualRenderModel({ gameId = null }) {
      this.manualRenderFormErrors = [];
      const game = this.gameElements.find(g => g.id === +gameId) || null;
      this.selectedGameId = (game && game.id) || null;
      this.selectedGameName = (game && game.name) || '';
      this.selectedNetworkId = null;
      this.selectedNetworkName = '';
      this.selectedMetadataOrigin = null;
      this.manualRenderModel = { elements: {} };
    },
    async addManualRenderElementModel() {
      if (!this.selectedGameId || !this.selectedNetworkId) {
        this.$notify({ type: 'warning', message: this.$createElement('b', 'Select game and network') });
        return;
      }
      const game = this.gameElements.find(g => g.id === this.selectedGameId);
      const network = this.networkElements.find(n => n.id === this.selectedNetworkId);
      const key = `${game.id}-${network.id}`;
      if (!this.manualRenderModel.elements[key]) {
        this.manualRenderModel.elements[key] = { game, network, items: [] };
        this.manualRenderModel = JSON.parse(JSON.stringify(this.manualRenderModel));
      }
    },
    removeManualRenderElementModel(key) {
      delete this.manualRenderModel.elements[key];
      this.manualRenderModel = JSON.parse(JSON.stringify(this.manualRenderModel));
    },
    onSearchElement(elements = [], key, queryString, cb) {
      elements.sort((a, b) => a[key].length - b[key].length);
      if (queryString) {
        elements = elements.filter(element => element[key].toLowerCase().includes(queryString.toLowerCase()));
      }
      cb(elements.map(element => ({ ...element, id: element.id, value: element[key] })));
    },
    onSearchGame(queryString, cb) {
      this.onSearchElement([...this.gameElements], 'name', queryString, cb);
    },
    onSelectGame(game) {
      this.selectedGameId = game.id;
    },
    onSearchNetwork(queryString, cb) {
      this.onSearchElement([...this.networkElements], 'name', queryString, cb);
    },
    onSelectNetwork(network) {
      this.selectedNetworkId = network.id;
    },
    async onChangeUpload(documents, key) {
      const element = this.manualRenderModel.elements[key];
      element.items = await Promise.all(documents.map(
        document => this.validateDocument(element, document),
      ));

      // force re-render
      this.manualRenderModel = JSON.parse(JSON.stringify(this.manualRenderModel));
    },

    getRushNamesBypasses() {
      return [
        // OLD rush names are used for former rushes that are not respecting the new name format [a-zA-Z]+[0-9]+
        'OLD',
      ];
    },

    parseRushNumbers(rawRushNames) {
      const isValidRaw = typeof rawRushNames === 'string' && rawRushNames.length > 0;
      if (!isValidRaw) {
        return [];
      }

      return rawRushNames.split('-');
    },

    isValidRushName(rushName) {
      const isString = typeof rushName === 'string' && rushName.length > 0;

      if (!isString) {
        return false;
      }

      const validRushNumbers = ['[a-z]+[0-9]+', ...this.getRushNamesBypasses()].join('|');

      return new RegExp(`^(${validRushNumbers})$`, 'i').test(rushName);
    },

    parseFilename(filename) {
      const parts = filename.split('_');

      // Formats like 1920x1080
      if (parts.length === 5) {
        return {
          gameCode: parts[0],
          adNumber: parts[1],
          rushNames: this.parseRushNumbers(parts[2]),
          formatName: parts[3],
          languageName: parts[4],
        };
      }

      // Formats like 1920x1080_5s
      if (parts.length === 6) {
        return {
          gameCode: parts[0],
          adNumber: parts[1],
          rushNames: this.parseRushNumbers(parts[2]),
          formatName: `${parts[3]}_${parts[4]}`,
          languageName: parts[5],
        };
      }

      return null;
    },

    lookupByName(items, name) {
      if (typeof name !== 'string') {
        return null;
      }

      const item = items.find(i => i.name.toUpperCase() === name.toUpperCase());

      if (typeof item === 'undefined') {
        return null;
      }

      return item;
    },

    async validateDocument(element, document) {
      const elementGameCode = element.game.code.toUpperCase();
      const fileName = document.fileName.replace('.mp4', '').split('/').pop();
      const VALID_FILE_NAME = /^[-_A-Z0-9x]+$/; // alphanumeric

      const result = {
        document,
        render: null,
        valid: false,
        errors: [],
        warnings: [],
      };

      if (!VALID_FILE_NAME.test(fileName)) {
        result.errors.push({
          message: 'Invalid file name: must be only uppercase alphanumeric, with dashes and underscores.',
        });

        return result;
      }

      const parsedFilename = this.parseFilename(fileName);

      if (parsedFilename === null) {
        result.errors.push(
          {
            message: `Invalid filename format, please use: ${
              element.game.code.toUpperCase()
            }_<AD_NUMBER>_<RUSH-NUMBERS>_<FORMAT>_<LANGUAGE>.mp4`,
          },
        );

        return result;
      }

      const {
        gameCode, adNumber, formatName, rushNames, languageName,
      } = parsedFilename;

      if (gameCode !== elementGameCode) {
        result.errors.push({
          message: `Game code "${elementGameCode}" not found in file name ("Found: ${gameCode}").`,
        });
      }

      const format = this.lookupByName(element.network.formats, formatName);
      if (format === null) {
        result.errors.push({ message: `Format "${formatName}" provided in file name does not exists.` });
      } else {
        const videoFormat = `${document.infos.size.width}x${document.infos.size.height}`;
        const compareFormat = format.name.split('_').shift();
        if (compareFormat !== videoFormat) {
          result.errors.push({
            message: `Expect video format to be "${format.name}" ("${videoFormat}" found).`,
          });
        }
      }

      // Migration to iso languages codes, check also old languages codes
      const language = (
        this.lookupByName(this.languageElements, languageName) ||
        this.lookupByName(this.languageElements, languageAlias[languageName])
      );
      if (language === null) {
        result.errors.push({ message: `Language "${languageName}" provided in file name does not exists.` });
      }

      const hasRushes = Array.isArray(rushNames) && rushNames.length > 0;

      if (!hasRushes) {
        result.errors.push({
          message: 'Rush(es) names not recognized. ' +
            'Ensure rush name starts with one ore more letters ' +
            'followed by one or more numbers. ' +
            'Separate multiple rushes numbers using "-". ' +
            '(e.g. A01, B02-INF666, C03-D04-E05)',
        });
      }

      if (hasRushes) {
        rushNames.forEach((rushName) => {
          if (!this.isValidRushName(rushName)) {
            result.errors.push({
              message: `Rush name "${
                rushName
              }" is invalid. Expected letters followed by numbers (ex: A01) or one of [${
                this.getRushNamesBypasses().map(bypass => bypass.toUpperCase()).join(', ')
              }]`,
            });
          }
        });

        const validatedRushes = await this.validateRushesExist({ gameId: element.game.id, names: rushNames });

        validatedRushes.forEach(({ name, rushes }) => {
          const rushExists = Array.isArray(rushes) && rushes.length > 0;

          if (!rushExists && !this.getRushNamesBypasses().includes(name.toUpperCase())) {
            result.errors.push({
              message: `Rush "${
                name
              }" does not exists for the game "${element.game.code}". Ensure to upload it to Render Kid first.`,
            });
          }

          if (rushExists && rushes.every(rush => rush.best === false)) {
            result.warnings.push({
              message: `Rush "${name}" is not flagged best.`,
            });
          }
        });
      }
      const fileMetadata = document.infos.metadata;
      const hasFileMetadata = typeof fileMetadata === 'object' &&
        fileMetadata !== null &&
        typeof fileMetadata.origin === 'string' &&
        fileMetadata.origin.length > 0;
      const hasSelectedMetadata = typeof this.selectedMetadataOrigin === 'string' &&
        this.selectedMetadataOrigin.length > 0;

      if (!hasFileMetadata && !hasSelectedMetadata) {
        result.errors.push({
          message: 'No metadata origin found in file or selected in dropdown.',
        });
      }

      if (result.errors.length === 0) {
        result.valid = true;
        result.render = {
          gameId: element.game.id,
          networkId: element.network.id,
          adNumber,
          rushNumber: rushNames.join('-'),
          formatId: format.id,
          languageId: language.id,
          metadata: document.infos.metadata || null,
        };
      }

      return result;
    },

    closeUploadManualRenderDialog() {
      this.resetManualRenderModel({});
      this.uploadManualRenderDialogVisible = false;
    },
    manualRenderFormValidation() {
      let valid = true;
      this.manualRenderFormErrors = [];
      let nbDocuments = 0;
      Object.values(this.manualRenderModel.elements).forEach((element) => {
        nbDocuments += element.items.length;
      });
      if (!nbDocuments) {
        this.manualRenderFormErrors.push('You must upload renders');
        valid = false;
      }
      return valid;
    },
    transformManualRenderMetadata(render) {
      // Document metadata will be processed by backend from the doc
      // Sending only overrides if selected
      render.metadata = JSON.stringify({
        origin: this.selectedMetadataOrigin,
      });
    },
    async submitManualRenderForm() {
      this.manualRenderFormSubmit = true;
      this.manualRenderFormSubmitTimeout = setTimeout(async () => {
        if (this.manualRenderFormValidation()) {
          const data = { renders: [] };
          Object.values(this.manualRenderModel.elements).forEach((element) => {
            element.items.forEach((item) => {
              if (item.valid) {
                this.transformManualRenderMetadata(item.render);
                data.renders.push({
                  render: item.render,
                  renderDocument: { key: item.document.key, url: item.document.url },
                });
              }
            });
          });
          const response = this.uploadManualRenders(data);
          if (response) {
            this.$notify({ type: 'success', message: this.$createElement('b', 'Renders are uploaded') });
            this.closeUploadManualRenderDialog();
          } else {
            this.$notify({ type: 'error', message: this.$createElement('b', 'An error has occurred') });
          }
        }
        this.manualRenderFormSubmit = false;
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.uploadManualRenderDialog {
  display: block;

  .form {
    .setting {
      h4 {
        margin: 0;
      }

      display: inline-block;
      background-color: #f9f9f9;
      margin-right: 10px;
      margin-bottom: 15px;
      padding: 10px;
      border-radius: 2px;

      .autocomplete {
        margin-right: 10px;
      }

      .select {
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .error {
      margin-bottom: 10px;
    }

    .submit {
      text-align: right;
      margin: 0;
    }
  }

  .right {
    float: right;
  }

  .element-warning {
    font-size: 12px;
    color: #ffc04c;
    line-height: 1;
    padding-bottom: 10px;
  }

  .element-formats {
    line-height: 20px;
    font-size: 12px;
    color: #333;

    .element-formats-item {
      margin-left: 10px;
    }
  }

  .error-list {
    margin: 0;
    list-style: none;
    padding-left: 1em;
  }

  .element-bloc {
    margin-top: 10px;
    padding: 10px;
    border: solid 1px #eee;
    line-height: 20px;

    .element-name {
      margin-right: 10px;
      font-size: 11px;
      color: #333;

      &.element-success {
        color: green;
      }

      &.element-warning {
        color: darkorange;
      }

      &.element-error {
        color: red;
      }
    }
  }

  /deep/.el-dialog {
    .el-dialog__header {
      margin: 0;
      padding: 15px;
      border-bottom: 1px solid #ebeef5;
    }

    .el-dialog__body {
      padding: 15px;
    }
  }
}
</style>

