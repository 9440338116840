<template>
  <div class="renderPresetDialog">
    <el-dialog
      :close-on-click-modal="false"
      :before-close="closeRenderPresetDialog"
      title="Render preset"
      :visible.sync="renderPresetDialogVisible"
      width="50%"
    >
      <el-form
        class="form"
        ref="renderPresetModel"
        label-width="120px"
        :model="renderPresetModel"
        @submit.native.prevent="submitRenderPresetForm"
      >
        <el-form-item v-if="renderPresetFormErrors.length">
          <el-alert
            v-for="(error, index) in renderPresetFormErrors"
            :key="index"
            :title="error"
            :closable="false"
            type="error"
            class="error"
          ></el-alert>
        </el-form-item>
        <el-form-item label="Name">
          <el-input placeholder="Name" v-model="renderPresetModel.name"></el-input>
        </el-form-item>
        <el-form-item class="submit">
          <el-button @click="closeRenderPresetDialog">Cancel</el-button>
          <el-button type="primary" native-type="submit" :disabled="renderPresetFormSubmit">Save</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'render-preset-dialog',
  data() {
    return {
      renderPresetDialogVisible: false,
      renderPresetFormSubmit: false,
      renderPresetFormSubmitTimeout: null,
      renderPresetFormErrors: [],
      renderPresetModel: {
        id: null,
        name: null,
        meta: {
          templateTypeId: null,
          rushIds: [],
          templateIds: [],
          wordingIds: [],
          networkFormatIds: [],
          languageIds: [],
        },
      },
    };
  },
  beforeDestroy() {
    clearTimeout(this.renderPresetFormSubmitTimeout);
  },
  methods: {
    ...mapActions('renderPreset', ['addRenderPreset', 'editRenderPreset']),
    openRenderPresetDialog(renderPreset = null) {
      this.resetRenderPresetModel(renderPreset);
      this.renderPresetDialogVisible = true;
    },
    resetRenderPresetModel(renderPreset = null) {
      this.renderPresetFormErrors = [];
      if (renderPreset) {
        this.renderPresetModel = { ...renderPreset };
      } else {
        this.renderPresetModel = {
          id: null,
          name: null,
          meta: {
            templateTypeId: null,
            rushIds: [],
            templateIds: [],
            wordingIds: [],
            networkFormatIds: [],
            languageIds: [],
          },
        };
      }
    },
    closeRenderPresetDialog() {
      this.resetRenderPresetModel();
      this.renderPresetDialogVisible = false;
    },
    renderPresetFormValidation() {
      let valid = true;
      this.renderPresetFormErrors = [];
      if (!this.renderPresetModel.name) {
        this.renderPresetFormErrors.push('Name required');
        valid = false;
      }
      return valid;
    },
    async submitRenderPresetForm() {
      this.renderPresetFormSubmit = true;
      this.renderPresetFormSubmitTimeout = setTimeout(async () => {
        if (this.renderPresetFormValidation()) {
          if (this.renderPresetModel.id) {
            await this.editRenderPreset(this.renderPresetModel);
          } else {
            await this.addRenderPreset(this.renderPresetModel);
          }
          this.closeRenderPresetDialog();
        }
        this.renderPresetFormSubmit = false;
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.renderPresetDialog {
  display: block;

  .form {
    .error {
      margin-bottom: 10px;
    }

    .submit {
      text-align: right;
      margin: 0;
    }
  }

  /deep/.el-dialog {
    .el-dialog__header {
      margin: 0;
      padding: 15px;
      border-bottom: 1px solid #ebeef5;
    }

    .el-dialog__body {
      padding: 15px;
    }
  }
}
</style>

