import Vue from 'vue';
import Vuex from 'vuex';
import gql from 'graphql-tag';
import { apolloClient } from '../../utils/vue-apollo';

Vue.use(Vuex);

const GRAPHQL_RENDER_REQUEST_PROPS = `
  id
  name
  type
  usage
  validated
  userEmail
  progress {
    tasks
    percentage
  }
  renders {
    id
    rush {
      game {
        id
        appId
        name
        code
        uaEmail
      }
    }
    network {
      id
      name
      emails
    }
    outputDocument {
      url
    }
  }
  autoCMRenders {
    id
    game {
      id
      appId
      name
      code
      uaEmail
    }
    network {
      id
      name
      emails
    }
    outputDocument {
      url
    }
  }
  createdAt
`;

const GRAPHQL = {
  queries: {
    fetchRenderRequests: gql`
      query($type: String!) {
        renderRequests(type: $type) {
          ${GRAPHQL_RENDER_REQUEST_PROPS}
        }
      }
    `,
    fetchRenderRequestsByPage: gql`
      query($input: RenderRequestsByPageInput!) {
        renderRequestsByPage(input: $input) {
          elements {
            ${GRAPHQL_RENDER_REQUEST_PROPS}
          }
          total
        }
      }
    `,
    fetchRenderRequest: gql`
      query($id: Int!) {
        renderRequest(id: $id) {
          ${GRAPHQL_RENDER_REQUEST_PROPS}
        }
      }
    `,
    forceRerender: gql`
      query($id: Int!) {
        forceRerender(id: $id)
      }
    `,
    validateRenderRequest: gql`
      query($input: ValidateRenderRequestInput!) {
        validateRenderRequest(input: $input) {
          ${GRAPHQL_RENDER_REQUEST_PROPS}
        }
      }
    `,
    uploadRenderRequest: gql`
      query($input: UploadRenderRequestInput!) {
        uploadRenderRequest(input: $input) {
          ${GRAPHQL_RENDER_REQUEST_PROPS}
        }
      }
    `,
    fetchDataCsv: gql`
      query($id: Int!) {
        fetchDataCsv(id: $id) {
          name
          rows
        }
      }
    `,
  },
};

export default {
  namespaced: true,
  state: {
    elements: [],
    total: 0,
    loading: false,
    autoCM: {
      elements: [],
      total: 0,
    },
    endcard: {
      elements: [],
      total: 0,
    },
    static: {
      elements: [],
      total: 0,
    },
  },
  mutations: {
    setRenderRequests(state, { elements, total = 0 }) {
      state.elements = elements;
      state.total = total;
      state.loading = false;
    },
    setAutoCMRenderRequests(state, { elements, total = 0 }) {
      state.autoCM.elements = elements;
      state.autoCM.total = total;
      state.loading = false;
    },
    setEndcardRenderRequests(state, { elements, total = 0 }) {
      state.endcard.elements = elements;
      state.endcard.total = total;
      state.loading = false;
    },
    setStaticRenderRequests(state, { elements, total = 0 }) {
      state.static.elements = elements;
      state.static.total = total;
      state.loading = false;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
  },
  actions: {
    async fetchRenderRequest({ commit }, { id }) {
      commit('setLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchRenderRequest,
          variables: {
            id,
          },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      commit('setLoading', false);
      return (results && results.data && results.data.renderRequest) || null;
    },
    async fetchRenderRequests({ commit }) {
      commit('setLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchRenderRequests,
          variables: {
            type: 'render',
          },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const renderRequests = results && results.data && results.data.renderRequests ? results.data.renderRequests : [];
      commit('setRenderRequests', { elements: renderRequests, total: renderRequests.length });
    },
    async fetchRenderRequestsByPage({ commit }, { page = 1, pageSize = 1, options = {} }) {
      commit('setLoading', true);
      const optionsStr = JSON.stringify(options);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchRenderRequestsByPage,
          variables: {
            input: {
              type: 'render',
              page,
              pageSize,
              options: optionsStr,
            },
          },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const renderRequests =
        results && results.data && results.data.renderRequestsByPage ? results.data.renderRequestsByPage : {};
      commit('setRenderRequests', renderRequests);
    },
    async fetchAutoCMRenderRequests({ commit }) {
      commit('setLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchRenderRequests,
          variables: {
            type: 'autocm',
          },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const renderRequests = results && results.data && results.data.renderRequests ? results.data.renderRequests : [];
      commit('setAutoCMRenderRequests', { elements: renderRequests, total: renderRequests.length });
    },
    async fetchAutoCMRenderRequestsByPage({ commit }, { page = 1, pageSize = 1, options = {} }) {
      commit('setLoading', true);
      const optionsStr = JSON.stringify(options);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchRenderRequestsByPage,
          variables: {
            input: {
              type: 'autocm',
              page,
              pageSize,
              options: optionsStr,
            },
          },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const renderRequests =
        results && results.data && results.data.renderRequestsByPage ? results.data.renderRequestsByPage : {};
      commit('setAutoCMRenderRequests', renderRequests);
    },
    async fetchEndcardRenderRequests({ commit }) {
      commit('setLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchRenderRequests,
          variables: {
            type: 'endcard',
          },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const renderRequests = results && results.data && results.data.renderRequests ? results.data.renderRequests : [];
      commit('setEndcardRenderRequests', { elements: renderRequests, total: renderRequests.length });
    },
    async fetchEndcardRenderRequestsByPage({ commit }, { page = 1, pageSize = 1, options = {} }) {
      commit('setLoading', true);
      const optionsStr = JSON.stringify(options);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchRenderRequestsByPage,
          variables: {
            input: {
              type: 'endcard',
              page,
              pageSize,
              options: optionsStr,
            },
          },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const renderRequests =
        results && results.data && results.data.renderRequestsByPage ? results.data.renderRequestsByPage : {};
      commit('setEndcardRenderRequests', renderRequests);
    },
    async fetchStaticRenderRequestsByPage({ commit }, { page = 1, pageSize = 1, options = {} }) {
      commit('setLoading', true);
      const optionsStr = JSON.stringify(options);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchRenderRequestsByPage,
          variables: {
            input: {
              type: 'static',
              page,
              pageSize,
              options: optionsStr,
            },
          },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const renderRequests =
        results && results.data && results.data.renderRequestsByPage ? results.data.renderRequestsByPage : {};
      commit('setStaticRenderRequests', renderRequests);
    },
    async forceRerender({ commit }, { id }) {
      commit('setLoading', true);
      await apolloClient
        .query({
          query: GRAPHQL.queries.forceRerender,
          variables: {
            id,
          },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      commit('setLoading', false);
    },
    async validateRenderRequest({ commit }, { id }) {
      commit('setLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.validateRenderRequest,
          variables: {
            input: {
              id,
            },
          },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      commit('setLoading', false);
      return (results && results.data && results.data.validateRenderRequest) || null;
    },
    async uploadRenderRequest({ commit }, { id, emailData, renderGroups }) {
      commit('setLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.uploadRenderRequest,
          variables: {
            input: {
              id,
              emailData,
              renderGroups,
            },
          },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      commit('setLoading', false);
      return (results && results.data && results.data.uploadRenderRequest) || null;
    },
    async fetchDataCsv({ commit }, { id }) {
      commit('setLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchDataCsv,
          variables: {
            id,
          },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const dataCsv = results && results.data && results.data.fetchDataCsv ? results.data.fetchDataCsv : null;
      commit('setLoading', false);
      return dataCsv;
    },
  },
};
