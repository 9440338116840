module.exports = {
  jp: 'ja',
  'cn-si': 'zh-cn',
  sp: 'es',
  br: 'pt-br',
  kr: 'ko',
  hin: 'hi',
  hk: 'zh-hk',
  tw: 'zh-tw',
  vt: 'vi',
  se: 'sv',
  'cn-tr': 'zh-tr',
  il: 'he',
};
