<template>
  <div class="upload">
    <el-upload
      v-loading="loading > 0"
      :drag="true"
      :multiple="false"
      :show-file-list="false"
      :auto-upload="true"
      :before-upload="onBeforeUpload"
      :on-success="onUploadSuccess"
      :action="uploadUrl"
      :with-credentials="true"
      :headers="requestHeaders"
      :http-request="httpRequest"
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">
        Drop file here or
        <em>click to submit</em>
      </div>
    </el-upload>
    <div class="upload-infos">
      <div class="upload-size">File size under 500mb</div>
      <a
        v-if="document && document.url"
        class="el-button--text upload-link"
        target="_blank"
        :href="document.url"
      >{{ file.name }}</a>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Config from '../../services/config.service';
import correlationHeader from '../../utils/correlation-header';

export default {
  name: 'vd-upload',
  props: {
    uploadPath: String,
  },
  data() {
    return {
      uploadUrl: '',
      requestHeaders: {},
      file: null,
      document: null,
      loading: 0,
    };
  },
  async mounted() {
    this.uploadUrl = `${Config.apiUrl}/${
      this.uploadPath ? this.uploadPath : 'documents/upload'
    }`;

    this.requestHeaders = { authorization: `Bearer ${this.$auth.getAccessToken()}`, ...correlationHeader() };
  },
  methods: {
    resetUpload() {
      this.file = null;
      this.document = null;
      this.loading = 0;
    },
    onBeforeUpload(file) {
      this.file = file;
      this.loading += 1;
      return true;
    },
    onUploadSuccess(response, file) {
      this.file = null;
      this.document = null;
      if (file && file.response) {
        this.file = file;
        this.document = file.response;
        this.$emit('upload', this.document);
      }
      this.loading -= 1;
    },
    async httpRequest(upload) {
      const formData = new FormData();
      formData.append('file', upload.file);
      axios.defaults.withCredentials = true;
      return axios.post(upload.action, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          ...upload.headers,
          ...correlationHeader(),
        },
      }).then(res => ((res && res.data) || null)).catch(() => null);
    },
  },
};
</script>

<style lang="scss" scoped>
.upload {
  display: block;

  /deep/.el-upload {
    width: 100%;

    .el-upload-dragger {
      width: 100%;
    }
  }

  .upload-infos {
    line-height: 20px;

    .upload-size {
      font-size: 11px;
    }

    .upload-link {
      font-size: 12px;
      margin-right: 12px;
    }
  }
}
</style>

