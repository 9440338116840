const networksByPriority = [
  'facebook',
  'applovin',
  'tiktok',
  'snapchat',
  'adwords',
  'mintegral',
  'ironsource',
  'unity',
  'vungle',
  'moblike',
  'jetfuel',
  'crosspromo',
];

export function networkIndex(network) {
  let index = networksByPriority.indexOf(network.name.toLowerCase());

  index = index !== -1 ? index : 99;

  return index;
}

export function compareByNetworkPriority(network1, network2) {
  return networkIndex(network1) - networkIndex(network2);
}
