<template>
  <section id="app">
    <vd-app-header v-if="showHeader"></vd-app-header>
    <div class="app-container">
      <router-view></router-view>
    </div>
  </section>
</template>

<script>
import VdAppHeader from '@/components/vd-app-header/VdAppHeader.vue';

export default {
  name: 'app',
  data() {
    return {};
  },
  computed: {
    isAuthenticated() {
      return this.authState.isAuthenticated;
    },
    showHeader() {
      return this.authState.isAuthenticated && this.$route.name !== 'login';
    },
  },
  components: {
    VdAppHeader,
  },
  watch: {
    async isAuthenticated(newVal) {
      if (newVal) {
        const user = await this.$auth.getUser();

        this.$store.commit('auth/setUser', user);
      }
    },
  },
};
</script>

<style lang="scss">
@import './assets/style/_variables';

html,
body,
#app {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  font-family: $app_font_family;
  font-weight: 100;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $app_color_main;
  background-color: #f0f1f2;
}

#app {
  width: 100vw;
  height: 100vh;

  .app-container {
    height: calc(100vh - 156px);
    padding: 15px;
    overflow: auto;
  }
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-thumb {
  background: #c0c0c1;
  border-radius: 16px;
}

*::-webkit-scrollbar-track {
  background: rgba(192, 192, 192, 0.1);
}

</style>
