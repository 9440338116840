import Vue from 'vue';
import Vuex from 'vuex';
import gql from 'graphql-tag';
import { apolloClient } from '../../utils/vue-apollo';

Vue.use(Vuex);

const GRAPHQL_RATIO_PROPS = `
  id
  name
`;

const GRAPHQL = {
  queries: {
    fetchRatios: gql`
      query {
        ratios {
          ${GRAPHQL_RATIO_PROPS}
        }
      }
    `,
    fetchRatiosByPage: gql`
      query($input: RatiosByPageInput!) {
        ratiosByPage(input: $input) {
          elements {
            ${GRAPHQL_RATIO_PROPS}
          }
          total
        }
      }
    `,
  },
  mutations: {
    addRatio: gql`
      mutation($input: AddRatioInput!) { 
        addRatio(input: $input) {
          ${GRAPHQL_RATIO_PROPS}
        }
      }
    `,
    editRatio: gql`
      mutation($input: EditRatioInput!) { 
        editRatio(input: $input) {
          ${GRAPHQL_RATIO_PROPS}
        }
      }
    `,
    deleteRatio: gql`
      mutation($input: DeleteRatioInput!) {
        deleteRatio(input: $input)
      }
    `,
  },
};

export default {
  namespaced: true,
  state: {
    elements: [],
    total: 0,
    loading: false,
  },
  mutations: {
    setRatios(state, { elements, total = 0 }) {
      state.elements = elements;
      state.total = total;
      state.loading = false;
    },
    pushRatio(state, item) {
      state.elements.push(item);
      state.total += 1;
      state.loading = false;
    },
    updateRatio(state, item) {
      const index = state.elements.findIndex(elem => elem.id === item.id);
      if (index !== -1) {
        state.elements[index] = item;
        state.elements = [...state.elements];
      }
      state.loading = false;
    },
    removeRatio(state, id) {
      const index = state.elements.findIndex(elem => elem.id === id);
      if (index !== -1) {
        state.elements.splice(index, 1);
        state.total -= 1;
      }
      state.loading = false;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
  },
  actions: {
    async fetchRatios({ commit }) {
      commit('setLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchRatios,
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const ratios = results && results.data && results.data.ratios ? results.data.ratios : [];
      commit('setRatios', { elements: ratios, total: ratios.length });
    },
    async fetchRatiosByPage({ commit }, { page = 1, pageSize = 1, options = {} }) {
      commit('setLoading', true);
      const optionsStr = JSON.stringify(options);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchRatiosByPage,
          variables: {
            input: {
              page,
              pageSize,
              options: optionsStr,
            },
          },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const ratios = results && results.data && results.data.ratiosByPage ? results.data.ratiosByPage : {};
      commit('setRatios', ratios);
    },
    async addRatio({ commit }, { name }) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.addRatio,
          variables: {
            input: { name },
          },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const ratio = results && results.data && results.data.addRatio ? results.data.addRatio : null;
      if (ratio) {
        commit('pushRatio', ratio);
      } else {
        commit('setLoading', false);
      }
    },
    async editRatio({ commit }, { id, name }) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.editRatio,
          variables: {
            input: { id, name },
          },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const ratio = results && results.data && results.data.editRatio ? results.data.editRatio : null;
      if (ratio) {
        commit('updateRatio', ratio);
      } else {
        commit('setLoading', false);
      }
    },
    async deleteRatio({ commit }, { id }) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.deleteRatio,
          variables: { input: { id } },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const deleted = results && results.data && results.data.deleteRatio;
      if (deleted) {
        commit('removeRatio', id);
      } else {
        commit('setLoading', false);
      }
    },
  },
};
