import Vue from 'vue';
import Vuex from 'vuex';
import gql from 'graphql-tag';
import { apolloClient } from '../../utils/vue-apollo';

Vue.use(Vuex);

const GRAPHQL_APP_PROPS = `
  id
  name
  iconUrl
  marketingCode
  business
  genre
  appStoreUrl
  playStoreUrl
`;

const GRAPHQL = {
  queries: {
    fetchAppByGameAppId: gql`
      query($appId: String!) {
        app(appId: $appId) {
          ${GRAPHQL_APP_PROPS}
        }
      }
    `,
    fetchApps: gql`
      query($input: SearchAppsInput!) {
        searchApps(input: $input) {
          ${GRAPHQL_APP_PROPS}
        }
      }
    `,
  },
};

export default {
  namespaced: true,
  state: {
    elements: [],
    total: 0,
    loading: false,
  },
  mutations: {
    setApps(state, { elements, total = 0 }) {
      state.elements = elements;
      state.total = total;
      state.loading = false;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
  },
  actions: {
    async fetchAppByGameAppId({ commit }, { appId }) {
      commit('setLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchAppByGameAppId,
          variables: { appId },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const app = results && results.data && results.data.app ? results.data.app : null;
      commit('setLoading', false);
      return app;
    },
    async fetchApps({ commit }, { name, isVoodoo, isPublished }) {
      commit('setLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchApps,
          variables: {
            input: {
              name,
              isVoodoo,
              isPublished,
            },
          },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const apps = results && results.data && results.data.searchApps ? results.data.searchApps : [];
      const appsSorted = apps.concat().sort((a, b) => {
        const textA = a.name;
        const textB = b.name;
        if (textA < textB) {
          return -1;
        }
        if (textA > textB) {
          return 1;
        }
        return 0;
      });
      commit('setApps', { elements: appsSorted, total: appsSorted.length });
    },
  },
};
