import Vue from 'vue';
import Vuex from 'vuex';
import gql from 'graphql-tag';
import { apolloClient } from '../../utils/vue-apollo';

Vue.use(Vuex);

const GRAPHQL_MANUAL_RENDER_PROPS = `
  id
  name
  game {
    id
    appId
    name
    code
    uaEmail
  }
  adNumber
  rushNumber
  network {
    id
    name
  }
  format {
    id
    name
    width
    height
    time
  }
  language {
    id
    name
  }
  renderDocument {
    id
    key
    url
  }
  outputDocument {
    id
    key
    url
  }
  createdAt
  updatedAt
`;

const GRAPHQL = {
  queries: {
    fetchManualRenders: gql`
      query {
        manualRenders {
          ${GRAPHQL_MANUAL_RENDER_PROPS}
        }
      }
    `,
    fetchManualRendersByPage: gql`
      query($input: ManualRendersByPageInput!) {
        manualRendersByPage(input: $input) {
          elements {
            ${GRAPHQL_MANUAL_RENDER_PROPS}
          }
          total
        }
      }
    `,
  },
  mutations: {
    uploadManualRenders: gql`
      mutation($input: UploadManualRendersInput!) {
        uploadManualRenders(input: $input)
      }
    `,
    uploadManualRendersToCampaign: gql`
      mutation($input: UploadManualRendersToCampaignInput!) {
        uploadManualRendersToCampaign(input: $input)
      }
    `,
  },
};

export default {
  namespaced: true,
  state: {
    elements: [],
    total: 0,
    loading: false,
  },
  mutations: {
    setManualRenders(state, { elements, total = 0 }) {
      state.elements = elements;
      state.total = total;
      state.loading = false;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
  },
  actions: {
    async fetchManualRenders({ commit }) {
      commit('setLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchManualRenders,
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const renders = results && results.data && results.data.manualRenders ? results.data.manualRenders : [];
      commit('setManualRenders', { elements: renders, total: renders.length });
    },
    async fetchManualRendersByPage({ commit }, { page = 1, pageSize = 1, options = {} }) {
      commit('setLoading', true);
      const optionsStr = JSON.stringify(options);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchManualRendersByPage,
          variables: {
            input: {
              page,
              pageSize,
              options: optionsStr,
            },
          },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const renders =
        results && results.data && results.data.manualRendersByPage ? results.data.manualRendersByPage : {};
      commit('setManualRenders', renders);
    },
    async uploadManualRenders({ commit }, { renders }) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.uploadManualRenders,
          variables: { input: { renders } },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      commit('setLoading', false);
      return (results && results.data && results.data.uploadManualRenders) || null;
    },
    async uploadManualRendersToCampaign({ commit }, { elements, emailData }) {
      commit('setLoading', true);
      const elementsStr = JSON.stringify(elements);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.uploadManualRendersToCampaign,
          variables: { input: { emailData, elements: elementsStr } },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      commit('setLoading', false);
      return (results && results.data && results.data.uploadManualRendersToCampaign) || null;
    },
  },
};
