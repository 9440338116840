<template>
  <div class="staticConfigList">
    <vd-listing-header :title="title" :actions="headerActions"></vd-listing-header>
    <div class="content">
      <vd-paged-table
        tableKey="staticConfigList"
        :loading="loading"
        :data="elements"
        :totalDataSize="total"
        :paging="fetchStaticConfigsByPage"
        :actions="actions"
        :columns="columns"
        :configVisible="configVisible"
        :configClose="closeConfig"
      ></vd-paged-table>
    </div>
    <static-config-dialog ref="staticConfigDialog"></static-config-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import StaticConfigDialog from '@/views/staticConfig/StaticConfigDialog.vue';
import VdPagedTable from '@/components/vd-paged-table/VdPagedTable';
import VdListingHeader from '@/components/vd-listing-header/VdListingHeader';

export default {
  name: 'staticConfigList',
  data() {
    return {
      title: 'Static configuration list',
      headerActions: [
        {
          callback: this.openStaticConfigDialog,
          title: 'Add',
          icon: 'circle-plus-outline',
          key: 'add',
        },
        {
          callback: this.openConfig,
          title: 'Configuration',
          icon: 'setting',
          key: 'setting',
        },
      ],
      actions: [
        { callback: this.editItem, icon: 'edit', key: 'edit' },
        { callback: this.deleteItem, icon: 'delete', key: 'delete' },
      ],
      columns: [
        {
          key: 'network.name',
          prop: scope => scope.row.network.name,
          label: 'Network',
        },
        {
          key: 'format.name',
          prop: scope => scope.row.format.name,
          label: 'Format',
        },
        {
          key: 'bg',
          prop: scope => (scope.row.bg ? 'Yes' : 'No'),
          label: 'Background',
        },
        {
          key: 'icon.name',
          prop: scope => (scope.row.icon ? scope.row.icon.name : ''),
          label: 'Icon',
        },
        {
          key: 'title.name',
          prop: scope => (scope.row.title ? scope.row.title.name : ''),
          label: 'Title',
        },
        {
          key: 'iosBadge.name',
          prop: scope => (scope.row.iosBadge ? scope.row.iosBadge.name : ''),
          label: 'Ios Badge',
        },
        {
          key: 'googleBadge.name',
          prop: scope => (scope.row.googleBadge ? scope.row.googleBadge.name : ''),
          label: 'Google Badge',
        },
        {
          key: 'cta.name',
          prop: scope => (scope.row.cta ? scope.row.cta.name : ''),
          label: 'CTA',
        },
      ],
      configVisible: false,
    };
  },
  components: {
    StaticConfigDialog,
    VdPagedTable,
    VdListingHeader,
  },
  computed: {
    ...mapState('staticConfig', ['loading', 'elements', 'total']),
  },
  methods: {
    ...mapActions('staticConfig', [
      'fetchStaticConfigsByPage',
      'deleteStaticConfig',
    ]),
    openStaticConfigDialog() {
      this.$refs.staticConfigDialog.openStaticConfigDialog();
    },
    editItem(element) {
      this.$refs.staticConfigDialog.openStaticConfigDialog(element);
    },
    deleteItem(element) {
      this.$confirm('Do you really want to delete this configuration ?')
        .then(async () => {
          await this.deleteStaticConfig(element);
        })
        .catch(() => {});
    },
    openConfig() {
      this.configVisible = true;
    },
    closeConfig() {
      this.configVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.staticConfigList {
  display: block;

  .content {
    .table {
      width: 100%;
    }
  }
}
</style>

