import Vue from 'vue';
import Vuex from 'vuex';
import gql from 'graphql-tag';
import { apolloClient } from '../../utils/vue-apollo';

Vue.use(Vuex);

const GRAPHQL_ENDCARD_TEMPLATE_PROPS = `
  id
  name
  adwordsDocument {
    id
    key
    url
  }
  applovinDocument {
    id
    key
    url
  }
  facebookDocument {
    id
    key
    url
  }
  ironsourceDocument {
    id
    key
    url
  }
  tapjoyDocument {
    id
    key
    url
  }
  unityDocument {
    id
    key
    url
  }
  vungleDocument {
    id
    key
    url
  }
  voodooDocument {
    id
    key
    url
  }
  config {
    id
    key
    type
    name
  }
`;

const GRAPHQL = {
  queries: {
    fetchEndcardTemplates: gql`
      query {
        endcardTemplates {
          ${GRAPHQL_ENDCARD_TEMPLATE_PROPS}
        }
      }
    `,
  },
};

export default {
  namespaced: true,
  state: {
    elements: [],
    total: 0,
    loading: false,
  },
  mutations: {
    setEndcardTemplates(state, { elements, total = 0 }) {
      state.elements = elements;
      state.total = total;
      state.loading = false;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
  },
  actions: {
    async fetchEndcardTemplates({ commit }) {
      commit('setLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchEndcardTemplates,
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const endcardTemplates =
        results && results.data && results.data.endcardTemplates ? results.data.endcardTemplates : [];
      commit('setEndcardTemplates', { elements: endcardTemplates, total: endcardTemplates.length });
    },
  },
};
