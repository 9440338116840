<template>
  <div class="property">
    <span class="title">{{title + ' : '}}</span>
    <span class="value" v-if="value && type !== 'link'">{{value}}</span>
    <span v-if="values && type !== 'link'">
      <span class="value" v-for="oneValue of values" v-bind:key="oneValue">{{oneValue}}</span>
    </span>
    <a class="el-button--text" v-if="value && type === 'link'" :href="value">{{value}}</a>
    <span v-if="values && type === 'link'">
      <a
        class="el-button--text"
        v-for="oneValue of values"
        v-bind:key="oneValue"
        :href="oneValue"
      >{{oneValue}}</a>
    </span>
  </div>
</template>

<script>
export default {
  name: 'vd-property',
  props: {
    title: String,
    values: Array,
    // eslint-disable-next-line
    value: String | Number,
    type: String,
  },
  data() {
    return {};
  },
  methods: {
    getPropString() {
      return JSON.stringify(this.$props);
    },
  },
};
</script>

<style lang="scss" scoped>
.property {
  display: block;
  padding: 8px;

  .title {
    font-size: 14px;
    font-weight: 600;
  }

  .value {
    font-size: 12px;
    font-weight: 400;
    margin: 4px;
    padding: 4px;
  }
}
</style>

