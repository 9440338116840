import Vue from 'vue';
import Vuex from 'vuex';
import gql from 'graphql-tag';
import { apolloClient } from '../../utils/vue-apollo';

Vue.use(Vuex);

const GRAPHQL_GAME_PROPS = `
  id
  appId
  orientationId
  mechanicId
  code
  name
  genre
  business
  uaEmail
  orientation {
    id
    name
  }
  mechanic {
    id
    name
  }
  static {
    icon
  }
`;

const GRAPHQL_DOCUMENT_PROPS = `
  id
  key
  url
  fileName
  fileSize
  fileType
`;

const GRAPHQL_GAME_STATIC_PROPS = `
  id
  static {
    cta {
      ${GRAPHQL_DOCUMENT_PROPS}
    }
    googleBadge {
      id
      name
      document {
        ${GRAPHQL_DOCUMENT_PROPS}
      }
    }
    icon
    iosBadge {
      id
      name
      document {
        ${GRAPHQL_DOCUMENT_PROPS}
      }
    }
    landscape {
      ${GRAPHQL_DOCUMENT_PROPS}
    }
    logo {
      image {
        ${GRAPHQL_DOCUMENT_PROPS}
      }
      font {
        id
      }
      wording
    }
    portrait {
      ${GRAPHQL_DOCUMENT_PROPS}
    }
    square {
      ${GRAPHQL_DOCUMENT_PROPS}
    }
  }
`;

const GRAPHQL_DASHBOARD_GAME_STATIC_PROPS = `
  game {
    ${GRAPHQL_GAME_PROPS}
  }
  renders {
    id
    name
    renderDocument {
      ${GRAPHQL_DOCUMENT_PROPS}
    }
  }
  endcards {
    id
    name
    document {
      ${GRAPHQL_DOCUMENT_PROPS}
    }
  }
  rushes {
    id
    name
    document {
      ${GRAPHQL_DOCUMENT_PROPS}
    }
  }
  statics {
    id
    name
    document {
      ${GRAPHQL_DOCUMENT_PROPS}
    }
  }
  endcardCount
  staticCount
  rushCount
  renderCount
`;

const GRAPHQL = {
  queries: {
    fetchGames: gql`
      query {
        games {
          ${GRAPHQL_GAME_PROPS}
        }
      }
    `,
    fetchGame: gql`
      query($id: Int!) {
        game(id: $id) {
          ${GRAPHQL_GAME_PROPS}
        }
      }
    `,
    fetchGamesByPage: gql`
      query( $pagination: Pagination!, $fieldsCondition: GameFieldsCondition, $fieldOrders: [FieldOrder] ) {
        gamesByPage(pagination: $pagination, fieldsCondition: $fieldsCondition, fieldOrders: $fieldOrders) {
          elements {
            ${GRAPHQL_GAME_PROPS}
          }
          total
        }
      }
    `,
    refreshStaticsByGame: gql`
      query($input: Int!) {
        refreshStaticsByGame(id: $input)
      }
    `,
    fetchStaticParamsByGameId: gql`
      query($id: Int!) {
        game(id: $id) {
          ${GRAPHQL_GAME_STATIC_PROPS}
        }
      }
    `,
    fetchDashboardGame: gql`
      query($id: Int!) {
        dashboardGame(id: $id) {
          ${GRAPHQL_DASHBOARD_GAME_STATIC_PROPS}
        }
      }
    `,
  },
  mutations: {
    addGame: gql`
      mutation($input: AddGameInput!) {
        addGame(input: $input) {
          ${GRAPHQL_GAME_PROPS}
        }
      }
    `,
    deleteGame: gql`
      mutation($input: DeleteGameInput!) {
        deleteGame(input: $input)
      }
    `,
    editGame: gql`
      mutation($input: EditGameInput!) {
        editGame(input: $input) {
          ${GRAPHQL_GAME_PROPS}
        }
      }
    `,
    updateStatic: gql`
      mutation($input: UpdateStaticInput!) {
        updateStatic(input: $input) {
          ${GRAPHQL_GAME_STATIC_PROPS}
        }
      }
    `,
  },
};

function documentThis(doc) {
  if (!doc) return null;
  return {
    id: doc.id,
    key: doc.key,
    url: doc.url,
    fileName: doc.fileName,
    fileType: doc.fileType,
    fileSize: doc.fileSize,
  };
}

function getLogo(logo) {
  if (!logo) return null;
  if (logo.logoSwitch) {
    return {
      fontId: logo.fontId,
      text: logo.text,
    };
  }
  return {
    image: documentThis(logo.image),
  };
}

export default {
  namespaced: true,
  state: {
    elements: [],
    total: 0,
    loading: false,
    game: null,
    dashboardGame: null,
  },
  mutations: {
    setGames(state, { elements, total = 0 }) {
      state.elements = elements;
      state.total = total;
      state.loading = false;
    },
    setGame(state, { game }) {
      state.game = game;
      state.loading = false;
    },
    setDashboardGame(state, { dashboardGame }) {
      state.dashboardGame = dashboardGame;
      state.loading = false;
    },
    pushGame(state, item) {
      state.elements.push(item);
      state.total += 1;
      state.loading = false;
    },
    updateGame(state, item) {
      const index = state.elements.findIndex(elem => elem.id === item.id);
      if (index !== -1) {
        state.elements[index] = item;
        state.elements = [...state.elements];
      }
      state.loading = false;
    },
    removeGame(state, id) {
      const index = state.elements.findIndex(elem => elem.id === id);
      if (index !== -1) {
        state.elements.splice(index, 1);
        state.total -= 1;
      }
      state.loading = false;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
  },
  actions: {
    async fetchGames({ commit }) {
      commit('setLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchGames,
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });

      const games = results && results.data && results.data.games ? results.data.games : {};
      commit('setGames', { elements: games, total: games.length });
    },
    async fetchGame({ commit }, { id }) {
      commit('setLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchGame,
          variables: {
            id,
          },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });

      const game = results && results.data && results.data.game ? results.data.game : {};
      commit('setGame', { game });
    },
    async fetchDashboardGame({ commit }, { id }) {
      commit('setLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchDashboardGame,
          variables: {
            id,
          },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });

      const dashboardGame = results && results.data && results.data.dashboardGame ? results.data.dashboardGame : {};
      commit('setDashboardGame', { dashboardGame });
    },
    async fetchGamesByPage({ commit }, { page = 1, pageSize = 1, options = { where: {}, order: [] } }) {
      commit('setLoading', true);
      const { where, order: orders } = options;
      const pagination = { page, pageSize };
      const fieldsCondition = {
        name: where.name,
        code: where.code,
        appId: where.appId,
        uaEmail: where.uaEmail,
        orientation: { name: where['orientation.name'] },
        mechanic: { name: where['mechanic.name'] },
      };
      const fieldOrders = orders.map(order => ({ field: order[0], sortOrder: order[1] }));
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchGamesByPage,
          variables: {
            pagination,
            fieldsCondition,
            fieldOrders,
          },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const games = results && results.data && results.data.gamesByPage ? results.data.gamesByPage : {};
      commit('setGames', games);
    },
    async addGame(
      { commit },
      {
        appId,
        orientationId,
        mechanicId,
        code,
        name,
        genre,
        business,
        uaEmail,
        staticParams: {
          generate, cta, googleBadgeId, icon, iosBadgeId, landscape, logo, portrait, square,
        },
      },
    ) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.addGame,
          variables: {
            input: {
              appId,
              orientationId,
              mechanicId,
              code,
              name,
              genre,
              business,
              uaEmail,
              staticParams: {
                generate,
                cta: documentThis(cta),
                googleBadgeId,
                icon,
                iosBadgeId,
                landscape: documentThis(landscape),
                logo: getLogo(logo),
                portrait: documentThis(portrait),
                square: documentThis(square),
              },
            },
          },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const game = results && results.data && results.data.addGame ? results.data.addGame : null;
      if (game) {
        commit('pushGame', game);
      } else {
        commit('setLoading', false);
      }
    },
    async editGame(
      { commit },
      {
        id,
        appId,
        orientationId,
        mechanicId,
        code,
        name,
        genre,
        business,
        uaEmail,
        staticParams: {
          generate, cta, googleBadgeId, icon, iosBadgeId, landscape, logo, portrait, square,
        },
      },
    ) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.editGame,
          variables: {
            input: {
              id,
              appId,
              orientationId,
              mechanicId,
              code,
              name,
              genre,
              business,
              uaEmail,
              staticParams: {
                generate,
                cta: documentThis(cta),
                googleBadgeId,
                icon,
                iosBadgeId,
                landscape: documentThis(landscape),
                logo: getLogo(logo),
                portrait: documentThis(portrait),
                square: documentThis(square),
              },
            },
          },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const game = results && results.data && results.data.editGame ? results.data.editGame : null;
      if (game) {
        commit('updateGame', game);
      } else {
        commit('setLoading', false);
      }
    },
    async deleteGame({ commit }, { id }) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.deleteGame,
          variables: {
            input: { id },
          },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const deleted = results && results.data && results.data.deleteGame;
      if (deleted) {
        commit('removeGame', id);
      } else {
        commit('setLoading', false);
      }
    },
    async refreshStaticsByGame({ commit }, { id }) {
      commit('setLoading', true);
      await apolloClient
        .query({
          query: GRAPHQL.queries.refreshStaticsByGame,
          variables: { input: id },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      commit('setLoading', false);
    },
    async fetchStaticParamsByGameId({ commit }, { id }) {
      commit('setLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchStaticParamsByGameId,
          variables: { id },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });

      const staticParams = results && results.data && results.data.game ? results.data.game : {};
      commit('setLoading', false);
      return staticParams;
    },
    async editStatic(
      { commit },
      {
        gameId,
        staticParams: {
          cta, landscape, portrait, square, logo,
        },
        generate,
      },
    ) {
      commit('setLoading', true);
      const result = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.updateStatic,
          variables: {
            input: {
              gameId,
              static: {
                cta: documentThis(cta),
                landscape: documentThis(landscape),
                logo: getLogo(logo),
                portrait: documentThis(portrait),
                square: documentThis(square),
              },
              generate,
            },
          },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      commit('setLoading', false);
      return result;
    },
  },
};
