<template>
  <div class="classificationDialog">
    <el-dialog
      :close-on-click-modal="false"
      :before-close="closeClassificationDialog"
      title="Classification"
      :visible.sync="classificationDialogVisible"
      width="50%"
    >
      <el-form
        class="form"
        ref="classificationModel"
        label-width="120px"
        :model="classificationModel"
        @submit.native.prevent="submitClassificationForm"
      >
        <el-form-item v-if="classificationFormErrors.length">
          <el-alert
            v-for="(error, index) in classificationFormErrors"
            :key="index"
            :title="error"
            :closable="false"
            type="error"
            class="error"
          ></el-alert>
        </el-form-item>
        <el-form-item label="Name">
          <el-input placeholder="Name" v-model="classificationModel.name"></el-input>
        </el-form-item>
        <el-form-item class="submit">
          <el-button @click="closeClassificationDialog">Cancel</el-button>
          <el-button type="primary" native-type="submit" :disabled="classificationFormSubmit">Save</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'classification-dialog',
  data() {
    return {
      classificationDialogVisible: false,
      classificationFormSubmit: false,
      classificationFormSubmitTimeout: null,
      classificationFormErrors: [],
      classificationModel: {
        id: null,
        name: null,
      },
    };
  },
  beforeDestroy() {
    clearTimeout(this.classificationFormSubmitTimeout);
  },
  methods: {
    ...mapActions('classification', [
      'addClassification',
      'editClassification',
    ]),
    openClassificationDialog(classification = null) {
      this.resetClassificationModel(classification);
      this.classificationDialogVisible = true;
    },
    resetClassificationModel(classification = null) {
      this.classificationFormErrors = [];
      if (classification) {
        this.classificationModel = { ...classification };
      } else {
        this.classificationModel = {
          id: null,
          name: null,
        };
      }
    },
    closeClassificationDialog() {
      this.resetClassificationModel();
      this.classificationDialogVisible = false;
    },
    classificationFormValidation() {
      let valid = true;
      this.classificationFormErrors = [];
      if (!this.classificationModel.name) {
        this.classificationFormErrors.push('Name required');
        valid = false;
      }
      return valid;
    },
    async submitClassificationForm() {
      this.classificationFormSubmit = true;
      this.classificationFormSubmitTimeout = setTimeout(async () => {
        if (this.classificationFormValidation()) {
          if (this.classificationModel.id) {
            await this.editClassification(this.classificationModel);
          } else {
            await this.addClassification(this.classificationModel);
          }
          this.closeClassificationDialog();
        }
        this.classificationFormSubmit = false;
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.classificationDialog {
  display: block;

  .form {
    .error {
      margin-bottom: 10px;
    }

    .submit {
      text-align: right;
      margin: 0;
    }
  }

  /deep/.el-dialog {
    .el-dialog__header {
      margin: 0;
      padding: 15px;
      border-bottom: 1px solid #ebeef5;
    }

    .el-dialog__body {
      padding: 15px;
    }
  }
}
</style>

