<template>
  <div class="video-position">
    <div>
      <span>vertical offset {{ currentPosition.offsetVertical }}</span>,
      <span>horizontal offset {{ currentPosition.offsetHorizontal }}</span>
    </div>
    <video ref="video" id="video" :controls="false" crossorigin="anonymous">
      <source :src="src" type="video/mp4">
    </video>
    <div id="preview">
      <div class="clippers" v-if="currentSrc">
        <clipper-basic ref="clipper" class="clipper-left" preview="preview" :src="currentSrc" :ratio="1">
        </clipper-basic>
        <clipper-preview class="clipper-right" name="preview"></clipper-preview>
      </div>
      <div class="slider" v-if="updated">
        <el-slider
          v-model="currentTime"
          :step="0.5"
          :min="0"
          :max="duration"
          @change="onChangeCurrentTime"
          :format-tooltip="() => `Preview at ${currentTime}s`"
        ></el-slider>
      </div>
    </div>
  </div>
</template>

<script>
import Config from '../../services/config.service';

export default {
  name: 'vd-video-position',
  props: {
    document: Object,
    offsetVertical: Number,
    offsetHorizontal: Number,
  },
  data() {
    return {
      started: false,
      updated: false,
      src: null,
      duration: 0,
      currentTime: 0,
      currentSrc: null,
      currentPosition: {
        offsetVertical: this.offsetVertical || 0,
        offsetHorizontal: this.offsetHorizontal || 0,
      },
    };
  },
  async mounted() {
    if (Config.env === 'development') {
      this.src = this.document.url;
    } else {
      this.src = `${Config.s3Url}/${this.document.key}`;
    }
  },
  updated() {
    if (!this.started) {
      if (this.$refs && this.$refs.video) {
        this.$refs.video.onloadeddata = () => {
          this.started = true;
          if (this.$refs.video.duration !== this.duration) {
            this.duration = this.$refs.video.duration || 0;
            this.$refs.video.currentTime = this.duration / 2;
          }
          this.$refs.video.ontimeupdate = () => {
            setTimeout(this.canvasImage, 10);
          };
        };
      }
    }
    if (!this.updated) {
      if (this.$refs && this.$refs.clipper) {
        this.updated = true;
        this.$refs.clipper.onChange$.subscribe(() => {
          setTimeout(this.onChangePosition, 10);
        });
      }
    }
  },
  methods: {
    onChangeCurrentTime() {
      this.$refs.video.currentTime = this.currentTime || 0;
    },
    canvasImage() {
      const canvas = document.createElement('canvas');
      canvas.width = this.$refs.video.videoWidth;
      canvas.height = this.$refs.video.videoHeight;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(this.$refs.video, 0, 0, canvas.width, canvas.height);
      this.currentSrc = canvas.toDataURL('image/png');
      setTimeout(this.setUpZoom, 10);
    },
    getPositionOffsets(zoomSize) {
      let offsetHorizontal =
        this.currentPosition.offsetHorizontal * -1 -
        zoomSize / 2 +
        this.$refs.video.videoWidth / 2;
      offsetHorizontal = offsetHorizontal > 0 ? offsetHorizontal : 0;
      offsetHorizontal =
        offsetHorizontal < this.$refs.video.videoWidth ?
          offsetHorizontal :
          this.$refs.video.videoWidth;
      let offsetVertical =
        this.currentPosition.offsetVertical * -1 -
        zoomSize / 2 +
        this.$refs.video.videoHeight / 2;
      offsetVertical = offsetVertical > 0 ? offsetVertical : 0;
      offsetVertical =
        offsetVertical < this.$refs.video.videoHeight ?
          offsetVertical :
          this.$refs.video.videoHeight;
      return {
        offsetVertical,
        offsetHorizontal,
      };
    },
    setUpZoom() {
      let zoomWidth = 0;
      let zoomHeight = 0;
      let zoomSize = 0;
      if (this.$refs.video.videoWidth < this.$refs.video.videoHeight) {
        zoomWidth = 100;
        zoomHeight =
          (this.$refs.video.videoWidth / this.$refs.video.videoHeight) * 100;
        zoomSize = this.$refs.video.videoWidth;
      } else if (this.$refs.video.videoHeight < this.$refs.video.videoWidth) {
        zoomWidth =
          (this.$refs.video.videoHeight / this.$refs.video.videoWidth) * 100;
        zoomHeight = 100;
        zoomSize = this.$refs.video.videoHeight;
      } else {
        zoomWidth = 100;
        zoomHeight = 100;
        zoomSize = this.$refs.video.videoWidth || this.$refs.video.videoHeight;
      }
      this.$refs.clipper.setWH$.next({
        width: zoomWidth,
        height: zoomHeight,
      });
      const offsets = this.getPositionOffsets(zoomSize);
      this.$refs.clipper.setTL$.next({
        left: (offsets.offsetHorizontal / this.$refs.video.videoWidth) * 100,
        top: (offsets.offsetVertical / this.$refs.video.videoHeight) * 100,
      });
    },
    onChangePosition() {
      const clipper = this.$refs.clipper.getDrawPos();
      if (
        this.$refs.clipper.zoomWH$.width === 100 ||
        this.$refs.clipper.zoomWH$.height === 100
      ) {
        this.currentPosition.offsetVertical =
          Math.round(
            clipper.pos.sy +
              clipper.pos.sheight / 2 -
              this.$refs.video.videoHeight / 2,
          ) * -1;
        this.currentPosition.offsetHorizontal =
          Math.round(
            clipper.pos.sx +
              clipper.pos.swidth / 2 -
              this.$refs.video.videoWidth / 2,
          ) * -1;
        this.$emit('position', this.currentPosition);
      } else {
        this.setUpZoom();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.video-position {
  display: block;

  #video {
    display: none;
  }

  /deep/ #preview {
    width: 500px;
    height: 500px;

    .clippers {
      width: 100%;
      display: flex;
      flex-direction: row;
      background: #efefef;

      .clipper-left,
      .clipper-right {
        margin: 4px;
      }

      .clipper-right {
        .wrap {
          border: solid 1px #999;
        }
      }
    }

    .clip-area {
      .extend {
        &.outer {
          display: none;
        }

        &.inner[style] {
          padding: 0 !important;
        }
      }

      .corner {
        display: none;
      }
    }
  }
}
</style>

