import Vue from 'vue';
import Vuex from 'vuex';
import gql from 'graphql-tag';
import { apolloClient } from '../../utils/vue-apollo';

Vue.use(Vuex);

const GRAPHQL_STATIC_CONFIG_PROPS = `
  id
  network {
    id
    name
  }
  format {
    id
    name
  }
  bg
  icon {
    id
    name
    width
    height
    x
    y
  }
  title {
    id
    name
    width
    height
    x
    y
  }
  iosBadge {
    id
    name
    width
    height
    x
    y
  }
  googleBadge {
    id
    name
    width
    height
    x
    y
  }
  cta {
    id
    name
    width
    height
    x
    y
  }
`;

const GRAPHQL = {
  queries: {
    fetchStaticConfigs: gql`
      query {
        staticConfigs {
          ${GRAPHQL_STATIC_CONFIG_PROPS}
        }
      }
    `,
    fetchStaticConfigsByPage: gql`
      query($input: StaticConfigsByPageInput!) {
        staticConfigsByPage(input: $input) {
          elements {
            ${GRAPHQL_STATIC_CONFIG_PROPS}
          }
          total
        }
      }
    `,
  },
  mutations: {
    addStaticConfig: gql`
      mutation ($input: AddStaticConfigInput!) { 
        addStaticConfig(input: $input) {
          ${GRAPHQL_STATIC_CONFIG_PROPS}
        }
      }
    `,
    editStaticConfig: gql`
      mutation ($input: EditStaticConfigInput!) { 
        editStaticConfig(input: $input) {
          ${GRAPHQL_STATIC_CONFIG_PROPS}
        }
      }
    `,
    deleteStaticConfig: gql`
      mutation($input: DeleteStaticConfigInput!) {
        deleteStaticConfig(input: $input)
      }
    `,
  },
};

export default {
  namespaced: true,
  state: {
    elements: [],
    total: 0,
    loading: false,
  },
  mutations: {
    setStaticConfigs(state, { elements, total = 0 }) {
      state.elements = elements;
      state.total = total;
      state.loading = false;
    },
    pushStaticConfig(state, item) {
      state.elements.push(item);
      state.total += 1;
      state.loading = false;
    },
    updateStaticConfig(state, item) {
      const index = state.elements.findIndex(elem => elem.id === item.id);
      if (index !== -1) {
        state.elements[index] = item;
        state.elements = [...state.elements];
      }
      state.loading = false;
    },
    removeStaticConfig(state, id) {
      const index = state.elements.findIndex(elem => elem.id === id);
      if (index !== -1) {
        state.elements.splice(index, 1);
        state.total -= 1;
      }
      state.loading = false;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
  },
  actions: {
    async fetchStaticConfigs({ commit }) {
      commit('setLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchStaticConfigs,
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const staticConfigs = results && results.data && results.data.staticConfigs ? results.data.staticConfigs : [];
      commit('setStaticConfigs', { elements: staticConfigs, total: staticConfigs.length });
    },
    async fetchStaticConfigsByPage({ commit }, { page = 1, pageSize = 1, options = {} }) {
      commit('setLoading', true);
      const optionsStr = JSON.stringify(options);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchStaticConfigsByPage,
          variables: {
            input: {
              page,
              pageSize,
              options: optionsStr,
            },
          },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const staticConfigs =
        results && results.data && results.data.staticConfigsByPage ? results.data.staticConfigsByPage : {};
      commit('setStaticConfigs', staticConfigs);
    },
    async addStaticConfig({ commit }, {
      networkId, formatId, bg, icon, title, iosBadge, googleBadge, cta,
    }) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.addStaticConfig,
          variables: {
            input: {
              networkId,
              formatId,
              bg,
              icon,
              title,
              iosBadge,
              googleBadge,
              cta,
            },
          },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const staticConfig =
        results && results.data && results.data.addStaticConfig ? results.data.addStaticConfig : null;
      if (staticConfig) {
        commit('pushStaticConfig', staticConfig);
      } else {
        commit('setLoading', false);
      }
    },
    async editStaticConfig({ commit }, {
      id, networkId, formatId, bg, icon, title, iosBadge, googleBadge, cta,
    }) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.editStaticConfig,
          variables: {
            input: {
              id,
              networkId,
              formatId,
              bg,
              icon,
              title,
              iosBadge,
              googleBadge,
              cta,
            },
          },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const staticConfig =
        results && results.data && results.data.editStaticConfig ? results.data.editStaticConfig : null;
      if (staticConfig) {
        commit('updateStaticConfig', staticConfig);
      } else {
        commit('setLoading', false);
      }
    },
    async deleteStaticConfig({ commit }, { id }) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.deleteStaticConfig,
          variables: { input: { id } },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const deleted = results && results.data && results.data.deleteStaticConfig;
      if (deleted) {
        commit('removeStaticConfig', id);
      } else {
        commit('setLoading', false);
      }
    },
  },
};
