import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { createApolloClient } from 'vue-cli-plugin-apollo/graphql-client';
import { setContext } from 'apollo-link-context';
import { from } from 'apollo-client-preset';
import { onError } from 'apollo-link-error';
import correlationHeader from './correlation-header';

import Config from '../services/config.service';

Vue.use(VueApollo);

const httpEndpoint = `${Config.apiUrl}/graphql`;

const authLink = setContext(async (_, { headers }) => {
  const accessToken = await Vue.prototype.$auth.getAccessToken();

  return {
    headers: {
      ...headers,
      authorization: `Bearer ${accessToken}`,
      ...correlationHeader(),
    },
  };
});

const errorLink = onError(async ({ networkError }) => {
  if (networkError.statusCode === 401) {
    await Vue.prototype.$auth.signOut();
  }
});

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  wsEndpoint: null,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  link: from([errorLink, authLink]),

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  // link: myLink

  // Override default cache
  // cache: myCache

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }

  connectToDevTools: true,
};

// Call this in the Vue app file
// export function createProvider(options = {}) {
// Create apollo client
export const { apolloClient, wsClient } = createApolloClient({
  ...defaultOptions,
  connectToDevTools: process.env.CONNECT_TO_DEV_TOOLS || false,
  httpLinkOptions: {
    credentials: 'include',
  },
});
apolloClient.wsClient = wsClient;

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  errorHandler(error) {
    // eslint-disable-next-line no-console
    // eslint-disable-next-line no-restricted-syntax
    console.log(
      '%cError',
      'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
      error.message,
    );
  },
});

export default apolloProvider;
