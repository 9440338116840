const ConfigService = {
  env: process.env.NODE_ENV,
  apiUrl: `${process.env.VUE_APP_API_URL}/api`,
  s3Url: `${process.env.VUE_APP_API_URL}/s3`,
  kitchenUrl: process.env.VUE_APP_KITCHEN_URL,
  okta: {
    issuer: process.env.VUE_APP_OKTA_ISSUER || 'https://voodoo.okta.com/oauth2/default',
    clientId: process.env.VUE_APP_CLIENT_ID,
    redirectUrl: `${process.env.VUE_APP_REDIRECT_URL}/implicit/callback`,
  },
  renderStatuses: ['Error', 'New', 'In progress', 'Project done', 'Rendering', 'Render done', 'Error'],
  endcardStatuses: ['Error', 'New', 'In progress', 'Endcard done', 'Error'],
  staticStatuses: ['Error', 'New', 'In progress', 'Static done', 'Error'],
  featureFlag: { applovin: true },
};

export default ConfigService;
