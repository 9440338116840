module.exports = {
  weights: {
    thin: {
      id: 'thin',
      name: 'Thin',
      value: 100,
    },
    extraLight: {
      id: 'extra-light',
      name: 'Extra-Light',
      value: 200,
    },
    light: {
      id: 'light',
      name: 'Light',
      value: 300,
    },
    regular: {
      id: 'regular',
      name: 'Regular',
      value: 400,
    },
    medium: {
      id: 'medium',
      name: 'Medium',
      value: 500,
    },
    semiBold: {
      id: 'semi-bold',
      name: 'Semi-Bold',
      value: 600,
    },
    bold: {
      id: 'bold',
      name: 'Bold',
      value: 700,
    },
    extraBold: {
      id: 'extra-bold',
      name: 'Extra-Bold',
      value: 800,
    },
    heavy: {
      id: 'heavy',
      name: 'Heavy',
      value: 900,
    },
  },
  widths: {
    ultraCondensed: {
      id: 'ultra-condensed',
      name: 'Ultra-Condensed',
      value: 1,
    },
    extraCondensed: {
      id: 'extra-condensed',
      name: 'Extra-Condensed',
      value: 2,
    },
    condensed: {
      id: 'condensed',
      name: 'Condensed',
      value: 3,
    },
    semiCondensed: {
      id: 'semi-condensed',
      name: 'Semi-Condensed',
      value: 4,
    },
    normal: {
      id: 'normal',
      name: 'Normal',
      value: 5,
    },
    semiExpanded: {
      id: 'semi-expanded',
      name: 'Semi-Expanded',
      value: 6,
    },
    expanded: {
      id: 'expanded',
      name: 'Expanded',
      value: 7,
    },
    extraExpanded: {
      id: 'extra-expanded',
      name: 'Extra-Expanded',
      value: 8,
    },
    ultraExpanded: {
      id: 'ultra-expanded',
      name: 'Ultra-Expanded',
      value: 9,
    },
  },
};
