<template>
  <div class="deviceDialog">
    <el-dialog
      :close-on-click-modal="false"
      :before-close="closeDeviceDialog"
      title="Device"
      :visible.sync="deviceDialogVisible"
      width="50%"
    >
      <el-form
        class="form"
        ref="deviceModel"
        label-width="120px"
        :model="deviceModel"
        @submit.native.prevent="submitDeviceForm"
      >
        <el-form-item v-if="deviceFormErrors.length">
          <el-alert
            v-for="(error, index) in deviceFormErrors"
            :key="index"
            :title="error"
            :closable="false"
            type="error"
            class="error"
          ></el-alert>
        </el-form-item>
        <el-form-item label="Name">
          <el-input placeholder="Name" v-model="deviceModel.name"></el-input>
        </el-form-item>
        <el-form-item>
          <el-select v-model="deviceModel.formatId" placeholder="Format">
            <el-option
              v-for="option in formatElements"
              :key="option.id"
              :label="`${option.name}${option.ratio ? ` - ${option.ratio.name}` : ''}`"
              :value="option.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="submit">
          <el-button @click="closeDeviceDialog">Cancel</el-button>
          <el-button type="primary" native-type="submit" :disabled="deviceFormSubmit">Save</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'device-dialog',
  data() {
    return {
      deviceDialogVisible: false,
      deviceFormSubmit: false,
      deviceFormSubmitTimeout: null,
      deviceFormErrors: [],
      deviceModel: {
        id: null,
        name: null,
        formatId: null,
      },
    };
  },
  async mounted() {
    await this.init();
  },
  beforeDestroy() {
    clearTimeout(this.deviceFormSubmitTimeout);
  },
  computed: {
    ...mapState('format', { formatElements: 'inputFormats' }),
  },
  methods: {
    ...mapActions('format', ['fetchInputFormats']),
    ...mapActions('device', ['addDevice', 'editDevice']),
    async init() {
      await this.fetchInputFormats();
    },
    async openDeviceDialog(device = null) {
      await this.init();
      this.resetDeviceModel(device);
      this.deviceDialogVisible = true;
    },
    resetDeviceModel(device = null) {
      this.deviceFormErrors = [];
      if (device) {
        this.deviceModel = { ...device };
      } else {
        this.deviceModel = {
          id: null,
          name: null,
          formatId: null,
        };
      }
    },
    closeDeviceDialog() {
      this.resetDeviceModel();
      this.deviceDialogVisible = false;
    },
    deviceFormValidation() {
      let valid = true;
      this.deviceFormErrors = [];
      if (!this.deviceModel.name) {
        this.deviceFormErrors.push('Name required');
        valid = false;
      }
      if (!this.deviceModel.formatId) {
        this.deviceFormErrors.push('Format required');
        valid = false;
      }
      return valid;
    },
    async submitDeviceForm() {
      this.deviceFormSubmit = true;
      this.deviceFormSubmitTimeout = setTimeout(async () => {
        if (this.deviceFormValidation()) {
          if (this.deviceModel.id) {
            await this.editDevice(this.deviceModel);
          } else {
            await this.addDevice(this.deviceModel);
          }
          this.closeDeviceDialog();
        }
        this.deviceFormSubmit = false;
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.deviceDialog {
  display: block;

  .form {
    .error {
      margin-bottom: 10px;
    }

    .submit {
      text-align: right;
      margin: 0;
    }
  }

  /deep/.el-dialog {
    .el-dialog__header {
      margin: 0;
      padding: 15px;
      border-bottom: 1px solid #ebeef5;
    }

    .el-dialog__body {
      padding: 15px;
    }
  }
}
</style>

