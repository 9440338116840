<template>
  <div class="gameDialog">
    <el-dialog
      :close-on-click-modal="false"
      :before-close="closeGameDialog"
      title="Game"
      :visible.sync="gameDialogVisible"
      width="50%"
    >
      <el-form class="form" ref="gameModel" :model="gameModel" @submit.native.prevent="submitGameForm">

        <el-row class="dam-top-row interesting">
          <el-col  :span="24" class="top-row-col">
            <el-form-item v-if="gameFormErrors.length">
              <el-alert
                v-for="(error, index) in gameFormErrors"
                :key="index"
                :title="error"
                :closable="false"
                type="error"
                class="error"
              ></el-alert>
            </el-form-item>
            <el-form-item label="Choose Application">
              <el-autocomplete
                class="app-autocomplete"
                v-model="selectedAppName"
                placeholder="Search app by name (3 char min.)"
                :fetch-suggestions="onSearchApps"
                :clearable="true"
                @select="onSelectApp"
              >
                <template slot-scope="{item}">
                  <div class="app-item app-icon">
                    <el-image v-if="item.iconUrl" class="app-thumbnail" :src="item.iconUrl"></el-image>
                  </div>
                  <div class="app-item app-marketing-code"><span>{{ item.marketingCode }}</span></div>
                  <div class="app-item app-name"><span>{{ item.name }}</span></div>
                </template>
              </el-autocomplete>
              <el-checkbox style="margin-left: 30px;" v-model="isVoodoo">Voodoo Game</el-checkbox>
              <el-checkbox v-model="isPublished">Is Published</el-checkbox>
              <el-button @click="openKitchen" icon="el-icon-edit" style="margin-left: 8px;"></el-button>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20" class="dam-top-row interesting">
          <el-col :span="12" class="top-row-col">
            <el-form-item>
              <el-select v-model="gameModel.orientationId" placeholder="Orientation">
                <el-option
                  v-for="option in orientationElements"
                  :key="option.id"
                  :label="option.name"
                  :value="option.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select v-model="gameModel.mechanicId" placeholder="Mechanic">
                <el-option
                  v-for="option in mechanicElements"
                  :key="option.id"
                  :label="option.name"
                  :value="option.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="UA Email">
              <el-input placeholder="UA Email" type="email" v-model="gameModel.uaEmail"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12" class="top-row-col"  justify="end">
            <ul class="game-info">
              <li>Marketing Code: <span>{{gameModel.code}}</span></li>
              <li>Name: <span>{{gameModel.name}}</span></li>
              <li>Business: <span>{{gameModel.business}}</span></li>
              <li>Genre: <span>{{gameModel.genre}}</span></li>
            </ul>
          </el-col>
        </el-row>
        <div v-if="!gameModel.id">
          <hr />
          <div class="static-title">Static</div>
          <el-switch
            class="switch"
            v-model="gameModel.staticParams.generate"
            active-text="Generate"
          ></el-switch>
          <div v-if="gameModel.staticParams.generate">
            <clipper-upload class="clipper-upload" v-model="imgURL">
              <el-button type="primary">Upload file</el-button>
            </clipper-upload>
            <div class="clippers">
              <clipper-basic
                ref="squareClipper"
                class="my-clipper"
                preview="square"
                :ratio="1"
                :src="imgURL"
              ></clipper-basic>
              <clipper-basic
                ref="landscapeClipper"
                class="my-clipper"
                preview="landscape"
                :ratio="16/9"
                :src="imgURL"
              ></clipper-basic>
              <clipper-basic
                ref="portraitClipper"
                class="my-clipper"
                preview="portrait"
                :ratio="9/16"
                :src="imgURL"
              ></clipper-basic>
            </div>
            <div class="previews" v-if="this.imgURL">
              <clipper-preview class="preview" name="square"></clipper-preview>
              <clipper-preview class="preview" name="landscape"></clipper-preview>
              <clipper-preview class="preview" name="portrait"></clipper-preview>
            </div>
            <el-form-item>
              Logo
              <div>
                <el-switch
                  class="switch"
                  v-model="gameModel.staticParams.logo.logoSwitch"
                  active-text="Text"
                  inactive-text="Image"
                ></el-switch>
                <div v-if="!gameModel.staticParams.logo.logoSwitch" class="logo-image">
                  <vd-upload ref="logoUpload" uploadPath="documents/logos/upload"></vd-upload>
                </div>
                <div v-if="gameModel.staticParams.logo.logoSwitch" class="logo-text">
                  <el-select
                    v-model="gameModel.staticParams.logo.fontId"
                    placeholder="Font"
                    class="selector"
                  >
                    <el-option
                      v-for="option in fontElements"
                      :key="option.id"
                      :label="option.name"
                      :value="option.id"
                    ></el-option>
                  </el-select>
                  <el-input v-model="gameModel.staticParams.logo.text" placeholder="Logo text"></el-input>
                </div>
              </div>
            </el-form-item>
            <el-form-item class="logo-text">
              CTA
              <div>
                <vd-upload ref="ctaUpload" uploadPath="documents/ctas/upload"></vd-upload>
              </div>
            </el-form-item>
          </div>
        </div>
        <el-form-item class="submit">
          <el-button @click="closeGameDialog">Cancel</el-button>
          <el-button type="primary" native-type="submit" :disabled="gameFormSubmit">Save</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { uploadFile } from '@/utils/uploadFile';
import VdUpload from '@/components/vd-upload/VdUpload.vue';
import Config from '@/services/config.service';


export default {
  name: 'game-dialog',
  data() {
    return {
      imgURL: null,
      gameDialogVisible: false,
      gameFormSubmit: false,
      gameFormSubmitTimeout: null,
      gameFormErrors: [],
      selectedApp: null,
      selectedAppName: '',
      isVoodoo: true,
      isPublished: true,
      importerPlatform: 'ios',
      importerStoreId: null,
      gameModel: {
        id: null,
        appId: null,
        orientationId: null,
        mechanicId: null,
        code: null,
        name: null,
        genre: null,
        business: null,
        uaEmail: null,
        staticParams: {
          generate: false,
          logo: {
            logoSwitch: false,
            text: null,
            image: null,
            fontId: null,
          },
          cta: null,
          iosBadgeId: 1,
          googleBadgeId: 2,
        },
      },
      squareClipper: null,
      landscapeClipper: null,
      portraitClipper: null,
      uploadUrl: `${Config.apiUrl}/documents/upload`,
    };
  },
  components: {
    VdUpload,
  },
  async mounted() {
    await this.init();
  },
  beforeDestroy() {
    clearTimeout(this.gameFormSubmitTimeout);
  },
  computed: {
    ...mapState('appService', { appElements: 'elements' }),
    ...mapState('orientation', { orientationElements: 'elements' }),
    ...mapState('mechanic', { mechanicElements: 'elements' }),
    ...mapState('font', { fontElements: 'elements' }),
  },
  methods: {
    ...mapActions('appService', ['fetchAppByGameAppId', 'fetchApps']),
    ...mapActions('orientation', ['fetchOrientations']),
    ...mapActions('mechanic', ['fetchMechanics']),
    ...mapActions('font', ['fetchFonts']),
    ...mapActions('game', ['addGame', 'editGame']),
    ...mapMutations('appService', ['setApps']),
    async init() {
      await Promise.all([
        this.fetchOrientations(),
        this.fetchMechanics(),
        this.fetchFonts(),
      ]);
    },
    async openGameDialog(game = null) {
      await this.init();
      await this.resetGameModel(game);
      this.gameDialogVisible = true;
    },
    async resetGameModel(game = null) {
      this.gameFormErrors = [];
      this.selectedApp = null;
      this.selectedAppName = '';
      this.imgURL = null;
      this.gameModel = {
        id: null,
        appId: null,
        orientationId: null,
        mechanicId: null,
        code: null,
        name: null,
        genre: null,
        business: null,
        uaEmail: null,
        staticParams: {
          generate: false,
          logo: {
            logoSwitch: false,
            text: null,
            image: null,
            fontId: null,
          },
          cta: null,
          iosBadgeId: 1,
          googleBadgeId: 2,
        },
      };
      if (game) {
        this.gameModel = {
          ...game,
          staticParams: {
            generate: false,
            logo: {
              logoSwitch: false,
              text: null,
              image: null,
              fontId: null,
            },
            cta: null,
            iosBadgeId: 1,
            googleBadgeId: 2,
          },
        };
        const app = await this.fetchAppByGameAppId({ appId: game.appId });
        if (app) {
          this.selectedApp = app;
          this.selectedAppName = app.name;
        }
      }
    },
    closeGameDialog() {
      this.resetGameModel();
      this.gameDialogVisible = false;
    },
    gameFormValidation(withClip = true) {
      let valid = true;
      this.gameFormErrors = [];
      if (!this.gameModel.appId) {
        this.gameFormErrors.push('App required');
        valid = false;
      }
      if (!this.gameModel.orientationId) {
        this.gameFormErrors.push('Orientation required');
        valid = false;
      }
      if (!this.gameModel.mechanicId) {
        this.gameFormErrors.push('Mechanic required');
        valid = false;
      }
      if (!this.gameModel.code) {
        this.gameFormErrors.push('Code required');
        valid = false;
      }
      if (!this.gameModel.name) {
        this.gameFormErrors.push('Name required');
        valid = false;
      }
      if (!this.gameModel.genre) {
        this.gameFormErrors.push('Genre required');
        valid = false;
      }
      if (!this.gameModel.business) {
        this.gameFormErrors.push('Business required');
        valid = false;
      }
      if (this.gameModel.staticParams.generate) {
        if (
          withClip &&
          (!this.gameModel.staticParams.square ||
            !this.gameModel.staticParams.landscape ||
            !this.gameModel.staticParams.portrait)
        ) {
          this.gameFormErrors.push('Static file required');
          valid = false;
        }
        if (this.gameModel.staticParams.logo.logoSwitch) {
          if (!this.gameModel.staticParams.logo.fontId) {
            this.gameFormErrors.push('Font required in text logo mode');
            valid = false;
          }
          if (!this.gameModel.staticParams.logo.text) {
            this.gameFormErrors.push('Text required in text logo mode');
            valid = false;
          }
        } else if (!this.$refs.logoUpload.document) {
          this.gameFormErrors.push('Image required in image logo mode');
          valid = false;
        }
        if (!this.$refs.ctaUpload.document) {
          this.gameFormErrors.push('CTA image required');
          valid = false;
        }
      }
      return valid;
    },
    async submitGameForm() {
      this.gameFormSubmit = true;
      this.gameFormSubmitTimeout = setTimeout(async () => {
        this.gameModel = {
          ...this.gameModel,
          staticParams: {
            ...this.gameModel.staticParams,
            icon: this.selectedApp ?
              this.selectedApp.iconUrl :
              this.gameModel.icon,
            logo: this.$refs.logoUpload ?
              {
                logoSwitch: this.gameModel.staticParams.logo.logoSwitch,
                image: this.$refs.logoUpload.document,
              } :
              this.gameModel.staticParams.logo,
            cta: this.$refs.ctaUpload ? this.$refs.ctaUpload.document : null,
          },
        };
        if (this.imgURL && this.gameFormValidation(false)) {
          const clips = await this.uploadClips();
          if (clips) {
            this.gameModel.staticParams = {
              ...this.gameModel.staticParams,
              ...clips,
            };
          }
        }
        if (this.gameFormValidation()) {
          if (this.gameModel.id) {
            await this.editGame(this.gameModel);
          } else {
            await this.addGame(this.gameModel);
          }
          this.closeGameDialog();
        }
        this.gameFormSubmit = false;
      }, 300);
    },
    async uploadClips() {
      const squareCanvas = this.$refs.squareClipper.clip();
      const landscapeCanvas = this.$refs.landscapeClipper.clip();
      const portraitCanvas = this.$refs.portraitClipper.clip();

      if (
        (!squareCanvas.width && !squareCanvas.height) ||
        (!landscapeCanvas.width && !landscapeCanvas.height) ||
        (!portraitCanvas.width && !portraitCanvas.height)
      ) {
        return null;
      }

      const [square, landscape, portrait] = await Promise.all([
        uploadFile(
          this.uploadUrl,
          squareCanvas,
          `square_${this.gameModel.name}.jpg`,
        ),
        uploadFile(
          this.uploadUrl,
          landscapeCanvas,
          `landscape_${this.gameModel.name}.jpg`,
        ),
        uploadFile(
          this.uploadUrl,
          portraitCanvas,
          `portrait_${this.gameModel.name}.jpg`,
        ),
      ]);

      return {
        square,
        landscape,
        portrait,
      };
    },
    onSearchElement(elements = [], key, queryString, cb) {
      elements.sort((a, b) => a[key].length - b[key].length);
      if (queryString) {
        elements = elements.filter(element => element[key].toLowerCase().includes(queryString.toLowerCase()));
      }
      cb(elements.map(element => ({ ...element, id: element.id, value: element[key] })));
    },
    async onSearchApps(queryString, cb) {
      await this.setApps([]);
      if (queryString && queryString.length >= 3) {
        await this.fetchApps({ name: queryString, isVoodoo: !!this.isVoodoo, isPublished: !!this.isPublished });
      }
      this.onSearchElement([...(this.appElements || [])], 'name', queryString, cb);
    },
    onSelectApp(app) {
      this.gameModel.appId = app.id;
      this.gameModel.code = app.marketingCode;
      this.gameModel.name = app.name;
      this.gameModel.business = app.business;
      this.gameModel.genre = app.genre;
      this.selectedApp = app;
    },
    openKitchen() {
      window.open(
        `${Config.kitchenUrl}${this.selectedApp ? `/app/general/information?appId=${this.selectedApp.id}` : ''}`,
        '_blank',
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.gameDialog {
  display: block;

  .form {
    .error {
      margin-bottom: 10px;
    }

    .submit {
      text-align: right;
      margin: 0;
    }
  }

  .clipper-upload {
    display: inline-block;
    margin-bottom: 16px;
  }

  .clippers {
    display: flex;
    flex-direction: row;

    .my-clipper {
      width: 100%;
      max-width: 32.33%;
      margin: 0.5%;
    }
  }

  .previews {
    display: flex;
    flex-direction: row;
    margin-top: 32px;

    .preview {
      width: 100%;
      max-width: 32.33%;
      margin: 0.5%;
    }
  }

  .static-title {
    margin-bottom: 16px;
  }

  .logo-text {
    display: flex;

    .selector {
      margin-right: 16px;
    }

    .wording-autocomplete {
      width: calc(100% - 50px);
    }

    .wording-button {
      margin-left: 10px;
    }
  }

  ul.game-info {
    list-style: none;

    li {
      span {
        font-weight: normal;
      }

      font-weight: bold;
      margin-bottom: 5px;
    }
  }

  .switch {
    margin-bottom: 16px;
  }

  /deep/.el-dialog {
    .el-dialog__header {
      margin: 0;
      padding: 15px;
      border-bottom: 1px solid #ebeef5;
    }

    .el-dialog__body {
      padding: 15px;
    }
  }
}

.app-autocomplete {
  width: 400px;
}

.app-item {
  height: 40px;
  display: inline-block;
  vertical-align: middle;
  font-size: 13px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;

  &.app-icon {
    width: 40px;
  }

  &.app-marketing-code {
    width: 75px;
  }

  &.app-name {
    width: 240px;
  }

  .app-thumbnail {
    width: 32px;
  }
}

.import-dialog {
  background-color: #efefef;
  border-radius: 24px;
  padding: 32px;
}
</style>

