import Vue from 'vue';
import Vuex from 'vuex';
import gql from 'graphql-tag';
import { apolloClient } from '../../utils/vue-apollo';

Vue.use(Vuex);

const GRAPHQL_ORIENTATION_PROPS = `
  id
  name
`;

const GRAPHQL = {
  queries: {
    fetchOrientations: gql`
      query {
        orientations {
          ${GRAPHQL_ORIENTATION_PROPS}
        }
      }
    `,
    fetchOrientationsByPage: gql`
      query($input: OrientationsByPageInput!) {
        orientationsByPage(input: $input) {
          elements {
            ${GRAPHQL_ORIENTATION_PROPS}
          }
          total
        }
      }
    `,
  },
  mutations: {
    addOrientation: gql`
      mutation($input: AddOrientationInput!) { 
        addOrientation(input: $input) {
          ${GRAPHQL_ORIENTATION_PROPS}
        }
      }
    `,
    editOrientation: gql`
      mutation($input: EditOrientationInput!) { 
        editOrientation(input: $input) {
          ${GRAPHQL_ORIENTATION_PROPS}
        }
      }
    `,
    deleteOrientation: gql`
      mutation($input: DeleteOrientationInput!) {
        deleteOrientation(input: $input)
      }
    `,
  },
};

export default {
  namespaced: true,
  state: {
    elements: [],
    total: 0,
    loading: false,
  },
  mutations: {
    setOrientations(state, { elements, total = 0 }) {
      state.elements = elements;
      state.total = total;
      state.loading = false;
    },
    pushOrientation(state, item) {
      state.elements.push(item);
      state.total += 1;
      state.loading = false;
    },
    updateOrientation(state, item) {
      const index = state.elements.findIndex(elem => elem.id === item.id);
      if (index !== -1) {
        state.elements[index] = item;
        state.elements = [...state.elements];
      }
      state.loading = false;
    },
    removeOrientation(state, id) {
      const index = state.elements.findIndex(elem => elem.id === id);
      if (index !== -1) {
        state.elements.splice(index, 1);
        state.total -= 1;
      }
      state.loading = false;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
  },
  actions: {
    async fetchOrientations({ commit }) {
      commit('setLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchOrientations,
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const orientations = results && results.data && results.data.orientations ? results.data.orientations : [];
      commit('setOrientations', { elements: orientations, total: orientations.length });
    },
    async fetchOrientationsByPage({ commit }, { page = 1, pageSize = 1, options = {} }) {
      commit('setLoading', true);
      const optionsStr = JSON.stringify(options);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchOrientationsByPage,
          variables: {
            input: {
              page,
              pageSize,
              options: optionsStr,
            },
          },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const orientations =
        results && results.data && results.data.orientationsByPage ? results.data.orientationsByPage : {};
      commit('setOrientations', orientations);
    },
    async addOrientation({ commit }, { name }) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.addOrientation,
          variables: {
            input: { name },
          },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const orientation = results && results.data && results.data.addOrientation ? results.data.addOrientation : null;
      if (orientation) {
        commit('pushOrientation', orientation);
      } else {
        commit('setLoading', false);
      }
    },
    async editOrientation({ commit }, { id, name }) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.editOrientation,
          variables: {
            input: { id, name },
          },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const orientation = results && results.data && results.data.editOrientation ? results.data.editOrientation : null;
      if (orientation) {
        commit('updateOrientation', orientation);
      } else {
        commit('setLoading', false);
      }
    },
    async deleteOrientation({ commit }, { id }) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.deleteOrientation,
          variables: { input: { id } },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const deleted = results && results.data && results.data.deleteOrientation;
      if (deleted) {
        commit('removeOrientation', id);
      } else {
        commit('setLoading', false);
      }
    },
  },
};
