<template>
  <span class="content">
    <span class="title" v-bind:class="{ active: !!search }">{{column.label}}</span>
    <el-popover class="search" placement="bottom" title="Search" width="200" trigger="click">
      <el-input
        v-model="search"
        @input="searchMiddleware"
        @focus="searchMiddleware"
        @blur="searchMiddleware"
        @change="searchMiddleware"
        size="mini"
        placeholder="Type to search"
        clearable
      ></el-input>
      <el-button
        slot="reference"
        class="search-button"
        v-bind:class="{ active: !!search }"
        type="text"
        icon="el-icon-search"
        circle @click="searchClick"
      >
      </el-button>
    </el-popover>
  </span>
</template>

<script>
export default {
  name: 'vd-table-search',
  props: {
    column: Object,
    handleSearch: Function,
  },
  data() {
    return {
      search: '',
    };
  },
  methods: {
    searchMiddleware() {
      this.handleSearch({ key: this.column.key, search: this.search });
    },
    searchClick(event) {
      event.stopPropagation();
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .title {
    margin-right: 8px;

    &.active {
      color: teal;
    }
  }

  .search {
    margin-right: 8px;
  }

  .search-button {
    /deep/.el-icon-search {
      font-weight: bold;
      color: #c0c4cc;
    }

    &.active {
      /deep/.el-icon-search {
        font-weight: bold;
        color: teal;
      }
    }
  }
}
</style>
