import Vue from 'vue';
import Vuex from 'vuex';
import gql from 'graphql-tag';
import { apolloClient } from '../../utils/vue-apollo';

Vue.use(Vuex);

const GRAPHQL_TEMPLATE_TYPE_PROPS = `
  id
  name
  shortName
  nbKeys
`;

const GRAPHQL = {
  queries: {
    fetchTemplateTypes: gql`
      query {
        templateTypes {
          ${GRAPHQL_TEMPLATE_TYPE_PROPS}
        }
      }
    `,
  },
};

export default {
  namespaced: true,
  state: {
    elements: [],
    total: 0,
    loading: false,
  },
  mutations: {
    setTemplateTypes(state, { elements, total = 0 }) {
      state.elements = elements;
      state.total = total;
      state.loading = false;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
  },
  actions: {
    async fetchTemplateTypes({ commit }) {
      commit('setLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchTemplateTypes,
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const templateTypes = results && results.data && results.data.templateTypes ? results.data.templateTypes : [];
      commit('setTemplateTypes', { elements: templateTypes, total: templateTypes.length });
    },
  },
};
