<template>
  <div class="orientationDialog">
    <el-dialog
      :close-on-click-modal="false"
      :before-close="closeOrientationDialog"
      title="Orientation"
      :visible.sync="orientationDialogVisible"
      width="50%"
    >
      <el-form
        class="form"
        ref="orientationModel"
        label-width="120px"
        :model="orientationModel"
        @submit.native.prevent="submitOrientationForm"
      >
        <el-form-item v-if="orientationFormErrors.length">
          <el-alert
            v-for="(error, index) in orientationFormErrors"
            :key="index"
            :title="error"
            :closable="false"
            type="error"
            class="error"
          ></el-alert>
        </el-form-item>
        <el-form-item label="Name">
          <el-input placeholder="Name" v-model="orientationModel.name"></el-input>
        </el-form-item>
        <el-form-item class="submit">
          <el-button @click="closeOrientationDialog">Cancel</el-button>
          <el-button type="primary" native-type="submit" :disabled="orientationFormSubmit">Save</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'orientation-dialog',
  data() {
    return {
      orientationDialogVisible: false,
      orientationFormSubmit: false,
      orientationFormSubmitTimeout: null,
      orientationFormErrors: [],
      orientationModel: {
        id: null,
        name: null,
      },
    };
  },
  beforeDestroy() {
    clearTimeout(this.orientationFormSubmitTimeout);
  },
  methods: {
    ...mapActions('orientation', ['addOrientation', 'editOrientation']),
    openOrientationDialog(orientation = null) {
      this.resetOrientationModel(orientation);
      this.orientationDialogVisible = true;
    },
    resetOrientationModel(orientation = null) {
      this.orientationFormErrors = [];
      if (orientation) {
        this.orientationModel = { ...orientation };
      } else {
        this.orientationModel = {
          id: null,
          name: null,
        };
      }
    },
    closeOrientationDialog() {
      this.resetOrientationModel();
      this.orientationDialogVisible = false;
    },
    orientationFormValidation() {
      let valid = true;
      this.orientationFormErrors = [];
      if (!this.orientationModel.name) {
        this.orientationFormErrors.push('Name required');
        valid = false;
      }
      return valid;
    },
    async submitOrientationForm() {
      this.orientationFormSubmit = true;
      this.orientationFormSubmitTimeout = setTimeout(async () => {
        if (this.orientationFormValidation()) {
          if (this.orientationModel.id) {
            await this.editOrientation(this.orientationModel);
          } else {
            await this.addOrientation(this.orientationModel);
          }
          this.closeOrientationDialog();
        }
        this.orientationFormSubmit = false;
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.orientationDialog {
  display: block;

  .form {
    .error {
      margin-bottom: 10px;
    }

    .submit {
      text-align: right;
      margin: 0;
    }
  }

  /deep/.el-dialog {
    .el-dialog__header {
      margin: 0;
      padding: 15px;
      border-bottom: 1px solid #ebeef5;
    }

    .el-dialog__body {
      padding: 15px;
    }
  }
}
</style>

