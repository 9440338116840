import Vue from 'vue';
import Vuex from 'vuex';
import gql from 'graphql-tag';
import { apolloClient } from '../../utils/vue-apollo';

Vue.use(Vuex);

const GRAPHQL_FORMAT_PROPS = `
  id
  name
  formatTypeId
  ratioId
  width
  height
  time
  formatType {
    id
    name
  }
  ratio {
    id
    name
  }
`;

const GRAPHQL = {
  queries: {
    fetchFormats: gql`
      query {
        formats {
          ${GRAPHQL_FORMAT_PROPS}
        }
      }
    `,
    fetchInputFormats: gql`
      query {
        inputFormats {
          ${GRAPHQL_FORMAT_PROPS}
        }
      }
    `,
    fetchOutputFormats: gql`
      query {
        outputFormats {
          ${GRAPHQL_FORMAT_PROPS}
        }
      }
    `,
    fetchStaticFormats: gql`
      query {
        staticFormats {
          ${GRAPHQL_FORMAT_PROPS}
        }
      }
    `,
    fetchFormatsByPage: gql`
      query($input: FormatsByPageInput!) {
        formatsByPage(input: $input) {
          elements {
            ${GRAPHQL_FORMAT_PROPS}
          }
          total
        }
      }
    `,
  },
  mutations: {
    addFormat: gql`
      mutation($input: AddFormatInput!) { 
        addFormat(input: $input) {
          ${GRAPHQL_FORMAT_PROPS}
        }
      }
    `,
    editFormat: gql`
      mutation($input: EditFormatInput!) { 
        editFormat(input: $input) {
          ${GRAPHQL_FORMAT_PROPS}
        }
      }
    `,
    deleteFormat: gql`
      mutation($input: DeleteFormatInput!) {
        deleteFormat(input: $input)
      }
    `,
  },
};

export default {
  namespaced: true,
  state: {
    elements: [],
    total: 0,
    loading: false,
    inputFormats: [],
    outputFormats: [],
    staticFormats: [],
    inputFormatsLoading: false,
    outputFormatsLoading: false,
    staticFormatsLoading: false,
  },
  mutations: {
    setFormats(state, { elements, total = 0 }) {
      state.elements = elements;
      state.total = total;
      state.loading = false;
    },
    setInputFormats(state, formats) {
      state.inputFormats = formats;
      state.inputFormatsLoading = false;
    },
    setOutputFormats(state, formats) {
      state.outputFormats = formats;
      state.outputFormatsLoading = false;
    },
    setStaticFormats(state, formats) {
      state.staticFormats = formats;
      state.staticFormatsLoading = false;
    },
    pushFormat(state, item) {
      state.elements.push(item);
      state.total += 1;
      state.loading = false;
    },
    updateFormat(state, item) {
      const index = state.elements.findIndex(elem => elem.id === item.id);
      if (index !== -1) {
        state.elements[index] = item;
        state.elements = [...state.elements];
      }
      state.loading = false;
    },
    removeFormat(state, id) {
      const index = state.elements.findIndex(elem => elem.id === id);
      if (index !== -1) {
        state.elements.splice(index, 1);
        state.total -= 1;
      }
      state.loading = false;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
    setInputFormatsLoading(state, isLoading) {
      state.inputFormatsLoading = isLoading;
    },
    setOutputFormatsLoading(state, isLoading) {
      state.outputFormatsLoading = isLoading;
    },
    setStaticFormatsLoading(state, isLoading) {
      state.staticFormatsLoading = isLoading;
    },
  },
  actions: {
    async fetchFormats({ commit }) {
      commit('setLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchFormats,
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const formats = results && results.data && results.data.formats ? results.data.formats : [];
      commit('setFormats', { elements: formats, total: formats.length });
    },
    async fetchInputFormats({ commit }) {
      commit('setInputFormatsLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchInputFormats,
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const formats = results && results.data && results.data.inputFormats ? results.data.inputFormats : [];
      commit('setInputFormats', formats);
    },
    async fetchOutputFormats({ commit }) {
      commit('setOutputFormatsLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchOutputFormats,
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const formats = results && results.data && results.data.outputFormats ? results.data.outputFormats : [];
      commit('setOutputFormats', formats);
    },
    async fetchStaticFormats({ commit }) {
      commit('setStaticFormatsLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchStaticFormats,
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const formats = results && results.data && results.data.staticFormats ? results.data.staticFormats : [];
      commit('setStaticFormats', formats);
    },
    async fetchFormatsByPage({ commit }, { page = 1, pageSize = 1, options = {} }) {
      commit('setLoading', true);
      const optionsStr = JSON.stringify(options);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchFormatsByPage,
          variables: {
            input: {
              page,
              pageSize,
              options: optionsStr,
            },
          },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const formats = results && results.data && results.data.formatsByPage ? results.data.formatsByPage : {};
      commit('setFormats', formats);
    },
    async addFormat({ commit }, {
      formatTypeId, ratioId, width, height, time,
    }) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.addFormat,
          variables: {
            input: {
              formatTypeId,
              ratioId,
              width,
              height,
              time,
            },
          },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const format = results && results.data && results.data.addFormat ? results.data.addFormat : null;
      if (format) {
        commit('pushFormat', format);
      } else {
        commit('setLoading', false);
      }
    },
    async editFormat({ commit }, {
      id, formatTypeId, ratioId, width, height, time,
    }) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.editFormat,
          variables: {
            input: {
              id,
              formatTypeId,
              ratioId,
              width,
              height,
              time,
            },
          },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const format = results && results.data && results.data.editFormat ? results.data.editFormat : null;
      if (format) {
        commit('updateFormat', format);
      } else {
        commit('setLoading', false);
      }
    },
    async deleteFormat({ commit }, { id }) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.deleteFormat,
          variables: { input: { id } },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const deleted = results && results.data && results.data.deleteFormat;
      if (deleted) {
        commit('removeFormat', id);
      } else {
        commit('setLoading', false);
      }
    },
  },
};
