import Vue from 'vue';
import Vuex from 'vuex';
import gql from 'graphql-tag';
import { apolloClient } from '../../utils/vue-apollo';

Vue.use(Vuex);

const GRAPHQL_RENDER_PROPS = `
  id
  name
  concept
  rush {
    id
    name
    gameId
    deviceId
    version
    game {
      id
      appId
      name
      code
      uaEmail
    }
    device {
      id
      name
    }
    document {
      id
      key
      url
    }
  }
  template {
    id
    name
    templateTypeId
    key
    version
    templateType {
      id
      name
      shortName
      nbKeys
    }
    document {
      id
      key
      url
    }
  }
  network {
    id
    name
  }
  format {
    id
    name
    width
    height
    time
  }
  language {
    id
    name
  }
  classification {
    id
    name
  }
  renderDocument {
    id
    key
    url
  }
  projectDocument {
    id
    key
    url
  }
  outputDocument {
    id
    key
    url
    driveFileId
  }
  renderStatus
  renderMessage
  createdAt
  updatedAt
`;

const GRAPHQL = {
  queries: {
    fetchRenders: gql`
      query {
        renders {
          ${GRAPHQL_RENDER_PROPS}
        }
      }
    `,
    fetchRendersByPage: gql`
      query($input: RendersByPageInput!) {
        rendersByPage(input: $input) {
          elements {
            ${GRAPHQL_RENDER_PROPS}
          }
          total
        }
      }
    `,
    generateRender: gql`
      query($id: Int!) {
        generateRender(id: $id)
      }
    `,
    generateRenders: gql`
      query($input: RendersToGenerateInput!) {
        generateRenders(input: $input)
      }
    `,

  },
  mutations: {
    uploadRendersToCampaign: gql`
      mutation($input: UploadRendersToCampaignInput!) {
        uploadRendersToCampaign(input: $input)
      }
    `,
  },
};

export default {
  namespaced: true,
  state: {
    elements: [],
    total: 0,
    loading: false,
    generateLoading: false,
  },
  mutations: {
    setRenders(state, { elements, total = 0 }) {
      state.elements = elements;
      state.total = total;
      state.loading = false;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
    setGenerateLoading(state, isLoading) {
      state.generateLoading = isLoading;
    },
  },
  actions: {
    async fetchRenders({ commit }) {
      commit('setLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchRenders,
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const renders = results && results.data && results.data.renders ? results.data.renders : [];
      commit('setRenders', { elements: renders, total: renders.length });
    },
    async fetchRendersByPage({ commit }, { page = 1, pageSize = 1, options = {} }) {
      commit('setLoading', true);
      const optionsStr = JSON.stringify(options);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchRendersByPage,
          variables: {
            input: {
              page,
              pageSize,
              options: optionsStr,
            },
          },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const renders = results && results.data && results.data.rendersByPage ? results.data.rendersByPage : {};
      commit('setRenders', renders);
    },
    async generateRender({ commit }, { id }) {
      commit('setGenerateLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.generateRender,
          variables: { id },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const generateRender = (results && results.data && results.data.generateRender) || false;
      if (generateRender) {
        commit('setGenerateLoading', false);
      }
    },
    async generateRenders(
      { commit },
      {
        templateTypeId, gameId, rushIds, templateIds, conceptIds, wordingIds, networkFormatIds, languageIds, force,
      },
    ) {
      commit('setGenerateLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.generateRenders,
          variables: {
            input: {
              templateTypeId,
              gameId,
              rushIds,
              templateIds,
              conceptIds,
              wordingIds,
              networkFormatIds,
              languageIds,
              force,
            },
          },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const generateRenders = (results && results.data && results.data.generateRenders) || false;
      if (generateRenders) {
        commit('setGenerateLoading', false);
      }
    },
    async uploadRendersToCampaign({ commit }, { elements, emailData }) {
      commit('setLoading', true);
      const elementsStr = JSON.stringify(elements);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.uploadRendersToCampaign,
          variables: { input: { emailData, elements: elementsStr } },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      commit('setLoading', false);
      return (results && results.data && results.data.uploadRendersToCampaign) || null;
    },
  },
};
