import Vue from 'vue';
import Vuex from 'vuex';
import gql from 'graphql-tag';
import { apolloClient } from '../../utils/vue-apollo';

Vue.use(Vuex);

const GRAPHQL_CLASSIFICATION_PROPS = `
  id
  name
`;

const GRAPHQL = {
  queries: {
    fetchClassifications: gql`
      query {
        classifications {
          ${GRAPHQL_CLASSIFICATION_PROPS}
        }
      }
    `,
    fetchClassificationsByPage: gql`
      query($input: ClassificationsByPageInput!) {
        classificationsByPage(input: $input) {
          elements {
            ${GRAPHQL_CLASSIFICATION_PROPS}
          }
          total
        }
      }
    `,
  },
  mutations: {
    addClassification: gql`
      mutation($input: AddClassificationInput!) { 
        addClassification(input: $input) {
          ${GRAPHQL_CLASSIFICATION_PROPS}
        }
      }
    `,
    editClassification: gql`
      mutation($input: EditClassificationInput!) { 
        editClassification(input: $input) {
          ${GRAPHQL_CLASSIFICATION_PROPS}
        }
      }
    `,
    deleteClassification: gql`
      mutation($input: DeleteClassificationInput!) {
        deleteClassification(input: $input)
      }
    `,
  },
};

export default {
  namespaced: true,
  state: {
    elements: [],
    total: 0,
    loading: false,
  },
  mutations: {
    setClassifications(state, { elements, total = 0 }) {
      state.elements = elements;
      state.total = total;
      state.loading = false;
    },
    pushClassification(state, item) {
      state.elements.push(item);
      state.total += 1;
      state.loading = false;
    },
    updateClassification(state, item) {
      const index = state.elements.findIndex(elem => elem.id === item.id);
      if (index !== -1) {
        state.elements[index] = item;
        state.elements = [...state.elements];
      }
      state.loading = false;
    },
    removeClassification(state, id) {
      const index = state.elements.findIndex(elem => elem.id === id);
      if (index !== -1) {
        state.elements.splice(index, 1);
        state.total -= 1;
      }
      state.loading = false;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
  },
  actions: {
    async fetchClassifications({ commit }) {
      commit('setLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchClassifications,
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const classifications =
        results && results.data && results.data.classifications ? results.data.classifications : [];
      commit('setClassifications', { elements: classifications, total: classifications.length });
    },
    async fetchClassificationsByPage({ commit }, { page = 1, pageSize = 1, options = {} }) {
      commit('setLoading', true);
      const optionsStr = JSON.stringify(options);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchClassificationsByPage,
          variables: {
            input: {
              page,
              pageSize,
              options: optionsStr,
            },
          },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const classifications =
        results && results.data && results.data.classificationsByPage ? results.data.classificationsByPage : {};
      commit('setClassifications', classifications);
    },
    async addClassification({ commit }, { name }) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.addClassification,
          variables: {
            input: { name },
          },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const classification =
        results && results.data && results.data.addClassification ? results.data.addClassification : null;
      if (classification) {
        commit('pushClassification', classification);
      } else {
        commit('setLoading', false);
      }
    },
    async editClassification({ commit }, { id, name }) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.editClassification,
          variables: {
            input: { id, name },
          },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const classification =
        results && results.data && results.data.editClassification ? results.data.editClassification : null;
      if (classification) {
        commit('updateClassification', classification);
      } else {
        commit('setLoading', false);
      }
    },
    async deleteClassification({ commit }, { id }) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.deleteClassification,
          variables: { input: { id } },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const deleted = results && results.data && results.data.deleteClassification;
      if (deleted) {
        commit('removeClassification', id);
      } else {
        commit('setLoading', false);
      }
    },
  },
};
