<template>
  <div class="staticList">
    <vd-listing-header :title="title" :actions="headerActions" :datePicker="true" :handleDatesChange="handleDates">
    </vd-listing-header>
    <div class="content">
      <vd-paged-table
        tableKey="staticList"
        :loading="loading"
        :data="elements"
        :totalDataSize="total"
        :paging="fetchStaticsByPage"
        :query="$route.query"
        :dates="dates"
        :actions="actions"
        :columns="columns"
        :configVisible="configVisible"
        :configClose="closeConfig"
        :refetchTimeout="5000"
      ></vd-paged-table>
    </div>
    <upload-static-dialog ref="uploadStaticDialog"></upload-static-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';

import VdPagedTable from '@/components/vd-paged-table/VdPagedTable';
import VdListingHeader from '@/components/vd-listing-header/VdListingHeader';
import UploadStaticDialog from '@/views/static/UploadStaticDialog.vue';
import config from '../../services/config.service';
import {
  makeImage,
  makeLink,
  makeLinks,
  makeRouterLink,
  makeTooltip,
  getDriveFileUrl,
} from '../../utils/paged-table';

export default {
  name: 'staticList',
  data() {
    return {
      title: 'Static list',
      headerActions: [
        {
          callback: this.openUploadStaticDialog,
          title: 'Add',
          icon: 'circle-plus-outline',
          key: 'add',
        },
        {
          callback: this.openConfig,
          title: 'Configuration',
          icon: 'setting',
          key: 'setting',
        },
      ],
      actions: [],
      columns: [
        {
          key: 'game.name',
          prop: scope => makeRouterLink({
            path: `/game/${scope.row.game.id}`,
            text: scope.row.game.name,
          }),
          label: 'Game',
        },
        {
          key: 'game.code',
          prop: scope => makeRouterLink({
            path: `/game/${scope.row.game.id}`,
            text: scope.row.game.code,
          }),
          label: 'Game Code',
        },
        {
          key: 'name',
          prop: scope => (scope.row.document ?
            makeLink({
              href: scope.row.document.url,
              text: scope.row.name,
            }) :
            scope.row.name),
          label: 'Name',
        },
        {
          key: 'network.name',
          prop: scope => scope.row.network.name,
          label: 'Network',
        },
        {
          key: 'config.format.name',
          prop: scope => scope.row.config.format.name,
          label: 'Format',
        },
        {
          key: 'language.name',
          prop: scope => scope.row.language.name,
          label: 'Language',
        },
        {
          key: 'static',
          prop: scope => (scope.row.document ?
            makeImage({
              src: scope.row.document.url,
              text: scope.row.name,
            }) :
            scope.row.name),
          label: 'Static',
        },
        {
          key: 'renderStatus',
          prop: scope => (scope.row.renderMessage ?
            makeTooltip({
              text: this.getStatus(scope.row.renderStatus),
              content: scope.row.renderMessage,
            }) :
            this.getStatus(scope.row.renderStatus)),
          label: 'Status',
        },
        {
          key: 'output',
          prop: (scope) => {
            const links = [];
            if (scope.row.outputDocument) {
              links.push({
                href: scope.row.outputDocument.url,
                text: 'S3',
              });
              if (scope.row.outputDocument.driveFileId) {
                links.push({
                  href: getDriveFileUrl(scope.row.outputDocument.driveFileId),
                  text: 'Drive',
                });
              }
            }
            return makeLinks(links);
          },
          label: 'Output',
        },
        {
          key: 'createdAt',
          prop: scope => moment(scope.row.createdAt).format('YYYY-MM-DD HH:mm:ss'),
          label: 'Creation',
        },
        {
          key: 'updatedAt',
          prop: scope => moment(scope.row.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
          label: 'Updated',
        },
      ],
      dates: null,
      configVisible: false,
    };
  },
  async mounted() {
    if (this.$route.query && this.$route.query.renderRequestId) {
      this.title = `Static list of request: ${this.$route.query.renderRequestId}`;
    }
    if (this.$route.query && this.$route.query.gameId) {
      await this.fetchGame({ id: +this.$route.query.gameId });
      this.title = `Static list of game: ${this.game.name}`;
    }
  },
  components: {
    VdPagedTable,
    VdListingHeader,
    UploadStaticDialog,
  },
  computed: {
    ...mapState('game', ['game']),
    ...mapState('static', ['loading', 'elements', 'total']),
  },
  methods: {
    ...mapActions('game', ['fetchGame']),
    ...mapActions('static', ['fetchStaticsByPage']),
    getStatus(status) {
      try {
        return config.staticStatuses[status];
      } catch (e) {
        return 'Error';
      }
    },
    handleDates(dates) {
      this.dates = dates;
    },
    openUploadStaticDialog() {
      this.$refs.uploadStaticDialog.openUploadStaticDialog({
        gameId: (this.$route.query && this.$route.query.gameId) || null,
      });
    },
    openConfig() {
      this.configVisible = true;
    },
    closeConfig() {
      this.configVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.staticList {
  display: block;

  .content {
    .table {
      width: 100%;
    }
  }
}
</style>

