<template>
  <div class="staticDialog">
    <el-dialog
      :close-on-click-modal="false"
      :before-close="closeStaticDialog"
      title="Static"
      :visible.sync="staticDialogVisible"
      width="50%"
    >
      <el-form class="form" ref="staticModel" :model="staticModel" @submit.native.prevent="submitStaticForm">
        <el-form-item v-if="staticFormErrors.length">
          <el-alert
            v-for="(error, index) in staticFormErrors"
            :key="index"
            :title="error"
            :closable="false"
            type="error"
            class="error"
          ></el-alert>
        </el-form-item>
        <div v-if="!staticModel.landscape">
          <clipper-upload class="clipper-upload" v-model="imgURL">
            <el-button type="primary">Upload file</el-button>
          </clipper-upload>
          <div class="clippers">
            <clipper-basic
              ref="squareClipper"
              class="my-clipper"
              preview="square"
              :ratio="1"
              :src="imgURL"
            ></clipper-basic>
            <clipper-basic
              ref="landscapeClipper"
              class="my-clipper"
              preview="landscape"
              :ratio="16/9"
              :src="imgURL"
            ></clipper-basic>
            <clipper-basic
              ref="portraitClipper"
              class="my-clipper"
              preview="portrait"
              :ratio="9/16"
              :src="imgURL"
            ></clipper-basic>
          </div>
          <div class="previews" v-if="this.imgURL">
            <clipper-preview class="preview" name="square"></clipper-preview>
            <clipper-preview class="preview" name="landscape"></clipper-preview>
            <clipper-preview class="preview" name="portrait"></clipper-preview>
          </div>
        </div>
        <div v-if="staticModel.landscape">
          <div class="title">
            Background
            <i class="icon el-icon-refresh" @click="resetBackground"></i>
          </div>
          <el-image
            :src="staticModel.square.url"
            alt="Square"
            title="Square background"
            class="bg-preview"
          ></el-image>
          <el-image
            :src="staticModel.landscape.url"
            alt="Landscape"
            title="Landscape background"
            class="bg-preview"
          ></el-image>
          <el-image
            :src="staticModel.portrait.url"
            alt="Portrait"
            title="Portrait background"
            class="bg-preview"
          ></el-image>
        </div>
        <div v-if="!staticModel.logo.image">
          <el-form-item>
            Logo
            <div>
              <el-switch
                class="switch"
                v-model="staticModel.logo.logoSwitch"
                active-text="Text"
                inactive-text="Image"
              ></el-switch>
              <div v-if="!staticModel.logo.logoSwitch" class="logo-image">
                <vd-upload ref="staticLogoUpload" uploadPath="documents/logos/upload"></vd-upload>
              </div>
              <div v-if="staticModel.logo.logoSwitch" class="logo-text">
                <el-select v-model="staticModel.logo.fontId" placeholder="Font" class="selector">
                  <el-option
                    v-for="option in fontElements"
                    :key="option.id"
                    :label="option.name"
                    :value="option.id"
                  ></el-option>
                </el-select>
                <el-input v-model="staticModel.logo.text" placeholder="Logo text"></el-input>
              </div>
            </div>
          </el-form-item>
        </div>
        <div v-if="staticModel.logo.image">
          <div class="title">
            Logo
            <i class="icon el-icon-refresh" @click="resetLogo"></i>
          </div>
          <el-image class="image" :src="staticModel.logo.image.url" alt="Logo" title="Logo"></el-image>
        </div>
        <div v-if="!staticModel.cta">
          <el-form-item class="logo-text">
            CTA
            <div>
              <vd-upload ref="staticCtaUpload" uploadPath="documents/ctas/upload"></vd-upload>
            </div>
          </el-form-item>
        </div>
        <div v-if="staticModel.cta">
          <div class="title">
            CTA
            <i class="icon el-icon-refresh" @click="resetCTA"></i>
          </div>
          <el-image class="image" :src="staticModel.cta.url" alt="CTA" title="CTA"></el-image>
        </div>
        <el-form-item class="submit">
          <el-button @click="closeStaticDialog">Cancel</el-button>
          <el-button type="primary" native-type="submit" :disabled="staticFormSubmit">Save</el-button>
          <el-button type="primary" @click="generate" :disabled="staticFormSubmit">Generate</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { uploadFile } from '@/utils/uploadFile';
import VdUpload from '@/components/vd-upload/VdUpload.vue';
import Config from '@/services/config.service';

export default {
  name: 'static-dialog',
  data() {
    return {
      imgURL: null,
      staticDialogVisible: false,
      staticFormSubmit: false,
      staticFormSubmitTimeout: null,
      staticFormErrors: [],
      staticModel: {
        gameId: null,
        logo: {
          logoSwitch: false,
          text: null,
          image: null,
          fontId: null,
        },
        cta: null,
        landscape: null,
        portrait: null,
        square: null,
      },
      squareClipper: null,
      landscapeClipper: null,
      portraitClipper: null,
      uploadUrl: `${Config.apiUrl}/documents/upload`,
    };
  },
  components: {
    VdUpload,
  },
  async mounted() {
    await this.init();
  },
  beforeDestroy() {
    clearTimeout(this.staticFormSubmitTimeout);
  },
  computed: {
    ...mapState('font', { fontElements: 'elements' }),
    ...mapState('badge', { parentBadgeElements: 'parentBadges' }),
  },
  methods: {
    ...mapActions('font', ['fetchFonts']),
    ...mapActions('badge', ['fetchParentBadges']),
    ...mapActions('game', ['fetchStaticParamsByGameId', 'editStatic']),
    async init() {
      await Promise.all([
        this.fetchFonts(),
        this.fetchParentBadges(),
      ]);
    },
    async openStaticDialog(staticParams = null) {
      await this.init();
      if (staticParams.gameId) {
        const fullParams = await this.fetchStaticParamsByGameId({
          id: staticParams.gameId,
        });
        this.resetStaticModel({
          ...fullParams.static,
          gameId: staticParams.gameId,
        });
      }
      this.staticDialogVisible = true;
    },
    resetStaticModel(staticParams = null) {
      this.staticFormErrors = [];
      this.staticModel = {
        gameId: null,
        logo: {
          logoSwitch: false,
          text: null,
          image: null,
          fontId: null,
        },
        cta: null,
        landscape: null,
        portrait: null,
        square: null,
      };
      if (staticParams) {
        this.staticModel = {
          gameId: staticParams.gameId,
          cta: staticParams.cta,
          logo: {
            logoSwitch: staticParams.logo && !!staticParams.logo.font,
            text: staticParams.logo && staticParams.logo.wording,
            image: staticParams.logo && staticParams.logo.image,
            fontId:
              staticParams.logo && staticParams.logo.font ?
                staticParams.logo.font.id :
                null,
          },
          landscape: staticParams.landscape,
          portrait: staticParams.portrait,
          square: staticParams.square,
        };
      }
      this.imgURL = null;
    },
    closeStaticDialog() {
      this.resetStaticModel();
      this.staticDialogVisible = false;
    },
    staticFormValidation(withClip = true) {
      let valid = true;
      this.staticFormErrors = [];
      if (
        withClip &&
        (!this.staticModel.square ||
          !this.staticModel.landscape ||
          !this.staticModel.portrait)
      ) {
        this.staticFormErrors.push('Static file required');
        valid = false;
      }
      if (this.staticModel.logo && this.staticModel.logo.logoSwitch) {
        if (!this.staticModel.logo.fontId) {
          this.staticFormErrors.push('Font required in text logo mode');
          valid = false;
        }
        if (!this.staticModel.logo.text) {
          this.staticFormErrors.push('Text required in text logo mode');
          valid = false;
        }
      } else if (
        !this.staticModel.logo.image &&
        !this.$refs.staticLogoUpload.document
      ) {
        this.staticFormErrors.push('Image required in image logo mode');
        valid = false;
      }
      if (!this.staticModel.cta && !this.$refs.staticCtaUpload.document) {
        this.staticFormErrors.push('CTA image required');
        valid = false;
      }
      return valid;
    },
    async submitStaticForm(generate = false) {
      this.staticFormSubmit = true;
      this.staticFormSubmitTimeout = setTimeout(async () => {
        const ctaImg =
          this.$refs.staticCtaUpload && this.$refs.staticCtaUpload.document;
        const logoImg =
          this.$refs.staticLogoUpload && this.$refs.staticLogoUpload.document;
        this.staticModel = {
          ...this.staticModel,
          logo: {
            logoSwitch: !!this.staticModel.logo.logoSwitch,
            text:
              !!this.staticModel.logo.logoSwitch && this.staticModel.logo.text,
            image:
              !this.staticModel.logo.logoSwitch &&
              (logoImg || this.staticModel.logo.image),
            fontId:
              !!this.staticModel.logo.logoSwitch && this.staticModel.logo.fontId,
          },
          cta: ctaImg || this.staticModel.cta,
        };
        if (this.imgURL && this.staticFormValidation(false)) {
          const clips = await this.uploadClips();
          if (clips) {
            this.staticModel = { ...this.staticModel, ...clips };
          }
        }
        if (this.staticFormValidation()) {
          await this.sendEditStatic(
            this.staticModel.gameId,
            this.staticModel,
            generate === true,
          );
          this.closeStaticDialog();
        }
        this.staticFormSubmit = false;
      }, 300);
    },
    async generate() {
      this.submitStaticForm(true);
    },
    async sendEditStatic(gameId, staticParams, generate) {
      await this.editStatic({ gameId, staticParams, generate });
    },
    async uploadClips() {
      const squareCanvas = this.$refs.squareClipper.clip();
      const landscapeCanvas = this.$refs.landscapeClipper.clip();
      const portraitCanvas = this.$refs.portraitClipper.clip();

      if (
        (!squareCanvas.width && !squareCanvas.height) ||
        (!landscapeCanvas.width && !landscapeCanvas.height) ||
        (!portraitCanvas.width && !portraitCanvas.height)
      ) {
        return null;
      }

      const [square, landscape, portrait] = await Promise.all([
        uploadFile(
          this.uploadUrl,
          squareCanvas,
          `square_${this.staticModel.name}.jpg`,
        ),
        uploadFile(
          this.uploadUrl,
          landscapeCanvas,
          `landscape_${this.staticModel.name}.jpg`,
        ),
        uploadFile(
          this.uploadUrl,
          portraitCanvas,
          `portrait_${this.staticModel.name}.jpg`,
        ),
      ]);

      return {
        square,
        landscape,
        portrait,
      };
    },
    resetBackground() {
      this.staticModel.square = null;
      this.staticModel.landscape = null;
      this.staticModel.portrait = null;
    },
    resetLogo() {
      this.staticModel.logo = {
        text: null,
        fontId: null,
        image: null,
        logoSwitch: false,
      };
    },
    resetCTA() {
      this.staticModel.cta = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.staticDialog {
  display: block;

  .form {
    .error {
      margin-bottom: 10px;
    }

    .submit {
      text-align: right;
      margin: 0;
    }
  }

  .clipper-upload {
    display: inline-block;
    margin-bottom: 16px;
  }

  .clippers {
    display: flex;
    flex-direction: row;

    .my-clipper {
      width: 100%;
      max-width: 32.33%;
      margin: 0.5%;
    }
  }

  .previews {
    display: flex;
    flex-direction: row;
    margin-top: 32px;

    .preview {
      width: 100%;
      max-width: 32.33%;
      margin: 0.5%;
    }
  }

  .bg-preview {
    width: 100%;
    max-width: 32.33%;
    margin: 0.5%;
    vertical-align: top;
  }

  .title {
    margin: 8px;
    font-weight: 600;
    font-size: 16px;
  }

  .icon {
    margin-left: 8px;
    font-size: 16px;
    cursor: pointer;
  }

  .static-title {
    margin-bottom: 16px;
  }

  .logo-text {
    display: flex;

    .selector {
      margin-right: 16px;
    }

    .wording-autocomplete {
      width: calc(100% - 50px);
    }

    .wording-button {
      margin-left: 10px;
    }
  }

  .switch {
    margin-bottom: 16px;
  }

  .image {
    margin-bottom: 16px;
  }

  /deep/.el-dialog {
    .el-dialog__header {
      margin: 0;
      padding: 15px;
      border-bottom: 1px solid #ebeef5;
    }

    .el-dialog__body {
      padding: 15px;
    }
  }
}

.app-thumbnail {
  width: 24px;
  position: relative;
  top: 7px;
  margin-right: 6px;
}

.import-dialog {
  background-color: #efefef;
  border-radius: 24px;
  padding: 32px;
}
</style>

