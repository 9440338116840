var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"templateDetailDialog"},[_c('el-dialog',{attrs:{"close-on-click-modal":false,"before-close":_vm.closeTemplateDetailDialog,"title":_vm.getTitle(),"visible":_vm.templateDetailDialogVisible,"width":"50%","custom-class":"test-dialog"},on:{"update:visible":function($event){_vm.templateDetailDialogVisible=$event}}},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"sidebar"},_vm._l((_vm.templateElements),function(element){return _c('div',{key:element.id,staticClass:"template",class:{bold: _vm.selectedElement && (element.id === _vm.selectedElement.id)},on:{"click":function($event){return _vm.selectElement(element)}}},[_vm._v(_vm._s(element.name))])}),0),(_vm.selectedElement)?_c('div',{staticClass:"detail"},[_c('vd-property',{attrs:{"title":"Name","value":_vm.selectedElement.name}}),_c('vd-property',{attrs:{"title":"Description","value":_vm.selectedElement.description}}),_c('vd-property',{attrs:{"title":"Template type","value":_vm.selectedElement.templateType.name}}),_c('vd-property',{attrs:{"title":"Key","value":_vm.selectedElement.key}}),_c('vd-property',{attrs:{"title":"Version","value":_vm.selectedElement.version}}),_c('vd-property',{attrs:{"title":"URL","value":_vm.selectedElement.document.url,"type":"link"}}),(_vm.meta)?_c('div',[_c('vd-property',{attrs:{"title":"Orientation","values":_vm.meta.orientation.map(function (ref) {
	var name = ref.name;

	return name;
})}}),_c('vd-property',{attrs:{"title":"Device","values":_vm.meta.device.map(function (ref) {
	var name = ref.name;

	return name;
})}}),_c('vd-property',{attrs:{"title":"Ratio","values":_vm.meta.ratio.map(function (ref) {
	var name = ref.name;

	return name;
})}}),_c('vd-property',{attrs:{"title":"Mechanic","values":_vm.meta.mechanic.map(function (ref) {
	var name = ref.name;

	return name;
})}}),_c('vd-property',{attrs:{"title":"Classification","values":_vm.meta.classification.map(function (ref) {
	var name = ref.name;

	return name;
})}}),_c('vd-property',{attrs:{"title":"Wording","values":_vm.meta.wording.map(function (ref) {
	var text = ref.text;

	return text;
})}}),_c('vd-property',{attrs:{"title":"CTA Wording","values":_vm.meta.cta.map(function (ref) {
	var text = ref.text;

	return text;
})}})],1):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"footer"},[_c('el-button',{on:{"click":_vm.closeTemplateDetailDialog}},[_vm._v("Close")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }