<template>
  <div class="renderList">
    <vd-listing-header :title="title" :actions="headerActions" :datePicker="true" :handleDatesChange="handleDates">
    </vd-listing-header>
    <div class="content">
      <vd-paged-table
        tableKey="renderList"
        :loading="loading"
        :data="elements"
        :totalDataSize="total"
        :paging="fetchRendersByPage"
        :query="$route.query"
        :dates="dates"
        :actions="actions"
        :columns="columns"
        :select="true"
        :handleSelect="handleSelect"
        :cancelSelect="cancelSelect"
        :selectedData="selectedElements"
        :configVisible="configVisible"
        :configClose="closeConfig"
        :refetchTimeout="5000"
      ></vd-paged-table>
    </div>
    <vd-upload-to-campaign-dialog ref="vdUploadToCampaignDialog"></vd-upload-to-campaign-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';

import VdPagedTable from '@/components/vd-paged-table/VdPagedTable';
import VdListingHeader from '@/components/vd-listing-header/VdListingHeader';
import VdUploadToCampaignDialog from '@/dialogs/vd-upload-to-campaign/VdUploadToCampaignDialog';
import config from '../../services/config.service';
import {
  makeLink,
  makeLinks,
  makeRouterLink,
  makeTooltip,
  makeVideo,
  getDriveFileUrl,
} from '../../utils/paged-table';

export default {
  name: 'renderList',
  data() {
    return {
      title: 'Render list',
      headerActions: [
        {
          callback: this.openUploadToCampaignDialog,
          title: 'Upload to campaign',
          icon: 'upload',
          key: 'upload',
        },
        {
          callback: this.openConfig,
          title: 'Configuration',
          icon: 'setting',
          key: 'setting',
        },
      ],
      actions: [
        {
          callback: this.generateVideo,
          title: 'Force generate render',
          icon: 'video-camera-solid',
          key: 'render',
        },
      ],
      columns: [
        {
          key: 'rush.game.name',
          prop: scope => makeRouterLink({
            path: `/game/${scope.row.rush.game.id}`,
            text: scope.row.rush.game.name,
          }),
          label: 'Game',
        },
        {
          key: 'concept',
          prop: scope => scope.row.concept,
          label: 'Concept',
        },
        {
          key: 'rush.name',
          prop: scope => makeLink({
            href: scope.row.rush.document.url,
            text: scope.row.rush.name,
          }),
          label: 'Rush',
        },
        {
          key: 'template.name',
          prop: scope => makeLink({
            href: scope.row.template.document.url,
            text: scope.row.template.name,
          }),
          label: 'Template',
        },
        {
          key: 'network.name',
          prop: scope => scope.row.network.name,
          label: 'Network',
        },
        {
          key: 'format.name',
          prop: scope => scope.row.format.name,
          label: 'Format',
        },
        {
          key: 'language.name',
          prop: scope => scope.row.language.name,
          label: 'Language',
        },
        {
          key: 'render',
          prop: scope => (scope.row.renderDocument ?
            makeVideo({
              src: scope.row.renderDocument.url,
              text: scope.row.name,
            }) :
            scope.row.name),
          label: 'Render',
        },
        {
          key: 'project',
          prop: scope => (scope.row.projectDocument ?
            makeLink({
              href: scope.row.projectDocument.url,
              text: `${scope.row.name}.zip`,
            }) :
            scope.row.name),
          label: 'Project',
        },
        {
          key: 'renderStatus',
          prop: scope => (scope.row.renderMessage ?
            makeTooltip({
              text: this.getStatus(scope.row.renderStatus),
              content: scope.row.renderMessage,
            }) :
            this.getStatus(scope.row.renderStatus)),
          label: 'Status',
        },
        {
          key: 'output',
          prop: (scope) => {
            const links = [];
            if (scope.row.outputDocument) {
              links.push({
                href: scope.row.outputDocument.url,
                text: 'S3',
              });
              if (scope.row.outputDocument.driveFileId) {
                links.push({
                  href: getDriveFileUrl(scope.row.outputDocument.driveFileId),
                  text: 'Drive',
                });
              }
            }
            return makeLinks(links);
          },
          label: 'Output',
        },
        {
          key: 'createdAt',
          prop: scope => moment(scope.row.createdAt).format('YYYY-MM-DD HH:mm:ss'),
          label: 'Creation',
        },
        {
          key: 'updatedAt',
          prop: scope => moment(scope.row.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
          label: 'Updated',
        },
      ],
      dates: null,
      selectedElements: [],
      configVisible: false,
    };
  },
  async mounted() {
    if (this.$route.query && this.$route.query.renderRequestId) {
      this.title = `Render list of request: ${
        this.$route.query.renderRequestId
      }`;
    }
    if (this.$route.query && this.$route.query.gameId) {
      await this.fetchGame({ id: +this.$route.query.gameId });
      this.title = `Render list of game: ${this.game.name}`;
    }
  },
  components: {
    VdPagedTable,
    VdListingHeader,
    VdUploadToCampaignDialog,
  },
  computed: {
    ...mapState('game', ['game']),
    ...mapState('render', ['loading', 'elements', 'total']),
  },
  methods: {
    ...mapActions('game', ['fetchGame']),
    ...mapActions('render', ['fetchRendersByPage', 'generateRender']),
    getStatus(status) {
      try {
        return config.renderStatuses[status];
      } catch (e) {
        return 'Error';
      }
    },
    handleDates(dates) {
      this.dates = dates;
    },
    handleSelect(elements, value) {
      elements.forEach((element) => {
        this.selectedElements = this.selectedElements.filter(elem => elem.id !== element.id);
        if (value) {
          this.selectedElements.push(element);
        }
      });
    },
    cancelSelect() {
      this.selectedElements = [];
    },
    openUploadToCampaignDialog() {
      if (!this.selectedElements.length || this.selectedElements.find(element => !element.outputDocument)) {
        this.$notify({ type: 'warning', message: this.$createElement('b', 'You must select valid renders to upload') });
        return;
      }
      this.$refs.vdUploadToCampaignDialog.openDialog({ elements: this.selectedElements, autocm: false });
    },
    async generateVideo(element) {
      await this.generateRender({
        id: element.id,
      });
    },
    openConfig() {
      this.configVisible = true;
    },
    closeConfig() {
      this.configVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.renderList {
  display: block;

  .content {
    .table {
      width: 100%;
    }
  }
}
</style>

