<template>
  <div class="rushList">
    <vd-listing-header :title="title" :actions="headerActions" :datePicker="true" :handleDatesChange="handleDates">
      <el-select v-model="rushType" placeholder="Select">
        <el-option
          v-for="item in rushTypes"
          :key="item.value"
          :label="item.label"
          :value="item.value"
          @change="onRushTypeChange"
        >
        </el-option>
      </el-select>
    </vd-listing-header>
    <div class="content">
      <vd-paged-table
        tableKey="rushList"
        :loading="loading"
        :data="elements"
        :totalDataSize="total"
        :paging="fetchRushesByPage"
        :query="$route.query"
        :dates="dates"
        :actions="actions"
        :columns="columns"
        :select="true"
        :handleSelect="handleSelect"
        :cancelSelect="cancelSelect"
        :selectedData="selectedElements"
        :configVisible="configVisible"
        :configClose="closeConfig"
        :refetchTimeout="5000"
        :additionalWhere="additionalWhere"
      ></vd-paged-table>
    </div>
    <rush-dialog ref="rushDialog"></rush-dialog>
    <upload-rush-dialog ref="uploadRushDialog"></upload-rush-dialog>
    <vd-upload-to-campaign-dialog ref="vdUploadToCampaignDialog"></vd-upload-to-campaign-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';

import VdPagedTable from '@/components/vd-paged-table/VdPagedTable';
import VdListingHeader from '@/components/vd-listing-header/VdListingHeader';
import RushDialog from '@/views/rush/RushDialog.vue';
import UploadRushDialog from '@/views/rush/UploadRushDialog.vue';
import VdUploadToCampaignDialog from '@/dialogs/vd-upload-to-campaign/VdUploadToCampaignDialog';
import {
  makeVideo, makeLink, makeLinks, makeRouterLink,
} from '../../utils/paged-table';

const RUSH_TYPES = {
  ALL: 'ALL',
  GAMEPLAY: 'GAMEPLAY',
  INFLUENCER: 'INFLUENCER',
};

export default {
  name: 'rushList',
  data() {
    return {
      title: 'Rushes list',
      headerActions: [
        {
          callback: this.openUploadRushDialog,
          title: 'Add',
          icon: 'circle-plus-outline',
          key: 'add',
        },
        {
          callback: this.openUploadToCampaignDialog,
          title: 'Upload to campaign',
          icon: 'upload',
          key: 'upload',
        },
        {
          callback: this.openConfig,
          title: 'Configuration',
          icon: 'setting',
          key: 'setting',
        },
      ],
      actions: [
        {
          callback: this.editAndAdaptItem,
          icon: 'star-on',
          key: 'editAndAdapt',
          title: 'Edit and adapt',
          disableCallback: this.preventEditAndAdapt,
        },
        {
          callback: this.editItem, icon: 'edit', key: 'edit', title: 'Edit',
        },
        {
          callback: this.deleteItem, icon: 'delete', key: 'delete', title: 'delete',
        },
      ],
      columns: [
        {
          key: 'game.name',
          prop: (scope) => {
            const { game } = scope.row;

            if (game === null) {
              return '';
            }

            return makeRouterLink({
              path: `/game/${game.id}`,
              text: game.name,
            });
          },
          label: 'Game',
        },
        {
          key: 'game.code',
          prop: (scope) => {
            const { game } = scope.row;

            if (game === null) {
              return '';
            }
            return makeRouterLink({
              path: `/game/${game.id}`,
              text: game.code,
            });
          },
          label: 'Game Code',
        },
        {
          key: 'name',
          prop: scope => makeLink({
            href: scope.row.document.url,
            text: scope.row.name,
          }),
          label: 'Name',
        },
        {
          key: 'device.name',
          prop: scope => scope.row.device.name,
          label: 'Device',
        },
        {
          key: 'device.format.name',
          prop: scope => scope.row.device.format.name,
          label: 'Format',
        },
        {
          key: 'best',
          prop: scope => (scope.row.best ? 'Yes' : 'No'),
          label: 'Best',
        },
        {
          key: 'rush',
          prop: scope => (scope.row.document ?
            makeVideo({
              src: scope.row.document.url,
              text: scope.row.name,
            }) :
            scope.row.name),
          label: 'Rush',
        },
        {
          key: 'output',
          prop: (scope) => {
            const links = [];
            if (scope.row.outputDocument) {
              links.push({
                href: scope.row.outputDocument.url,
                text: 'S3',
              });
            }
            return makeLinks(links);
          },
          label: 'Output',
        },
        {
          key: 'createdAt',
          prop: scope => moment(scope.row.createdAt).format('YYYY-MM-DD HH:mm:ss'),
          label: 'Creation',
        },
        {
          key: 'updatedAt',
          prop: scope => moment(scope.row.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
          label: 'Updated',
        },
      ],
      dates: null,
      selectedElements: [],
      configVisible: false,
      rushType: RUSH_TYPES.ALL,
      rushTypes: [
        { value: RUSH_TYPES.ALL, label: 'All' },
        { value: RUSH_TYPES.GAMEPLAY, label: 'Gameplay' },
        { value: RUSH_TYPES.INFLUENCER, label: 'Influencer' },
      ],
    };
  },
  async mounted() {
    if (this.$route.query && this.$route.query.gameId) {
      await this.fetchGame({ id: +this.$route.query.gameId });
      this.title = `Rush list of game: ${this.game.name}`;
    }
  },
  components: {
    VdPagedTable,
    VdListingHeader,
    RushDialog,
    UploadRushDialog,
    VdUploadToCampaignDialog,
  },
  computed: {
    ...mapState('game', ['game']),
    ...mapState('rush', ['loading', 'elements', 'total']),
    additionalWhere() {
      return {
        type: this.rushType,
      };
    },
  },
  methods: {
    ...mapActions('game', ['fetchGame']),
    ...mapActions('rush', ['fetchRushesByPage', 'deleteRush']),
    openRushDialog() {
      this.$refs.rushDialog.openRushDialog();
    },
    editItem(element) {
      this.$refs.rushDialog.openRushDialog(element);
    },
    editAndAdaptItem(element) {
      this.$refs.rushDialog.openRushDialog(element, true);
    },
    deleteItem(element) {
      this.$confirm('Do you really want to delete this rush ?')
        .then(async () => {
          await this.deleteRush(element);
        })
        .catch(() => {});
    },
    handleDates(dates) {
      this.dates = dates;
    },
    onRushTypeChange(rushType) {
      this.additionalWhere['rush.type'] = rushType;
    },
    handleSelect(elements, value) {
      elements.forEach((element) => {
        this.selectedElements = this.selectedElements.filter(elem => elem.id !== element.id);
        if (value) {
          this.selectedElements.push(element);
        }
      });
    },
    cancelSelect() {
      this.selectedElements = [];
    },
    openUploadRushDialog() {
      this.$refs.uploadRushDialog.openUploadRushDialog({
        gameId: (this.$route.query && this.$route.query.gameId) || null,
        deviceId: 5, // 1080x1920 by default
      });
    },
    openUploadToCampaignDialog() {
      if (!this.selectedElements.length || this.selectedElements.find(element => !element.outputDocument)) {
        this.$notify({ type: 'warning', message: this.$createElement('b', 'You must select rushes to upload') });
        return;
      }
      this.$refs.vdUploadToCampaignDialog.openDialog({ elements: this.selectedElements, type: 'rush' });
    },
    openConfig() {
      this.configVisible = true;
    },
    closeConfig() {
      this.configVisible = false;
    },
    preventEditAndAdapt(element) {
      return element.game === null;
    },
  },
};
</script>

<style lang="scss" scoped>
.rushList {
  display: block;

  .content {
    .table {
      width: 100%;
    }
  }
}
</style>

