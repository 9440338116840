<template>
  <div class="networkDialog">
    <el-dialog
      :close-on-click-modal="false"
      :before-close="closeNetworkDialog"
      title="Network"
      :visible.sync="networkDialogVisible"
      width="50%"
    >
      <el-form class="form" ref="networkModel" :model="networkModel" @submit.native.prevent="submitNetworkForm">
        <el-form-item v-if="networkFormErrors.length">
          <el-alert
            v-for="(error, index) in networkFormErrors"
            :key="index"
            :title="error"
            :closable="false"
            type="error"
            class="error"
          ></el-alert>
        </el-form-item>
        <el-form-item label="Name">
          <el-input placeholder="Name" v-model="networkModel.name"></el-input>
        </el-form-item>
        <el-form-item label="Formats">
          <el-checkbox-group v-model="networkModel.formatIds">
            <el-checkbox
              v-for="format in formatElements"
              :label="format.id"
              :key="format.id"
            >{{format.name}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item v-if="networkEmailFormVisible" label="Default Network Emails for Creative Uploads">
          <div v-for="(email, index) in networkModel.emails" :key="index">
            <el-row class="email" :gutter="20">
              <el-col :span="20">
                <el-input type="email" placeholder="Email" v-model="networkModel.emails[index]" :key="index">
                  </el-input>
              </el-col>
              <el-col :span="2">
                <div @click="deleteEmail(index)">
                 <i class="el-icon-delete"></i>
                </div>
              </el-col>
            </el-row>
          </div>
          <el-button class="email" @click="addEmail">Add Email</el-button>
        </el-form-item>
        <el-form-item class="submit">
          <el-button @click="closeNetworkDialog">Cancel</el-button>
          <el-button type="primary" native-type="submit" :disabled="networkFormSubmit">Save</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { isNetworkWithEmails } from '../../utils/email';

export default {
  name: 'network-dialog',
  data() {
    return {
      networkDialogVisible: false,
      networkFormSubmit: false,
      networkEmailFormVisible: false,
      networkFormSubmitTimeout: null,
      networkFormErrors: [],
      networkModel: {
        id: null,
        name: null,
        formatIds: [],
        emails: [],
      },
    };
  },
  async mounted() {
    await this.init();
  },
  beforeDestroy() {
    clearTimeout(this.networkFormSubmitTimeout);
  },
  computed: {
    ...mapState('format', { formatElements: 'outputFormats' }),
  },
  methods: {
    ...mapActions('network', ['addNetwork', 'editNetwork']),
    ...mapActions('format', ['fetchOutputFormats']),
    async init() {
      await this.fetchOutputFormats();
    },
    async openNetworkDialog(network = null) {
      await this.init();
      this.resetNetworkModel(network);
      this.networkDialogVisible = true;
      this.networkEmailFormVisible = isNetworkWithEmails(network);
    },
    resetNetworkModel(network = null) {
      this.networkFormErrors = [];
      if (network) {
        this.networkModel = {
          id: network.id,
          name: network.name,
          emails: network.emails || [],
          formatIds: network.formats.map(format => format.id) || [],
        };
      } else {
        this.networkModel = {
          id: null,
          name: null,
          emails: [],
          formatIds: [],
        };
      }
    },
    closeNetworkDialog() {
      this.resetNetworkModel();
      this.networkDialogVisible = false;
    },
    networkFormValidation() {
      let valid = true;
      this.networkFormErrors = [];
      if (!this.networkModel.name) {
        this.networkFormErrors.push('Name required');
        valid = false;
      }
      return valid;
    },
    addEmail() {
      this.networkModel.emails.push('');
    },
    deleteEmail(index) {
      this.$delete(this.networkModel.emails, index);
    },
    async submitNetworkForm() {
      this.networkFormSubmit = true;
      this.networkFormSubmitTimeout = setTimeout(async () => {
        if (this.networkFormValidation()) {
          this.networkModel.emails = this.networkModel.emails.filter(Boolean);
          if (this.networkModel.id) {
            await this.editNetwork(this.networkModel);
          } else {
            await this.addNetwork(this.networkModel);
          }
          this.closeNetworkDialog();
        }
        this.networkFormSubmit = false;
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.networkDialog {
  display: block;

  .form {
    .error {
      margin-bottom: 10px;
    }

    .email {
      margin-top: 10px;
    }

    .submit {
      text-align: right;
      margin: 0;
    }
  }

  /deep/.el-dialog {
    .el-dialog__header {
      margin: 0;
      padding: 15px;
      border-bottom: 1px solid #ebeef5;
    }

    .el-dialog__body {
      padding: 15px;
    }
  }
}
</style>

