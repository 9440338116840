import Vue from 'vue';
import Vuex from 'vuex';
import gql from 'graphql-tag';
import { apolloClient } from '../../utils/vue-apollo';

Vue.use(Vuex);

const GRAPHQL_TEMPLATE_PROPS = `
  id
  name
  description
  templateTypeId
  key
  version
  templateType {
    id
    name
    shortName
    nbKeys
  }
  templateMeta {
    id
    entityId
    entityName
    entityType
  }
  document {
    id
    key
    url
  }
`;

const GRAPHQL = {
  queries: {
    fetchTemplates: gql`
      query {
        templates {
          ${GRAPHQL_TEMPLATE_PROPS}
        }
      }
    `,
    fetchTemplatesByPage: gql`
      query($input: TemplatesByPageInput!) {
        templatesByPage(input: $input) {
          elements {
            ${GRAPHQL_TEMPLATE_PROPS}
          }
          total
        }
      }
    `,
    fetchTemplatesByTypeAndKey: gql`
    query($input: TemplatesByTypeAndKeyInput!) {
      templatesByTypeAndKey(input: $input) {
        elements {
          ${GRAPHQL_TEMPLATE_PROPS}
        }
        total
      }
    }
  `,
  },
  mutations: {
    addTemplate: gql`
      mutation($input: AddTemplateInput!) { 
        addTemplate(
          input: $input 
        ) {
          ${GRAPHQL_TEMPLATE_PROPS}
        }
      }
    `,
    upgradeTemplate: gql`
      mutation($input: UpgradeTemplateInput!) { 
        upgradeTemplate(
          input: $input 
        ) {
          ${GRAPHQL_TEMPLATE_PROPS}
        }
      }
    `,
    deleteTemplate: gql`
      mutation($input: DeleteTemplateInput!) {
        deleteTemplate(input: $input)
      }
    `,
  },
};

export default {
  namespaced: true,
  state: {
    elements: [],
    total: 0,
    loading: false,
    byTypeAndKey: {
      elements: [],
      total: 0,
    },
  },
  mutations: {
    setTemplates(state, { elements, total = 0 }) {
      state.elements = elements;
      state.total = total;
      state.loading = false;
    },
    setTemplatesByTypeAndKey(state, { elements, total = 0 }) {
      state.byTypeAndKey.elements = elements;
      state.byTypeAndKey.total = total;
      state.loading = false;
    },
    pushTemplate(state, item) {
      state.elements.push(item);
      state.total += 1;
      state.loading = false;
    },
    upgradeTemplate(state, item) {
      const index = state.elements.findIndex(
        ({ templateTypeId, key }) => templateTypeId === item.templateTypeId && key === item.key,
      );
      if (index !== -1) {
        state.elements[index] = item;
        state.elements = [...state.elements];
      }
      state.loading = false;
    },
    removeTemplate(state, id) {
      const index = state.elements.findIndex(elem => elem.id === id);
      if (index !== -1) {
        state.elements.splice(index, 1);
        state.total -= 1;
      }
      state.loading = false;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
  },
  actions: {
    async fetchTemplates({ commit }) {
      commit('setLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchTemplates,
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const templates = results && results.data && results.data.templates ? results.data.templates : [];
      commit('setTemplates', { elements: templates, total: templates.length });
    },
    async fetchTemplatesByPage({ commit }, { page = 1, pageSize = 1, options = {} }) {
      commit('setLoading', true);
      const optionsStr = JSON.stringify(options);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchTemplatesByPage,
          variables: {
            input: {
              page,
              pageSize,
              options: optionsStr,
            },
          },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const templates = results && results.data && results.data.templatesByPage ? results.data.templatesByPage : {};
      commit('setTemplates', templates);
    },
    async fetchTemplatesByTypeAndKey({ commit }, { templateTypeId, key }) {
      commit('setLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchTemplatesByTypeAndKey,
          variables: {
            input: {
              templateTypeId,
              key,
            },
          },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const templates =
        results && results.data && results.data.templatesByTypeAndKey ? results.data.templatesByTypeAndKey : {};
      commit('setTemplatesByTypeAndKey', templates);
    },
    async addTemplate({ commit }, {
      description, templateTypeId, key, hasWording, hasCtaWording, meta, document,
    }) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.addTemplate,
          variables: {
            input: {
              description,
              templateTypeId,
              key,
              hasWording,
              hasCtaWording,
              meta: {
                orientationIds: meta.orientationIds,
                mechanicIds: meta.mechanicIds,
                deviceIds: meta.deviceIds,
                ratioIds: meta.ratioIds,
                classificationIds: meta.classificationIds,
                wordingId: meta.wordingId,
                ctaWordingId: meta.ctaWordingId,
              },
              document: {
                id: document.id,
                key: document.key,
                url: document.url,
                fileName: document.fileName,
                fileType: document.fileType,
                fileSize: document.fileSize,
              },
            },
          },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const template = results && results.data && results.data.addTemplate ? results.data.addTemplate : null;
      if (template) {
        commit('pushTemplate', template);
      } else {
        commit('setLoading', false);
      }
    },
    async upgradeTemplate(
      { commit },
      {
        id, description, templateTypeId, key, hasWording, hasCtaWording, meta, document,
      },
    ) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.upgradeTemplate,
          variables: {
            input: {
              id,
              description,
              templateTypeId,
              key,
              hasWording,
              hasCtaWording,
              meta: {
                orientationIds: meta.orientationIds,
                mechanicIds: meta.mechanicIds,
                deviceIds: meta.deviceIds,
                ratioIds: meta.ratioIds,
                classificationIds: meta.classificationIds,
                wordingId: meta.wordingId,
                ctaWordingId: meta.ctaWordingId,
              },
              document: {
                id: document.id,
                key: document.key,
                url: document.url,
                fileName: document.fileName,
                fileType: document.fileType,
                fileSize: document.fileSize,
              },
            },
          },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const template = results && results.data && results.data.upgradeTemplate ? results.data.upgradeTemplate : null;
      if (template) {
        commit('upgradeTemplate', template);
      } else {
        commit('setLoading', false);
      }
    },
    async deleteTemplate({ commit }, { id }) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.deleteTemplate,
          variables: {
            input: { id },
          },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const deleted = results && results.data && results.data.deleteTemplate;
      if (deleted) {
        commit('removeTemplate', id);
      } else {
        commit('setLoading', false);
      }
    },
  },
};
