import Vue from 'vue';
import Vuex from 'vuex';
import gql from 'graphql-tag';
import { apolloClient } from '../../utils/vue-apollo';

Vue.use(Vuex);

const GRAPHQL_RENDER_PRESET_PROPS = `
  id
  name
  meta {
    templateTypeId
    rushIds
    templateIds
    wordingIds
    networkFormatIds {
      networkId
      formatId
    }
    languageIds
  }
`;

const GRAPHQL = {
  queries: {
    fetchRenderPresets: gql`
      query {
        renderPresets {
          ${GRAPHQL_RENDER_PRESET_PROPS}
        }
      }
    `,
    fetchRenderPresetsByPage: gql`
      query($input: RenderPresetsByPageInput!) {
        renderPresetsByPage(input: $input) {
          elements {
            ${GRAPHQL_RENDER_PRESET_PROPS}
          }
          total
        }
      }
    `,
  },
  mutations: {
    addRenderPreset: gql`
      mutation($input: AddRenderPresetInput!) { 
        addRenderPreset(
          input: $input 
        ) {
          ${GRAPHQL_RENDER_PRESET_PROPS}
        }
      }
    `,
    editRenderPreset: gql`
      mutation($input: EditRenderPresetInput!) { 
        editRenderPreset(
          input: $input 
        ) {
          ${GRAPHQL_RENDER_PRESET_PROPS}
        }
      }
    `,
    deleteRenderPreset: gql`
      mutation($input: DeleteRenderPresetInput!) {
        deleteRenderPreset(input: $input)
      }
    `,
  },
};

export default {
  namespaced: true,
  state: {
    elements: [],
    total: 0,
    loading: false,
  },
  mutations: {
    setRenderPresets(state, { elements, total = 0 }) {
      state.elements = elements;
      state.total = total;
      state.loading = false;
    },
    pushRenderPreset(state, item) {
      state.elements.push(item);
      state.total += 1;
      state.loading = false;
    },
    updateRenderPreset(state, item) {
      const index = state.elements.findIndex(elem => elem.id === item.id);
      if (index !== -1) {
        state.elements[index] = item;
        state.elements = [...state.elements];
      }
      state.loading = false;
    },
    removeRenderPreset(state, id) {
      const index = state.elements.findIndex(elem => elem.id === id);
      if (index !== -1) {
        state.elements.splice(index, 1);
        state.total -= 1;
      }
      state.loading = false;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
  },
  actions: {
    async fetchRenderPresets({ commit }) {
      commit('setLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchRenderPresets,
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const renderPresets = results && results.data && results.data.renderPresets ? results.data.renderPresets : [];
      commit('setRenderPresets', { elements: renderPresets, total: renderPresets.length });
    },
    async fetchRenderPresetsByPage({ commit }, { page = 1, pageSize = 1, options = {} }) {
      commit('setLoading', true);
      const optionsStr = JSON.stringify(options);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchRenderPresetsByPage,
          variables: {
            input: {
              page,
              pageSize,
              options: optionsStr,
            },
          },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const renderPresets =
        results && results.data && results.data.renderPresetsByPage ? results.data.renderPresetsByPage : {};
      commit('setRenderPresets', renderPresets);
    },
    async addRenderPreset({ commit }, { name, meta }) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.addRenderPreset,
          variables: {
            input: {
              name,
              meta: {
                templateTypeId: meta.templateTypeId,
                rushIds: meta.rushIds,
                templateIds: meta.templateIds,
                wordingIds: meta.wordingIds,
                networkFormatIds: meta.networkFormatIds,
                languageIds: meta.languageIds,
              },
            },
          },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const renderPreset =
        results && results.data && results.data.addRenderPreset ? results.data.addRenderPreset : null;
      if (renderPreset) {
        commit('pushRenderPreset', renderPreset);
      } else {
        commit('setLoading', false);
      }
    },
    async editRenderPreset({ commit }, { id, name, meta }) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.editRenderPreset,
          variables: {
            input: {
              id,
              name,
              meta: {
                templateTypeId: meta.templateTypeId,
                rushIds: meta.rushIds,
                templateIds: meta.templateIds,
                wordingIds: meta.wordingIds,
                networkFormatIds: meta.networkFormatIds,
                languageIds: meta.languageIds,
              },
            },
          },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const renderPreset =
        results && results.data && results.data.editRenderPreset ? results.data.editRenderPreset : null;
      if (renderPreset) {
        commit('updateRenderPreset', renderPreset);
      } else {
        commit('setLoading', false);
      }
    },
    async deleteRenderPreset({ commit }, { id }) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.deleteRenderPreset,
          variables: {
            input: { id },
          },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const deleted = results && results.data && results.data.deleteRenderPreset;
      if (deleted) {
        commit('removeRenderPreset', id);
      } else {
        commit('setLoading', false);
      }
    },
  },
};
