<template>
  <div class="rushDialog">
    <el-dialog
      :close-on-click-modal="false"
      :before-close="closeRushDialog"
      :title="`Rush ${isAdaptation ? 'adaptation' : '' }`"
      :visible.sync="rushDialogVisible"
      width="50%"
    >
      <el-form class="form" ref="form" :model="rushModel" @submit.native.prevent="submitRushForm">
        <el-form-item v-if="rushFormErrors.length">
          <el-alert
            v-for="(error, index) in rushFormErrors"
            :key="index"
            :title="error"
            :closable="false"
            type="error"
            class="error"
          ></el-alert>
        </el-form-item>
        <el-form-item v-if="!isAdaptation">
          <el-select v-model="rushModel.gameId" placeholder="Game">
            <el-option
              v-for="option in gameElements"
              :key="option.id"
              :label="option.name"
              :value="option.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="!isAdaptation">
          <el-select v-model="rushModel.deviceId" placeholder="Device">
            <el-option
              v-for="option in deviceElements"
              :key="option.id"
              :label="`${option.name} (${option.format.name}${
                option.format.ratio ? ` - ${option.format.ratio.name}` : ''
              })`"
              :value="option.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Best rush">
          <el-checkbox v-model="rushModel.best"></el-checkbox>
        </el-form-item>
        <el-form-item v-if="!isAdaptation" label="Description">
          <el-input
            type="textarea"
            :rows="3"
            placeholder="Description"
            v-model="rushModel.description"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="!isAdaptation">
          <vd-upload
            v-if="!rushModel.id"
            ref="upload"
            uploadPath="documents/rushes/upload"
            @upload="onChangeUpload"
          ></vd-upload>
        </el-form-item>
        <el-form-item v-if="rushModel.document" label="Area of interest:">
          <vd-video-position
            ref="videoPosition"
            :document="rushModel.document"
            :offsetVertical="rushModel.offsetVertical"
            :offsetHorizontal="rushModel.offsetHorizontal"
            @position="onChangePosition"
          ></vd-video-position>
        </el-form-item>
        <el-form-item class="submit">
          <el-button @click="closeRushDialog">Cancel</el-button>
          <el-button type="primary" native-type="submit" :disabled="rushFormSubmit">
            Save {{ isAdaptation ? '& Adapt' : '' }}
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import VdUpload from '@/components/vd-upload/VdUpload.vue';
import VdVideoPosition from '@/components/vd-video-position/VdVideoPosition.vue';
import { mapState, mapActions } from 'vuex';

const rushFormData = {
  rushModel: {
    id: null,
    description: null,
    offsetVertical: 0,
    offsetHorizontal: 0,
    best: null,
    gameId: null,
    deviceId: null,
    document: null,
  },
};

export default {
  name: 'rush-dialog',
  data() {
    return {
      rushDialogVisible: false,
      rushFormSubmit: false,
      rushFormSubmitTimeout: null,
      rushFormErrors: [],
      rushModel: { ...rushFormData.rushModel },
      isAdaptation: false,
    };
  },
  async mounted() {
    await this.init();
  },
  beforeDestroy() {
    clearTimeout(this.rushFormSubmitTimeout);
  },
  components: {
    VdUpload,
    VdVideoPosition,
  },
  computed: {
    ...mapState('game', { gameElements: 'elements' }),
    ...mapState('device', { deviceElements: 'elements' }),
  },
  methods: {
    ...mapActions('rush', ['addRush', 'editRush']),
    ...mapActions('game', ['fetchGames']),
    ...mapActions('device', ['fetchDevices']),
    async init() {
      await Promise.all([
        this.fetchGames(),
        this.fetchDevices(),
      ]);
    },
    async openRushDialog(rush = null, isAdaptation = false) {
      await this.init();
      this.resetRushModel(rush);
      this.isAdaptation = isAdaptation;
      if (this.$refs.upload) {
        this.$refs.upload.resetUpload();
      }
      this.rushDialogVisible = true;
    },
    resetRushModel(rush = null) {
      this.rushFormErrors = [];
      if (rush) {
        this.rushModel = { ...rush };
      } else {
        this.rushModel = { ...rushFormData.rushModel };
      }
    },
    onChangeUpload(document) {
      this.rushModel.document = null;
      setTimeout(() => {
        this.rushModel.document = document;
      }, 250);
    },
    onChangePosition(position) {
      this.rushModel.offsetVertical = position.offsetVertical || 0;
      this.rushModel.offsetHorizontal = position.offsetHorizontal || 0;
    },
    closeRushDialog() {
      this.resetRushModel();
      this.rushDialogVisible = false;
    },
    rushFormValidation() {
      let valid = true;
      this.rushFormErrors = [];
      if (!this.rushModel.deviceId) {
        this.rushFormErrors.push('Device required');
        valid = false;
      }
      if (!this.rushModel.document) {
        this.rushFormErrors.push('MP4 file required');
        valid = false;
      }
      return valid;
    },
    async submitRushForm() {
      this.rushFormSubmit = true;
      this.rushFormSubmitTimeout = setTimeout(async () => {
        if (this.$refs.upload) {
          this.rushModel.document = this.$refs.upload.document;
        }
        if (this.rushFormValidation()) {
          if (this.rushModel.id) {
            await this.editRush({ ...this.rushModel, isAdaptation: this.isAdaptation });
          } else {
            await this.addRush(this.rushModel);
          }
          this.closeRushDialog();
        }
        this.rushFormSubmit = false;
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.rushDialog {
  display: block;

  .form {
    .error {
      margin-bottom: 10px;
    }

    .submit {
      text-align: right;
      margin: 0;
    }
  }

  /deep/.el-dialog {
    .el-dialog__header {
      margin: 0;
      padding: 15px;
      border-bottom: 1px solid #ebeef5;
    }

    .el-dialog__body {
      padding: 15px;
    }
  }
}
</style>

