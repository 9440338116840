<template>
  <div class="wordingDialog">
    <el-dialog
      :close-on-click-modal="false"
      :before-close="closeWordingDialog"
      title="Wording"
      :visible.sync="wordingDialogVisible"
      width="50%"
    >
      <el-form
        class="form"
        ref="wordingModel"
        label-width="120px"
        :model="wordingModel"
        @submit.native.prevent="submitWordingForm"
      >
        <el-form-item v-if="wordingFormErrors.length">
          <el-alert
            v-for="(error, index) in wordingFormErrors"
            :key="index"
            :title="error"
            :closable="false"
            type="error"
            class="error"
          ></el-alert>
        </el-form-item>
        <el-form-item label="Text">
          <el-input type="textarea" :rows="3" placeholder="Text" v-model="wordingModel.text"></el-input>
        </el-form-item>
        <el-form-item label="Types">
          <el-checkbox-group v-model="wordingModel.wordingTypeIds">
            <el-checkbox
              v-for="wordingType in wordingTypeElements"
              :label="wordingType.id"
              :key="wordingType.id"
            >{{wordingType.name}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="Default on">
          <el-checkbox-group v-model="wordingModel.wordingTypeDefaultIds">
            <el-checkbox
              v-for="wordingType in wordingTypeElements"
              :label="wordingType.id"
              :key="wordingType.id"
            >{{wordingType.name}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item class="submit">
          <el-button @click="closeWordingDialog">Cancel</el-button>
          <el-button type="primary" native-type="submit" :disabled="wordingFormSubmit">Save</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'wording-dialog',
  data() {
    return {
      wordingDialogVisible: false,
      wordingFormSubmit: false,
      wordingFormSubmitTimeout: null,
      wordingFormErrors: [],
      wordingModel: {
        id: null,
        text: null,
        wordingTypeIds: [],
        wordingTypeDefaultIds: [],
      },
    };
  },
  async mounted() {
    await this.init();
  },
  beforeDestroy() {
    clearTimeout(this.wordingFormSubmitTimeout);
  },
  computed: {
    ...mapState('wordingType', { wordingTypeElements: 'elements' }),
  },
  methods: {
    ...mapActions('wording', ['addWording', 'editWording']),
    ...mapActions('wordingType', ['fetchWordingTypes']),
    async init() {
      await this.fetchWordingTypes();
    },
    async openWordingDialog(wording = null) {
      await this.init();
      this.resetWordingModel(wording);
      this.wordingDialogVisible = true;
    },
    resetWordingModel(wording = null) {
      this.wordingFormErrors = [];
      if (wording) {
        this.wordingModel = {
          id: wording.id,
          text: wording.text,
          wordingTypeIds:
            wording.wordingTypes.map(wordingType => wordingType.id) || [],
          wordingTypeDefaultIds:
            wording.wordingTypes
              .filter(wordingType => wordingType.default)
              .map(wordingType => wordingType.id) || [],
        };
      } else {
        this.wordingModel = {
          id: null,
          text: null,
          wordingTypeIds: [],
          wordingTypeDefaultIds: [],
        };
      }
    },
    closeWordingDialog() {
      this.resetWordingModel();
      this.wordingDialogVisible = false;
    },
    wordingFormValidation() {
      let valid = true;
      this.wordingFormErrors = [];
      if (!this.wordingModel.text) {
        this.wordingFormErrors.push('Text required');
        valid = false;
      }
      return valid;
    },
    async submitWordingForm() {
      this.wordingFormSubmit = true;
      this.wordingFormSubmitTimeout = setTimeout(async () => {
        if (this.wordingFormValidation()) {
          if (this.wordingModel.id) {
            await this.editWording(this.wordingModel);
          } else {
            await this.addWording(this.wordingModel);
          }
          this.closeWordingDialog();
        }
        this.wordingFormSubmit = false;
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.wordingDialog {
  display: block;

  .form {
    .error {
      margin-bottom: 10px;
    }

    .submit {
      text-align: right;
      margin: 0;
    }
  }

  /deep/.el-dialog {
    .el-dialog__header {
      margin: 0;
      padding: 15px;
      border-bottom: 1px solid #ebeef5;
    }

    .el-dialog__body {
      padding: 15px;
    }
  }
}
</style>

