import { render, staticRenderFns } from "./TemplateDetailDialog.vue?vue&type=template&id=21b02862&scoped=true&"
import script from "./TemplateDetailDialog.vue?vue&type=script&lang=js&"
export * from "./TemplateDetailDialog.vue?vue&type=script&lang=js&"
import style0 from "./TemplateDetailDialog.vue?vue&type=style&index=0&id=21b02862&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21b02862",
  null
  
)

export default component.exports