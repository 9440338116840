import Vue from 'vue';
import Vuex from 'vuex';
import gql from 'graphql-tag';
import { apolloClient } from '../../utils/vue-apollo';

Vue.use(Vuex);

const GRAPHQL_DEVICE_PROPS = `
  id
  name
  formatId
  format {
    id
    name
    ratio {
      id
      name
    }
  }
`;

const GRAPHQL = {
  queries: {
    fetchDevices: gql`
      query {
        devices {
          ${GRAPHQL_DEVICE_PROPS}
        }
      }
    `,
    fetchDevicesByPage: gql`
      query($input: DevicesByPageInput!) {
        devicesByPage(input: $input) {
          elements {
            ${GRAPHQL_DEVICE_PROPS}
          }
          total
        }
      }
    `,
  },
  mutations: {
    addDevice: gql`
      mutation($input: AddDeviceInput!) { 
        addDevice(input: $input) {
          ${GRAPHQL_DEVICE_PROPS}
        }
      }
    `,
    editDevice: gql`
      mutation($input: EditDeviceInput!) { 
        editDevice(input: $input) {
          ${GRAPHQL_DEVICE_PROPS}
        }
      }
    `,
    deleteDevice: gql`
      mutation($input: DeleteDeviceInput!) {
        deleteDevice(input: $input)
      }
    `,
  },
};

export default {
  namespaced: true,
  state: {
    elements: [],
    total: 0,
    loading: false,
  },
  mutations: {
    setDevices(state, { elements, total = 0 }) {
      state.elements = elements;
      state.total = total;
      state.loading = false;
    },
    pushDevice(state, item) {
      state.elements.push(item);
      state.total += 1;
      state.loading = false;
    },
    updateDevice(state, item) {
      const index = state.elements.findIndex(elem => elem.id === item.id);
      if (index !== -1) {
        state.elements[index] = item;
        state.elements = [...state.elements];
      }
      state.loading = false;
    },
    removeDevice(state, id) {
      const index = state.elements.findIndex(elem => elem.id === id);
      if (index !== -1) {
        state.elements.splice(index, 1);
        state.total -= 1;
      }
      state.loading = false;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
  },
  actions: {
    async fetchDevices({ commit }) {
      commit('setLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchDevices,
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const devices = results && results.data && results.data.devices ? results.data.devices : [];
      commit('setDevices', { elements: devices, total: devices.length });
    },
    async fetchDevicesByPage({ commit }, { page = 1, pageSize = 1, options = {} }) {
      commit('setLoading', true);
      const optionsStr = JSON.stringify(options);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchDevicesByPage,
          variables: {
            input: {
              page,
              pageSize,
              options: optionsStr,
            },
          },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const devices = results && results.data && results.data.devicesByPage ? results.data.devicesByPage : {};
      commit('setDevices', devices);
    },
    async addDevice({ commit }, { name, formatId }) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.addDevice,
          variables: {
            input: {
              name,
              formatId,
            },
          },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const device = results && results.data && results.data.addDevice ? results.data.addDevice : null;
      if (device) {
        commit('pushDevice', device);
      } else {
        commit('setLoading', false);
      }
    },
    async editDevice({ commit }, { id, name, formatId }) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.editDevice,
          variables: {
            input: {
              id,
              name,
              formatId,
            },
          },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const device = results && results.data && results.data.editDevice ? results.data.editDevice : null;
      if (device) {
        commit('updateDevice', device);
      } else {
        commit('setLoading', false);
      }
    },
    async deleteDevice({ commit }, { id }) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.deleteDevice,
          variables: { input: { id } },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const deleted = results && results.data && results.data.deleteDevice;
      if (deleted) {
        commit('removeDevice', id);
      } else {
        commit('setLoading', false);
      }
    },
  },
};
