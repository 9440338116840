<template>
  <div class="fontList">
    <vd-listing-header :title="title" :actions="headerActions"></vd-listing-header>
    <div class="content">
      <vd-paged-table
        tableKey="fontList"
        :loading="loading"
        :data="elements"
        :totalDataSize="total"
        :paging="fetchFontsByPage"
        :actions="actions"
        :columns="columns"
        :configVisible="configVisible"
        :configClose="closeConfig"
      ></vd-paged-table>
    </div>
    <font-dialog ref="fontDialog"></font-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import FontDialog from '@/views/font/FontDialog.vue';
import VdPagedTable from '@/components/vd-paged-table/VdPagedTable';
import VdListingHeader from '@/components/vd-listing-header/VdListingHeader';
import { makeLink } from '../../utils/paged-table';

export default {
  name: 'fontList',
  data() {
    return {
      title: 'Font list',
      headerActions: [
        {
          callback: this.openFontDialog,
          title: 'Add',
          icon: 'circle-plus-outline',
          key: 'add',
        },
        {
          callback: this.openConfig,
          title: 'Configuration',
          icon: 'setting',
          key: 'setting',
        },
      ],
      actions: [
        { callback: this.editItem, icon: 'edit', key: 'edit' },
        { callback: this.deleteItem, icon: 'delete', key: 'delete' },
      ],
      columns: [
        {
          key: 'name',
          prop: scope => makeLink({
            href: scope.row.document.url,
            text: scope.row.name,
          }),
          label: 'Font',
        },
      ],
      configVisible: false,
    };
  },
  components: {
    FontDialog,
    VdPagedTable,
    VdListingHeader,
  },
  computed: {
    ...mapState('font', ['loading', 'elements', 'total']),
  },
  methods: {
    ...mapActions('font', ['fetchFontsByPage', 'deleteFont']),
    openFontDialog() {
      this.$refs.fontDialog.openFontDialog();
    },
    editItem(element) {
      this.$refs.fontDialog.openFontDialog(element);
    },
    deleteItem(element) {
      this.$confirm('Do you really want to delete this font ?')
        .then(async () => {
          await this.deleteFont(element);
        })
        .catch(() => {});
    },
    openConfig() {
      this.configVisible = true;
    },
    closeConfig() {
      this.configVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.fontList {
  display: block;

  .content {
    .table {
      width: 100%;
    }
  }
}
</style>

