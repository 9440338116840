<template>
  <div class="endcardDialog">
    <el-dialog
      :close-on-click-modal="false"
      :before-close="closeEndcardDialog"
      title="Endcard"
      :visible.sync="endcardDialogVisible"
      width="50%"
    >
      <el-form
        class="form"
        ref="endcardModel"
        label-width="120px"
        :model="endcardModel"
        @submit.native.prevent="submitEndcardForm"
      >
        <el-form-item v-if="endcardFormErrors.length">
          <el-alert
            v-for="(error, index) in endcardFormErrors"
            :key="index"
            :title="error"
            :closable="false"
            type="error"
            class="error"
          ></el-alert>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="endcardModel.endcardTemplateId"
            placeholder="Template"
            @change="onSelectEndcardTemplate"
          >
            <el-option
              v-for="option in endcardTemplateElements"
              :key="option.id"
              :label="option.name"
              :value="option.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-for="config in endcardModel.config" :key="config.id" :label="config.name">
          <div class="el-input" v-if="config.type === 'string'">
            <input class="el-input__inner" v-model="config.value" :placeholder="config.name">
          </div>
          <div v-if="config.type === 'icon'">
            <div v-if="config.value">
              <el-image class="icon-image" :src="config.value"></el-image>
              <i class="icon el-icon-close icon-close" @click="onDeleteImage(config.key)"></i>
            </div>
            <div v-if="!config.value">
              <vd-upload
                uploadPath="documents/images/upload"
                @upload="onUploadImage($event, config.key)"
              ></vd-upload>
            </div>
          </div>
          <div v-if="config.type === 'wording'">
            <el-autocomplete
              class="wording-autocomplete"
              v-model="wordings[config.key]"
              :placeholder="config.name"
              :label="config.name"
              :fetch-suggestions="onSearchWording"
              @select="onSelectWording($event, config.key)"
            ></el-autocomplete>
          </div>
          <div v-if="config.type === 'rush'">
            <el-select v-model="config.value" :placeholder="config.name" :label="config.name">
              <el-option
                v-for="option in rushElements"
                :key="`${config.id}-${option.id}`"
                :label="option.name"
                :value="option.id"
              ></el-option>
            </el-select>
            <span>
              Start at:
              <el-input-number v-model="config.startAt" :min="0" :max="60" placeholder="0"></el-input-number>
            </span>
          </div>
        </el-form-item>
        <el-form-item class="submit">
          <el-button @click="closeEndcardDialog">Cancel</el-button>
          <el-button type="primary" native-type="submit" :disabled="endcardFormSubmit">Save</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import VdUpload from '@/components/vd-upload/VdUpload.vue';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'endcard-dialog',
  data() {
    return {
      endcardDialogVisible: false,
      endcardFormSubmit: false,
      endcardFormSubmitTimeout: null,
      endcardFormErrors: [],
      endcardModel: {
        gameId: null,
        endcardTemplateId: null,
        config: [],
      },
      app: null,
      rushElements: [],
      wordings: {},
    };
  },
  components: {
    VdUpload,
  },
  async mounted() {
    await this.init();
  },
  beforeDestroy() {
    clearTimeout(this.endcardFormSubmitTimeout);
  },
  computed: {
    ...mapState('endcardTemplate', { endcardTemplateElements: 'elements' }),
    ...mapState('wording', { wordingElements: 'elements' }),
  },
  methods: {
    ...mapActions('appService', ['fetchAppByGameAppId']),
    ...mapActions('rush', ['fetchRushesByGameId']),
    ...mapActions('endcardTemplate', ['fetchEndcardTemplates']),
    ...mapActions('endcardRender', ['generateEndcardRenders']),
    ...mapActions('wording', ['fetchWordings']),
    async init() {
      await Promise.all([
        this.fetchEndcardTemplates(),
        this.fetchWordings(),
      ]);
    },
    async openEndcardDialog(game = null) {
      await this.init();
      this.resetEndcardModel(game);
      this.app = await this.fetchAppByGameAppId({ appId: game.appId });
      this.rushElements = await this.fetchRushesByGameId({ gameId: game.id });
      this.endcardDialogVisible = true;
    },
    resetEndcardModel(game = null) {
      this.endcardFormErrors = [];
      this.endcardModel = {
        gameId: game ? game.id : null,
        endcardTemplateId: null,
        config: [],
      };
      this.app = null;
      this.rushElements = [];
      this.wordings = {};
    },
    closeEndcardDialog() {
      this.resetEndcardModel();
      this.endcardDialogVisible = false;
    },
    getEndcardTemplateById(endcardTemplateId) {
      return (
        this.endcardTemplateElements.find(
          elem => elem.id === endcardTemplateId,
        ) || this.endcardTemplateElements[0]
      );
    },
    setEndcardModelConfigValue(configKey, value) {
      const index = this.endcardModel.config.findIndex(
        elem => elem.key === configKey,
      );
      if (index !== -1) {
        this.endcardModel.config[index].value = value;
        this.endcardModel.config = [...this.endcardModel.config];
      }
    },
    onSelectEndcardTemplate(endcardTemplateId) {
      const endcardTemplate = this.getEndcardTemplateById(endcardTemplateId);
      this.endcardModel.config = endcardTemplate.config
        .map(config => ({
          ...config,
        }))
        .sort((a, b) => a.id - b.id);
      this.setEndcardModelConfigValue('GAME_NAME', this.app.name);
      this.setEndcardModelConfigValue('GAME_ICON', this.app.iconUrl);
      this.setEndcardModelConfigValue('APPSTORE_URL', this.app.appStoreUrl);
      this.setEndcardModelConfigValue('PLAYSTORE_URL', this.app.playStoreUrl);
    },
    searchWording(wordings = [], queryString, cb) {
      wordings.sort((a, b) => a.text.length - b.text.length);
      if (queryString) {
        wordings = wordings.filter(wording => wording.text.toLowerCase().includes(queryString.toLowerCase()));
      }
      cb(wordings.map(wording => ({ id: wording.id, value: wording.text })));
    },
    onSearchWording(queryString, cb) {
      this.searchWording([...this.wordingElements], queryString, cb);
    },
    onSelectWording(event, configKey) {
      this.setEndcardModelConfigValue(configKey, event.id);
    },
    onDeleteImage(configKey) {
      this.setEndcardModelConfigValue(configKey, null);
    },
    onUploadImage(event, configKey) {
      this.setEndcardModelConfigValue(configKey, event.url);
    },
    endcardFormValidation() {
      let valid = true;
      this.endcardFormErrors = [];
      if (!this.endcardModel.gameId) {
        this.endcardFormErrors.push('Game required');
        valid = false;
      }
      if (!this.endcardModel.endcardTemplateId) {
        this.endcardFormErrors.push('Template required');
        valid = false;
      }
      this.endcardModel.config.forEach((config) => {
        if (!config.value) {
          this.endcardFormErrors.push(`${config.name} required`);
          valid = false;
        }
      });
      return valid;
    },
    async submitEndcardForm() {
      this.endcardFormSubmit = true;
      this.endcardFormSubmitTimeout = setTimeout(async () => {
        if (this.endcardFormValidation()) {
          this.generateEndcardRenders(this.endcardModel);
          this.closeEndcardDialog();
        }
        this.endcardFormSubmit = false;
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.endcardDialog {
  display: block;

  .form {
    .error {
      margin-bottom: 10px;
    }

    .submit {
      text-align: right;
      margin: 0;
    }
  }

  .icon-image {
    max-width: 64px;
  }

  .icon-close {
    cursor: pointer;
    color: red;
    font-weight: bold;
    padding: 5px;
  }

  .wording-autocomplete {
    width: 100%;
  }

  /deep/.el-dialog {
    .el-dialog__header {
      margin: 0;
      padding: 15px;
      border-bottom: 1px solid #ebeef5;
    }

    .el-dialog__body {
      padding: 15px;
    }
  }
}
</style>

