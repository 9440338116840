<template>
  <div class="render-tree">
    <render-input
      v-for="(entry, index) of tree"
      v-bind:key="entry.key"
      :entryKey="entry.key"
      :title="entry.title"
      :type="entry.type"
      :dataSource="entry.dataSource"
      :value="value[entry.key]"
      :disabled="getDisabled(index)"
      :withSearch="entry.withSearch"
      :onChange="onChangeValue"
      ref="renderInput"
    ></render-input>
  </div>
</template>

<script>
import RenderInput from './RenderInput.vue';

export default {
  name: 'render-tree',
  data() {
    return {};
  },
  props: ['value', 'tree', 'selectionDone', 'onChange'],
  components: {
    RenderInput,
  },
  computed: {},
  methods: {
    onChangeValue(newValue, entry) {
      this.onChange(newValue, entry.key, entry);
    },
    getDisabled(index) {
      return (
        index < this.tree.length - 2 ||
        (index < this.tree.length - 1 && this.selectionDone)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.render-tree {
  display: inline-block;
  width: calc(100% - 16px);
  overflow: auto;
  border: solid 1px #e6e6e6;
  border-radius: 8px;
  padding: 8px;
  background-color: #fff;
}
</style>

