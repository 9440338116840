const rowsToCsvString = (rows) => {
  if (rows && rows.length) {
    return `${rows.map(col => col.join(',')).join('\n')}`;
  }
  return '';
};

const downloadCsv = (name = 'data', rows) => {
  if (rows && rows.length) {
    const link = document.createElement('a');
    link.setAttribute('href', encodeURI(`data:text/csv;charset=utf-8,${this.rowsToCsvString(rows)}`));
    link.setAttribute('download', `${name}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

exports.rowsToCsvString = rowsToCsvString;
exports.downloadCsv = downloadCsv;
