import axios from 'axios';
import correlationHeader from './correlation-header';

// eslint-disable-next-line import/prefer-default-export
export function uploadFile(url, canvas, fileName) {
  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      const formData = new FormData();
      formData.append('file', blob, fileName);
      axios
        .post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            ...correlationHeader(),
          },
        })
        .then(res => resolve(res.data))
        .catch(reject);
    });
  });
}


