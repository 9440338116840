<template>
  <div class="deviceList">
    <vd-listing-header :title="title" :actions="headerActions"></vd-listing-header>
    <div class="content">
      <vd-paged-table
        tableKey="deviceList"
        :loading="loading"
        :data="elements"
        :totalDataSize="total"
        :paging="fetchDevicesByPage"
        :actions="actions"
        :columns="columns"
        :configVisible="configVisible"
        :configClose="closeConfig"
      ></vd-paged-table>
    </div>
    <device-dialog ref="deviceDialog"></device-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import DeviceDialog from '@/views/device/DeviceDialog.vue';
import VdPagedTable from '@/components/vd-paged-table/VdPagedTable';
import VdListingHeader from '@/components/vd-listing-header/VdListingHeader';

export default {
  name: 'deviceList',
  data() {
    return {
      title: 'Device list',
      headerActions: [
        {
          callback: this.openDeviceDialog,
          title: 'Add',
          icon: 'circle-plus-outline',
          key: 'add',
        },
        {
          callback: this.openConfig,
          title: 'Configuration',
          icon: 'setting',
          key: 'setting',
        },
      ],
      actions: [
        { callback: this.editItem, icon: 'edit', key: 'edit' },
        { callback: this.deleteItem, icon: 'delete', key: 'delete' },
      ],
      columns: [
        {
          key: 'name',
          prop: scope => scope.row.name,
          label: 'Device',
        },
        {
          key: 'format.name',
          prop: scope => scope.row.format.name,
          label: 'Format',
        },
        {
          key: 'format.ratio.name',
          prop: scope => (scope.row.format.ratio ? scope.row.format.ratio.name : ''),
          label: 'Ratio',
        },
      ],
      configVisible: false,
    };
  },
  components: {
    DeviceDialog,
    VdPagedTable,
    VdListingHeader,
  },
  computed: {
    ...mapState('device', ['loading', 'elements', 'total']),
  },
  methods: {
    ...mapActions('device', ['fetchDevicesByPage', 'deleteDevice']),
    openDeviceDialog() {
      this.$refs.deviceDialog.openDeviceDialog();
    },
    editItem(element) {
      this.$refs.deviceDialog.openDeviceDialog(element);
    },
    deleteItem(element) {
      this.$confirm('Do you really want to delete this device ?')
        .then(async () => {
          await this.deleteDevice(element);
        })
        .catch(() => {});
    },
    openConfig() {
      this.configVisible = true;
    },
    closeConfig() {
      this.configVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.deviceList {
  display: block;

  .content {
    .table {
      width: 100%;
    }
  }
}
</style>

