<template>
  <div class="manualRenderList">
    <vd-listing-header :title="title" :actions="headerActions" :datePicker="true" :handleDatesChange="handleDates">
    </vd-listing-header>
    <div class="content">
      <vd-paged-table
        tableKey="manualRenderList"
        :loading="loading"
        :data="elements"
        :totalDataSize="total"
        :paging="fetchManualRendersByPage"
        :query="$route.query"
        :dates="dates"
        :columns="columns"
        :select="true"
        :handleSelect="handleSelect"
        :cancelSelect="cancelSelect"
        :selectedData="selectedElements"
        :configVisible="configVisible"
        :configClose="closeConfig"
        :refetchTimeout="5000"
      ></vd-paged-table>
    </div>
    <upload-manual-render-dialog ref="uploadManualRenderDialog"></upload-manual-render-dialog>
    <vd-upload-to-campaign-dialog ref="vdUploadToCampaignDialog"></vd-upload-to-campaign-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';

import VdPagedTable from '@/components/vd-paged-table/VdPagedTable';
import VdListingHeader from '@/components/vd-listing-header/VdListingHeader';
import UploadManualRenderDialog from '@/views/manualRender/UploadManualRenderDialog.vue';
import VdUploadToCampaignDialog from '@/dialogs/vd-upload-to-campaign/VdUploadToCampaignDialog';
import config from '../../services/config.service';
import { makeVideo, makeLinks, makeRouterLink } from '../../utils/paged-table';

export default {
  name: 'manualRenderList',
  data() {
    return {
      title: 'Creatives list',
      headerActions: [
        {
          callback: this.openUploadManualRenderDialog,
          title: 'Add',
          icon: 'circle-plus-outline',
          key: 'add',
        },
        {
          callback: this.openUploadToCampaignDialog,
          title: 'Upload to campaign',
          icon: 'upload',
          key: 'upload',
        },
        {
          callback: this.openConfig,
          title: 'Configuration',
          icon: 'setting',
          key: 'setting',
        },
      ],
      actions: [],
      columns: [
        {
          key: 'game.code',
          prop: scope => (scope.row.game ?
            makeRouterLink({
              path: `/game/${scope.row.game.id}`,
              text: scope.row.game.code,
            }) :
            null),
          label: 'Game Code',
        },
        {
          key: 'adNumber',
          prop: scope => scope.row.adNumber,
          label: 'Ad number',
        },
        {
          key: 'rushNumber',
          prop: scope => scope.row.rushNumber,
          label: 'Rush number',
        },
        {
          key: 'network.name',
          prop: scope => scope.row.network.name,
          label: 'Network',
        },
        {
          key: 'format.name',
          prop: scope => scope.row.format.name,
          label: 'Format',
        },
        {
          key: 'language.name',
          prop: scope => scope.row.language.name,
          label: 'Language',
        },
        {
          key: 'render',
          prop: scope => (scope.row.renderDocument ?
            makeVideo({
              src: scope.row.renderDocument.url,
              text: scope.row.name,
            }) :
            scope.row.name),
          label: 'Render',
        },
        {
          key: 'output',
          prop: (scope) => {
            const links = [];
            if (scope.row.outputDocument) {
              links.push({
                href: scope.row.outputDocument.url,
                text: 'S3',
              });
            }
            return makeLinks(links);
          },
          label: 'Output',
        },
        {
          key: 'createdAt',
          prop: scope => moment(scope.row.createdAt).format('YYYY-MM-DD HH:mm:ss'),
          label: 'Creation',
        },
        {
          key: 'updatedAt',
          prop: scope => moment(scope.row.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
          label: 'Updated',
        },
      ],
      dates: null,
      selectedElements: [],
      configVisible: false,
    };
  },
  async mounted() {
    if (this.$route.query && this.$route.query.gameId) {
      await this.fetchGame({ id: +this.$route.query.gameId });
      this.title = `Creatives of game: ${this.game.name}`;
    }
  },
  components: {
    VdPagedTable,
    VdListingHeader,
    UploadManualRenderDialog,
    VdUploadToCampaignDialog,
  },
  computed: {
    ...mapState('game', ['game']),
    ...mapState('manualRender', ['loading', 'elements', 'total']),
  },
  methods: {
    ...mapActions('game', ['fetchGame']),
    ...mapActions('manualRender', ['fetchManualRendersByPage']),
    getStatus(status) {
      try {
        return config.renderStatuses[status];
      } catch (e) {
        return 'Error';
      }
    },
    handleDates(dates) {
      this.dates = dates;
    },
    handleSelect(elements, value) {
      elements.forEach((element) => {
        this.selectedElements = this.selectedElements.filter(elem => elem.id !== element.id);
        if (value) {
          this.selectedElements.push(element);
        }
      });
    },
    cancelSelect() {
      this.selectedElements = [];
    },
    openUploadManualRenderDialog() {
      this.$refs.uploadManualRenderDialog.openUploadManualRenderDialog({
        gameId: (this.$route.query && this.$route.query.gameId) || null,
      });
    },
    openUploadToCampaignDialog() {
      if (!this.selectedElements.length || this.selectedElements.find(element => !element.outputDocument)) {
        this.$notify({ type: 'warning', message: this.$createElement('b', 'You must select renders to upload') });
        return;
      }
      this.$refs.vdUploadToCampaignDialog.openDialog({ elements: this.selectedElements, type: 'manual' });
    },
    openConfig() {
      this.configVisible = true;
    },
    closeConfig() {
      this.configVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.manualRenderList {
  display: block;

  .content {
    .table {
      width: 100%;
    }
  }
}
</style>

