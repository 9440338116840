<template>
  <div class="ratioDialog">
    <el-dialog
      :close-on-click-modal="false"
      :before-close="closeRatioDialog"
      title="Ratio"
      :visible.sync="ratioDialogVisible"
      width="50%"
    >
      <el-form
        class="form"
        ref="ratioModel"
        label-width="120px"
        :model="ratioModel"
        @submit.native.prevent="submitRatioForm"
      >
        <el-form-item v-if="ratioFormErrors.length">
          <el-alert
            v-for="(error, index) in ratioFormErrors"
            :key="index"
            :title="error"
            :closable="false"
            type="error"
            class="error"
          ></el-alert>
        </el-form-item>
        <el-form-item label="Name">
          <el-input placeholder="Name" v-model="ratioModel.name"></el-input>
        </el-form-item>
        <el-form-item class="submit">
          <el-button @click="closeRatioDialog">Cancel</el-button>
          <el-button type="primary" native-type="submit" :disabled="ratioFormSubmit">Save</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ratio-dialog',
  data() {
    return {
      ratioDialogVisible: false,
      ratioFormSubmit: false,
      ratioFormSubmitTimeout: null,
      ratioFormErrors: [],
      ratioModel: {
        id: null,
        name: null,
      },
    };
  },
  beforeDestroy() {
    clearTimeout(this.ratioFormSubmitTimeout);
  },
  methods: {
    ...mapActions('ratio', ['addRatio', 'editRatio']),
    openRatioDialog(ratio = null) {
      this.resetRatioModel(ratio);
      this.ratioDialogVisible = true;
    },
    resetRatioModel(ratio = null) {
      this.ratioFormErrors = [];
      if (ratio) {
        this.ratioModel = { ...ratio };
      } else {
        this.ratioModel = {
          id: null,
          name: null,
        };
      }
    },
    closeRatioDialog() {
      this.resetRatioModel();
      this.ratioDialogVisible = false;
    },
    ratioFormValidation() {
      let valid = true;
      this.ratioFormErrors = [];
      if (!this.ratioModel.name) {
        this.ratioFormErrors.push('Name required');
        valid = false;
      }
      return valid;
    },
    async submitRatioForm() {
      this.ratioFormSubmit = true;
      this.ratioFormSubmitTimeout = setTimeout(async () => {
        if (this.ratioFormValidation()) {
          if (this.ratioModel.id) {
            await this.editRatio(this.ratioModel);
          } else {
            await this.addRatio(this.ratioModel);
          }
          this.closeRatioDialog();
        }
        this.ratioFormSubmit = false;
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.ratioDialog {
  display: block;

  .form {
    .error {
      margin-bottom: 10px;
    }

    .submit {
      text-align: right;
      margin: 0;
    }
  }

  /deep/.el-dialog {
    .el-dialog__header {
      margin: 0;
      padding: 15px;
      border-bottom: 1px solid #ebeef5;
    }

    .el-dialog__body {
      padding: 15px;
    }
  }
}
</style>

