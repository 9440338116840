<template>
  <div class="staticConfigDialog">
    <el-dialog
      :close-on-click-modal="false"
      :before-close="closeStaticConfigDialog"
      title="Static config"
      :visible.sync="staticConfigDialogVisible"
      width="50%"
    >
      <el-form
        class="form"
        ref="staticConfigModel"
        label-width="120px"
        :model="staticConfigModel"
        @submit.native.prevent="submitStaticConfigForm"
      >
        <el-form-item v-if="staticConfigFormErrors.length">
          <el-alert
            v-for="(error, index) in staticConfigFormErrors"
            :key="index"
            :title="error"
            :closable="false"
            type="error"
            class="error"
          ></el-alert>
        </el-form-item>
        <el-form-item>
          <el-select v-model="staticConfigModel.networkId" placeholder="Network">
            <el-option
              v-for="option in networkElements"
              :key="option.id"
              :label="option.name"
              :value="option.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="staticConfigModel.formatId" placeholder="Format">
            <el-option
              v-for="option in staticFormats"
              :key="option.id"
              :label="option.name"
              :value="option.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-switch class="switch" v-model="staticConfigModel.bg"></el-switch>Background
        </el-form-item>
        <el-form-item>
          <static-asset-component v-model="staticConfigModel.icon" title="Icon"></static-asset-component>
        </el-form-item>
        <el-form-item>
          <static-asset-component v-model="staticConfigModel.title" title="Title"></static-asset-component>
        </el-form-item>
        <el-form-item>
          <static-asset-component v-model="staticConfigModel.iosBadge" title="Ios badge"></static-asset-component>
        </el-form-item>
        <el-form-item>
          <static-asset-component v-model="staticConfigModel.googleBadge" title="Google badge"></static-asset-component>
        </el-form-item>
        <el-form-item>
          <static-asset-component v-model="staticConfigModel.cta" title="CTA"></static-asset-component>
        </el-form-item>
        <el-form-item class="submit">
          <el-button @click="closeStaticConfigDialog">Cancel</el-button>
          <el-button type="primary" native-type="submit" :disabled="staticConfigFormSubmit">Save</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import StaticAssetComponent from '@/views/staticConfig/StaticAssetComponent.vue';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'static-config-dialog',
  data() {
    return {
      staticConfigDialogVisible: false,
      staticConfigFormSubmit: false,
      staticConfigFormSubmitTimeout: null,
      staticConfigFormErrors: [],
      staticConfigModel: {
        id: null,
        networkId: null,
        formatId: null,
        bg: false,
        icon: {
          has: null,
          id: null,
          width: null,
          height: null,
          x: null,
          y: null,
        },
        title: {
          has: null,
          id: null,
          width: null,
          height: null,
          x: null,
          y: null,
        },
        iosBadge: {
          has: null,
          id: null,
          width: null,
          height: null,
          x: null,
          y: null,
        },
        googleBadge: {
          has: null,
          id: null,
          width: null,
          height: null,
          x: null,
          y: null,
        },
        cta: {
          has: null,
          id: null,
          width: null,
          height: null,
          x: null,
          y: null,
        },
      },
    };
  },
  async mounted() {
    await this.init();
  },
  components: {
    StaticAssetComponent,
  },
  beforeDestroy() {
    clearTimeout(this.staticConfigFormSubmitTimeout);
  },
  computed: {
    ...mapState('network', { networkElements: 'elements' }),
    ...mapState('format', ['staticFormats']),
  },
  methods: {
    ...mapActions('staticConfig', ['addStaticConfig', 'editStaticConfig']),
    ...mapActions('network', ['fetchNetworks']),
    ...mapActions('format', ['fetchStaticFormats']),
    async init() {
      await Promise.all([
        this.fetchNetworks(),
        this.fetchStaticFormats(),
      ]);
    },
    async openStaticConfigDialog(staticConfig = null) {
      await this.init();
      this.resetStaticConfigModel(staticConfig);
      this.staticConfigDialogVisible = true;
    },
    transformAsset(asset) {
      return asset ?
        {
          has: true,
          ...asset,
        } :
        {
          has: null,
          id: null,
          width: null,
          height: null,
          x: null,
          y: null,
        };
    },
    resetStaticConfigModel(staticConfig = null) {
      this.staticConfigFormErrors = [];
      if (staticConfig) {
        this.staticConfigModel = {
          ...staticConfig,
          networkId: staticConfig.network.id,
          formatId: staticConfig.format.id,
          icon: this.transformAsset(staticConfig.icon),
          title: this.transformAsset(staticConfig.title),
          iosBadge: this.transformAsset(staticConfig.iosBadge),
          googleBadge: this.transformAsset(staticConfig.googleBadge),
          cta: this.transformAsset(staticConfig.cta),
        };
      } else {
        this.staticConfigModel = {
          id: null,
          networkId: null,
          formatId: null,
          bg: false,
          icon: {
            has: null,
            id: null,
            width: null,
            height: null,
            x: null,
            y: null,
          },
          title: {
            has: null,
            id: null,
            width: null,
            height: null,
            x: null,
            y: null,
          },
          iosBadge: {
            has: null,
            id: null,
            width: null,
            height: null,
            x: null,
            y: null,
          },
          googleBadge: {
            has: null,
            id: null,
            width: null,
            height: null,
            x: null,
            y: null,
          },
          cta: {
            has: null,
            id: null,
            width: null,
            height: null,
            x: null,
            y: null,
          },
        };
      }
    },
    closeStaticConfigDialog() {
      this.resetStaticConfigModel();
      this.staticConfigDialogVisible = false;
    },
    validateAsset(asset, key) {
      let valid = true;
      if (!asset.width) {
        this.staticConfigFormErrors.push(`Width required for ${key}`);
        valid = false;
      }
      if (!asset.height) {
        this.staticConfigFormErrors.push(`Height required for ${key}`);
        valid = false;
      }
      if (!asset.x) {
        this.staticConfigFormErrors.push(`X required for ${key}`);
        valid = false;
      }
      if (!asset.y) {
        this.staticConfigFormErrors.push(`Y required for ${key}`);
        valid = false;
      }
      return valid;
    },
    staticConfigFormValidation() {
      let valid = true;
      this.staticConfigFormErrors = [];
      if (!this.staticConfigModel.networkId) {
        this.staticConfigFormErrors.push('Network required');
        valid = false;
      }
      if (!this.staticConfigModel.formatId) {
        this.staticConfigFormErrors.push('Format required');
        valid = false;
      }
      if (this.staticConfigModel.icon.has) {
        valid = this.validateAsset(this.staticConfigModel.icon, 'icon');
      }
      if (this.staticConfigModel.title.has) {
        valid = this.validateAsset(this.staticConfigModel.title, 'title');
      }
      if (this.staticConfigModel.iosBadge.has) {
        valid = this.validateAsset(
          this.staticConfigModel.iosBadge,
          'ios badge',
        );
      }
      if (this.staticConfigModel.googleBadge.has) {
        valid = this.validateAsset(
          this.staticConfigModel.googleBadge,
          'google badge',
        );
      }
      if (this.staticConfigModel.cta.has) {
        valid = this.validateAsset(this.staticConfigModel.cta, 'cta');
      }
      return valid;
    },
    async submitStaticConfigForm() {
      this.staticConfigFormSubmit = true;
      this.staticConfigFormSubmitTimeout = setTimeout(async () => {
        const toUpload = {
          ...this.staticConfigModel,
          icon: this.parseAsset(this.staticConfigModel.icon),
          title: this.parseAsset(this.staticConfigModel.title),
          iosBadge: this.parseAsset(this.staticConfigModel.iosBadge),
          googleBadge: this.parseAsset(this.staticConfigModel.googleBadge),
          cta: this.parseAsset(this.staticConfigModel.cta),
        };
        if (this.staticConfigFormValidation()) {
          if (toUpload.id) {
            await this.editStaticConfig(toUpload);
          } else {
            await this.addStaticConfig(toUpload);
          }
          this.closeStaticConfigDialog();
        }
        this.staticConfigFormSubmit = false;
      }, 300);
    },
    parseAsset({
      has, id, width, height, x, y,
    }) {
      if (!has) return null;
      return {
        id: id && parseInt(id, 10),
        width: parseInt(width, 10),
        height: parseInt(height, 10),
        x: parseInt(x, 10),
        y: parseInt(y, 10),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.staticConfigDialog {
  display: block;

  .form {
    .error {
      margin-bottom: 10px;
    }

    .submit {
      text-align: right;
      margin: 0;
    }
  }

  .switch {
    margin-right: 16px;
  }

  /deep/.el-dialog {
    .el-dialog__header {
      margin: 0;
      padding: 15px;
      border-bottom: 1px solid #ebeef5;
    }

    .el-dialog__body {
      padding: 15px;
    }
  }
}
</style>

