const makeLink = element => this.makeLinks([element]);

const makeLinks = elements => ({
  tag: 'a',
  elements: elements.map(element => ({
    props: {
      class: element.class || 'el-button--text',
      target: element.target || '_blank',
      href: element.href,
    },
    text: element.text,
  })),
});

const makeRouterLink = element => this.makeRouterLinks([element]);

const makeRouterLinks = elements => ({
  tag: 'router-link',
  elements: elements.map(element => ({
    props: {
      class: element.class || 'el-button--text',
      to: {
        path: element.path,
        query: element.query || '',
      },
    },
    text: element.text,
  })),
});

const makeProgress = element => ({
  tag: 'el-progress',
  props: {
    percentage: element.percentage,
  },
});

const makeTooltip = element => ({
  tag: 'el-tooltip',
  props: {
    content: element.content,
  },
  text: element.text,
});

const makeImage = element => ({
  tag: 'image',
  props: {
    src: element.src,
  },
  linkProps: {
    class: element.class || 'el-button--text',
    target: element.target || '_blank',
    href: element.src,
  },
  text: element.text,
});

const makeVideo = element => ({
  tag: 'video',
  props: {
    type: element.type || 'video/mp4',
    src: element.src,
  },
  linkProps: {
    class: element.class || 'el-button--text',
    target: element.target || '_blank',
    href: element.src,
  },
  text: element.text,
});

const getDriveFileUrl = driveFileId => (driveFileId ? `https://drive.google.com/file/d/${driveFileId}/view` : null);

exports.makeLink = makeLink;
exports.makeLinks = makeLinks;
exports.makeRouterLink = makeRouterLink;
exports.makeRouterLinks = makeRouterLinks;
exports.makeProgress = makeProgress;
exports.makeTooltip = makeTooltip;
exports.makeVideo = makeVideo;
exports.makeImage = makeImage;
exports.getDriveFileUrl = getDriveFileUrl;
