import Vue from 'vue';
import Vuex from 'vuex';
import gql from 'graphql-tag';
import { apolloClient } from '../../utils/vue-apollo';

Vue.use(Vuex);

const GRAPHQL_ENDCARD_RENDER_PROPS = `
  id
  name
  endcardRush {
    id
    rushId
    rush {
      id
      name
      gameId
      deviceId
      version
      game {
        id
        appId
        name
        code
        uaEmail
      }
      device {
        id
        name
      }
      document {
        id
      key
      url
      }
    }
    document {
      id
      key
      url
    }
  }
  endcardTemplate {
    id
    name
    adwordsDocument {
      id
      key
      url
    }
    applovinDocument {
      id
      key
      url
    }
    facebookDocument {
      id
      key
      url
    }
    ironsourceDocument {
      id
      key
      url
    }
    tapjoyDocument {
      id
      key
      url
    }
    unityDocument {
      id
      key
      url
    }
    vungleDocument {
      id
      key
      url
    }
    voodooDocument {
      id
      key
      url
    }
    config {
      id
      key
      type
      name
    }
  }
  network {
    id
    name
  }
  language {
    id
    name
  }
  document {
    id
    key
    url
  }
  outputDocument {
    id
    key
    url
    driveFileId
  }
  meta {
    id
    key
    type
    value
    entityId
    entityName
  }
  renderStatus
  renderMessage
  createdAt
  updatedAt
`;

const GRAPHQL = {
  queries: {
    fetchEndcardRenders: gql`
      query {
        endcardRenders {
          ${GRAPHQL_ENDCARD_RENDER_PROPS}
        }
      }
    `,
    fetchEndcardRendersByPage: gql`
      query($input: EndcardRendersByPageInput!) {
        endcardRendersByPage(input: $input) {
          elements {
            ${GRAPHQL_ENDCARD_RENDER_PROPS}
          }
          total
        }
      }
    `,
    generateEndcardRenders: gql`
      query($input: EndcardRendersToGenerateInput!) {
        generateEndcardRenders(input: $input)
      }
    `,
  },
};

export default {
  namespaced: true,
  state: {
    elements: [],
    total: 0,
    loading: false,
    generateLoading: false,
  },
  mutations: {
    setEndcardRenders(state, { elements, total = 0 }) {
      state.elements = elements;
      state.total = total;
      state.loading = false;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
    setGenerateLoading(state, isLoading) {
      state.generateLoading = isLoading;
    },
  },
  actions: {
    async fetchEndcardRenders({ commit }) {
      commit('setLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchEndcardRenders,
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const endcardRenders = results && results.data && results.data.endcardRenders ? results.data.endcardRenders : [];
      commit('setEndcardRenders', { elements: endcardRenders, total: endcardRenders.length });
    },
    async fetchEndcardRendersByPage({ commit }, { page = 1, pageSize = 1, options = {} }) {
      commit('setLoading', true);
      const optionsStr = JSON.stringify(options);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchEndcardRendersByPage,
          variables: {
            input: {
              page,
              pageSize,
              options: optionsStr,
            },
          },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const endcardRenders =
        results && results.data && results.data.endcardRendersByPage ? results.data.endcardRendersByPage : {};
      commit('setEndcardRenders', endcardRenders);
    },
    async generateEndcardRenders({ commit }, { gameId, endcardTemplateId, config }) {
      commit('setGenerateLoading', true);
      const configStr = JSON.stringify(config);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.generateEndcardRenders,
          variables: {
            input: { gameId, endcardTemplateId, config: configStr },
          },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const generateEndcardRenders = (results && results.data && results.data.generateEndcardRenders) || false;
      if (generateEndcardRenders) {
        commit('setGenerateLoading', false);
      }
    },
  },
};
