<template>
  <div class="render-input">
    <div class="top">
      <div class="title">{{title}}</div>
      <div class="check-all" v-if="type === 'tree'">
        <el-checkbox
          :disabled="disabled"
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
        >Check all</el-checkbox>
      </div>
    </div>
    <div class="card">
      <el-input
        :disabled="disabled"
        class="search"
        v-if="!!withSearch"
        placeholder="Search"
        v-model="filterText"
      ></el-input>
      <div v-if="type === 'radio'">
        <el-radio-group :disabled="disabled" :value="value" @input="handleRadio">
          <el-radio
            class="input"
            v-for="data of filterText ? dataSource.filter(entry => entry.label.indexOf(filterText) !== -1) : dataSource"
            v-bind:key="data.id"
            :label="data.id"
          >{{data.label}}</el-radio>
        </el-radio-group>
      </div>
      <div v-if="type === 'tree'">
        <el-tree
          class="tree"
          :ref="'tree' + entryKey"
          :data="dataSource"
          node-key="id"
          :show-checkbox="!disabled"
          @check="handleCheckChange"
          :filter-node-method="filterTree"
        >
          <template slot-scope="scope">
            <span @click="stopPropagation">
              <span
                class="check"
                v-bind:class="{ checked: scope.node.checked }"
                @click="handleCheckNodeChange(scope.node)"
              >{{scope.data.label}}</span>
              <el-tooltip v-if="scope.data.description" placement="bottom" effect="dark">
                <div slot="content" style="font-size: 14px;">{{scope.data.description}}</div>
                <i class="info-icon icon el-icon-info"></i>
              </el-tooltip>
            </span>
          </template>
        </el-tree>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'render-input',
  props: [
    'title',
    'type',
    'dataSource',
    'value',
    'disabled',
    'withSearch',
    'exceptions',
    'onChange',
    'entryKey',
  ],
  data() {
    return {
      localValue: this.value,
      filterText: null,
      isIndeterminate: false,
      checkAll: false,
    };
  },
  watch: {
    filterText(val) {
      this.$refs[`tree${this.entryKey}`].filter(val);
    },
  },
  updated() {
    if (this.type === 'tree') {
      this.$refs[`tree${this.entryKey}`].setCheckedNodes(this.localValue || []);
    }
  },
  methods: {
    stopPropagation(event) {
      event.stopPropagation();
    },
    handleRadio(val) {
      this.localValue = val;
      this.onChange(val, {
        title: this.title,
        dataSource: this.dataSource,
        key: this.entryKey,
      });
    },
    handleCheckChange() {
      const allCheckedSource = this.dataSource.map(elem => elem.id);
      const checkedKeys = this.$refs[`tree${this.entryKey}`].getCheckedKeys(
        false,
      );
      const checkedRootNodes = checkedKeys.filter(
        key => key.split('/').length === 2,
      );
      if (allCheckedSource.length === checkedRootNodes.length) {
        this.checkAll = true;
        this.isIndeterminate = false;
      } else if (checkedKeys.length === 0) {
        this.checkAll = false;
        this.isIndeterminate = false;
      } else {
        this.checkAll = false;
        this.isIndeterminate = true;
      }
      const nodes = this.$refs[`tree${this.entryKey}`].getCheckedNodes(true);
      this.localValue = nodes;
      this.onChange(nodes, {
        title: this.title,
        dataSource: this.dataSource,
        key: this.entryKey,
      });
      return null;
    },
    filterTree(value, data) {
      if (!this.withSearch || !value) {
        return true;
      }
      return data.label.indexOf(value) !== -1;
    },
    handleCheckNodeChange(node, value = null) {
      if (this.disabled) {
        return;
      }
      node.checked = value === null ? !node.checked : value;
      node.indeterminate = false;
      if (node.childNodes && node.childNodes.length) {
        node.childNodes.forEach((childNode) => {
          this.handleCheckNodeChange(childNode, node.checked);
        });
      }
      if (
        value === null &&
        node.parent &&
        node.parent.childNodes &&
        node.parent.childNodes.length
      ) {
        let checked = 0;
        node.parent.childNodes.forEach((childNode) => {
          if (childNode.checked) {
            checked += 1;
          }
        });
        node.parent.checked = checked === node.parent.childNodes.length;
        node.parent.indeterminate =
          checked !== 0 && checked !== node.parent.childNodes.length;
      }
      this.handleCheckChange();
    },
    handleCheckAllChange(value) {
      this.checkAll = value;
      this.isIndeterminate = false;
      if (!this.checkAll) {
        this.$refs[`tree${this.entryKey}`].setCheckedKeys([]);
        const nodes = this.$refs[`tree${this.entryKey}`].getCheckedNodes(true);
        this.localValue = nodes;
        this.onChange(nodes, {
          title: this.title,
          dataSource: this.dataSource,
          key: this.entryKey,
        });
      } else {
        const toCheck = this.dataSource.map(elem => elem.id);
        this.$refs[`tree${this.entryKey}`].setCheckedKeys(toCheck);
        const nodes = this.$refs[`tree${this.entryKey}`].getCheckedNodes(true);
        this.localValue = nodes;
        this.onChange(nodes, {
          title: this.title,
          dataSource: this.dataSource,
          key: this.entryKey,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.render-input {
  width: calc(25% - 16px);
  margin: 8px;
  display: inline-block;
  color: #606266;

  .top {
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;

    .title {
      font-size: 14px;
    }

    .check-all {
      font-weight: bold;
    }
  }

  .card {
    height: 384px;
    border: solid 1px #e6e6e6;
    border-radius: 8px;
    padding: 4px;
    overflow: auto;

    .tree {
      .check {
        font-size: 14px;
        padding-left: 10px;
        vertical-align: text-top;
      }

      .checked {
        color: teal;
      }
    }

    .input {
      display: block;
      margin: 8px;
    }

    .search {
      margin-bottom: 16px;
    }

    .info-icon {
      margin-left: 8px;
    }
  }
}
</style>

