<template>
  <div class="templateDialog">
    <el-dialog
      :close-on-click-modal="false"
      :before-close="closeTemplateDialog"
      title="Template"
      :visible.sync="templateDialogVisible"
      width="50%"
    >
      <el-form class="form" ref="form" :model="templateModel" @submit.native.prevent="submitTemplateForm">
        <el-form-item v-if="templateFormErrors.length">
          <el-alert
            v-for="(error, index) in templateFormErrors"
            :key="index"
            :title="error"
            :closable="false"
            type="error"
            class="error"
          ></el-alert>
        </el-form-item>
        <el-form-item v-if="!templateModel.id">
          <el-select
            v-model="templateModel.templateTypeId"
            placeholder="Type"
            @change="onSelectTemplateType"
          >
            <el-option
              v-for="option in templateTypeElements"
              :key="option.id"
              :label="option.name"
              :value="option.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="templateForm.keyMax !== 1 && !templateModel.id">
          <el-input-number
            placeholder="Key"
            :min="1"
            :max="templateForm.keyMax || undefined"
            v-model="templateModel.key"
          ></el-input-number>
        </el-form-item>
        <div v-if="templateModel.id" class="template-name">{{getTemplateName()}}</div>
        <el-form-item label="Orientations">
          <el-checkbox
            :indeterminate="templateForm.orientationIndeterminate"
            v-model="templateForm.orientationCheckAll"
            @change="handleCheckAllOrientationChange"
          >All</el-checkbox>
          <el-checkbox-group
            v-model="templateModel.meta.orientationIds"
            @change="handleCheckedOrientationChange"
          >
            <el-checkbox
              v-for="orientation in orientationElements"
              :label="orientation.id"
              :key="orientation.id"
            >{{orientation.name}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="Mechanics">
          <el-checkbox
            :indeterminate="templateForm.mechanicIndeterminate"
            v-model="templateForm.mechanicCheckAll"
            @change="handleCheckAllMechanicChange"
          >All</el-checkbox>
          <el-checkbox-group
            v-model="templateModel.meta.mechanicIds"
            @change="handleCheckedMechanicChange"
          >
            <el-checkbox
              v-for="mechanic in mechanicElements"
              :label="mechanic.id"
              :key="mechanic.id"
            >{{mechanic.name}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="Devices">
          <el-checkbox
            :indeterminate="templateForm.deviceIndeterminate"
            v-model="templateForm.deviceCheckAll"
            @change="handleCheckAllDeviceChange"
          >All</el-checkbox>
          <el-checkbox-group
            v-model="templateModel.meta.deviceIds"
            @change="handleCheckedDeviceChange"
          >
            <el-checkbox
              v-for="device in deviceElements"
              :label="device.id"
              :key="device.id"
            >{{device.name}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="Ratios">
          <el-checkbox
            :indeterminate="templateForm.ratioIndeterminate"
            v-model="templateForm.ratioCheckAll"
            @change="handleCheckAllRatioChange"
          >All</el-checkbox>
          <el-checkbox-group
            v-model="templateModel.meta.ratioIds"
            @change="handleCheckedRatioChange"
          >
            <el-checkbox
              v-for="ratio in ratioElements"
              :label="ratio.id"
              :key="ratio.id"
            >{{ratio.name}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="Classifications">
          <el-checkbox
            :indeterminate="templateForm.classificationIndeterminate"
            v-model="templateForm.classificationCheckAll"
            @change="handleCheckAllClassificationChange"
          >All</el-checkbox>
          <el-checkbox-group
            v-model="templateModel.meta.classificationIds"
            @change="handleCheckedClassificationChange"
          >
            <el-checkbox
              v-for="classification in classificationElements"
              :label="classification.id"
              :key="classification.id"
            >{{classification.name}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="Wording">
          <el-switch v-model="templateModel.hasWording"></el-switch>
        </el-form-item>
        <el-form-item v-if="templateModel.hasWording">
          <el-autocomplete
            class="wording-autocomplete"
            v-model="templateForm.wording"
            :fetch-suggestions="onSearchWording"
            placeholder="Wording"
            @select="onSelectWording"
          ></el-autocomplete>
          <el-button
            class="wording-button"
            v-if="templateForm.wording"
            @click="onCancelWording"
            icon="el-icon-close"
            circle
          ></el-button>
        </el-form-item>
        <el-form-item label="CTA">
          <el-switch v-model="templateModel.hasCtaWording"></el-switch>
        </el-form-item>
        <el-form-item v-if="templateModel.hasCtaWording">
          <el-autocomplete
            class="wording-autocomplete"
            v-model="templateForm.ctaWording"
            :fetch-suggestions="onSearchCtaWording"
            placeholder="CTA"
            @select="onSelectCtaWording"
          ></el-autocomplete>
          <el-button
            class="wording-button"
            v-if="templateForm.ctaWording"
            @click="onCancelWording('cta')"
            icon="el-icon-close"
            circle
          ></el-button>
        </el-form-item>
        <el-form-item label="Description">
          <el-input
            type="textarea"
            :rows="3"
            placeholder="Description"
            v-model="templateModel.description"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <vd-upload ref="upload" uploadPath="documents/templates/upload"></vd-upload>
        </el-form-item>
        <el-form-item class="submit">
          <el-button @click="closeTemplateDialog()">Cancel</el-button>
          <el-button type="primary" native-type="submit" :disabled="templateFormSubmit">Save</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import VdUpload from '@/components/vd-upload/VdUpload.vue';
import { mapState, mapActions } from 'vuex';

const templateFormData = {
  templateModel: {
    id: null,
    description: null,
    templateTypeId: null,
    key: 1,
    document: null,
    hasWording: false,
    hasCtaWording: false,
    meta: {
      orientationIds: [],
      mechanicIds: [],
      deviceIds: [],
      ratioIds: [],
      classificationIds: [],
      wordingId: null,
      ctaWordingId: null,
    },
  },
  templateForm: {
    keyMax: null,
    wording: null,
    ctaWording: null,
    orientationCheckAll: false,
    orientationIndeterminate: false,
    mechanicCheckAll: false,
    mechanicIndeterminate: false,
    deviceCheckAll: false,
    deviceIndeterminate: false,
    ratioCheckAll: false,
    ratioIndeterminate: false,
    classificationCheckAll: false,
    classificationIndeterminate: false,
  },
};

export default {
  name: 'template-dialog',
  data() {
    return {
      templateDialogVisible: false,
      templateFormSubmit: false,
      templateFormSubmitTimeout: null,
      templateFormErrors: [],
      templateModel: {
        ...templateFormData.templateModel,
        meta: { ...templateFormData.templateModel.meta },
      },
      templateForm: { ...templateFormData.templateForm },
    };
  },
  async mounted() {
    await this.init();
  },
  beforeDestroy() {
    clearTimeout(this.templateFormSubmitTimeout);
  },
  components: {
    VdUpload,
  },
  computed: {
    ...mapState('templateType', { templateTypeElements: 'elements' }),
    ...mapState('orientation', { orientationElements: 'elements' }),
    ...mapState('mechanic', { mechanicElements: 'elements' }),
    ...mapState('device', { deviceElements: 'elements' }),
    ...mapState('ratio', { ratioElements: 'elements' }),
    ...mapState('classification', { classificationElements: 'elements' }),
    ...mapState('wording', {
      wordingElements: 'elements',
      ctaWordingElements: 'ctaWordings',
    }),
  },
  methods: {
    ...mapActions('template', ['addTemplate', 'upgradeTemplate']),
    ...mapActions('templateType', ['fetchTemplateTypes']),
    ...mapActions('orientation', ['fetchOrientations']),
    ...mapActions('mechanic', ['fetchMechanics']),
    ...mapActions('device', ['fetchDevices']),
    ...mapActions('ratio', ['fetchRatios']),
    ...mapActions('classification', ['fetchClassifications']),
    ...mapActions('wording', ['fetchWordings', 'fetchCtaWordings']),
    async init() {
      await Promise.all([
        this.fetchTemplateTypes(),
        this.fetchOrientations(),
        this.fetchMechanics(),
        this.fetchDevices(),
        this.fetchRatios(),
        this.fetchClassifications(),
        this.fetchWordings(),
        this.fetchCtaWordings(),
      ]);
    },
    async openTemplateDialog(template = null) {
      await this.init();
      this.resetTemplateModel(template);
      if (this.$refs.upload) {
        this.$refs.upload.resetUpload();
      }
      this.templateDialogVisible = true;
    },
    resetTemplateModel(template = null) {
      this.templateFormErrors = [];
      this.templateForm = { ...templateFormData.templateForm };
      if (template) {
        const wordings = template.templateMeta.filter(
          ({ entityName, entityType }) => entityName === 'wording' && entityType === 'wording',
        );
        const ctas = template.templateMeta.filter(
          ({ entityName, entityType }) => entityName === 'wording' && entityType === 'cta',
        );
        this.templateModel = {
          ...template,
          hasWording: wordings.length !== 0,
          hasCtaWording: ctas.length !== 0,
          meta: {
            orientationIds: template.templateMeta
              .filter(({ entityName }) => entityName === 'orientation')
              .map(({ entityId }) => entityId),
            mechanicIds: template.templateMeta
              .filter(({ entityName }) => entityName === 'mechanic')
              .map(({ entityId }) => entityId),
            deviceIds: template.templateMeta
              .filter(({ entityName }) => entityName === 'device')
              .map(({ entityId }) => entityId),
            ratioIds: template.templateMeta
              .filter(({ entityName }) => entityName === 'ratio')
              .map(({ entityId }) => entityId),
            classificationIds: template.templateMeta
              .filter(({ entityName }) => entityName === 'classification')
              .map(({ entityId }) => entityId),
            wordingId: wordings.length && wordings[0].entityId,
            ctaWordingId: ctas.length && ctas[0].entityId,
          },
        };
        this.handleCheckedChange(
          'orientation',
          this.templateModel.meta.orientationIds,
        );
        this.handleCheckedChange(
          'mechanic',
          this.templateModel.meta.mechanicIds,
        );
        this.handleCheckedChange('device', this.templateModel.meta.deviceIds);
        this.handleCheckedChange('ratio', this.templateModel.meta.ratioIds);
        this.handleCheckedChange(
          'classification',
          this.templateModel.meta.classificationIds,
        );
        if (this.templateModel.hasWording) {
          this.templateForm.wording = this.wordingElements.find(
            ({ id }) => id === this.templateModel.meta.wordingId,
          ).text;
        }
        if (this.templateModel.hasCtaWording) {
          this.templateForm.ctaWording = this.wordingElements.find(
            ({ id }) => id === this.templateModel.meta.ctaWordingId,
          ).text;
        }
      } else {
        this.templateModel = {
          ...templateFormData.templateModel,
          meta: { ...templateFormData.templateModel.meta },
        };
        const ctaWordingDef = this.ctaWordingElements.find(wording => wording.wordingTypes.find(type => type.default));
        if (ctaWordingDef) {
          this.templateModel.meta.ctaWordingId = ctaWordingDef.id;
          this.templateForm.ctaWording = ctaWordingDef.text;
        }
      }
    },
    closeTemplateDialog() {
      this.resetTemplateModel();
      this.templateDialogVisible = false;
    },
    getTemplateTypeById(templateTypeId) {
      return (
        this.templateTypeElements.find(elem => elem.id === templateTypeId) ||
        this.templateTypeElements[0]
      );
    },
    onSelectTemplateType(templateTypeId) {
      const templateType = this.getTemplateTypeById(templateTypeId);
      this.templateModel.formatId = null;
      this.templateModel.key = 1;
      this.templateForm.keyMax = templateType.nbKeys;
    },
    handleCheckAllOrientationChange(value) {
      this.handleCheckAllChange('orientation', value);
    },
    handleCheckedOrientationChange(value) {
      this.handleCheckedChange('orientation', value);
    },
    handleCheckAllMechanicChange(value) {
      this.handleCheckAllChange('mechanic', value);
    },
    handleCheckedMechanicChange(value) {
      this.handleCheckedChange('mechanic', value);
    },
    handleCheckAllDeviceChange(value) {
      this.handleCheckAllChange('device', value);
    },
    handleCheckedDeviceChange(value) {
      this.handleCheckedChange('device', value);
    },
    handleCheckAllRatioChange(value) {
      this.handleCheckAllChange('ratio', value);
    },
    handleCheckedRatioChange(value) {
      this.handleCheckedChange('ratio', value);
    },
    handleCheckAllClassificationChange(value) {
      this.handleCheckAllChange('classification', value);
    },
    handleCheckedClassificationChange(value) {
      this.handleCheckedChange('classification', value);
    },
    handleCheckAllChange(element, value) {
      this.templateModel.meta[`${element}Ids`] = value ?
        this[`${element}Elements`].map(item => item.id) :
        [];
      this.templateForm[`${element}Indeterminate`] = false;
    },
    handleCheckedChange(element, value) {
      const count = value.length;
      const total = this[`${element}Elements`].length;
      this.templateForm[`${element}CheckAll`] = count === total;
      this.templateForm[`${element}Indeterminate`] = count > 0 && count < total;
    },
    searchWording(wordings = [], queryString, cb) {
      wordings.sort((a, b) => a.text.length - b.text.length);
      if (queryString) {
        wordings = wordings.filter(wording => wording.text.toLowerCase().includes(queryString.toLowerCase()));
      }
      cb(wordings.map(wording => ({ id: wording.id, value: wording.text })));
    },
    onSearchWording(queryString, cb) {
      this.searchWording([...this.wordingElements], queryString, cb);
    },
    onSearchCtaWording(queryString, cb) {
      this.searchWording([...this.ctaWordingElements], queryString, cb);
    },
    onSelectWording(wording) {
      this.templateModel.meta.wordingId = wording.id;
      this.templateForm.wording = wording.value;
    },
    onSelectCtaWording(wording) {
      this.templateModel.meta.ctaWordingId = wording.id;
      this.templateForm.ctaWording = wording.value;
    },
    onCancelWording(type = 'wording') {
      if (type === 'cta') {
        this.templateModel.meta.ctaWordingId = null;
        this.templateForm.ctaWording = null;
      } else {
        this.templateModel.meta.wordingId = null;
        this.templateForm.wording = null;
      }
    },
    templateFormValidation() {
      let valid = true;
      this.templateFormErrors = [];
      if (!this.templateModel.templateTypeId) {
        this.templateFormErrors.push('Template type required');
        valid = false;
      }
      if (!this.templateModel.document) {
        this.templateFormErrors.push('ZIP file required');
        valid = false;
      }
      return valid;
    },
    async submitTemplateForm() {
      this.templateFormSubmit = true;
      this.templateFormSubmitTimeout = setTimeout(async () => {
        this.templateModel.document = this.$refs.upload.document;
        if (this.templateFormValidation()) {
          if (this.templateModel.id) {
            await this.upgradeTemplate(this.templateModel);
          } else {
            await this.addTemplate(this.templateModel);
          }
          this.closeTemplateDialog();
        }
        this.templateFormSubmit = false;
      }, 300);
    },
    getTemplateName() {
      const templateType = this.templateTypeElements.find(
        ({ id }) => id === this.templateModel.templateTypeId,
      );
      return `${templateType.name}_${`${this.templateModel.key}`.padStart(
        3,
        0,
      )}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.templateDialog {
  display: block;

  .form {
    .error {
      margin-bottom: 10px;
    }

    .submit {
      text-align: right;
      margin: 0;
    }
  }

  .template-name {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  .wording-autocomplete {
    width: calc(100% - 50px);
  }

  .wording-button {
    margin-left: 10px;
  }

  /deep/.el-dialog {
    .el-dialog__header {
      margin: 0;
      padding: 15px;
      border-bottom: 1px solid #ebeef5;
    }

    .el-dialog__body {
      padding: 15px;
    }
  }
}
</style>

