<template>
  <div class="vdUploadRequestToCampaignDialog">
    <el-dialog
      :close-on-click-modal="false"
      :before-close="closeDialog"
      title="Upload to campaign"
      :visible.sync="dialogVisible"
      width="90%"
    >
    <div v-loading="loading">
      <el-form
        class="form"
        ref="model"
        :model="model"
        @submit.native.prevent="submitForm"
      >
        <el-form-item v-if="formErrors.length">
          <el-alert
            v-for="(error, index) in formErrors"
            :key="index"
            :title="error"
            :closable="false"
            type="error"
            class="error"
          ></el-alert>
        </el-form-item>
        <el-form-item>
          <el-card>
            <el-checkbox v-model="groupCheckbox" @change="toggleGroupCheckbox()"></el-checkbox>
            <span v-if="groupCheckbox">
              Unselect All
            </span>
            <span v-else>
              Select All
            </span>
          </el-card>
        </el-form-item>
        <el-form-item v-for="(element, elementIndex) in model.elements" :key="elementIndex">
          <el-card shadow="hover">
            <div slot="header" class="card-header">
              <el-checkbox v-model="element.uploadToCampaign" @change="checkGroupCheckbox()"></el-checkbox>
              <span><b>{{ element.game.name }} - {{ element.network.name }}</b></span>
            </div>
            <div v-if="!element.emailData">
              Creatives will be automatically uploaded to campaigns.
            </div>
            <div v-if="element.network.name === 'Applovin'">
              <el-form-item label="iOS Playable Url">
                <el-input type="url" v-model="element.settings.iosPlayableUrl"></el-input>
              </el-form-item>
              <el-form-item label="Android Playable Url">
                <el-input type="url" v-model="element.settings.androidPlayableUrl"></el-input>
              </el-form-item>
              <el-form-item label="China Playable Url">
                <el-input type="url" v-model="element.settings.iosChinaPlayableUrl"></el-input>
              </el-form-item>
            </div>
            <div v-if="element.emailData">
              <div v-if="element.network.name === 'Ironsource' ">
                Creatives will be automatically uploaded to IronSource, but must be manually added to campaign by email.
              </div>
              <div v-else>
                An email will be sent for a manual creative upload.
              </div>
              <el-collapse>
              <el-collapse-item title="Display the email">
              <el-form-item label="Sender">
                <el-input type="email" v-model="element.emailData.sender"></el-input>
              </el-form-item>
              <el-form-item label="Recipients">
              <el-input
                type="email"
                multiple="true"
                v-model="element.emailData.recipients"
              >
              </el-input>
              </el-form-item>
                <el-form-item label="Subject">
                  <el-input v-model="element.emailData.subject"></el-input>
                </el-form-item>
                <el-form-item label="Body">
                  <el-input type="textarea" rows="8" v-model="element.emailData.body"></el-input>
                </el-form-item>
              </el-collapse-item>
              </el-collapse>
            </div>
          </el-card>
          </el-form-item>
          <el-form-item class="submit">
            <el-button @click="closeDialog">Cancel</el-button>
            <el-button type="primary" native-type="submit" :disabled="formSubmit">Upload</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import { mapActions } from 'vuex';
import { buildEmailData } from '../../utils/email';
import { compareByNetworkPriority } from '../../utils/networks-by-priority';

export default {
  name: 'upload-request-to-campaign-dialog',
  data() {
    return {
      loading: true,
      dialogVisible: false,
      groupCheckbox: false,
      formSubmit: false,
      formSubmitTimeout: null,
      formErrors: [],
      model: {
        elements: {},
      },
    };
  },
  beforeDestroy() {
    clearTimeout(this.formSubmitTimeout);
  },
  methods: {
    ...mapActions('renderRequest', ['uploadRenderRequest', 'fetchRenderRequest']),
    async openDialog(element) {
      this.resetModel(element);
      this.element = element;
      this.dialogVisible = true;
      this.groupCheckbox = false;
    },
    toggleGroupCheckbox() {
      Object.values(this.model.elements).forEach((element) => {
        element.uploadToCampaign = this.groupCheckbox;

        return null;
      });
    },
    checkGroupCheckbox() {
      const elements = Object.values(this.model.elements);

      this.groupCheckbox = elements.length === elements.filter(element => element.uploadToCampaign).length;
    },
    async resetModel(element) {
      this.loading = true;
      const renderRequest = await this.fetchRenderRequest(element);

      if (renderRequest.renders) {
        for (const render of renderRequest.renders) {
          const networkName = render.network.name.toLowerCase();
          const gameName = render.rush.game.name;
          const gameUaEmail = render.rush.game.uaEmail;
          const key = [`${networkName}-${gameName}`];

          if (!this.model.elements[key]) {
            this.model.elements[key] = {};
            this.model.elements[key].renders = [];
          }
          this.model.elements[key].renders.push(render);
          this.model.elements[key].network = render.network;
          this.model.elements[key].game = render.rush.game;
          this.model.elements[key].gameUaEmail = gameUaEmail;
          this.model.elements[key].settings = {
            iosPlayableUrl: '',
            iosChinaPlayableUrl: '',
            androidPlayableUrl: '',
          };
        }
      }

      if (renderRequest.autoCMRenders) {
        for (const autoCMRender of renderRequest.autoCMRenders) {
          const networkName = autoCMRender.network.name.toLowerCase();
          const gameName = autoCMRender.game.name;
          const gameUaEmail = autoCMRender.game.uaEmail;
          const key = [`${networkName}-${gameName}`];
          if (!this.model.elements[key]) {
            this.model.elements[key] = {};
            this.model.elements[key].renders = [];
          }
          this.model.elements[key].renders.push(autoCMRender);
          this.model.elements[key].network = autoCMRender.network;
          this.model.elements[key].game = autoCMRender.game;
          this.model.elements[key].gameUaEmail = gameUaEmail;
          this.model.elements[key].settings = {
            iosPlayableUrl: '',
            iosChinaPlayableUrl: '',
            androidPlayableUrl: '',
          };
        }
      }

      for (const key of Object.keys(this.model.elements)) {
        this.model.elements[key].key = key;
        this.model.elements[key].uploadToCampaign = false;
        this.model.elements[key].emailData = buildEmailData(
          {
            gameName: this.model.elements[key].game.name,
            gameUaEmail: this.model.elements[key].gameUaEmail,
            network: this.model.elements[key].network,
            renders: this.model.elements[key].renders,
            userEmail: renderRequest.userEmail,
          },
        );
      }

      this.model = JSON.parse(JSON.stringify(this.model));

      this.model.elements = Object.values(this.model.elements)
        .sort((a, b) => compareByNetworkPriority(a.network, b.network))
        .reduce((obj, item) => ({ ...obj, [item.key]: item }), {});

      this.loading = false;
    },
    closeDialog() {
      this.dialogVisible = false;
      this.model.elements = {};
    },
    async validateRequest() {
      const emailData = [];
      const renderGroups = [];
      for (const key of Object.keys(this.model.elements)) {
        const data = this.model.elements[key];
        if (data.emailData && data.uploadToCampaign) {
          emailData.push(data.emailData);
        }
        if (data.uploadToCampaign) {
          renderGroups.push({ networkId: data.network.id, gameId: data.game.id, settings: data.settings });
        }
      }

      const renderRequest = await this.uploadRenderRequest({
        ...this.element,
        emailData,
        renderGroups,
      });

      const message = 'Upload to campaign';

      if (renderRequest && renderRequest.validated) {
        this.$notify({ type: 'success', message: this.$createElement('b', `${message} completed`) });
      } else {
        this.$notify({ type: 'warning', message: this.$createElement('b', `${message} canceled`) });
      }
    },
    async submitForm() {
      this.formSubmit = true;
      this.formSubmitTimeout = setTimeout(async () => {
        if (this.formValidation()) {
          this.validateRequest();
          this.closeDialog();
        }
        this.formSubmit = false;
      }, 300);
    },
    formValidation() {
      let valid = true;
      this.formErrors = [];

      Object.values(this.model.elements).forEach((element) => {
        const { emailData } = element;

        if (emailData) {
          if (!emailData.sender || emailData.sender === '') {
            this.formErrors.push(
              `You must specify a valid email sender for ${element.gameName} - ${element.network.name}`,
            );
            valid = false;
          }

          if (!emailData.recipients || emailData.recipients === '') {
            this.formErrors.push(
              `You must specify valid email recipients for ${element.gameName} - ${element.network.name}`,
            );
            valid = false;
          }

          if (!emailData.subject || emailData.subject === '') {
            this.formErrors.push(`You must add a email subject for ${element.gameName} - ${element.network.name}`);
            valid = false;
          }

          if (!emailData.body || emailData.body === '') {
            this.formErrors.push(`You must add an email body for ${element.gameName} - ${element.network.name}`);
            valid = false;
          }
        }
      });

      if (Object.values(this.model.elements).every(element => !element.uploadToCampaign)) {
        this.formErrors.push('You must select at least one network');
        valid = false;
      }

      return valid;
    },
  },
};
</script>

<style lang="scss" scoped>
.vdUploadRequestToCampaignDialog {
  display: block;

  .form {
    .error {
      margin-bottom: 10px;
    }

    .submit {
      text-align: right;
      margin: 0;
    }
  }

  /deep/.el-dialog {
    .el-dialog__header {
      margin: 0;
      padding: 15px;
      border-bottom: 1px solid #ebeef5;
    }

    .el-dialog__body {
      padding: 15px;
    }
  }

  .el-checkbox {
    width: 30px;
  }
}
</style>

