import Vue from 'vue';
import Router from 'vue-router';
import { OktaAuth } from '@okta/okta-auth-js';
import OktaVue, { LoginCallback } from '@okta/okta-vue';

import GameListing from './views/game/GameListing.vue';
import GameView from './views/game/GameView.vue';
import TemplateListing from './views/template/TemplateListing.vue';
import RushListing from './views/rush/RushListing.vue';
import NetworkListing from './views/network/NetworkListing.vue';
import DeviceListing from './views/device/DeviceListing.vue';
import FormatListing from './views/format/FormatListing.vue';
import RatioListing from './views/ratio/RatioListing.vue';
import OrientationListing from './views/orientation/OrientationListing.vue';
import MechanicListing from './views/mechanic/MechanicListing.vue';
import ClassificationListing from './views/classification/ClassificationListing.vue';
import WordingListing from './views/wording/WordingListing.vue';
import LanguageListing from './views/language/LanguageListing.vue';
import RenderPresetListing from './views/renderPreset/RenderPresetListing.vue';
import StaticConfigListing from './views/staticConfig/StaticConfigListing.vue';
import FontListing from './views/font/FontListing.vue';
import BadgeListing from './views/badge/BadgeListing.vue';
import RenderListing from './views/render/RenderListing.vue';
import AutoCMRenderListing from './views/autoCMRender/AutoCMRenderListing.vue';
import ManualRenderListing from './views/manualRender/ManualRenderListing.vue';
import EndcardRenderListing from './views/endcardRender/EndcardRenderListing.vue';
import StaticListing from './views/static/StaticListing.vue';
import RenderRequestListing from './views/renderRequest/RenderRequestListing.vue';
import AutoCMRenderRequestListing from './views/autoCMRenderRequest/AutoCMRenderRequestListing.vue';
import EndcardRenderRequestListing from './views/endcardRenderRequest/EndcardRenderRequestListing.vue';
import StaticRenderRequestListing from './views/staticRenderRequest/StaticRenderRequestListing.vue';
import GenerateView from './views/generate/GenerateView.vue';
import Login from './views/Login';
import oktaConfig from './plugins/okta';

const tilte = 'CM DCO Dashboard';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: GameListing,
      meta: {
        title: `${tilte} | Home`,
        requiresAuth: true,
      },
    },
    {
      path: '/games',
      name: 'games',
      component: GameListing,
      meta: {
        title: `${tilte} | Game list`,
        requiresAuth: true,
      },
    },
    {
      path: '/game/:id',
      component: GameView,
      meta: {
        title: `${tilte} | Game view`,
        requiresAuth: true,
      },
    },
    {
      path: '/rushes',
      name: 'rushes',
      component: RushListing,
      meta: {
        title: `${tilte} | Rushes list`,
        requiresAuth: true,
      },
    },
    {
      path: '/templates',
      name: 'templates',
      component: TemplateListing,
      meta: {
        title: `${tilte} | Template list`,
        requiresAuth: true,
      },
    },
    {
      path: '/networks',
      name: 'networks',
      component: NetworkListing,
      meta: {
        title: `${tilte} | Network list`,
        requiresAuth: true,
      },
    },
    {
      path: '/devices',
      name: 'devices',
      component: DeviceListing,
      meta: {
        title: `${tilte} | Device list`,
        requiresAuth: true,
      },
    },
    {
      path: '/formats',
      name: 'formats',
      component: FormatListing,
      meta: {
        title: `${tilte} | Format list`,
        requiresAuth: true,
      },
    },
    {
      path: '/ratios',
      name: 'ratios',
      component: RatioListing,
      meta: {
        title: `${tilte} | Ratio list`,
        requiresAuth: true,
      },
    },
    {
      path: '/orientations',
      name: 'orientations',
      component: OrientationListing,
      meta: {
        title: `${tilte} | Orientation list`,
        requiresAuth: true,
      },
    },
    {
      path: '/mechanics',
      name: 'mechanics',
      component: MechanicListing,
      meta: {
        title: `${tilte} | Mechanic list`,
        requiresAuth: true,
      },
    },
    {
      path: '/classifications',
      name: 'classifications',
      component: ClassificationListing,
      meta: {
        title: `${tilte} | Classification list'`,
        requiresAuth: true,
      },
    },
    {
      path: '/wordings',
      name: 'wordings',
      component: WordingListing,
      meta: {
        title: `${tilte} | Wording list`,
        requiresAuth: true,
      },
    },
    {
      path: '/languages',
      name: 'languages',
      component: LanguageListing,
      meta: {
        title: `${tilte} | Language list`,
        requiresAuth: true,
      },
    },
    {
      path: '/renders',
      name: 'renders',
      component: RenderListing,
      meta: {
        title: `${tilte} | Render list`,
        requiresAuth: true,
      },
    },
    {
      path: '/autocm-renders',
      name: 'autocm renders',
      component: AutoCMRenderListing,
      meta: {
        title: `${tilte} | AutoCM render list`,
        requiresAuth: true,
      },
    },
    {
      path: '/manual-renders',
      name: 'manual renders',
      component: ManualRenderListing,
      meta: {
        title: `${tilte} | Creatives list`,
        requiresAuth: true,
      },
    },
    {
      path: '/endcard-renders',
      name: 'endcard renders',
      component: EndcardRenderListing,
      meta: {
        title: `${tilte} | Endcard render list`,
        requiresAuth: true,
      },
    },
    {
      path: '/statics',
      name: 'statics',
      component: StaticListing,
      meta: {
        title: `${tilte} | Static list`,
        requiresAuth: true,
      },
    },
    {
      path: '/render-presets',
      name: 'render presets',
      component: RenderPresetListing,
      meta: {
        title: `${tilte} | Render preset list`,
        requiresAuth: true,
      },
    },
    {
      path: '/render-requests',
      name: 'render requests',
      component: RenderRequestListing,
      meta: {
        title: `${tilte} | Resizes list`,
        requiresAuth: true,
      },
    },
    {
      path: '/autocm-render-requests',
      name: 'autocm render requests',
      component: AutoCMRenderRequestListing,
      meta: {
        title: `${tilte} | Auto CM render request list`,
        requiresAuth: true,
      },
    },
    {
      path: '/endcard-render-requests',
      name: 'endcard render requests',
      component: EndcardRenderRequestListing,
      meta: {
        title: `${tilte} | Endcard render request list`,
        requiresAuth: true,
      },
    },
    {
      path: '/static-render-requests',
      name: 'static render requests',
      component: StaticRenderRequestListing,
      meta: {
        title: `${tilte} | Static render request list`,
        requiresAuth: true,
      },
    },
    {
      path: '/fonts',
      name: 'fonts',
      component: FontListing,
      meta: {
        title: `${tilte} | Font list`,
        requiresAuth: true,
      },
    },
    {
      path: '/static-configs',
      name: 'static configs',
      component: StaticConfigListing,
      meta: {
        title: `${tilte} | Static config list`,
        requiresAuth: true,
      },
    },
    {
      path: '/badges',
      name: 'badges',
      component: BadgeListing,
      meta: {
        title: `${tilte} | Badge list`,
        requiresAuth: true,
      },
    },
    {
      path: '/generate',
      name: 'generate',
      component: GenerateView,
      meta: {
        title: `${tilte} | Generate`,
        requiresAuth: true,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      props: true,
      meta: {
        title: `${tilte} | Log in`,
      },
    },
    {
      path: '/implicit/callback',
      name: 'handle-callback',
      component: LoginCallback,
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  document.title = to.meta ? to.meta.title : 'CM DCO Dashboard - Render Kid';

  return next();
});

Vue.use(OktaVue, {
  oktaAuth: new OktaAuth(oktaConfig),
  onAuthRequired: () => {
    router.push({ name: 'login' });
  },
});


export default router;
