<template>
  <div class="gameList">
    <vd-listing-header :title="title" :actions="headerActions"></vd-listing-header>
    <div class="content">
      <vd-paged-table
        tableKey="gameList"
        :loading="loading"
        :data="elements"
        :totalDataSize="total"
        :paging="fetchGamesByPage"
        :actions="actions"
        :columns="columns"
        :configVisible="configVisible"
        :configClose="closeConfig"
      ></vd-paged-table>
    </div>
    <game-dialog ref="gameDialog"></game-dialog>
    <static-dialog ref="staticDialog"></static-dialog>
    <endcard-dialog ref="endcardDialog"></endcard-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import GameDialog from '@/views/game/GameDialog.vue';
import StaticDialog from '@/views/game/StaticDialog.vue';
import EndcardDialog from '@/views/game/EndcardDialog.vue';
import VdPagedTable from '@/components/vd-paged-table/VdPagedTable';
import VdListingHeader from '@/components/vd-listing-header/VdListingHeader';
import { makeRouterLink, makeLink } from '../../utils/paged-table';

export default {
  name: 'gameList',
  data() {
    return {
      title: 'Game list',
      headerActions: [
        {
          callback: this.openGameDialog,
          title: 'Add',
          icon: 'circle-plus-outline',
          key: 'add',
        },
        {
          callback: this.openConfig,
          title: 'Configuration',
          icon: 'setting',
          key: 'setting',
        },
      ],
      actions: [
        {
          callback: this.goToRushPage, icon: 'film', key: 'rushes', title: 'Rushes',
        },
        {
          callback: this.goToManualRenderPage, icon: 'download', key: 'manual-renders', title: 'Creatives',
        },
        {
          callback: this.goToRenderPage, icon: 'folder', key: 'renders', title: 'Render kid renders',
        },
        {
          callback: this.goToAutoCMRenderPage, icon: 'files', key: 'autocm-renders', title: 'Creatives resizes',
        },
        {
          callback: this.goToStaticPage, icon: 'picture', key: 'statics', title: 'Statics',
        },

        {
          callback: this.openStaticDialog, icon: 'picture-outline-round', key: 'static', title: 'Generate static',
        },
        {
          callback: this.openEndcardDialog, icon: 'ice-cream', key: 'endcard', title: 'Generate endcard',
        },
        { callback: this.editItem, icon: 'edit', key: 'edit' },
        { callback: this.deleteItem, icon: 'delete', key: 'delete' },
      ],
      columns: [
        { key: 'code', prop: scope => scope.row.code, label: 'Code' },
        {
          key: 'name',
          prop: scope => makeRouterLink({
            path: `/game/${scope.row.id}`,
            text: scope.row.name,
          }),
          label: 'Game',
        },
        {
          key: 'uaEmail',
          prop: scope => makeLink({
            text: scope.row.uaEmail,
            href: `mailto:${scope.row.uaEmail}`,
          }),
          label: 'UA Email',
        },
        {
          key: 'orientation.name',
          prop: scope => scope.row.orientation.name,
          label: 'Orientation',
        },
        {
          key: 'mechanic.name',
          prop: scope => scope.row.mechanic.name,
          label: 'Mechanic',
        },
      ],
      configVisible: false,
    };
  },
  components: {
    GameDialog,
    StaticDialog,
    EndcardDialog,
    VdPagedTable,
    VdListingHeader,
  },
  computed: {
    ...mapState('game', ['loading', 'elements', 'total']),
  },
  methods: {
    ...mapActions('game', [
      'fetchGamesByPage',
      'deleteGame',
      'refreshStaticsByGame',
    ]),
    openGameDialog() {
      this.$refs.gameDialog.openGameDialog();
    },
    goToRushPage(element) {
      this.$router.push({ path: `/rushes?gameId=${element.id}` });
    },
    goToManualRenderPage(element) {
      this.$router.push({ path: `/manual-renders?gameId=${element.id}` });
    },
    goToRenderPage(element) {
      this.$router.push({ path: `/renders?gameId=${element.id}` });
    },
    goToAutoCMRenderPage(element) {
      this.$router.push({ path: `/autocm-renders?gameId=${element.id}` });
    },
    goToStaticPage(element) {
      this.$router.push({ path: `/statics?gameId=${element.id}` });
    },
    editItem(element) {
      this.$refs.gameDialog.openGameDialog(element);
    },
    openStaticDialog(element) {
      this.$refs.staticDialog.openStaticDialog({ gameId: element.id, ...element });
    },
    openEndcardDialog(element) {
      this.$refs.endcardDialog.openEndcardDialog(element);
    },
    regenStatics(element) {
      this.refreshStaticsByGame(element);
    },
    deleteItem(element) {
      this.$confirm('Do you really want to delete this game ?')
        .then(async () => {
          await this.deleteGame(element);
        })
        .catch(() => {});
    },
    openConfig() {
      this.configVisible = true;
    },
    closeConfig() {
      this.configVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.gameList {
  display: block;

  .content {
    .table {
      width: 100%;
    }
  }
}
</style>

