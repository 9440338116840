<template>
  <div class="templateList">
    <vd-listing-header :title="title" :actions="headerActions"></vd-listing-header>
    <div class="content">
      <vd-paged-table
        tableKey="templateList"
        :loading="loading"
        :data="elements"
        :totalDataSize="total"
        :paging="fetchTemplatesByPage"
        :actions="actions"
        :columns="columns"
        :configVisible="configVisible"
        :configClose="closeConfig"
      ></vd-paged-table>
    </div>
    <template-dialog ref="templateDialog"></template-dialog>
    <template-detail-dialog ref="templateDetailDialog"></template-detail-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import TemplateDialog from '@/views/template/TemplateDialog.vue';
import TemplateDetailDialog from '@/views/template/TemplateDetailDialog.vue';
import VdPagedTable from '@/components/vd-paged-table/VdPagedTable';
import VdListingHeader from '@/components/vd-listing-header/VdListingHeader';
import { makeLink } from '../../utils/paged-table';

export default {
  name: 'templateList',
  data() {
    return {
      title: 'Template list',
      headerActions: [
        {
          callback: this.openTemplateDialog,
          title: 'Add',
          icon: 'circle-plus-outline',
          key: 'add',
        },
        {
          callback: this.openConfig,
          title: 'Configuration',
          icon: 'setting',
          key: 'setting',
        },
      ],
      actions: [
        { callback: this.checkDetails, icon: 'tickets', key: 'details' },
        { callback: this.upgradeItem, icon: 'arrow-up', key: 'upgrade' },
        { callback: this.deleteItem, icon: 'delete', key: 'delete' },
      ],
      columns: [
        {
          key: 'name',
          prop: scope => makeLink({
            href: scope.row.document.url,
            text: scope.row.name,
          }),
          label: 'Template',
        },
        {
          key: 'description',
          prop: scope => (scope.row.description ? scope.row.description : ''),
          label: 'Description',
        },
        {
          key: 'templateType.name',
          prop: scope => scope.row.templateType.name,
          label: 'Template Type',
        },
      ],
      configVisible: false,
    };
  },
  components: {
    TemplateDialog,
    TemplateDetailDialog,
    VdPagedTable,
    VdListingHeader,
  },
  computed: {
    ...mapState('template', ['loading', 'elements', 'total']),
  },
  methods: {
    ...mapActions('template', ['fetchTemplatesByPage', 'deleteTemplate']),
    openTemplateDialog() {
      this.$refs.templateDialog.openTemplateDialog();
    },
    upgradeItem(element) {
      this.$refs.templateDialog.openTemplateDialog(element);
    },
    checkDetails(element) {
      this.$refs.templateDetailDialog.openTemplateDetailDialog(element);
    },
    deleteItem(element) {
      this.$confirm('Do you really want to delete this template ?')
        .then(async () => {
          await this.deleteTemplate(element);
        })
        .catch(() => {});
    },
    openConfig() {
      this.configVisible = true;
    },
    closeConfig() {
      this.configVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.templateList {
  display: block;

  .content {
    .table {
      width: 100%;
    }
  }
}
</style>

