<template>
  <div class="vdUploadToCampaignDialog">
    <el-dialog
      :close-on-click-modal="false"
      :before-close="closeDialog"
      title="Upload to campaign"
      :visible.sync="dialogVisible"
      width="90%"
    >
      <div v-loading="loading">
        <el-form
          class="form"
          ref="model"
          :model="model"
          @submit.native.prevent="submitForm"
        >
          <el-form-item v-if="formErrors.length">
            <el-alert
              v-for="(error, index) in formErrors"
              :key="index"
              :title="error"
              :closable="false"
              type="error"
              class="error"
            ></el-alert>
          </el-form-item>
          <el-form-item v-if="type === 'rush'">
            <el-select
              v-model="selectedNetworks"
              :multiple="true"
              :filterable="true"
              placeholder="Networks"
              @change="onSelectNetworks"
            >
              <el-option
                v-for="network in networkElements"
                :key="network.id"
                :label="network.name"
                :value="network.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-for="(element, elementIndex) in model.elements" :key="elementIndex">
            <el-card shadow="hover">
              <div slot="header" class="card-header">
                <b>{{ element.gameName }} - {{ element.networkName }}</b>
              </div>
              <div class="element-warning" v-if="element.network !== 'tiktok' && !element.emailData">
                <i class="el-icon-info"></i> Yellow row mean that the upload will exceed the ads limit for the ad group.
              </div>
              <div class="element-danger" v-if="element.network !== 'tiktok' && !element.emailData">
                <i class="el-icon-info"></i> Red row mean that the ad group is full of ads.
              </div>
              <div
                class="element-warning"
                v-if="element.network === 'tiktok' && element.elements.length > 5 && !element.emailData"
              >
                <i class="el-icon-info"></i> You have selected more than 5 renders for Tiktok.
                This is not recommended to upload more than 5 ad videos in an ad group.
              </div>
              <div class="element-warning" v-if="element.emailData">
                <div v-if="element.network === 'ironsource'">
                  <i class="el-icon-info"></i>
                  Creatives will be upload to IronSource, but they must be manually added to a campaign by email.
                </div>
                <div v-else-if="element.network === 'applovin'">
                  <div><i class="el-icon-info"></i>Creatives will be upload to the selected campaigns.</div>
                  <div><i class="el-icon-info"></i>An email will be sent as a backup.</div>
                  <div>
                    <i class="el-icon-info"></i>
                    The playable url set up here will override the one set up in Kitchen
                  </div>
                </div>
                <div v-else>
                  <i class="el-icon-info"></i> An email will be sent for a manual creative upload.
                </div>
              </div>
              <div class="element-bloc">
                <div
                  v-for="(item, itemIndex) in element.elements"
                  :key="itemIndex"
                  class="element-name"
                  :class="{'element-red': !item.outputDocument}"
                >
                  {{ item.name }} <span v-if="!item.outputDocument">(invalid)</span>
                </div>
              </div>
              <el-form-item label="Playable Url" v-if="applovinFeature(element.network)">
                  <el-input type="url" v-model="element.settings.playableUrl"></el-input>
              </el-form-item>
              <div class="element-settings" v-if="!element.emailData">
                <div>
                  <el-checkbox v-model="element.settings.canDuplicateAd">
                    Duplicate ad if already exist (use with caution)
                  </el-checkbox>
                </div>
                <div v-if="element.network === 'mintegral'">
                  <el-checkbox v-model="element.settings.usCountryOnly">
                    US target country only for EN videos (use in testing case)
                  </el-checkbox>
                </div>
              </div>
              <el-table
                :data="element.campaignSettings.filtered"
                @selection-change="(value) => handleCheckedChange(element.campaignSettings, value)"
                size="mini"
                height="355"
                v-if="!element.emailData || applovinFeature(element.network)"
              >
                <el-table-column type="expand" v-if="element.network !== 'applovin'">
                  <template slot-scope="props">
                    <div class="row-table" v-if="element.campaignElements[props.row.id].adGroups.length">
                      <el-table
                        :data="element.campaignElements[props.row.id].adGroupSettings.filtered"
                        @selection-change="(value) => handleCheckedChange(
                          element.campaignElements[props.row.id].adGroupSettings, value,
                        )"
                        :row-class-name="({ row }) => tableRowClassName(row, element.elements.length)"
                        size="mini"
                      >
                        <el-table-column type="selection" width="55"></el-table-column>
                        <el-table-column label="Ad Group ID" property="id" width="150"></el-table-column>
                        <el-table-column property="name">
                          <template slot="header">
                            <el-input
                              v-model="element.campaignElements[props.row.id].adGroupSettings.search"
                              @input="(value) => handleSearchChange(
                                element.campaignElements[props.row.id].adGroups,
                                element.campaignElements[props.row.id].adGroupSettings,
                                value
                              )"
                              size="mini"
                              placeholder="Ad Group Name" />
                          </template>
                        </el-table-column>
                        <el-table-column label="Ad Group Status" property="status" width="150"></el-table-column>
                        <el-table-column label="Ad Group Ads" property="adsCount" width="150"></el-table-column>
                      </el-table>
                    </div>
                    <div class="row-table" v-else-if="element.network === 'tiktok'">
                      Adgroups are automaticaly created for non IOS 14+ campaign on TikTok.
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  type="selection"
                  :selectable="() => element.network === 'tiktok' || element.network === 'applovin'"
                  width="55"
                >
                </el-table-column>
                <el-table-column label="Campaign ID" property="id" width="150"></el-table-column>
                <el-table-column property="name">
                  <template slot="header">
                    <el-input
                      v-model="element.campaignSettings.search"
                      @input="(value) => handleSearchChange(
                        element.campaignElements,
                        element.campaignSettings,
                        value
                      )"
                      size="mini"
                      placeholder="Campaign Name" />
                  </template>
                </el-table-column>
                <el-table-column label="Campaign Status" property="status" width="150"></el-table-column>
              </el-table>
              <div v-if="element.emailData">
                <el-form-item label="Sender">
                  <el-input type="email" v-model="element.emailData.sender"></el-input>
                </el-form-item>
                <el-form-item label="Recipients">
                  <el-input
                    type="email"
                    multiple="true"
                    v-model="element.emailData.recipients"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item label="Subject">
                  <el-input v-model="element.emailData.subject"></el-input>
                </el-form-item>
                <el-form-item label="Body">
                  <el-input type="textarea" rows="8" v-model="element.emailData.body"></el-input>
                </el-form-item>
              </div>
            </el-card>
          </el-form-item>
          <el-form-item class="submit">
            <el-button @click="closeDialog">Cancel</el-button>
            <el-button type="primary" native-type="submit" :disabled="formSubmit">Upload</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { buildEmailData } from '../../utils/email';
import config from '../../services/config.service';

export default {
  name: 'upload-to-campaign-dialog',
  data() {
    return {
      dialogVisible: false,
      formSubmit: false,
      formSubmitTimeout: null,
      formErrors: [],
      model: {
        elements: {},
      },
      type: null,
      selectedNetworks: [],
    };
  },
  async mounted() {
    await this.init();
  },
  beforeDestroy() {
    clearTimeout(this.formSubmitTimeout);
  },
  computed: {
    ...mapState('networkService', ['loading']),
    ...mapState('network', { networkElements: 'elements' }),
    ...mapGetters('auth', ['user']),
  },
  methods: {
    ...mapActions('networkService', ['fetchNetworkCampaigns', 'fetchNetworkAdGroups']),
    ...mapActions('render', ['uploadRendersToCampaign']),
    ...mapActions('rush', ['uploadRushesToCampaign']),
    ...mapActions('autoCMRender', ['uploadAutoCMRendersToCampaign']),
    ...mapActions('manualRender', ['uploadManualRendersToCampaign']),
    ...mapActions('network', ['fetchNetworks']),
    async init() {
      await this.fetchNetworks();
    },
    async openDialog({ elements = null, type = 'default' }) {
      this.resetModel({ elements, type });
      this.elements = elements;
      this.type = type;
      this.selectedNetworks = [];
      this.dialogVisible = true;
    },
    async resetModel({ elements = null, type = 'default' }) {
      this.formErrors = [];
      if (type === 'rush') {
        if (!this.selectedNetworks || !this.selectedNetworks.length) {
          this.model = { elements: {} };
          return;
        }
        for (const key in this.model.elements) {
          if (this.model.elements[key]) {
            const keyParts = key.split('-');
            const network = this.networkElements.find(n => n.name.toLowerCase() === keyParts[0]);
            if (this.selectedNetworks.find(networkId => networkId === network.id)) {
              this.model.elements[key].elements = [];
            } else {
              delete this.model.elements[key];
            }
          }
        }
      } else {
        this.model = { elements: {} };
      }

      if (elements && elements.length) {
        for (const element of elements) {
          const { name: networkName } = element.network;
          const fullNetwork = this.networkElements.find(n => n.name === networkName);
          const { appId, name: gameName, uaEmail: gameUaEmail } = element.game || element.rush.game;
          const network = networkName.toLowerCase();
          const key = `${network}-${appId}`;

          if (!this.model.elements[key]) {
            let campaignElements = {};

            if (this.applovinFeature(network)) {
              // eslint-disable-next-line no-await-in-loop
              const campaigns = await this.fetchNetworkCampaigns({ network, appId }) || [];
              campaignElements = campaigns.reduce((acc, cur) => { acc[cur.id] = cur; return acc; }, {});
            } else {
              // eslint-disable-next-line no-await-in-loop
              const adGroups = await this.fetchNetworkAdGroups({ network, appId }) || [];
              campaignElements = adGroups.reduce((acc, cur) => {
                if (cur.campaignId && !acc[cur.campaignId]) {
                  acc[cur.campaignId] = {
                    id: cur.campaignId,
                    name: cur.campaignName,
                    status: cur.campaignStatus,
                    adGroups: [],
                    adGroupSettings: { filtered: [], checked: [], search: '' },
                  };
                }
                const campaignTiktokIos14Plus = (network === 'tiktok' && cur.campaignName.includes(' - SKA'));
                if (acc[cur.campaignId] && (network !== 'tiktok' || campaignTiktokIos14Plus)) {
                  acc[cur.campaignId].adGroups.push(cur);
                  acc[cur.campaignId].adGroupSettings.filtered = [...acc[cur.campaignId].adGroups];
                }
                return acc;
              }, {});
            }

            this.model.elements[key] = {
              appId,
              gameName,
              gameUaEmail,
              network,
              networkName,
              campaignElements,
              campaignSettings: { filtered: [], checked: [], search: '' },
              elements: [],
              settings: { canDuplicateAd: false, usCountryOnly: false, playableUrl: '' },
            };
            this.model.elements[key].campaignSettings.filtered = [
              ...Object.values(this.model.elements[key].campaignElements),
            ];
          }
          this.model.elements[key].elements.push({ ...element });

          this.model.elements[key].emailData = buildEmailData(
            {
              gameName,
              gameUaEmail,
              network: fullNetwork,
              userEmail: this.user.email,
              renders: this.model.elements[key].elements,
            },
          );
        }
        this.model = JSON.parse(JSON.stringify(this.model));
      }
    },
    onSelectNetworks(networks) {
      const elements = [];
      if (networks && networks.length) {
        networks.forEach((networkId) => {
          const network = this.networkElements.find(n => n.id === networkId);
          const items = JSON.parse(JSON.stringify(this.elements));
          items.forEach((item) => {
            item.network = network;
            elements.push(item);
          });
        });
      }
      this.resetModel({ elements, type: this.type });
    },
    tableRowClassName(row, elements) {
      if (row.adsCount) {
        const ads = row.adsCount.split('/')[0];
        const max = row.adsCount.split('/')[1];
        if (+ads === +max) {
          return 'table-row-danger';
        } if (+ads + +elements > +max) {
          return 'table-row-warning';
        }
      }
      return '';
    },
    handleCheckedChange(element, value) {
      element.checked = [...value];
    },
    handleSearchChange(elements, element, value) {
      element.filtered = Object.values(elements).filter(
        data => !value || data.name.toLowerCase().includes(value.toLowerCase()),
      );
    },
    closeDialog() {
      this.resetModel({});
      this.dialogVisible = false;
    },
    adgroupValidation(campaignElements) {
      let elementValid = false;

      Object.values(campaignElements).forEach((campaign) => {
        if (campaign.adGroupSettings && campaign.adGroupSettings.checked.length) {
          elementValid = true;
        }
      });

      return elementValid;
    },
    formValidation() {
      let valid = true;
      this.formErrors = [];
      Object.values(this.model.elements).forEach((element) => {
        const { emailData } = element;

        if (element.network === 'tiktok') {
          if (!element.campaignSettings.checked.length && !this.adgroupValidation(element.campaignElements)) {
            this.formErrors.push(
              `You must select a campaign or an adgroup for ${element.gameName} - ${element.networkName}`,
            );
            valid = false;
          }
        } else if (element.network === 'applovin') {
          if (!element.campaignSettings.checked.length) {
            this.formErrors.push(
              `You must select a campaign for ${element.gameName} - ${element.networkName}`,
            );
            valid = false;
          }
        } else if (!emailData && Object.values(element.campaignElements).length) {
          if (!this.adgroupValidation(element.campaignElements)) {
            this.formErrors.push(`You must select an ad group for ${element.gameName} - ${element.networkName}`);
            valid = false;
          }
        }

        if (emailData) {
          if (!emailData.sender || emailData.sender === '') {
            this.formErrors.push(
              `You must specify a valid email sender for ${element.gameName} - ${element.networkName}`,
            );
            valid = false;
          }

          if (!emailData.recipients || !emailData.recipients.length) {
            this.formErrors.push(
              `You must specify valid email recipients for ${element.gameName} - ${element.networkName.name}`,
            );
            valid = false;
          }

          if (!emailData.subject || emailData.subject === '') {
            this.formErrors.push(`You must add a email subject for ${element.gameName} - ${element.networkName}`);
            valid = false;
          }

          if (!emailData.body || emailData.body === '') {
            this.formErrors.push(`You must add an email body for ${element.gameName} - ${element.networkName}`);
            valid = false;
          }
        }
      });
      return valid;
    },
    applovinFeature(network) {
      return network === 'applovin' && config.featureFlag.applovin;
    },
    async submitForm() {
      this.formSubmit = true;
      this.formSubmitTimeout = setTimeout(async () => {
        if (this.formValidation()) {
          const data = { elements: [], emailData: [] };
          Object.values(this.model.elements).forEach((element) => {
            if (element.emailData) {
              data.emailData.push(element.emailData);
            }
            element.elements.forEach((elem) => {
              data.elements.push({
                element: elem,
                campaigns: element.campaignSettings.checked,
                adGroups: [
                  ...Object.values(element.campaignElements).map(
                    campaign => (campaign.adGroupSettings && campaign.adGroupSettings.checked) || [],
                  ).flat(),
                ],
                settings: element.settings,
              });
            });
          });
          let response = null;
          if (this.type === 'rush') {
            response = await this.uploadRushesToCampaign(data);
          } else if (this.type === 'autocm') {
            response = await this.uploadAutoCMRendersToCampaign(data);
          } else if (this.type === 'manual') {
            response = await this.uploadManualRendersToCampaign(data);
          } else {
            response = await this.uploadRendersToCampaign(data);
          }
          if (response) {
            this.$notify({ type: 'success', message: this.$createElement('b', 'Upload is in progress') });
            this.closeDialog();
          } else {
            this.$notify({ type: 'error', message: this.$createElement('b', 'An error has occurred') });
          }
        }
        this.formSubmit = false;
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.vdUploadToCampaignDialog {
  display: block;

  .form {
    .error {
      margin-bottom: 10px;
    }

    .submit {
      text-align: right;
      margin: 0;
    }
  }

  /deep/.el-table__expanded-cell {
    padding: 5px 80px 30px 80px;
    border-left: 20px solid #ebeff5;
    border-right: 20px solid #ebeff5;
  }

  /deep/.el-table__row.table-row-warning {
    color: #333;
    background-color: #ffc966;

    &:hover td {
      background-color: #ffc04c;
    }
  }

  /deep/.el-table__row.table-row-danger {
    color: #333;
    background-color: #f66;

    &:hover td {
      background-color: #ff4c4c;
    }
  }

  .element-warning {
    font-size: 12px;
    color: #ffc04c;
    line-height: 1;
    padding-bottom: 10px;
  }

  .element-danger {
    font-size: 12px;
    color: #ff4c4c;
    line-height: 1;
    padding-bottom: 10px;
  }

  .element-bloc {
    padding: 10px;
    border: solid 1px #eee;
    line-height: 20px;

    .element-name {
      display: inline-block;
      margin-right: 10px;
      font-size: 11px;
      color: #333;

      &.element-green {
        color: green;
      }

      &.element-red {
        color: red;
      }
    }
  }

  .element-settings {
    /deep/.el-checkbox__label {
      font-size: 12px;
    }
  }

  .el-container {
    margin-top: 5px;
  }

  .el-aside {
    background-color: #d3dce6;
    color: #333;
    text-align: center;
    width: 200px;
  }

  .el-main {
    background-color: #e9eef3;
    color: #333;
    text-align: left;
    padding: 0;
  }

  p {
    margin-block-start: 0;
    margin-block-end: 0;
  }

  /deep/.el-dialog {
    .el-dialog__header {
      margin: 0;
      padding: 15px;
      border-bottom: 1px solid #ebeef5;
    }

    .el-dialog__body {
      padding: 15px;
    }
  }
}
</style>
