<template>
  <div class="staticConfigAsset">
    <div>
      <el-switch class="switch" v-model="value.has"></el-switch>
      {{title}}
    </div>
    <div class="inputs" v-if="value.has">
      <el-input v-model="value.width" type="number" placeholder="Width"></el-input>
      <el-input v-model="value.height" type="number" placeholder="Height"></el-input>
      <div class="spacer"></div>
      <el-input v-model="value.x" type="number" placeholder="X Offset"></el-input>
      <el-input v-model="value.y" type="number" placeholder="Y Offset"></el-input>
    </div>
  </div>
</template>

<script>
export default {
  name: 'static-asset-component',
  data() {
    return {};
  },
  props: {
    value: Object,
    title: String,
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.staticConfigAsset {
  display: block;

  .switch {
    margin-right: 16px;
  }

  .inputs {
    display: flex;

    .spacer {
      min-width: 16px;
    }
  }
}
</style>

