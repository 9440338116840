import Vue from 'vue';
import Vuex from 'vuex';
import gql from 'graphql-tag';
import { apolloClient } from '../../utils/vue-apollo';

Vue.use(Vuex);

const GRAPHQL_NETWORK_PROPS = `
  id
  name
  emails
  formats {
    id
    name
    width
    height
    time
  }
`;

const GRAPHQL = {
  queries: {
    fetchNetworks: gql`
      query {
        networks {
          ${GRAPHQL_NETWORK_PROPS}
        }
      }
    `,
    fetchNetworksByPage: gql`
      query($input: NetworksByPageInput!) {
        networksByPage(input: $input) {
          elements {
            ${GRAPHQL_NETWORK_PROPS}
          }
          total
        }
      }
    `,
  },
  mutations: {
    addNetwork: gql`
      mutation($input: AddNetworkInput!) {
        addNetwork(input: $input) {
          ${GRAPHQL_NETWORK_PROPS}
        }
      }
    `,
    editNetwork: gql`
      mutation($input: EditNetworkInput!) {
        editNetwork(input: $input) {
          ${GRAPHQL_NETWORK_PROPS}
        }
      }
    `,
    deleteNetwork: gql`
      mutation($input: DeleteNetworkInput!) {
        deleteNetwork(input: $input)
      }
    `,
  },
};

export default {
  namespaced: true,
  state: {
    elements: [],
    total: 0,
    loading: false,
  },
  mutations: {
    setNetworks(state, { elements, total = 0 }) {
      state.elements = elements;
      state.total = total;
      state.loading = false;
    },
    pushNetwork(state, item) {
      state.elements.push(item);
      state.total += 1;
      state.loading = false;
    },
    updateNetwork(state, item) {
      const index = state.elements.findIndex(elem => elem.id === item.id);
      if (index !== -1) {
        state.elements[index] = item;
        state.elements = [...state.elements];
      }
      state.loading = false;
    },
    removeNetwork(state, id) {
      const index = state.elements.findIndex(elem => elem.id === id);
      if (index !== -1) {
        state.elements.splice(index, 1);
        state.total -= 1;
      }
      state.loading = false;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
  },
  actions: {
    async fetchNetworks({ commit }) {
      commit('setLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchNetworks,
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const networks = results && results.data && results.data.networks ? results.data.networks : [];
      commit('setNetworks', { elements: networks, total: networks.length });
    },
    async fetchNetworksByPage({ commit }, { page = 1, pageSize = 1, options = {} }) {
      commit('setLoading', true);
      const optionsStr = JSON.stringify(options);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchNetworksByPage,
          variables: {
            input: {
              page,
              pageSize,
              options: optionsStr,
            },
          },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const networks = results && results.data && results.data.networksByPage ? results.data.networksByPage : {};
      commit('setNetworks', networks);
    },
    async addNetwork({ commit }, { name, formatIds, emails }) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.addNetwork,
          variables: {
            input: { name, formatIds, emails },
          },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const network = results && results.data && results.data.addNetwork ? results.data.addNetwork : null;
      if (network) {
        commit('pushNetwork', network);
      } else {
        commit('setLoading', false);
      }
    },
    async editNetwork({ commit }, {
      id, name, formatIds, emails,
    }) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.editNetwork,
          variables: {
            input: {
              id, name, formatIds, emails,
            },
          },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const network = results && results.data && results.data.editNetwork ? results.data.editNetwork : null;
      if (network) {
        commit('updateNetwork', network);
      } else {
        commit('setLoading', false);
      }
    },
    async deleteNetwork({ commit }, { id }) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.deleteNetwork,
          variables: {
            input: { id },
          },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const deleted = results && results.data && results.data.deleteNetwork;
      if (deleted) {
        commit('removeNetwork', id);
      } else {
        commit('setLoading', false);
      }
    },
  },
};
