<template>
  <div class="vdUploadRenderDialog">
    <el-dialog
      :close-on-click-modal="false"
      :before-close="closeDialog"
      title="Upload renders"
      :visible.sync="dialogVisible"
      width="90%"
    >
      <div v-loading="loading">
        <el-form
          class="form"
          ref="model"
          :model="model"
          @submit.native.prevent="submitForm"
        >
          <el-form-item v-if="formErrors.length">
            <el-alert
              v-for="(error, index) in formErrors"
              :key="index"
              :title="error"
              :closable="false"
              type="error"
              class="error"
            ></el-alert>
          </el-form-item>
          <el-form-item v-for="(element, elementIndex) in model.elements" :key="elementIndex">
            <el-card shadow="hover">
              <div slot="header" class="card-header">
                <b>{{ element.gameName }} - {{ element.networkName }}</b>
              </div>
              <vd-upload-multiple
                uploadPath="documents/renders/upload"
                @upload="onChangeUpload($event, elementIndex)"
              ></vd-upload-multiple>
              <div class="element-bloc">
                <div
                  v-for="(item, itemIndex) in element.elements"
                  :key="itemIndex"
                  class="element-name"
                  :class="{
                    'element-green': !!element.documents.find(
                      d => d.fileName.toLowerCase().includes(item.name.toLowerCase())
                    )
                  }"
                >
                  {{ item.name }}
                  <i
                    v-if="!!element.documents.find(d => d.fileName.toLowerCase().includes(item.name.toLowerCase()))"
                    class="el-icon-check">
                  </i>
                </div>
              </div>
            </el-card>
          </el-form-item>
          <el-form-item class="submit">
            <el-button @click="closeDialog">Cancel</el-button>
            <el-button type="primary" native-type="submit" :disabled="formSubmit">Upload</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import VdUploadMultiple from '@/components/vd-upload-multiple/VdUploadMultiple.vue';

export default {
  name: 'upload-render-dialog',
  data() {
    return {
      dialogVisible: false,
      formSubmit: false,
      formSubmitTimeout: null,
      formErrors: [],
      model: {
        renderRequestId: null,
        elements: {},
      },
      type: 'default',
    };
  },
  beforeDestroy() {
    clearTimeout(this.formSubmitTimeout);
  },
  components: {
    VdUploadMultiple,
  },
  computed: {
    ...mapState('autoCMRender', ['loading', 'elements', 'total']),
  },
  methods: {
    ...mapActions('autoCMRender', ['fetchAutoCMRendersByPage', 'uploadAutoCMRenders']),
    async openDialog({ renderRequestId = null }) {
      await this.fetchAutoCMRendersByPage({
        page: 1,
        pageSize: 1000,
        options: { order: [], where: {}, query: { renderRequestId } },
      });
      this.resetModel({ renderRequestId, elements: this.elements });
      this.dialogVisible = true;
    },
    async resetModel({ renderRequestId = null, elements = null }) {
      this.formErrors = [];
      this.model = { renderRequestId, elements: {} };
      if (elements) {
        elements.forEach((element) => {
          const { name: networkName } = element.network;
          const { appId, name: gameName } = element.game || element.rush.game;
          const network = networkName.toLowerCase();
          const key = `${network}-${appId}`;
          if (!this.model.elements[key]) {
            this.model.elements[key] = {
              gameName, networkName, elements: [], documents: [],
            };
          }
          this.model.elements[key].elements.push({ ...element });
        });
        this.model = JSON.parse(JSON.stringify(this.model));
      }
    },
    closeDialog() {
      this.resetModel({});
      this.dialogVisible = false;
    },
    onChangeUpload(documents, key) {
      this.model.elements[key].documents = [];
      setTimeout(() => {
        this.model.elements[key].documents = [...documents];
      }, 250);
    },
    formValidation() {
      let valid = true;
      this.formErrors = [];
      let nbDocuments = 0;
      Object.values(this.model.elements).forEach((element) => {
        nbDocuments += element.documents.length;
      });
      if (!nbDocuments) {
        this.formErrors.push('You must upload renders');
        valid = false;
      }
      return valid;
    },
    async submitForm() {
      this.formSubmit = true;
      this.formSubmitTimeout = setTimeout(async () => {
        if (this.formValidation()) {
          const data = { request: { id: this.model.renderRequestId }, renders: [] };
          Object.values(this.model.elements).forEach((element) => {
            element.elements.forEach((render) => {
              const document = element.documents.find(
                d => d.fileName.toLowerCase().includes(render.name.toLowerCase()),
              );
              if (document) {
                data.renders.push({
                  render: { id: render.id }, renderDocument: { key: document.key, url: document.url },
                });
              }
            });
          });
          const response = this.uploadAutoCMRenders(data);
          if (response) {
            this.$notify({ type: 'success', message: this.$createElement('b', 'Renders are uploaded') });
            this.closeDialog();
          } else {
            this.$notify({ type: 'error', message: this.$createElement('b', 'An error has occurred') });
          }
        }
        this.formSubmit = false;
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.vdUploadRenderDialog {
  display: block;

  .form {
    .error {
      margin-bottom: 10px;
    }

    .submit {
      text-align: right;
      margin: 0;
    }
  }

  .element-bloc {
    margin-top: 10px;
    padding: 10px;
    border: solid 1px #eee;
    line-height: 20px;

    .element-name {
      display: inline-block;
      margin-right: 10px;
      font-size: 11px;
      color: #bbb;

      &.element-green {
        color: green;
      }

      &.element-red {
        color: red;
      }
    }
  }

  /deep/.el-dialog {
    .el-dialog__header {
      margin: 0;
      padding: 15px;
      border-bottom: 1px solid #ebeef5;
    }

    .el-dialog__body {
      padding: 15px;
    }
  }
}
</style>
