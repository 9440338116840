const prefix = 'cm-dco-dashboard';

const getStorageData = (key) => {
  const data = localStorage.getItem(`${prefix}-${key}`);
  if (data) {
    return JSON.parse(data);
  }
  return null;
};

const setStorageData = (key, data) => {
  if (data) {
    localStorage.setItem(`${prefix}-${key}`, JSON.stringify(data));
  }
};

const removeStorageData = key => localStorage.removeItem(`${prefix}-${key}`);


exports.getStorageData = getStorageData;
exports.setStorageData = setStorageData;
exports.removeStorageData = removeStorageData;
