import Vue from 'vue';
import Vuex from 'vuex';
import gql from 'graphql-tag';
import { apolloClient } from '../../utils/vue-apollo';

Vue.use(Vuex);

const GRAPHQL_FONT_PROPS = `
  id
  name
  postscriptName
  family
  style
  weight
  width
  italic
  monospace
  serif
  document {
    id
    key
    url
  }
`;

const GRAPHQL = {
  queries: {
    fetchFonts: gql`
      query {
        fonts {
          ${GRAPHQL_FONT_PROPS}
        }
      }
    `,
    fetchFontsByPage: gql`
      query($input: FontsByPageInput!) {
        fontsByPage(input: $input) {
          elements {
            ${GRAPHQL_FONT_PROPS}
          }
          total
        }
      }
    `,
  },
  mutations: {
    addFont: gql`
      mutation($input: AddFontInput!) { 
        addFont(input: $input) {
          ${GRAPHQL_FONT_PROPS}
        }
      }
    `,
    editFont: gql`
      mutation($input: EditFontInput!) { 
        editFont(input: $input) {
          ${GRAPHQL_FONT_PROPS}
        }
      }
    `,
    deleteFont: gql`
      mutation($input: DeleteFontInput!) {
        deleteFont(input: $input)
      }
    `,
  },
};

export default {
  namespaced: true,
  state: {
    elements: [],
    total: 0,
    loading: false,
  },
  mutations: {
    setFonts(state, { elements, total = 0 }) {
      state.elements = elements;
      state.total = total;
      state.loading = false;
    },
    pushFont(state, item) {
      state.elements.push(item);
      state.total += 1;
      state.loading = false;
    },
    updateFont(state, item) {
      const index = state.elements.findIndex(elem => elem.id === item.id);
      if (index !== -1) {
        state.elements[index] = item;
        state.elements = [...state.elements];
      }
      state.loading = false;
    },
    removeFont(state, id) {
      const index = state.elements.findIndex(elem => elem.id === id);
      if (index !== -1) {
        state.elements.splice(index, 1);
        state.total -= 1;
      }
      state.loading = false;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
  },
  actions: {
    async fetchFonts({ commit }) {
      commit('setLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchFonts,
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const fonts = results && results.data && results.data.fonts ? results.data.fonts : [];
      commit('setFonts', { elements: fonts, total: fonts.length });
    },
    async fetchFontsByPage({ commit }, { page = 1, pageSize = 1, options = {} }) {
      commit('setLoading', true);
      const optionsStr = JSON.stringify(options);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchFontsByPage,
          variables: {
            input: {
              page,
              pageSize,
              options: optionsStr,
            },
          },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const fonts = results && results.data && results.data.fontsByPage ? results.data.fontsByPage : {};
      commit('setFonts', fonts);
    },
    async addFont(
      { commit },
      {
        name, postscriptName, family, style, weight, width, italic, monospace, serif, document,
      },
    ) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.addFont,
          variables: {
            input: {
              name,
              postscriptName,
              family,
              style,
              weight,
              width,
              italic,
              monospace,
              serif,
              document: {
                id: document.id,
                key: document.key,
                url: document.url,
                fileName: document.fileName,
                fileType: document.fileType,
                fileSize: document.fileSize,
              },
            },
          },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const font = results && results.data && results.data.addFont ? results.data.addFont : null;
      if (font) {
        commit('pushFont', font);
      } else {
        commit('setLoading', false);
      }
    },
    async editFont(
      { commit },
      {
        id, name, postscriptName, family, style, weight, width, italic, monospace, serif, document,
      },
    ) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.editFont,
          variables: {
            input: {
              id,
              name,
              postscriptName,
              family,
              style,
              weight,
              width,
              italic,
              monospace,
              serif,
              document: {
                id: document.id,
                key: document.key,
                url: document.url,
                fileName: document.fileName,
                fileType: document.fileType,
                fileSize: document.fileSize,
              },
            },
          },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const font = results && results.data && results.data.editFont ? results.data.editFont : null;
      if (font) {
        commit('updateFont', font);
      } else {
        commit('setLoading', false);
      }
    },
    async deleteFont({ commit }, { id }) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.deleteFont,
          variables: { input: { id } },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const deleted = results && results.data && results.data.deleteFont;
      if (deleted) {
        commit('removeFont', id);
      } else {
        commit('setLoading', false);
      }
    },
  },
};
