<template>
  <div class="login">
    <el-card>
      <div class="login-form">
        <h3 class="login-title">Restricted access</h3>
        <el-button class="login-button" type="primary" @click="login()">
          Sign in with Okta
        </el-button>
        <div v-if="loginError">
          {{loginError}}<br />
          Contact an administrator if issue persists.
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'Login',
  props: ['loginError'],
  computed: {
    isAuthenticated() {
      return this.authState.isAuthenticated;
    },
  },
  methods: {
    async login() {
      await this.$auth.signInWithRedirect();
    },
  },
  watch: {
    isAuthenticated(newVal) {
      if (newVal === true) {
        this.$router.push({ name: 'home' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .login {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 400px;
  }

  .login-button {
    width: 100%;
  }

  .login-title {
    text-align: center;
  }

  .login-form {
    width: 400px;
  }
</style>
