import Vue from 'vue';
import axios from 'axios';
import correlationHeader from './correlation-header';

export default () => {
  axios.defaults.withCredentials = true;
  axios.interceptors.request.use(async (config) => {
    const accessToken = await Vue.prototype.$auth.getAccessToken();
    config.headers = {
      ...correlationHeader(),
    };
    if (accessToken) {
      config.headers.authorization = `Bearer ${accessToken}`;
    }
    return config;
  });
};

