import Vue from 'vue';
import Vuex from 'vuex';
import gql from 'graphql-tag';
import { apolloClient } from '../../utils/vue-apollo';

Vue.use(Vuex);

const GRAPHQL_RUSH_PROPS = `
  id
  name
  description
  gameId
  deviceId
  version
  offsetVertical
  offsetHorizontal
  best
  game {
    id
    appId
    name
    code
    uaEmail
  }
  device {
    id
    name
    format {
      id
      name
    }
  }
  document {
    id
    key
    url
    fileName
    fileType
    fileSize
  }
  outputDocument {
    id
    key
    url
  }
  createdAt
  updatedAt
`;

const GRAPHQL = {
  queries: {
    fetchRushes: gql`
      query {
        rushes {
          ${GRAPHQL_RUSH_PROPS}
        }
      }
    `,
    fetchRushesByGameId: gql`
      query($gameId: Int!) {
        rushesByGameId(gameId: $gameId) {
          ${GRAPHQL_RUSH_PROPS}
        }
      }
    `,
    fetchRushesForManualRenderValidation: gql`
      query($gameId: Int!) {
        rushesByGameId(gameId: $gameId) {
          id
          name
          best
        }
      }
    `,
    fetchRushesByPage: gql`
      query($input: RushesByPageInput!) {
        rushesByPage(input: $input) {
          elements {
            ${GRAPHQL_RUSH_PROPS}
          }
          total
        }
      }
    `,
    validateRushesExist: gql`
      query ($gameId: Int!, $names: [String!]!) {
        validateRushesExist(gameId: $gameId, names: $names) {
          name
          rushes {
            id
            name
            gameId
            best
            formatId
            formatName
          }
        }
      }
    `,
  },
  mutations: {
    addRush: gql`
      mutation($input: AddRushInput!) {
        addRush(input: $input) {
          ${GRAPHQL_RUSH_PROPS}
        }
      }
    `,
    editRush: gql`
      mutation($input: EditRushInput!, $isAdaptation: Boolean!) {
        editRush(input: $input, isAdaptation: $isAdaptation) {
          ${GRAPHQL_RUSH_PROPS}
        }
      }
    `,
    deleteRush: gql`
      mutation($input: DeleteRushInput!) {
        deleteRush(input: $input)
      }
    `,
    uploadRushes: gql`
      mutation($input: UploadRushesInput!) {
        uploadRushes(input: $input)
      }
    `,
    uploadRushesToCampaign: gql`
      mutation($input: UploadRushesToCampaignInput!) {
        uploadRushesToCampaign(input: $input)
      }
    `,
  },
};

export default {
  namespaced: true,
  state: {
    elements: [],
    total: 0,
    loading: false,
  },
  mutations: {
    setRushes(state, { elements, total = 0 }) {
      state.elements = elements;
      state.total = total;
      state.loading = false;
    },
    pushRush(state, item) {
      state.elements.push(item);
      state.total += 1;
      state.loading = false;
    },
    updateRush(state, item) {
      const index = state.elements.findIndex(elem => elem.id === item.id);
      if (index !== -1) {
        state.elements[index] = item;
        state.elements = [...state.elements];
      }
      state.loading = false;
    },
    removeRush(state, id) {
      const index = state.elements.findIndex(elem => elem.id === id);
      if (index !== -1) {
        state.elements.splice(index, 1);
        state.total -= 1;
      }
      state.loading = false;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
  },
  actions: {
    async fetchRushes({ commit }) {
      commit('setLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchRushes,
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const rushes = results && results.data && results.data.rushes ? results.data.rushes : [];
      commit('setRushes', { elements: rushes, total: rushes.length });
    },
    async fetchRushesByGameId({ commit }, { gameId }) {
      commit('setLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchRushesByGameId,
          variables: { gameId },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const rushes = results && results.data && results.data.rushesByGameId ? results.data.rushesByGameId : [];
      commit('setLoading', false);
      return rushes;
    },
    async fetchRushesForManualRenderValidation({ commit }, { gameId }) {
      commit('setLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchRushesForManualRenderValidation,
          variables: { gameId },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });

      const rushes = results && results.data && results.data.rushesByGameId ? results.data.rushesByGameId : [];
      commit('setLoading', false);
      return rushes;
    },
    async fetchRushesByPage({ commit }, { page = 1, pageSize = 1, options = {} }) {
      commit('setLoading', true);
      const optionsStr = JSON.stringify(options);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchRushesByPage,
          variables: {
            input: {
              page,
              pageSize,
              options: optionsStr,
            },
          },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const rushes = results && results.data && results.data.rushesByPage ? results.data.rushesByPage : {};
      commit('setRushes', rushes);
    },
    async validateRushesExist({ commit }, { gameId, names }) {
      commit('setLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.validateRushesExist,
          variables: { gameId, names },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const rushes = results && results.data && results.data.validateRushesExist ?
        results.data.validateRushesExist :
        [];
      commit('setLoading', false);
      return rushes;
    },
    async addRush({ commit }, {
      description, offsetVertical, offsetHorizontal, gameId, deviceId, document, best,
    }) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.addRush,
          variables: {
            input: {
              description,
              offsetVertical,
              offsetHorizontal,
              gameId,
              deviceId,
              document: {
                id: document.id,
                key: document.key,
                url: document.url,
                fileName: document.fileName,
                fileType: document.fileType,
                fileSize: document.fileSize,
              },
              best,
            },
          },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const rush = results && results.data && results.data.addRush ? results.data.addRush : null;
      if (rush) {
        commit('pushRush', rush);
      } else {
        commit('setLoading', false);
      }
    },
    async editRush({ commit }, {
      id, description, offsetVertical, offsetHorizontal, gameId, deviceId, best, isAdaptation,
    }) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.editRush,
          variables: {
            input: {
              id,
              description,
              offsetVertical,
              offsetHorizontal,
              gameId,
              deviceId,
              best,
            },
            isAdaptation,
          },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const rush = results && results.data && results.data.editRush ? results.data.editRush : null;
      if (rush) {
        commit('updateRush', rush);
      } else {
        commit('setLoading', false);
      }
    },
    async deleteRush({ commit }, { id }) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.deleteRush,
          variables: {
            input: { id },
          },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const deleted = results && results.data && results.data.deleteRush;
      if (deleted) {
        commit('removeRush', id);
      } else {
        commit('setLoading', false);
      }
    },
    async uploadRushes({ commit }, { rushes }) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.uploadRushes,
          variables: { input: { rushes } },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      commit('setLoading', false);
      return (results && results.data && results.data.uploadRushes) || null;
    },
    async uploadRushesToCampaign({ commit }, { elements, emailData }) {
      commit('setLoading', true);
      const elementsStr = JSON.stringify(elements);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.uploadRushesToCampaign,
          variables: { input: { emailData, elements: elementsStr } },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      commit('setLoading', false);
      return (results && results.data && results.data.uploadRushesToCampaign) || null;
    },
  },
};
