<template>
  <div class="renderRequestList">
    <vd-listing-header :title="title" :actions="headerActions" :datePicker="true" :handleDatesChange="handleDates">
    </vd-listing-header>
    <div class="content">
      <vd-paged-table
        tableKey="renderRequestList"
        :loading="loading"
        :data="elements"
        :totalDataSize="total"
        :paging="fetchRenderRequestsByPage"
        :dates="dates"
        :actions="actions"
        :columns="columns"
        :configVisible="configVisible"
        :configClose="closeConfig"
        :refetchTimeout="5000"
      ></vd-paged-table>
    </div>
    <vd-upload-request-to-campaign-dialog ref="vdUploadRequestToCampaignDialog"></vd-upload-request-to-campaign-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';

import VdPagedTable from '@/components/vd-paged-table/VdPagedTable';
import VdListingHeader from '@/components/vd-listing-header/VdListingHeader';
import VdUploadRequestToCampaignDialog from '@/dialogs/vd-upload-request-to-campaign/VdUploadRequestToCampaignDialog';
import { makeRouterLink, makeProgress } from '../../utils/paged-table';

export default {
  name: 'renderRequestList',
  data() {
    return {
      title: 'Rushes list',
      headerActions: [
        {
          callback: this.openConfig,
          title: 'Configuration',
          icon: 'setting',
          key: 'setting',
        },
      ],
      actions: [
        { callback: this.validateRequest, icon: 'check', key: 'validate-request' },
        {
          callback: this.uploadRequest,
          icon: 'upload',
          key: 'upload-request',
          disableCallback: this.disallowUploadRequest,
        },
        { callback: this.forceRerender, icon: 'refresh', key: 'rerender' },
      ],
      columns: [
        {
          key: 'id',
          prop: scope => scope.row.id,
          label: 'Id',
        },
        {
          key: 'name',
          prop: scope => makeRouterLink({
            path: '/renders',
            query: { renderRequestId: scope.row.id },
            text: scope.row.name,
          }),
          label: 'Request',
        },
        {
          key: 'userEmail',
          prop: scope => scope.row.userEmail,
          label: 'Email',
        },
        {
          key: 'progress.percentage',
          prop: scope => makeProgress({
            percentage: scope.row.progress.percentage,
          }),
          label: 'Progress',
        },
        {
          key: 'progress.tasks',
          prop: scope => scope.row.progress.tasks,
          label: 'Tasks',
        },
        {
          key: 'validated',
          prop: scope => (scope.row.validated ? 'Yes' : 'No'),
          label: 'Validated',
        },
        {
          key: 'createdAt',
          prop: scope => moment(scope.row.createdAt).format('YYYY-MM-DD HH:mm:ss'),
          label: 'Creation',
        },
      ],
      dates: null,
      configVisible: false,
    };
  },
  components: {
    VdPagedTable,
    VdListingHeader,
    VdUploadRequestToCampaignDialog,
  },
  computed: {
    ...mapState('renderRequest', ['loading', 'elements', 'total']),
  },
  methods: {
    ...mapActions('renderRequest', [
      'fetchRenderRequestsByPage',
      'validateRenderRequest',
      'forceRerender',
    ]),
    uploadRequest(element) {
      if (!this.disallowUploadRequest(element)) {
        this.$refs.vdUploadRequestToCampaignDialog.openDialog(element);
      }
    },
    disallowUploadRequest(element) {
      return !element.validated;
    },
    async validateRequest(element) {
      this.$confirm(
        '<div>Files will be duplicated to the output directory</div>',
        `${element.validated ? 'Revalidate' : ' Validate'} this request ?`,
        { type: 'warning', dangerouslyUseHTMLString: true },
      ).then(async () => {
        const renderRequest = await this.validateRenderRequest({ ...element });
        const message = 'Validation';
        if (renderRequest && renderRequest.validated) {
          this.$notify({ type: 'success', message: this.$createElement('b', `${message} completed`) });
        } else {
          this.$notify({ type: 'warning', message: this.$createElement('b', `${message} canceled`) });
        }
      });
    },
    handleDates(dates) {
      this.dates = dates;
    },
    openConfig() {
      this.configVisible = true;
    },
    closeConfig() {
      this.configVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.renderRequestList {
  display: block;

  .content {
    .table {
      width: 100%;
    }
  }
}
</style>

