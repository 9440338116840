const networkWithEmails = [
  'applovin',
  'crossinstall',
  'ironsource',
  'jetfuel',
  'moblike',
  'tapjoy',
  'vungle',
  'mytarget',
];

const emailSubject = 'New creatives for {gameName}';

const emailBody = [
  'Hello,',
  'Can you please add the following creatives for {gameName}\'s campaign in the right language ?',
  'They are available in the following links:',
  '{publicUrls}',
  'Thanks.',
].join('\n');

const applovinBody = [
  'Hello,',
  'The following creatives for {gameName}\'s should be uploaded automatically.',
  'Could you check if everything is fine ?',
  'They are available in the following links:',
  '{publicUrls}',
  'Thanks.',
].join('\n');

export function isNetworkWithEmails(network) {
  return networkWithEmails.includes(network.name.toLowerCase());
}

export function buildEmailData({
  gameName, gameUaEmail, network, userEmail, renders,
}) {
  if (!isNetworkWithEmails(network)) {
    return null;
  }

  const publicUrls = renders.map(render => render.outputDocument.url).join('\n- ');

  let body = network.name.toLowerCase() === 'applovin' ? applovinBody : emailBody;

  body = body
    .replace('{publicUrls}', `- ${publicUrls}`)
    .replace('{gameName}', gameName);

  const subject = emailSubject.replace('{gameName}', gameName);

  const recipients = network.emails || [];
  if (gameUaEmail && recipients.indexOf(gameUaEmail) === -1) {
    recipients.push(gameUaEmail);
  }

  return {
    sender: userEmail,
    recipients: recipients.join(', '),
    subject,
    body,
  };
}
