<template>
  <div class="content">
    <el-card class="box-card">
      <div class="loading" v-loading="loading"></div>
      <div class="table-setting">
        <span class="table-setting-item" v-if="select && selectedData && selectedData.length">
          Selected: {{ (selectedData && selectedData.length) || 0 }}
          <el-button class="cancel" @click="() => cancelSelect()" type="text" size="small" title="Cancel selection">
            <i class="icon el-icon-close"></i>
          </el-button>
        </span>
        <span class="table-setting-item" v-if="Object.keys(where).length !== 0">
          Search: {{ Object.keys(where).length }}
          <el-button class="cancel" @click="() => cancelSearch()" type="text" size="small" title="Cancel search">
            <i class="icon el-icon-close"></i>
          </el-button>
        </span>
        <span class="table-setting-item" v-if="order.length !== 0">
          Sort: {{ order.length }}
          <el-button class="cancel" @click="() => cancelSort()" type="text" size="small" title="Cancel sort">
            <i class="icon el-icon-close"></i>
          </el-button>
        </span>
      </div>
      <div class="table">
        <el-table stripe :data="data" @sort-change="handleSortChange">
          <el-table-column v-if="select" fixed width="35">
            <template slot="header">
              <el-checkbox @change="(value) => handleSelect(data, value)"></el-checkbox>
            </template>
            <template slot-scope="scope">
              <el-checkbox
              :value="!!selectedData.find(elem => elem.id === data[scope.$index].id)"
              @change="(value) => handleSelect([data[scope.$index]], value)"
              ></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column
            v-for="column in filteredColumns"
            v-bind:key="column.key"
            :prop="column.key"
            :label="column.label"
            :sortable="!paging || !!column.simple ? false : 'custom'"
            width="220"
          >
            <template slot="header">
              <vd-table-search
                v-if="column.simple !== true"
                :column="column"
                :handle-search="handleSearchChange"
              ></vd-table-search>
            </template>
            <template slot-scope="scope">
              <vd-custom-column :display="column.prop(scope)"></vd-custom-column>
            </template>
          </el-table-column>
          <el-table-column label="Action" v-if="actions && actions.length" fixed="right" :width="actionColWidth">
            <template slot-scope="scope">
              <div class="actions">
                <el-button
                  v-for="action in actions"
                  v-bind:key="action.key"
                  :disabled="action.disableCallback && action.disableCallback(data[scope.$index])"
                  @click.native.prevent="action.callback(data[scope.$index])"
                  type="text"
                  size="small"
                  :title="action.title"
                >
                  <span v-if="action.name">{{action.name}}</span>
                  <i v-if="action.icon" :class="'icon el-icon-' + action.icon"></i>
                </el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column v-if="actions && actions.length"></el-table-column>
        </el-table>
      </div>
      <div class="table-pagination">
        <el-pagination
          background
          v-if="paging"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          layout="sizes, prev, pager, next, jumper, ->, total, slot"
          :total="totalDataSize"
        ></el-pagination>
      </div>
    </el-card>
    <el-drawer class="config" title="Configuration" :visible="configVisible" @close="configClose" direction="rtl">
      <div class="config-content">
        <div class="config-title">Display columns</div>
        <div v-for="column in columns" v-bind:key="column.key">
          <el-checkbox
            v-model="config.columns[column.key].visible"
            @change="(visible) => handleConfigColumnChange(column, visible)"
          >
            {{ column.label }}
          </el-checkbox>
        </div>
        <div class="config-title">Elements per page</div>
        <el-select v-model="config.pageSize" @change="handleConfigPageSizeChange" size="small">
          <el-option v-for="size in pageSizes" :key="size" :label="`${size}/page`" :value="size">
            {{ size }}/page
          </el-option>
        </el-select>
        <div class="config-reset">
          <el-button type="danger" icon="el-icon-delete" @click="handleConfigReset()" size="small">
            <span>Reset configuration</span>
          </el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { getStorageData, setStorageData, removeStorageData } from '../../utils/storage';
import VdCustomColumn from './VdCustomColumn';
import VdTableSearch from './VdTableSearch';

export default {
  name: 'vd-paged-table',
  props: {
    tableKey: String,
    title: String,
    paging: Function,
    searchBar: Boolean,
    loading: Boolean,
    data: Array,
    totalDataSize: Number,
    actions: Array,
    columns: Array,
    query: Object,
    dates: Object,
    refetchTimeout: Number,
    select: Boolean,
    handleSelect: Function,
    cancelSelect: Function,
    selectedData: Array,
    configVisible: Boolean,
    configClose: Function,
    additionalWhere: Object,
  },
  data() {
    return {
      order: [],
      where: {},
      stopped: false,
      timeout: this.refetchTimeout || -1,
      page: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 40, 50, 100],
      config: { columns: {}, pageSize: 10 },
      filteredColumns: [],
    };
  },
  async beforeMount() {
    this.initConfig();
  },
  async mounted() {
    if (this.paging && this.timeout > 0) {
      this.stopped = false;
      this.poll();
    } else if (this.paging) {
      await this.fetch();
    }
  },
  async beforeDestroy() {
    this.stopped = true;
  },
  computed: {
    actionColWidth() {
      const items = this.actions.length || 1;
      return items * 30 < 70 ? 70 : items * 30;
    },
  },
  watch: {
    dates() {
      this.fetch();
    },
    additionalWhere() {
      this.fetch();
    },
  },
  methods: {
    async poll() {
      if (this.stopped) return;
      this.fetch().then(() => setTimeout(this.poll, this.timeout));
    },
    async fetch() {
      if (!this.paging) return;

      await this.paging({
        page: this.page,
        pageSize: this.pageSize,
        options: {
          order: this.order,
          where: {
            ...this.where,
            ...this.additionalWhere || {},
          },
          query: this.query || null,
          dates: this.dates || null,
        },
      });
    },
    initConfig() {
      this.config = { ...this.config, ...(getStorageData(`${this.tableKey}-config`) || {}) };
      this.pageSize = this.config.pageSize;
      this.filteredColumns = [];
      this.columns.forEach((column) => {
        if (!this.config.columns[column.key]) {
          this.config.columns[column.key] = { visible: true };
        }
        if (this.config.columns[column.key] && this.config.columns[column.key].visible) {
          this.filteredColumns.push(column);
        }
      });
    },
    handlePageChange(page) {
      this.page = page;
      this.fetch();
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.fetch();
    },
    handleSortChange({ prop, order }) {
      const current = this.order.findIndex(ord => ord[0] === prop);
      const newOrder = order ?
        [prop, order.indexOf('asc') !== -1 ? 'ASC' : 'DESC'] :
        null;
      if (current !== -1) {
        if (newOrder) {
          this.order[current] = newOrder;
        }
      } else if (newOrder) {
        this.order.push(newOrder);
      } else {
        this.order = [];
      }
      this.fetch();
    },
    cancelSort() {
      this.order = [];
      this.fetch();
    },
    handleSearchChange({ key, search }) {
      if (!search) {
        delete this.where[key];
      } else {
        this.where[key] = search;
      }
      this.fetch();
    },
    cancelSearch() {
      this.where = {};
      this.fetch();
    },
    handleConfigColumnChange(column, visible) {
      this.config.columns[column.key].visible = visible;
      setStorageData(`${this.tableKey}-config`, this.config);
      this.initConfig();
    },
    handleConfigPageSizeChange(pageSize) {
      this.config.pageSize = pageSize;
      setStorageData(`${this.tableKey}-config`, this.config);
      this.initConfig();
      this.handleSizeChange(pageSize);
    },
    handleConfigReset() {
      removeStorageData(`${this.tableKey}-config`);
      this.config = { columns: {}, pageSize: 10 };
      this.initConfig();
    },
  },
  components: {
    VdCustomColumn,
    VdTableSearch,
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: inline;

  /deep/.el-card {
    position: relative;

    .el-card__body {
      padding: 15px;
    }
  }

  .table {
    width: auto;
    min-height: 105px;
    max-height: calc(100vh - 370px);
    overflow: auto;
    border: 1px solid #ebeef5;

    /deep/.el-table {
      tr {
        th,
        td {
          border-bottom: 1px solid #ebeef5;
          border-right: 1px solid #ebeef5;
          padding: 2px 0;

          &:last-child {
            border-right: none;
          }
        }
      }
    }
  }

  .loading {
    margin: 10px 15px 0 0;
    position: absolute;
    width: 45px;
    right: 0;
  }

  .table-setting {
    font-size: 14px;
    color: teal;
    height: 34px;

    .table-setting-item {
      margin-right: 16px;
      padding: 2px 2px 2px 4px;
      border-radius: 4px;
      background-color: #f5f7fa;

      .cancel {
        padding: 0;
        margin: 0;
        color: red;
      }
    }
  }

  .actions {
    min-width: 180px;
    white-space: nowrap;
  }

  .table-pagination {
    margin-top: 15px;
  }

  .icon {
    font-size: 16px;
  }
}

.config {
  /deep/.el-drawer__header {
    margin: 0;
    padding: 15px;
    border-bottom: 1px solid #ebeef5;
  }

  .config-content {
    margin: 0;
    padding: 15px;
    height: calc(100vh - 56px);
    overflow: auto;

    .config-title {
      font-size: 14px;
      font-weight: bold;
      margin: 10px 0;
      color: #72767b;
    }

    .config-reset {
      text-align: right;
      margin-top: 10px;
    }
  }

  /deep/.el-drawer__container {
    outline: none;
  }

  /deep/.el-drawer__close-btn {
    outline: none;
  }
}
</style>
