<template>
  <div class="templateDetailDialog">
    <el-dialog
      :close-on-click-modal="false"
      :before-close="closeTemplateDetailDialog"
      :title="getTitle()"
      :visible.sync="templateDetailDialogVisible"
      width="50%"
      custom-class="test-dialog"
    >
      <div class="content">
        <div class="sidebar">
          <div
            v-for="element of templateElements"
            v-bind:key="element.id"
            class="template"
            v-bind:class="{bold: selectedElement && (element.id === selectedElement.id)}"
            @click="selectElement(element)"
          >{{element.name}}</div>
        </div>
        <div class="detail" v-if="selectedElement">
          <vd-property title="Name" :value="selectedElement.name"></vd-property>
          <vd-property title="Description" :value="selectedElement.description"></vd-property>
          <vd-property title="Template type" :value="selectedElement.templateType.name"></vd-property>
          <vd-property title="Key" :value="selectedElement.key"></vd-property>
          <vd-property title="Version" :value="selectedElement.version"></vd-property>
          <vd-property title="URL" :value="selectedElement.document.url" type="link"></vd-property>
          <div v-if="meta">
            <vd-property title="Orientation" :values="meta.orientation.map(({name}) => name)"></vd-property>
            <vd-property title="Device" :values="meta.device.map(({name}) => name)"></vd-property>
            <vd-property title="Ratio" :values="meta.ratio.map(({name}) => name)"></vd-property>
            <vd-property title="Mechanic" :values="meta.mechanic.map(({name}) => name)"></vd-property>
            <vd-property title="Classification" :values="meta.classification.map(({name}) => name)"></vd-property>
            <vd-property title="Wording" :values="meta.wording.map(({text}) => text)"></vd-property>
            <vd-property title="CTA Wording" :values="meta.cta.map(({text}) => text)"></vd-property>
          </div>
        </div>
      </div>
      <div class="footer">
        <el-button @click="closeTemplateDetailDialog">Close</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import VdProperty from '@/components/vd-property/VdProperty.vue';

export default {
  name: 'template-detail-dialog',
  data() {
    return {
      template: null,
      templateDetailDialogVisible: false,
      selectedElement: null,
      meta: null,
    };
  },
  async mounted() {
    await this.init();
  },
  computed: {
    ...mapState('templateType', { templateTypeElements: 'elements' }),
    ...mapState('orientation', { orientationElements: 'elements' }),
    ...mapState('mechanic', { mechanicElements: 'elements' }),
    ...mapState('device', { deviceElements: 'elements' }),
    ...mapState('ratio', { ratioElements: 'elements' }),
    ...mapState('classification', { classificationElements: 'elements' }),
    ...mapState('wording', { wordingElements: 'elements' }),
    ...mapState({
      templateElements: state => state.template.byTypeAndKey.elements,
    }),
  },
  methods: {
    ...mapActions('template', [
      'addTemplate',
      'upgradeTemplate',
      'fetchTemplatesByTypeAndKey',
    ]),
    ...mapActions('templateType', ['fetchTemplateTypes']),
    ...mapActions('orientation', ['fetchOrientations']),
    ...mapActions('mechanic', ['fetchMechanics']),
    ...mapActions('device', ['fetchDevices']),
    ...mapActions('ratio', ['fetchRatios']),
    ...mapActions('classification', ['fetchClassifications']),
    ...mapActions('wording', ['fetchWordings']),
    async init() {
      await Promise.all([
        this.fetchTemplateTypes(),
        this.fetchOrientations(),
        this.fetchMechanics(),
        this.fetchDevices(),
        this.fetchRatios(),
        this.fetchClassifications(),
        this.fetchWordings(),
      ]);
      this.selectedElement = null;
      this.meta = null;
      if (this.template) {
        await this.fetchTemplatesByTypeAndKey(this.template);
        this.selectElement(this.templateElements[0]);
      }
    },
    async openTemplateDetailDialog(template) {
      this.template = template;
      await this.init();
      this.templateDetailDialogVisible = true;
    },
    closeTemplateDetailDialog() {
      this.templateDetailDialogVisible = false;
    },
    getTitle() {
      if (!this.template) {
        return 'Template Detail';
      }
      return `${this.template.templateType.name} ${`${
        this.template.key
      }`.padStart(3, 0)} detail`;
    },
    selectElement(elem) {
      this.selectedElement = elem;
      this.meta = this.groupByMeta(elem.templateMeta);
    },
    findMeta(id, meta, templateMeta, metaType) {
      const metaEntries = metaType ?
        templateMeta.filter(
          ({ entityName, entityType }) => entityName === meta && entityType === metaType,
        ) :
        templateMeta.filter(({ entityName }) => entityName === meta);
      return (
        metaEntries
          .map(({ entityId }) => entityId)
          .findIndex(eid => eid === id) !== -1
      );
    },
    groupByMeta(meta) {
      return {
        orientation: this.orientationElements.filter(({ id }) => this.findMeta(id, 'orientation', meta)),
        device: this.deviceElements.filter(({ id }) => this.findMeta(id, 'device', meta)),
        ratio: this.ratioElements.filter(({ id }) => this.findMeta(id, 'ratio', meta)),
        mechanic: this.mechanicElements.filter(({ id }) => this.findMeta(id, 'mechanic', meta)),
        classification: this.classificationElements.filter(({ id }) => this.findMeta(id, 'classification', meta)),
        wording: this.wordingElements.filter(({ id }) => this.findMeta(id, 'wording', meta, 'wording')),
        cta: this.wordingElements.filter(({ id }) => this.findMeta(id, 'wording', meta, 'cta')),
      };
    },
  },
  components: {
    VdProperty,
  },
};
</script>

<style lang="scss" scoped>
.templateDetailDialog {
  display: block;
  height: 50%;

  .test-dialog {
    height: 70vh;

    :nth-child() div {
      height: 100%;
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    border-top: solid 1px #727272;
    border-bottom: solid 1px #727272;
    height: 50vh;

    .sidebar {
      max-width: 25%;
      height: 100%;
      border-right: solid 1px #727272;
      overflow: auto;

      .template {
        padding: 8px;
        cursor: pointer;
      }

      .bold {
        font-weight: bold;
        color: white;
        background-color: teal;
      }
    }

    .detail {
      width: 75%;
      overflow: auto;
    }
  }

  .footer {
    text-align: right;
    margin-top: 20px;
  }

  /deep/.el-dialog {
    .el-dialog__header {
      margin: 0;
      padding: 15px;
      border-bottom: 1px solid #ebeef5;
    }

    .el-dialog__body {
      padding: 15px;
    }
  }
}
</style>

