import Vue from 'vue';
import Vuex from 'vuex';
import gql from 'graphql-tag';
import { apolloClient } from '../../utils/vue-apollo';

Vue.use(Vuex);

const GRAPHQL_WORDING_TYPE_PROPS = `
  id
  name
`;

const GRAPHQL = {
  queries: {
    fetchWordingTypes: gql`
      query {
        wordingTypes {
          ${GRAPHQL_WORDING_TYPE_PROPS}
        }
      }
    `,
  },
};

export default {
  namespaced: true,
  state: {
    elements: [],
    total: 0,
    loading: false,
  },
  mutations: {
    setWordingTypes(state, { elements, total = 0 }) {
      state.elements = elements;
      state.total = total;
      state.loading = false;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
  },
  actions: {
    async fetchWordingTypes({ commit }) {
      commit('setLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchWordingTypes,
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const wordingTypes = results && results.data && results.data.wordingTypes ? results.data.wordingTypes : [];
      commit('setWordingTypes', { elements: wordingTypes, total: wordingTypes.length });
    },
  },
};
