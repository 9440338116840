<template>
  <div class="formatDialog">
    <el-dialog
      :close-on-click-modal="false"
      :before-close="closeFormatDialog"
      title="Format"
      :visible.sync="formatDialogVisible"
      width="50%"
    >
      <el-form
        class="form"
        ref="formatModel"
        label-width="120px"
        :model="formatModel"
        @submit.native.prevent="submitFormatForm"
      >
        <el-form-item v-if="formatFormErrors.length">
          <el-alert
            v-for="(error, index) in formatFormErrors"
            :key="index"
            :title="error"
            :closable="false"
            type="error"
            class="error"
          ></el-alert>
        </el-form-item>
        <el-form-item>
          <el-select v-model="formatModel.formatTypeId" placeholder="Type">
            <el-option
              v-for="option in formatTypeElements"
              :key="option.id"
              :label="option.name"
              :value="option.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="formatModel.ratioId" placeholder="Ratio">
            <el-option label="None" :value="null"></el-option>
            <el-option
              v-for="option in ratioElements"
              :key="option.id"
              :label="option.name"
              :value="option.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Width">
          <el-input-number placeholder="Width" :min="0" v-model="formatModel.width"></el-input-number>
        </el-form-item>
        <el-form-item label="Height">
          <el-input-number placeholder="Height" :min="0" v-model="formatModel.height"></el-input-number>
        </el-form-item>
        <el-form-item label="Time (seconds)">
          <el-input-number placeholder="Time" :min="0" v-model="formatModel.time"></el-input-number>
        </el-form-item>
        <el-form-item class="submit">
          <el-button @click="closeFormatDialog">Cancel</el-button>
          <el-button type="primary" native-type="submit" :disabled="formatFormSubmit">Save</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'format-dialog',
  data() {
    return {
      formatDialogVisible: false,
      formatFormSubmit: false,
      formatFormSubmitTimeout: null,
      formatFormErrors: [],
      formatModel: {
        id: null,
        formatTypeId: null,
        ratioId: null,
        width: null,
        height: null,
        time: null,
      },
    };
  },
  async mounted() {
    await this.init();
  },
  beforeDestroy() {
    clearTimeout(this.formatFormSubmitTimeout);
  },
  computed: {
    ...mapState('formatType', { formatTypeElements: 'elements' }),
    ...mapState('ratio', { ratioElements: 'elements' }),
  },
  methods: {
    ...mapActions('formatType', ['fetchFormatTypes']),
    ...mapActions('ratio', ['fetchRatios']),
    ...mapActions('format', ['addFormat', 'editFormat']),
    async init() {
      await Promise.all([
        this.fetchFormatTypes(),
        this.fetchRatios(),
      ]);
    },
    async openFormatDialog(format = null) {
      await this.init();
      this.resetFormatModel(format);
      this.formatDialogVisible = true;
    },
    resetFormatModel(format = null) {
      this.formatFormErrors = [];
      if (format) {
        this.formatModel = { ...format };
      } else {
        this.formatModel = {
          id: null,
          formatTypeId: null,
          ratioId: null,
          width: null,
          height: null,
          time: null,
        };
      }
    },
    closeFormatDialog() {
      this.resetFormatModel();
      this.formatDialogVisible = false;
    },
    formatFormValidation() {
      let valid = true;
      this.formatFormErrors = [];
      if (!this.formatModel.formatTypeId) {
        this.formatFormErrors.push('Format type required');
        valid = false;
      }
      if (!this.formatModel.width) {
        this.formatFormErrors.push('Width required');
        valid = false;
      }
      if (!this.formatModel.height) {
        this.formatFormErrors.push('Height required');
        valid = false;
      }
      return valid;
    },
    async submitFormatForm() {
      this.formatFormSubmit = true;
      this.formatFormSubmitTimeout = setTimeout(async () => {
        if (this.formatFormValidation()) {
          if (this.formatModel.id) {
            await this.editFormat(this.formatModel);
          } else {
            await this.addFormat(this.formatModel);
          }
          this.closeFormatDialog();
        }
        this.formatFormSubmit = false;
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.formatDialog {
  display: block;

  .form {
    .error {
      margin-bottom: 10px;
    }

    .submit {
      text-align: right;
      margin: 0;
    }
  }

  /deep/.el-dialog {
    .el-dialog__header {
      margin: 0;
      padding: 15px;
      border-bottom: 1px solid #ebeef5;
    }

    .el-dialog__body {
      padding: 15px;
    }
  }
}
</style>

