import Vue from 'vue';
import Vuex from 'vuex';
import gql from 'graphql-tag';
import { apolloClient } from '../../utils/vue-apollo';

Vue.use(Vuex);

const GRAPHQL_STATIC_PROPS = `
id
name
game {
  id
  appId
  name
  code
  uaEmail
}
network {
  id
  name
}
language {
  id
  name
}
config {
  id
  network {
    id
    name
  }
  format {
    id
    name
  }
  bg
  icon {
    id
    name
    width
    height
    x
    y
  }
  title {
    id
    name
    width
    height
    x
    y
  }
  iosBadge {
    id
    name
    width
    height
    x
    y
  }
  googleBadge {
    id
    name
    width
    height
    x
    y
  }
  cta {
    id
    name
    width
    height
    x
    y
  }
}
document {
  id
  key
  url
}
outputDocument {
  id
  key
  url
  driveFileId
}
renderStatus
renderMessage
createdAt
updatedAt
`;

const GRAPHQL = {
  queries: {
    fetchStatics: gql`
      query {
        statics {
          ${GRAPHQL_STATIC_PROPS}
        }
      }
    `,
    fetchStaticsByPage: gql`
      query($input: StaticsByPageInput!) {
        staticsByPage(input: $input) {
          elements {
            ${GRAPHQL_STATIC_PROPS}
          }
          total
        }
      }
    `,
  },
  mutations: {
    uploadStatics: gql`
      mutation($input: UploadStaticsInput!) {
        uploadStatics(input: $input)
      }
    `,
  },
};

export default {
  namespaced: true,
  state: {
    elements: [],
    total: 0,
    loading: false,
  },
  mutations: {
    setStatics(state, { elements, total = 0 }) {
      state.elements = elements;
      state.total = total;
      state.loading = false;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
  },
  actions: {
    async fetchStatics({ commit }) {
      commit('setLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchStatics,
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const statics = results && results.data && results.data.statics ? results.data.statics : [];
      commit('setStatics', { elements: statics, total: statics.length });
    },
    async fetchStaticsByPage({ commit }, { page = 1, pageSize = 1, options = {} }) {
      commit('setLoading', true);
      const optionsStr = JSON.stringify(options);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchStaticsByPage,
          variables: {
            input: {
              page,
              pageSize,
              options: optionsStr,
            },
          },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const statics = results && results.data && results.data.staticsByPage ? results.data.staticsByPage : {};
      commit('setStatics', statics);
    },
    async uploadStatics({ commit }, { statics }) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.uploadStatics,
          variables: { input: { statics } },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      commit('setLoading', false);
      return (results && results.data && results.data.uploadStatics) || null;
    },
  },
};
