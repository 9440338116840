import Vue from 'vue';
import Vuex from 'vuex';
import gql from 'graphql-tag';
import { apolloClient } from '../../utils/vue-apollo';

Vue.use(Vuex);

const GRAPHQL_METADATA_PROPS = `
  creative {
    origins {
      id
      name
    }
  }
  rush {
    origins {
      id
      name
    }
    intentions {
      id
      name
    }
  }
`;

const GRAPHQL = {
  queries: {
    fetchMetadata: gql`
      query {
        metadata {
          ${GRAPHQL_METADATA_PROPS}
        }
      }
    `,
  },
};

export default {
  namespaced: true,
  state: {
    metadata: {
      creative: {
        origins: [],
      },
      rush: {
        origins: [],
        intentions: [],
      },
    },
    loading: false,
  },
  mutations: {
    setMetadata(state, { metadata }) {
      if (metadata) {
        state.metadata = metadata;
      }
      state.loading = false;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
  },
  actions: {
    async fetchMetadata({ commit }) {
      commit('setLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchMetadata,
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const metadata = (results && results.data && results.data.metadata) || null;
      commit('setMetadata', { metadata });
    },
  },
};
