import Vue from 'vue';
import Vuex from 'vuex';
import gql from 'graphql-tag';
import { apolloClient } from '../../utils/vue-apollo';

Vue.use(Vuex);

const GRAPHQL_WORDING_PROPS = `
  id
  text
  wordingTypes {
    id
    name
    default
  }
`;

const GRAPHQL = {
  queries: {
    fetchWordings: gql`
      query {
        wordings {
          ${GRAPHQL_WORDING_PROPS}
        }
      }
    `,
    fetchCtaWordings: gql`
      query {
        ctaWordings {
          ${GRAPHQL_WORDING_PROPS}
        }
      }
    `,
    fetchWordingsByPage: gql`
      query($input: WordingsByPageInput!) {
        wordingsByPage(input: $input) {
          elements {
            ${GRAPHQL_WORDING_PROPS}
          }
          total
        }
      }
    `,
  },
  mutations: {
    addWording: gql`
      mutation($input: AddWordingInput!) { 
        addWording(input: $input) {
          ${GRAPHQL_WORDING_PROPS}
        }
      }
    `,
    editWording: gql`
      mutation($input: EditWordingInput!) { 
        editWording(input: $input) {
          ${GRAPHQL_WORDING_PROPS}
        }
      }
    `,
    deleteWording: gql`
      mutation($input: DeleteWordingInput!) {
        deleteWording(input: $input)
      }
    `,
  },
};

export default {
  namespaced: true,
  state: {
    elements: [],
    total: 0,
    loading: false,
    ctaWordings: [],
    ctaWordingsLoading: false,
  },
  mutations: {
    setWordings(state, { elements, total = 0 }) {
      state.elements = elements;
      state.total = total;
      state.loading = false;
    },
    setCtaWordings(state, wordings) {
      state.ctaWordings = wordings;
      state.ctaWordingsLoading = false;
    },
    pushWording(state, item) {
      state.elements.push(item);
      state.total += 1;
      state.loading = false;
    },
    updateWording(state, item) {
      const index = state.elements.findIndex(elem => elem.id === item.id);
      if (index !== -1) {
        state.elements[index] = item;
        state.elements = [...state.elements];
      }
      state.loading = false;
    },
    removeWording(state, id) {
      const index = state.elements.findIndex(elem => elem.id === id);
      if (index !== -1) {
        state.elements.splice(index, 1);
        state.total -= 1;
      }
      state.loading = false;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
    setCtaWordingsLoading(state, isLoading) {
      state.ctaWordingsLoading = isLoading;
    },
  },
  actions: {
    async fetchWordings({ commit }) {
      commit('setLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchWordings,
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const wordings = results && results.data && results.data.wordings ? results.data.wordings : [];
      commit('setWordings', { elements: wordings, total: wordings.length });
    },
    async fetchCtaWordings({ commit }) {
      commit('setCtaWordingsLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchCtaWordings,
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const wordings = results && results.data && results.data.ctaWordings ? results.data.ctaWordings : [];
      commit('setCtaWordings', wordings);
    },
    async fetchWordingsByPage({ commit }, { page = 1, pageSize = 1, options = {} }) {
      commit('setLoading', true);
      const optionsStr = JSON.stringify(options);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchWordingsByPage,
          fetchPolicy: 'no-cache',
          variables: {
            input: {
              page,
              pageSize,
              options: optionsStr,
            },
          },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const wordings = results && results.data && results.data.wordingsByPage ? results.data.wordingsByPage : {};
      commit('setWordings', wordings);
    },
    async addWording({ commit }, { text, wordingTypeIds, wordingTypeDefaultIds }) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.addWording,
          variables: {
            input: { text, wordingTypeIds, wordingTypeDefaultIds },
          },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const wording = results && results.data && results.data.addWording ? results.data.addWording : null;
      if (wording) {
        commit('pushWording', wording);
      } else {
        commit('setLoading', false);
      }
    },
    async editWording({ commit }, {
      id, text, wordingTypeIds, wordingTypeDefaultIds,
    }) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.editWording,
          variables: {
            input: {
              id,
              text,
              wordingTypeIds,
              wordingTypeDefaultIds,
            },
          },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const wording = results && results.data && results.data.editWording ? results.data.editWording : null;
      if (wording) {
        commit('updateWording', wording);
      } else {
        commit('setLoading', false);
      }
    },
    async deleteWording({ commit }, { id }) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.deleteWording,
          variables: { input: { id } },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const deleted = results && results.data && results.data.deleteWording;
      if (deleted) {
        commit('removeWording', id);
      } else {
        commit('setLoading', false);
      }
    },
  },
};
