export default {
  namespaced: true,
  getters: {
    user(state) {
      return state.user;
    },
  },
  state: {
    user: { given_name: '', family_name: '' },
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
  },
};
