<template>
  <div class="languageDialog">
    <el-dialog
      :close-on-click-modal="false"
      :before-close="closeLanguageDialog"
      title="Language"
      :visible.sync="languageDialogVisible"
      width="50%"
    >
      <el-form
        class="form"
        ref="languageModel"
        label-width="120px"
        :model="languageModel"
        @submit.native.prevent="submitLanguageForm"
      >
        <el-form-item v-if="languageFormErrors.length">
          <el-alert
            v-for="(error, index) in languageFormErrors"
            :key="index"
            :title="error"
            :closable="false"
            type="error"
            class="error"
          ></el-alert>
        </el-form-item>
        <el-form-item label="Name">
          <el-input placeholder="Name" v-model="languageModel.name"></el-input>
        </el-form-item>
        <el-form-item class="submit">
          <el-button @click="closeLanguageDialog">Cancel</el-button>
          <el-button type="primary" native-type="submit" :disabled="languageFormSubmit">Save</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'language-dialog',
  data() {
    return {
      languageDialogVisible: false,
      languageFormSubmit: false,
      languageFormSubmitTimeout: null,
      languageFormErrors: [],
      languageModel: {
        id: null,
        name: null,
      },
    };
  },
  beforeDestroy() {
    clearTimeout(this.languageFormSubmitTimeout);
  },
  methods: {
    ...mapActions('language', ['addLanguage', 'editLanguage']),
    openLanguageDialog(language = null) {
      this.resetLanguageModel(language);
      this.languageDialogVisible = true;
    },
    resetLanguageModel(language = null) {
      this.languageFormErrors = [];
      if (language) {
        this.languageModel = { ...language };
      } else {
        this.languageModel = {
          id: null,
          name: null,
        };
      }
    },
    closeLanguageDialog() {
      this.resetLanguageModel();
      this.languageDialogVisible = false;
    },
    languageFormValidation() {
      let valid = true;
      this.languageFormErrors = [];
      if (!this.languageModel.name) {
        this.languageFormErrors.push('Name required');
        valid = false;
      }
      return valid;
    },
    async submitLanguageForm() {
      this.languageFormSubmit = true;
      this.languageFormSubmitTimeout = setTimeout(async () => {
        if (this.languageFormValidation()) {
          if (this.languageModel.id) {
            await this.editLanguage(this.languageModel);
          } else {
            await this.addLanguage(this.languageModel);
          }
          this.closeLanguageDialog();
        }
        this.languageFormSubmit = false;
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.languageDialog {
  display: block;

  .form {
    .error {
      margin-bottom: 10px;
    }

    .submit {
      text-align: right;
      margin: 0;
    }
  }

  /deep/.el-dialog {
    .el-dialog__header {
      margin: 0;
      padding: 15px;
      border-bottom: 1px solid #ebeef5;
    }

    .el-dialog__body {
      padding: 15px;
    }
  }
}
</style>

