import Vue from 'vue';
import Vuex from 'vuex';
import gql from 'graphql-tag';
import { apolloClient } from '../../utils/vue-apollo';

Vue.use(Vuex);

const GRAPHQL_NETWORK_CAMPAIGN_PROPS = `
  unifiedAppId
  id
  name
  status
`;

const GRAPHQL_NETWORK_AD_GROUP_PROPS = `
  unifiedAppId
  id
  name
  status
  campaignId
  campaignName
  campaignStatus
  adsCount
`;

const GRAPHQL_NETWORK_AD_PROPS = `
  unifiedAppId
  id
  name
  status
  campaignId
  campaignName
  campaignStatus
  adGroupId
  adGroupName
  adGroupStatus
`;

const GRAPHQL = {
  queries: {
    fetchNetworkCampaigns: gql`
      query($input: NetworkCampaignsInput!) {
        networkCampaigns(input: $input) {
          ${GRAPHQL_NETWORK_CAMPAIGN_PROPS}
        }
      }
    `,
    fetchNetworkAdGroups: gql`
      query($input: NetworkAdGroupsInput!) {
        networkAdGroups(input: $input) {
          ${GRAPHQL_NETWORK_AD_GROUP_PROPS}
        }
      }
    `,
    fetchNetworkAds: gql`
      query($input: NetworkAdsInput!) {
        networkAds(input: $input) {
          ${GRAPHQL_NETWORK_AD_PROPS}
        }
      }
    `,
  },
};

export default {
  namespaced: true,
  state: {
    loading: false,
    loadingCount: 0,
  },
  mutations: {
    setLoading(state, isLoading) {
      state.loadingCount += isLoading ? 1 : -1;
      state.loading = state.loadingCount > 0;
    },
  },
  actions: {
    async fetchNetworkCampaigns({ commit }, { network, appId }) {
      commit('setLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchNetworkCampaigns,
          variables: {
            input: { network, appId },
          },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const campaigns = results && results.data && results.data.networkCampaigns ? results.data.networkCampaigns : [];
      commit('setLoading', false);
      return campaigns;
    },
    async fetchNetworkAdGroups({ commit }, { network, appId, campaignIds }) {
      commit('setLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchNetworkAdGroups,
          variables: {
            input: { network, appId, campaignIds },
          },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const adGroups = results && results.data && results.data.networkAdGroups ? results.data.networkAdGroups : [];
      commit('setLoading', false);
      return adGroups;
    },
    async fetchNetworkAds({ commit }, {
      network, appId, campaignIds, adGroupIds,
    }) {
      commit('setLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchNetworkAds,
          variables: {
            input: {
              network, appId, campaignIds, adGroupIds,
            },
          },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const ads = results && results.data && results.data.networkAds ? results.data.networkAds : [];
      commit('setLoading', false);
      return ads;
    },
  },
};
