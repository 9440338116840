<template>
  <div class="orientationList">
    <vd-listing-header :title="title" :actions="headerActions"></vd-listing-header>
    <div class="content">
      <vd-paged-table
        tableKey="orientationList"
        :loading="loading"
        :data="elements"
        :totalDataSize="total"
        :paging="fetchOrientationsByPage"
        :actions="actions"
        :columns="columns"
        :configVisible="configVisible"
        :configClose="closeConfig"
      ></vd-paged-table>
    </div>
    <orientation-dialog ref="orientationDialog"></orientation-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import OrientationDialog from '@/views/orientation/OrientationDialog.vue';
import VdPagedTable from '@/components/vd-paged-table/VdPagedTable';
import VdListingHeader from '@/components/vd-listing-header/VdListingHeader';

export default {
  name: 'orientationList',
  data() {
    return {
      title: 'Orientation list',
      headerActions: [
        {
          callback: this.openOrientationDialog,
          title: 'Add',
          icon: 'circle-plus-outline',
          key: 'add',
        },
        {
          callback: this.openConfig,
          title: 'Configuration',
          icon: 'setting',
          key: 'setting',
        },
      ],
      actions: [
        { callback: this.editItem, icon: 'edit', key: 'edit' },
        { callback: this.deleteItem, icon: 'delete', key: 'delete' },
      ],
      columns: [
        {
          key: 'name',
          prop: scope => scope.row.name,
          label: 'Orientation',
        },
      ],
      configVisible: false,
    };
  },
  components: {
    OrientationDialog,
    VdPagedTable,
    VdListingHeader,
  },
  computed: {
    ...mapState('orientation', ['loading', 'elements', 'total']),
  },
  methods: {
    ...mapActions('orientation', [
      'fetchOrientationsByPage',
      'deleteOrientation',
    ]),
    openOrientationDialog() {
      this.$refs.orientationDialog.openOrientationDialog();
    },
    editItem(element) {
      this.$refs.orientationDialog.openOrientationDialog(element);
    },
    deleteItem(element) {
      this.$confirm('Do you really want to delete this orientation ?')
        .then(async () => {
          await this.deleteOrientation(element);
        })
        .catch(() => {});
    },
    openConfig() {
      this.configVisible = true;
    },
    closeConfig() {
      this.configVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.orientationList {
  display: block;

  .content {
    .table {
      width: 100%;
    }
  }
}
</style>

