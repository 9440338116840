<template>
  <div class="badgeList">
    <vd-listing-header :title="title" :actions="headerActions"></vd-listing-header>
    <div class="content">
      <vd-paged-table
        tableKey="badgeList"
        :loading="loading"
        :data="elements"
        :totalDataSize="total"
        :paging="fetchBadgesByPage"
        :actions="actions"
        :columns="columns"
        :configVisible="configVisible"
        :configClose="closeConfig"
      ></vd-paged-table>
    </div>
    <badge-dialog ref="badgeDialog"></badge-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import BadgeDialog from '@/views/badge/BadgeDialog.vue';
import VdPagedTable from '@/components/vd-paged-table/VdPagedTable';
import VdListingHeader from '@/components/vd-listing-header/VdListingHeader';
import { makeLink } from '../../utils/paged-table';

export default {
  name: 'badgeList',
  data() {
    return {
      title: 'Badge list',
      headerActions: [
        {
          callback: this.openBadgeDialog,
          title: 'Add',
          icon: 'circle-plus-outline',
          key: 'add',
        },
        {
          callback: this.openConfig,
          title: 'Configuration',
          icon: 'setting',
          key: 'setting',
        },
      ],
      actions: [
        { callback: this.editItem, icon: 'edit', key: 'edit' },
        { callback: this.deleteItem, icon: 'delete', key: 'delete' },
      ],
      columns: [
        {
          key: 'name',
          prop: scope => makeLink({
            href: scope.row.document.url,
            text: scope.row.name,
          }),
          label: 'Badge',
        },
        {
          key: 'badgeParent.name',
          prop: scope => (scope.row.badgeParent ?
            makeLink({
              href: scope.row.badgeParent.document.url,
              text: scope.row.badgeParent.name,
            }) :
            ''),
          label: 'Badge Parent',
        },
        {
          key: 'badgeType.name',
          prop: scope => scope.row.badgeType.name,
          label: 'Badge Type',
        },
        {
          key: 'language.name',
          prop: scope => scope.row.language.name,
          label: 'Language',
        },
      ],
      configVisible: false,
    };
  },
  components: {
    BadgeDialog,
    VdPagedTable,
    VdListingHeader,
  },
  computed: {
    ...mapState('badge', ['loading', 'elements', 'total']),
  },
  methods: {
    ...mapActions('badge', ['fetchBadgesByPage', 'deleteBadge']),
    openBadgeDialog() {
      this.$refs.badgeDialog.openBadgeDialog();
    },
    editItem(element) {
      this.$refs.badgeDialog.openBadgeDialog(element);
    },
    deleteItem(element) {
      this.$confirm('Do you really want to delete this badge ?')
        .then(async () => {
          await this.deleteBadge(element);
        })
        .catch(() => {});
    },
    openConfig() {
      this.configVisible = true;
    },
    closeConfig() {
      this.configVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.badgeList {
  display: block;

  .content {
    .table {
      width: 100%;
    }
  }
}
</style>

