<template>
  <div class="fontDialog">
    <el-dialog
      :close-on-click-modal="false"
      :before-close="closeFontDialog"
      title="Font"
      :visible.sync="fontDialogVisible"
      width="50%"
    >
      <el-form
        class="form"
        ref="fontModel"
        label-width="120px"
        :model="fontModel"
        @submit.native.prevent="submitFontForm"
      >
        <el-form-item v-if="fontFormErrors.length">
          <el-alert
            v-for="(error, index) in fontFormErrors"
            :key="index"
            :title="error"
            :closable="false"
            type="error"
            class="error"
          ></el-alert>
        </el-form-item>
        <el-form-item label="Name">
          <el-input placeholder="Name" v-model="fontModel.name"></el-input>
        </el-form-item>
        <el-form-item label="Postscript Name">
          <el-input
            placeholder="Postscript Name e.g. Arial-BoldMT"
            v-model="fontModel.postscriptName"
          ></el-input>
        </el-form-item>
        <el-form-item label="Family">
          <el-input placeholder="Family e.g. Arial" v-model="fontModel.family"></el-input>
        </el-form-item>
        <el-form-item label="Style">
          <el-input placeholder="Style e.g. Bold" v-model="fontModel.style"></el-input>
        </el-form-item>
        <el-form-item label="Weight">
          <el-select v-model="fontModel.weight" placeholder="Weight">
            <el-option
              v-for="option in fontFormWeights"
              :key="option.id"
              :label="`${option.name} (${option.value})`"
              :value="option.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Width">
          <el-select v-model="fontModel.width" placeholder="Width">
            <el-option
              v-for="option in fontFormWidths"
              :key="option.id"
              :label="`${option.name} (${option.value})`"
              :value="option.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Italic">
          <el-switch v-model="fontModel.italic"></el-switch>
        </el-form-item>
        <el-form-item label="Monospace">
          <el-switch v-model="fontModel.monospace"></el-switch>
        </el-form-item>
        <el-form-item label="Serif">
          <el-switch v-model="fontModel.serif"></el-switch>
        </el-form-item>
        <el-form-item>
          <vd-upload ref="upload" uploadPath="documents/fonts/upload"></vd-upload>
        </el-form-item>
        <el-form-item class="submit">
          <el-button @click="closeFontDialog">Cancel</el-button>
          <el-button type="primary" native-type="submit" :disabled="fontFormSubmit">Save</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import VdUpload from '@/components/vd-upload/VdUpload.vue';
import fontStyles from '@/utils/font-styles';
import { mapActions } from 'vuex';

export default {
  name: 'font-dialog',
  data() {
    return {
      fontDialogVisible: false,
      fontFormSubmit: false,
      fontFormSubmitTimeout: null,
      fontFormErrors: [],
      fontFormWeights: Object.values(fontStyles.weights),
      fontFormWidths: Object.values(fontStyles.widths),
      fontModel: {
        id: null,
        name: null,
        postscriptName: null,
        family: null,
        style: null,
        weight: fontStyles.weights.regular.value,
        width: fontStyles.widths.normal.value,
        italic: false,
        monospace: false,
        serif: false,
        document: null,
      },
    };
  },
  components: {
    VdUpload,
  },
  beforeDestroy() {
    clearTimeout(this.fontFormSubmitTimeout);
  },
  methods: {
    ...mapActions('font', ['addFont', 'editFont']),
    async openFontDialog(font = null) {
      this.resetFontModel(font);
      if (this.$refs.upload) {
        this.$refs.upload.resetUpload();
      }
      this.fontDialogVisible = true;
    },
    resetFontModel(font = null) {
      this.fontFormErrors = [];
      if (font) {
        this.fontModel = { ...font };
      } else {
        this.fontModel = {
          id: null,
          name: null,
          postscriptName: null,
          family: null,
          style: null,
          weight: fontStyles.weights.regular.value,
          width: fontStyles.widths.normal.value,
          italic: false,
          monospace: false,
          serif: false,
          document: null,
        };
      }
    },
    closeFontDialog() {
      this.resetFontModel();
      this.fontDialogVisible = false;
    },
    fontFormValidation() {
      let valid = true;
      this.fontFormErrors = [];
      if (!this.fontModel.name) {
        this.fontFormErrors.push('Name required');
        valid = false;
      }
      if (!this.fontModel.postscriptName) {
        this.fontFormErrors.push('Postscript Name required');
        valid = false;
      }
      if (!this.fontModel.family) {
        this.fontFormErrors.push('Family required');
        valid = false;
      }
      if (!this.fontModel.style) {
        this.fontFormErrors.push('Style required');
        valid = false;
      }
      if (!this.fontModel.document) {
        this.fontFormErrors.push('FONT file required');
        valid = false;
      }
      return valid;
    },
    async submitFontForm() {
      this.fontFormSubmit = true;
      this.fontFormSubmitTimeout = setTimeout(async () => {
        this.fontModel.document = this.$refs.upload.document;
        if (this.fontFormValidation()) {
          if (this.fontModel.id) {
            await this.editFont(this.fontModel);
          } else {
            await this.addFont(this.fontModel);
          }
          this.closeFontDialog();
        }
        this.fontFormSubmit = false;
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.fontDialog {
  display: block;

  .form {
    .error {
      margin-bottom: 10px;
    }

    .submit {
      text-align: right;
      margin: 0;
    }
  }

  /deep/.el-dialog {
    .el-dialog__header {
      margin: 0;
      padding: 15px;
      border-bottom: 1px solid #ebeef5;
    }

    .el-dialog__body {
      padding: 15px;
    }
  }
}
</style>

