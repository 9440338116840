<template>
  <div class="content">
    <span v-if="typeof display !== 'object'">{{display}}</span>
    <div v-if="display && display.tag === 'router-link'" class="tag-router-link">
      <span v-for="element in display.elements" v-bind:key="element.text" class="tag-element">
        <router-link :class="element.props.class" :to="element.props.to || ''">{{element.text}}</router-link>
      </span>
    </div>
    <div v-if="display && display.tag === 'a'" class="tag-a">
      <span v-for="element in display.elements" v-bind:key="element.text" class="tag-element">
        <a
          :class="element.props.class"
          :target="element.props.target || '_self'"
          :href="element.props.href || ''"
        >{{element.text}}</a>
      </span>
    </div>
    <div v-if="display && display.tag === 'el-progress'" class="tag-el-progress">
      <span class="tag-element">
        <el-progress
          :class="display.props.class"
          :type="display.props.type || 'line'"
          :percentage="display.props.percentage"
          :color="display.props.color"
        ></el-progress>
      </span>
    </div>
    <div v-if="display && display.tag === 'el-tooltip'" class="tag-el-tooltip">
      <span class="tag-element">
        <el-tooltip placement="bottom" effect="dark">
          <div slot="content" style="font-size: 14px;">{{display.props.content}}</div>
          <span style="cursor: pointer;">
            <b>{{display.text}}</b>
          </span>
        </el-tooltip>
      </span>
    </div>
    <div v-if="display && display.tag === 'image'" class="tag-image">
      <span class="tag-element">
        <el-image v-if="display.props.src"
          v-bind:key="display.props.src || ''"
          v-bind:src="display.props.src || ''"
          v-bind:alt="display.text || ''"
        />
        <a v-if="!display.props.src"
          :class="display.linkProps.class"
          :target="display.linkProps.target || '_self'"
          :href="display.linkProps.href || ''"
        >{{display.text}}</a>
      </span>
    </div>
    <div v-if="display && display.tag === 'video'" class="tag-video">
      <span class="tag-element">
        <video v-bind:key="display.props.src || ''"
          :controls="videoControls"
          @mouseover="videoControls = true"
          @mouseleave="videoControls = false"
        >
          <source v-bind:src="display.props.src || ''" v-bind:type="display.props.type || 'video/mp4'">
            <a
              :class="display.linkProps.class"
              :target="display.linkProps.target || '_self'"
              :href="display.linkProps.href || ''"
            >{{display.text}}</a>
        </video>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'vd-custom-column',
  props: ['display'],
  data() {
    return {
      videoControls: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  .tag-image,
  .tag-video {
    .tag-element,
    /deep/.el-image {
      img,
      video {
        margin-top: 6px;
        width: 100%;
        max-height: 112px;
        object-fit: contain;

        &:focus {
          outline: none;
        }
      }
    }
  }

  .tag-a,
  .tag-router-link {
    .tag-element {
      &::after {
        content: ", ";
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }
}
</style>
