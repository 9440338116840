<template>
  <div class="header">
    <el-card class="box-card">
      <el-row :gutter="10">
        <el-col class="left" :span="12">
          <div class="title">{{title}}</div>
        </el-col>
        <el-col class="right" :span="12">
          <span class="additional"><slot></slot></span>
          <span class="dates" v-if="datePicker">
            <el-date-picker
              v-model="dates"
              type="daterange"
              range-separator="To"
              start-placeholder="Start date"
              end-placeholder="End date"
              size="small"
              @change="datesChange"
            >
            </el-date-picker>
          </span>
          <span class="actions" v-if="actions && actions.length">
            <el-button
              v-for="action in actions"
              v-bind:key="action.key"
              @click.native.prevent="action.callback()"
              type="text"
              size="small"
              :title="action.title"
            >
              <span v-if="action.name">{{action.name}}</span>
              <i v-if="action.icon" :class="'icon el-icon-' + action.icon"></i>
            </el-button>
          </span>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'vd-listing-header',
  props: {
    title: String,
    actions: Array,
    datePicker: Boolean,
    handleDatesChange: Function,
  },
  data() {
    return {
      dates: null,
    };
  },
  methods: {
    datesChange(dates) {
      if (dates && dates[0] && dates[1]) {
        const start = moment(dates[0]).utc();
        const end = moment(dates[1]).utc();
        this.handleDatesChange({ start, end });
      } else {
        this.handleDatesChange(null);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  margin-bottom: 15px;

  /deep/.el-card {
    .el-card__body {
      padding: 15px;
    }
  }

  .left {
    .title {
      text-align: left;
      font-weight: bold;
      padding: 15px 0;
    }
  }

  .right {
    text-align: right;

    .dates {
      vertical-align: middle;
      margin-right: 12px;
    }

    .additional {
      vertical-align: middle;
      margin-right: 12px;
    }

    .actions {
      vertical-align: middle;
    }

    .icon {
      font-size: 32px;
    }
  }
}
</style>

