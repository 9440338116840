<template>
  <el-header style="height: auto; padding: 0; margin: 0;">
    <el-card class="box-card">
      <el-row class="user-header">
        <el-col :span="14">
          <h1 class="page-title">{{title}}</h1>
        </el-col>
        <el-col :span="10">
          <div class="right">
            {{user.given_name}} {{user.family_name}}{{' - '}}
            <span
              @click="logout($router)"
              class="logout"
            >Logout</span>
          </div>
        </el-col>
      </el-row>
      <el-row class="menu-header">
        <el-col :span="14">
          <el-menu :default-active="activeMenuIndex" mode="horizontal" @select="onSelectMenuItem">
            <div class="block-menu">
              <div v-for="(page, pageIndex) in pages" :key="pageIndex">
                <el-menu-item v-if="!page.paths" :key="pageIndex" :index="page.path">
                  <i :class="`el-icon-${page.icon}`"></i>
                  <span>{{ page.name }}</span>
                </el-menu-item>
                <el-submenu v-if="page.paths" :key="pageIndex" :index="page.path">
                  <div slot="title" class="submenu-title">
                    <i :class="`el-icon-${page.icon}`"></i>
                    <span>{{ page.name }}</span>
                  </div>
                  <div v-for="(subPage, subPageIndex) in page.paths" :key="subPageIndex">
                    <el-menu-item v-if="!subPage.paths" :index="subPage.path">
                      <i :class="`el-icon-${subPage.icon}`"></i>
                      <span>{{ subPage.name }}</span>
                    </el-menu-item>
                    <el-submenu v-if="subPage.paths" :key="subPageIndex" :index="subPage.path">
                      <template slot="title">
                        <i :class="`el-icon-${subPage.icon}`"></i>
                        <span>{{ subPage.name }}</span>
                      </template>
                      <div
                        v-for="(subSubPage, subSubPageIndex) in subPage.paths"
                        :key="subSubPageIndex"
                      >
                        <el-menu-item v-if="subSubPage.path" :index="subSubPage.path">
                          <i :class="`el-icon-${subSubPage.icon}`"></i>
                          <span>{{ subSubPage.name }}</span>
                        </el-menu-item>
                      </div>
                    </el-submenu>
                  </div>
                </el-submenu>
              </div>
            </div>
          </el-menu>
        </el-col>
        <el-col :span="10">
          <div class="right right-button">
            <el-dropdown @command="handleNewItem">
              <el-button>
                New item
                <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="game">New game</el-dropdown-item>
                <el-dropdown-item command="rush">New rush</el-dropdown-item>
                <el-dropdown-item command="template">New template</el-dropdown-item>
                <el-dropdown-item command="wording">New wording</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-button @click="$router.push({path: '/generate'})"
              class="new-item"
            >Generate</el-button>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <game-dialog ref="gameDialog"></game-dialog>
    <rush-dialog ref="rushDialog"></rush-dialog>
    <template-dialog ref="templateDialog"></template-dialog>
    <wording-dialog ref="wordingDialog"></wording-dialog>
  </el-header>
</template>

<script>

import GameDialog from '@/views/game/GameDialog.vue';
import RushDialog from '@/views/rush/RushDialog.vue';
import TemplateDialog from '@/views/template/TemplateDialog.vue';
import WordingDialog from '@/views/wording/WordingDialog.vue';
import { mapState } from 'vuex';

export default {
  name: 'vd-app-header',
  components: {
    GameDialog,
    RushDialog,
    TemplateDialog,
    WordingDialog,
  },
  computed: {
    ...mapState('auth', ['user']),
    isAuthenticated() {
      return this.authState.isAuthenticated;
    },
  },
  data() {
    return {
      title: 'RENDER KID - Dashboard',
      pages: [
        {
          name: 'Games',
          icon: 'mobile-phone',
          path: '/games',
        },
        {
          name: 'Rushes',
          icon: 'film',
          path: '/rushes',
        },
        {
          name: 'Creatives',
          icon: 'download',
          path: '/manual-renders',
        },
        {
          name: 'Resizes',
          icon: 'check',
          path: '/request',
          paths: [
            {
              name: 'Rushes resizes',
              icon: 'folder',
              path: '/render-requests',
            },
            {
              name: 'Creatives resizes',
              icon: 'files',
              path: '/autocm-render-requests',
            },
            {
              name: 'Endcard resizes',
              icon: 'ice-cream',
              path: '/endcard-render-requests',
            },
            {
              name: 'Static resizes',
              icon: 'picture',
              path: '/static-render-requests',
            },
          ],
        },
        {
          name: 'Statics',
          icon: 'picture',
          path: '/statics',
        },
        {
          name: 'Templates',
          icon: 'files',
          path: '/templates',
        },
        {
          name: 'Settings',
          icon: 'setting',
          path: '/setting',
          paths: [
            {
              name: 'Networks',
              icon: 'share',
              path: '/networks',
            },
            {
              name: 'Devices',
              icon: 'mobile-phone',
              path: '/devices',
            },
            {
              name: 'Formats',
              icon: 'rank',
              path: '/formats',
            },
            {
              name: 'Ratios',
              icon: 'rank',
              path: '/ratios',
            },
            {
              name: 'Orientations',
              icon: 'rank',
              path: '/orientations',
            },
            {
              name: 'Mechanics',
              icon: 'mobile-phone',
              path: '/mechanics',
            },
            {
              name: 'Classifications',
              icon: 'view',
              path: '/classifications',
            },
            {
              name: 'Wordings',
              icon: 'chat-line-square',
              path: '/wordings',
            },
            {
              name: 'Languages',
              icon: 's-flag',
              path: '/languages',
            },
            {
              name: 'Render presets',
              icon: 'share',
              path: '/render-presets',
            },
            {
              name: 'Fonts',
              icon: 'notebook-2',
              path: '/fonts',
            },
            {
              name: 'Statics',
              icon: 'picture',
              path: '/statics',
              paths: [
                {
                  name: 'Config',
                  icon: 's-operation',
                  path: '/static-configs',
                },
                {
                  name: 'Badges',
                  icon: 's-help',
                  path: '/badges',
                },
              ],
            },
          ],
        },
      ],
      activeMenuIndex: null,
    };
  },
  watch: {
    '$route.path': function watchRouterPath(newVal) {
      let changed = false;
      this.pages.forEach((page) => {
        if (newVal === `/${page.path}`) {
          changed = true;
          this.activeMenuIndex = page.path;
        }
      });
      if (!changed) this.activeMenuIndex = null;
    },
  },
  methods: {
    onSelectMenuItem(activeMenuIndex) {
      if (this.activeMenuIndex !== activeMenuIndex) {
        this.$router.push({ path: activeMenuIndex });
      }
      this.activeMenuIndex = activeMenuIndex;
    },
    handleNewItem(command) {
      switch (command) {
      case 'game':
        return this.$refs.gameDialog.openGameDialog();
      case 'rush':
        return this.$refs.rushDialog.openRushDialog();
      case 'template':
        return this.$refs.templateDialog.openTemplateDialog();
      case 'wording':
        return this.$refs.wordingDialog.openWordingDialog();
      default:
        return null;
      }
    },
    async logout() {
      await this.$auth.signOut();
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  background-color: #fff;

  /deep/.el-menu.el-menu--horizontal {
    border: none;
  }

  /deep/.el-card {
    .el-card__body {
      padding: 15px;
    }
  }

  .user-header,
  .page-title {
    margin: 0;
    padding: 0;
  }

  .menu-header {
    .block-menu {
      display: flex;
      align-content: center;
      height: 100%;
      width: 924px;
      margin-top: 8px;

      &:focus {
        outline: none;
      }

      /deep/.el-menu-item {
        border-radius: 4px;
      }

      /deep/.el-submenu {
        .el-submenu__title {
          border-radius: 4px;

          .submenu-title {
            padding-right: 24px;
          }
        }
      }
    }
  }

  .new-item {
    background: #606266;
    color: white;
    margin-left: 10px;
  }

  .logout {
    color: red;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .right {
    text-align: right;
  }

  .right-button {
    margin-top: 16px;
  }
}
</style>
