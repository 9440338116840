import Config from '../services/config.service';

export default {
  clientId: Config.okta.clientId,
  issuer: Config.okta.issuer,
  redirectUri: Config.okta.redirectUrl,
  scopes: ['openid', 'profile', 'email'],
  pkce: true,
  testing: {
    disableHttpsCheck: process.env.OKTA_TESTING_DISABLEHTTPSCHECK,
  },
};
