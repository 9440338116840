<template>
  <div class="endcardRenderList">
    <vd-listing-header :title="title" :actions="headerActions" :datePicker="true" :handleDatesChange="handleDates">
    </vd-listing-header>
    <div class="content">
      <vd-paged-table
        tableKey="endcardRenderList"
        :loading="loading"
        :data="elements"
        :totalDataSize="total"
        :paging="fetchEndcardRendersByPage"
        :query="$route.query"
        :dates="dates"
        :columns="columns"
        :configVisible="configVisible"
        :configClose="closeConfig"
        :refetchTimeout="5000"
      ></vd-paged-table>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';

import VdPagedTable from '@/components/vd-paged-table/VdPagedTable';
import VdListingHeader from '@/components/vd-listing-header/VdListingHeader';
import config from '../../services/config.service';
import {
  makeLink,
  makeLinks,
  makeRouterLink,
  makeTooltip,
  getDriveFileUrl,
} from '../../utils/paged-table';

export default {
  name: 'endcardRenderList',
  data() {
    return {
      title: 'Endcard render list',
      headerActions: [
        {
          callback: this.openConfig,
          title: 'Configuration',
          icon: 'setting',
          key: 'setting',
        },
      ],
      actions: [],
      columns: [
        {
          key: 'endcard',
          prop: scope => (scope.row.document ?
            makeLink({
              href: scope.row.document.url,
              text: scope.row.name,
            }) :
            scope.row.name),
          label: 'Endcard',
        },
        {
          key: 'endcardRush.rush.game.name',
          prop: scope => makeRouterLink({
            path: `/game/${scope.row.endcardRush.rush.game.id}`,
            text: scope.row.endcardRush.rush.game.name,
          }),
          label: 'Game',
        },
        {
          key: 'endcardRush.rush.name',
          prop: scope => makeLink({
            href: scope.row.endcardRush.document.url,
            text: scope.row.endcardRush.rush.name,
          }),
          label: 'Rush',
        },
        {
          key: 'endcardTemplate.name',
          prop: scope => scope.row.endcardTemplate.name,
          label: 'Template',
        },
        {
          key: 'network.name',
          prop: scope => scope.row.network.name,
          label: 'Network',
        },
        {
          key: 'language.name',
          prop: scope => scope.row.language.name,
          label: 'Language',
        },
        {
          key: 'renderStatus',
          prop: scope => (scope.row.renderMessage ?
            makeTooltip({
              text: this.getStatus(scope.row.renderStatus),
              content: scope.row.renderMessage,
            }) :
            this.getStatus(scope.row.renderStatus)),
          label: 'Status',
        },
        {
          key: 'output',
          prop: (scope) => {
            const links = [];
            if (scope.row.outputDocument) {
              links.push({
                href: scope.row.outputDocument.url,
                text: 'S3',
              });
              if (scope.row.outputDocument.driveFileId) {
                links.push({
                  href: getDriveFileUrl(scope.row.outputDocument.driveFileId),
                  text: 'Drive',
                });
              }
            }
            return makeLinks(links);
          },
          label: 'Output',
        },
        {
          key: 'createdAt',
          prop: scope => moment(scope.row.createdAt).format('YYYY-MM-DD HH:mm:ss'),
          label: 'Creation',
        },
        {
          key: 'updatedAt',
          prop: scope => moment(scope.row.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
          label: 'Updated',
        },
      ],
      dates: null,
      configVisible: false,
    };
  },
  async mounted() {
    if (this.$route.query && this.$route.query.renderRequestId) {
      this.title = `Endcard render list of request: ${
        this.$route.query.renderRequestId
      }`;
    }
  },
  components: {
    VdPagedTable,
    VdListingHeader,
  },
  computed: {
    ...mapState('endcardRender', ['loading', 'elements', 'total']),
  },
  methods: {
    ...mapActions('endcardRender', ['fetchEndcardRendersByPage']),
    getStatus(status) {
      try {
        return config.endcardStatuses[status];
      } catch (e) {
        return 'Error';
      }
    },
    handleDates(dates) {
      this.dates = dates;
    },
    openConfig() {
      this.configVisible = true;
    },
    closeConfig() {
      this.configVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.endcardRenderList {
  display: block;

  .content {
    .table {
      width: 100%;
    }
  }
}
</style>

