<template>
  <div class="uploadRushDialog">
    <el-dialog
      :close-on-click-modal="false"
      :before-close="closeUploadRushDialog"
      title="Upload rush"
      :visible.sync="uploadRushDialogVisible"
      width="90%"
    >
      <el-form
        class="form"
        ref="model"
        :model="uploadRushModel"
        @submit.native.prevent="submitUploadRushForm"
      >
        <el-form-item v-if="uploadRushFormErrors.length">
          <el-alert
            v-for="(error, index) in uploadRushFormErrors"
            :key="index"
            :title="error"
            :closable="false"
            type="error"
            class="error"
          ></el-alert>
        </el-form-item>
        <el-form-item class="setting">
          <h4>Add upload blocs <span class="rush-type-switch">Gameplay <el-switch
            v-model="infRush"
            class="switch"
            inactive-color="teal"
            @change="onRushTypeSwitchChange"
          /> Influencer</span></h4>
          <el-autocomplete
            class="autocomplete"
            v-model="selectedGameName"
            placeholder="Game"
            :fetch-suggestions="onSearchGame"
            :clearable="true"
            @select="onSelectGame"
            size="small"
            :disabled="infRush"
          ></el-autocomplete>
          <el-autocomplete
            class="autocomplete"
            v-model="selectedDeviceName"
            placeholder="Device"
            :fetch-suggestions="onSearchDevice"
            :clearable="true"
            @select="onSelectDevice"
            size="small"
          ></el-autocomplete>
          <el-button @click="addUploadRushElementModel" size="small">+ add</el-button>
        </el-form-item>
        <el-form-item class="setting">
          <h4>Metadata settings</h4>
          <el-select
            class="select"
            v-model="selectedMetadataOrigin"
            placeholder="Origin"
            size="small"
            @change="onSelectMetadataOrigin"
          >
            <el-option :key="null" label="Origin from file" :value="null"></el-option>
            <el-option
              v-for="origin in metadata.rush.origins"
              :key="origin.id"
              :label="origin.name"
              :value="origin.name"
            ></el-option>
          </el-select>
          <el-select class="select" v-model="selectedMetadataIntention" placeholder="Intention" size="small">
            <el-option :key="null" label="Intention from file" :value="null"></el-option>
            <el-option
              v-for="intention in metadata.rush.intentions"
              :key="intention.id"
              :label="intention.name"
              :value="intention.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-for="(element, elementIndex) in uploadRushModel.elements" :key="elementIndex">
          <el-card shadow="hover">
            <div slot="header" class="card-header">
              <b>{{ element.game.name }} - {{ element.device.name }}</b>
              <span class="right">
                <el-button @click="removeUploadRushElementModel(elementIndex)" size="small">
                  <i class="el-icon-close"></i>
                </el-button>
              </span>
            </div>
            <div class="element-formats">
              <b>Valid format:</b>
              <span class="element-formats-item">
                {{ element.device.format.name }}
                <span v-if="element.device.format.ratio">({{ element.device.format.ratio.name }})</span>
              </span>
            </div>
            <vd-upload-multiple
              uploadPath="documents/rushes/upload"
              @upload="onChangeUpload($event, elementIndex)"
            ></vd-upload-multiple>
            <div v-if="element.items.length" class="element-bloc">
              <div
                v-for="(item, itemIndex) in element.items"
                :key="itemIndex"
                class="element-name"
                :class="{ 'element-success': item.valid, 'element-error': !item.valid }"
              >
                {{ item.document.fileName.replace('.mp4', '') }}
                <i v-if="!item.errors" class="el-icon-check"></i>
                <ul v-if="item.errors" class="error-list">
                  <li
                    v-for="(error, errorIndex) in item.errors"
                    :key="errorIndex"
                    class="element-name element-error"
                  >&#10060; {{ error.message }}</li>
                </ul>
              </div>
            </div>
          </el-card>
        </el-form-item>
        <el-form-item class="submit">
          <el-button @click="closeUploadRushDialog">Cancel</el-button>
          <el-button type="primary" native-type="submit" :disabled="uploadRushFormSubmit">Upload</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import VdUploadMultiple from '@/components/vd-upload-multiple/VdUploadMultiple.vue';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'uploadRush-dialog',
  data() {
    return {
      uploadRushDialogVisible: false,
      uploadRushFormSubmit: false,
      uploadRushFormSubmitTimeout: null,
      uploadRushFormErrors: [],
      uploadRushModel: {
        elements: {},
      },
      selectedGameId: null,
      selectedGameName: '',
      selectedDeviceId: null,
      selectedDeviceName: '',
      selectedMetadataOrigin: null,
      selectedMetadataIntention: null,
      infRush: false,
    };
  },
  async mounted() {
    await this.init();
  },
  beforeDestroy() {
    clearTimeout(this.uploadRushFormSubmitTimeout);
  },
  components: {
    VdUploadMultiple,
  },
  computed: {
    ...mapState('game', { gameElements: 'elements' }),
    ...mapState('device', { deviceElements: 'elements' }),
    ...mapState('metadata', { metadata: 'metadata' }),
  },
  methods: {
    ...mapActions('game', ['fetchGames']),
    ...mapActions('device', ['fetchDevices']),
    ...mapActions('metadata', ['fetchMetadata']),
    ...mapActions('rush', ['uploadRushes']),
    async init() {
      await Promise.all([
        this.fetchGames(),
        this.fetchDevices(),
        this.fetchMetadata(),
      ]);
    },
    async openUploadRushDialog({ gameId = null, deviceId = null }) {
      await this.init();
      this.resetUploadRushModel({ gameId, deviceId });
      this.uploadRushDialogVisible = true;
    },
    resetUploadRushModel({ gameId = null, deviceId = null }) {
      this.uploadRushFormErrors = [];
      const game = this.gameElements.find(g => g.id === +gameId) || null;
      const device = this.deviceElements.find(d => d.id === +deviceId) || null;
      this.selectedGameId = (game && game.id) || null;
      this.selectedGameName = (game && game.name) || '';
      this.selectedDeviceId = (device && device.id) || null;
      this.selectedDeviceName = (device && device.name) || '';
      this.selectedMetadataOrigin = null;
      this.selectedMetadataIntention = null;
      this.uploadRushModel = { elements: {} };
      this.infRush = false;
    },
    addUploadRushElementModel() {
      if (!this.selectedGameId && !this.infRush) {
        this.$notify({ type: 'warning', message: this.$createElement('b', 'Select a game') });
        return;
      }
      if (!this.selectedDeviceId) {
        this.$notify({ type: 'warning', message: this.$createElement('b', 'Select a device') });
        return;
      }
      const game = this.infRush ?
        { id: null, name: 'Influencer', code: '' } :
        this.gameElements.find(g => g.id === this.selectedGameId);
      const device = this.deviceElements.find(d => d.id === this.selectedDeviceId);
      const key = `${game.id}-${device.id}`;
      if (!this.uploadRushModel.elements[key]) {
        this.uploadRushModel.elements[key] = { game, device, items: [] };
        this.uploadRushModel = JSON.parse(JSON.stringify(this.uploadRushModel));
      }
    },
    removeUploadRushElementModel(key) {
      delete this.uploadRushModel.elements[key];
      this.uploadRushModel = JSON.parse(JSON.stringify(this.uploadRushModel));
    },
    onSearchElement(elements = [], key, queryString, cb) {
      elements.sort((a, b) => a[key].length - b[key].length);
      if (queryString) {
        elements = elements.filter(element => element[key].toLowerCase().includes(queryString.toLowerCase()));
      }
      cb(elements.map(element => ({ ...element, id: element.id, value: element[key] })));
    },
    onRushTypeSwitchChange(value) {
      if (value) {
        this.selectedGameName = '';
        this.selectedGameId = null;
      }
    },
    onSearchGame(queryString, cb) {
      this.onSearchElement([...this.gameElements], 'name', queryString, cb);
    },
    onSelectGame(game) {
      this.selectedGameId = game.id;
    },
    onSearchDevice(queryString, cb) {
      this.onSearchElement([...this.deviceElements], 'name', queryString, cb);
    },
    onSelectDevice(device) {
      this.selectedDeviceId = device.id;
    },
    onSelectMetadataOrigin(origin) {
      if (origin.toLowerCase() === 'influencer' || origin.toLowerCase() === 'ugc') {
        this.selectedMetadataIntention = 'None';
      }
    },

    validateElement({ fileName, element, document }) {
      const VALID_NAME_RE = /^[-a-z0-9]+$/i; // alphanumeric
      const errors = [];

      if (!VALID_NAME_RE.test(fileName)) {
        errors.push({ message: 'invalid name must be only alphanumeric and dash' });
      }

      const videoFormat = `${document.infos.size.width}x${document.infos.size.height}`;
      if (element.device.format.name !== videoFormat) {
        errors.push({ message: `invalid format ${videoFormat} must be ${element.device.format.name}` });
      }

      const fileMetadata = document.infos.metadata;
      const hasFileMetadata = typeof fileMetadata === 'object' &&
        fileMetadata !== null;

      const hasFileOrigin = hasFileMetadata &&
        typeof fileMetadata.origin === 'string' &&
        fileMetadata.origin.length > 0;

      const hasSelectedOrigin = typeof this.selectedMetadataOrigin === 'string' &&
        this.selectedMetadataOrigin.length > 0;

      if (!hasFileOrigin && !hasSelectedOrigin) {
        errors.push({
          message: 'No metadata origin found in file or selected in dropdown.',
        });
      }

      const hasFileIntention = hasFileMetadata &&
        typeof fileMetadata.intention === 'string' &&
        fileMetadata.intention.length > 0;

      const hasSelectedIntention = typeof this.selectedMetadataIntention === 'string' &&
        this.selectedMetadataIntention.length > 0;

      if (!hasFileIntention && !hasSelectedIntention) {
        errors.push({
          message: 'No metadata intention found in file or selected in dropdown.',
        });
      }

      return errors;
    },

    onChangeUpload(documents, key) {
      const element = this.uploadRushModel.elements[key];
      element.items = [];
      setTimeout(async () => {
        element.items = [];
        for (const document of documents) {
          const fileName = document.fileName
            .toLowerCase()
            .replace(`${element.game.code.toLowerCase()}_`, '')
            .replace('.mp4', '')
            .split('/')
            .pop();

          const errors = this.validateElement({ fileName, element, document });

          if (errors.length > 0) {
            element.items.push({
              rush: null, document, valid: false, errors,
            });
          } else {
            element.items.push({
              rush: {
                number: fileName || null,
                gameId: element.game.id,
                deviceId: element.device.id,
              },
              document,
              valid: true,
              errors,
            });
          }
        }
      }, 250);
    },
    closeUploadRushDialog() {
      this.resetUploadRushModel({});
      this.uploadRushDialogVisible = false;
    },
    uploadRushFormValidation() {
      let valid = true;
      this.uploadRushFormErrors = [];
      let nbDocuments = 0;
      Object.values(this.uploadRushModel.elements).forEach((element) => {
        nbDocuments += element.items.length;
      });
      if (!nbDocuments) {
        this.uploadRushFormErrors.push('You must upload rushes');
        valid = false;
      }
      return valid;
    },
    transformRushMetadata(rush) {
      // Document metadata will be processed by backend from the doc
      // Sending only overrides if selected
      rush.metadata = JSON.stringify({
        origin: this.selectedMetadataOrigin,
        intention: this.selectedMetadataIntention,
      });
    },
    async submitUploadRushForm() {
      this.uploadRushFormSubmit = true;
      this.uploadRushFormSubmitTimeout = setTimeout(async () => {
        if (this.uploadRushFormValidation()) {
          const data = { rushes: [] };
          Object.values(this.uploadRushModel.elements).forEach((element) => {
            element.items.forEach((item) => {
              if (item.valid) {
                this.transformRushMetadata(item.rush);
                data.rushes.push({
                  rush: item.rush,
                  document: { key: item.document.key, url: item.document.url },
                });
              }
            });
          });
          const response = this.uploadRushes(data);
          if (response) {
            this.$notify({ type: 'success', message: this.$createElement('b', 'Rushes are uploaded') });
            this.closeUploadRushDialog();
          } else {
            this.$notify({ type: 'error', message: this.$createElement('b', 'An error has occurred') });
          }
        }
        this.uploadRushFormSubmit = false;
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.uploadRushDialog {
  display: block;

  .form {
    .setting {
      h4 {
        margin: 0;
      }

      .rush-type-switch {
        display: inline-block;
        float: right;
        margin-left: 1em;
        font-weight: normal;

        .switch {
          margin: 0 0.5em;
        }
      }

      display: inline-block;
      background-color: #f9f9f9;
      margin-right: 10px;
      margin-bottom: 15px;
      padding: 10px;
      border-radius: 2px;

      .autocomplete {
        margin-right: 10px;
      }

      .select {
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .error {
      margin-bottom: 10px;
    }

    .submit {
      text-align: right;
      margin: 0;
    }
  }

  .right {
    float: right;
  }

  .element-warning {
    font-size: 12px;
    color: #ffc04c;
    line-height: 1;
    padding-bottom: 10px;
  }

  .element-formats {
    line-height: 20px;
    font-size: 12px;
    color: #333;

    .element-formats-item {
      margin-left: 10px;
    }
  }

  .error-list {
    margin: 0;
    list-style: none;
    padding-left: 1em;
  }

  .element-bloc {
    margin-top: 10px;
    padding: 10px;
    border: solid 1px #eee;
    line-height: 20px;

    .element-name {
      margin-right: 10px;
      font-size: 11px;
      color: #333;

      &.element-success {
        color: green;
      }

      &.element-warning {
        color: darkorange;
      }

      &.element-error {
        color: red;
      }
    }
  }

  /deep/.el-dialog {
    .el-dialog__header {
      margin: 0;
      padding: 15px;
      border-bottom: 1px solid #ebeef5;
    }

    .el-dialog__body {
      padding: 15px;
    }
  }
}
</style>

