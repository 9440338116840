<template>
  <div class="uploadMultiple">
    <el-upload
      v-loading="loading"
      :drag="true"
      :multiple="true"
      :show-file-list="false"
      :auto-upload="true"
      :before-upload="onBeforeUpload"
      :on-success="onUploadSuccess"
      :action="uploadUrl"
      :with-credentials="true"
      :headers="requestHeaders"
      :http-request="httpRequest"
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">
        Drop files here or
        <em>click to submit</em>
      </div>
    </el-upload>
    <el-progress :text-inside="true" :stroke-width="18" :percentage="progress"></el-progress>
    <div class="upload-infos">
      <div class="upload-size">Files size under 500mb</div>
      <a
        v-for="(document, documentIndex) in documents"
        :key="document.id"
        class="el-button--text upload-link"
        target="_blank"
        :href="document.url"
      >{{ files[documentIndex].name }}</a>
      <span v-for="errorFile in errorFiles" :key="errorFile.name" class="upload-error">{{ errorFile.name }}</span>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Config from '../../services/config.service';
import getFileInfos from '../../utils/xml-parser';
import correlationHeader from '../../utils/correlation-header';

export default {
  name: 'vd-upload-multiple',
  props: {
    uploadPath: String,
  },
  data() {
    return {
      uploadUrl: '',
      requestHeaders: {},
      files: [],
      errorFiles: [],
      documents: [],
      loading: 0,
      started: 0,
      finished: 0,
      progress: 0,
    };
  },
  async mounted() {
    this.uploadUrl = `${Config.apiUrl}/${
      this.uploadPath ? this.uploadPath : 'documents/upload'
    }`;

    this.requestHeaders = { authorization: `Bearer ${this.$auth.getAccessToken()}`, ...correlationHeader() };
  },
  methods: {
    resetUpload() {
      this.files = [];
      this.errorFiles = [];
      this.documents = [];
      this.loading = 0;
      this.started = 0;
      this.finished = 0;
      this.progress = 0;
    },
    onBeforeUpload(file) {
      const index = this.files.findIndex(f => f.name === file.name);
      if (index !== -1) {
        this.files.splice(index, 0);
        this.documents.splice(index, 0);
        this.started -= 1;
        this.finished -= 1;
      }
      this.started += 1;
      this.loading += 1;
      this.computeProgress();
      return true;
    },
    async onUploadSuccess(response, file) {
      if (file && file.response) {
        await getFileInfos(file).then((infos) => { file.response.infos = infos; }).catch(() => {});
        const index = this.files.findIndex(f => f.name === file.name);
        if (index !== -1) {
          this.files.splice(index, 1, file);
          this.documents.splice(index, 1, file.response);
        } else {
          this.files.push(file);
          this.documents.push(file.response);
        }
        this.sortFiles();
        this.finished += 1;
      } else if (file) {
        const index = this.errorFiles.findIndex(f => f.name === file.name);
        if (index !== -1) {
          this.errorFiles.splice(index, 1, file);
        } else {
          this.errorFiles.push(file);
        }
        this.sortErrorFiles();
        this.started -= 1;
      }
      this.loading -= 1;
      if (!this.loading) {
        this.$emit('upload', this.documents);
      }
      this.computeProgress();
    },
    computeProgress() {
      if (this.started) {
        this.progress = Math.floor(this.finished / this.started * 100);
      }
    },
    sortFiles() {
      this.files = [...(this.files.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      }))];
      this.documents = [...(this.documents.sort((a, b) => {
        if (a.fileName.toLowerCase() < b.fileName.toLowerCase()) return -1;
        if (a.fileName.toLowerCase() > b.fileName.toLowerCase()) return 1;
        return 0;
      }))];
    },
    sortErrorFiles() {
      this.errorFiles = [...(this.errorFiles.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      }))];
    },
    async httpRequest(upload) {
      const formData = new FormData();
      formData.append('file', upload.file);
      axios.defaults.withCredentials = true;
      return axios.post(upload.action, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          ...upload.headers,
          ...correlationHeader(),
        },
      }).then(res => ((res && res.data) || null)).catch(() => null);
    },
  },
};
</script>

<style lang="scss" scoped>
.uploadMultiple {
  display: block;

  /deep/.el-upload {
    line-height: 20px;
    width: 100%;

    .el-upload-dragger {
      width: 100%;
    }
  }

  .upload-infos {
    line-height: 20px;

    .upload-size {
      font-size: 11px;
    }

    .upload-link {
      font-size: 12px;
      margin-right: 12px;
    }

    .upload-error {
      font-size: 12px;
      margin-right: 12px;
      color: red;
    }
  }
}
</style>

