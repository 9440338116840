import Vue from 'vue';
import Vuex from 'vuex';
import gql from 'graphql-tag';
import { apolloClient } from '../../utils/vue-apollo';

Vue.use(Vuex);

const GRAPHQL_BADGE_PROPS = `
  id
  name
  parentId
  badgeParent {
    id
    name
    badgeTypeId
    document {
      id
      key
      url
    }
  }
  badgeTypeId
  badgeType {
    id
    name
  }
  document {
    id
    key
    url
  }
  language {
    id
    name
  }
`;

const GRAPHQL = {
  queries: {
    fetchBadges: gql`
      query {
        badges {
          ${GRAPHQL_BADGE_PROPS}
        }
      }
    `,
    fetchParentBadges: gql`
      query {
        parentBadges {
          ${GRAPHQL_BADGE_PROPS}
        }
      }
    `,
    fetchBadgesByPage: gql`
      query($input: BadgesByPageInput!) {
        badgesByPage(input: $input) {
          elements {
            ${GRAPHQL_BADGE_PROPS}
          }
          total
        }
      }
    `,
  },
  mutations: {
    addBadge: gql`
      mutation ($input: AddBadgeInput!) { 
        addBadge(input: $input) {
          ${GRAPHQL_BADGE_PROPS}
        }
      }
    `,
    editBadge: gql`
      mutation ($input: EditBadgeInput!) { 
        editBadge(input: $input) {
          ${GRAPHQL_BADGE_PROPS}
        }
      }
    `,
    deleteBadge: gql`
      mutation($input: DeleteBadgeInput!) {
        deleteBadge(input: $input)
      }
    `,
  },
};

export default {
  namespaced: true,
  state: {
    elements: [],
    total: 0,
    loading: false,
    parentBadges: [],
    parentBadgesLoading: false,
  },
  mutations: {
    setBadges(state, { elements, total = 0 }) {
      state.elements = elements;
      state.total = total;
      state.loading = false;
    },
    setParentBadges(state, badges) {
      state.parentBadges = badges;
      state.parentBadgesLoading = false;
    },
    pushBadge(state, item) {
      state.elements.push(item);
      state.total += 1;
      state.loading = false;
    },
    updateBadge(state, item) {
      const index = state.elements.findIndex(elem => elem.id === item.id);
      if (index !== -1) {
        state.elements[index] = item;
        state.elements = [...state.elements];
      }
      state.loading = false;
    },
    removeBadge(state, id) {
      const index = state.elements.findIndex(elem => elem.id === id);
      if (index !== -1) {
        state.elements.splice(index, 1);
        state.total -= 1;
      }
      state.loading = false;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
    setParentBadgesLoading(state, isLoading) {
      state.parentBadgesLoading = isLoading;
    },
  },
  actions: {
    async fetchBadges({ commit }) {
      commit('setLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchBadges,
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const badges = results && results.data && results.data.badges ? results.data.badges : [];
      commit('setBadges', { elements: badges, total: badges.length });
    },
    async fetchParentBadges({ commit }) {
      commit('setParentBadgesLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchParentBadges,
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const badges = results && results.data && results.data.parentBadges ? results.data.parentBadges : [];
      commit('setParentBadges', badges);
    },
    async fetchBadgesByPage({ commit }, { page = 1, pageSize = 1, options = {} }) {
      commit('setLoading', true);
      const optionsStr = JSON.stringify(options);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchBadgesByPage,
          variables: {
            input: {
              page,
              pageSize,
              options: optionsStr,
            },
          },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const badges = results && results.data && results.data.badgesByPage ? results.data.badgesByPage : {};
      commit('setBadges', badges);
    },
    async addBadge({ commit }, {
      parentId, badgeTypeId, languageId, name, document,
    }) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.addBadge,
          variables: {
            input: {
              parentId,
              badgeTypeId,
              languageId,
              name,
              document: {
                id: document.id,
                key: document.key,
                url: document.url,
                fileName: document.fileName,
                fileType: document.fileType,
                fileSize: document.fileSize,
              },
            },
          },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const badge = results && results.data && results.data.addBadge ? results.data.addBadge : null;
      if (badge) {
        commit('pushBadge', badge);
      } else {
        commit('setLoading', false);
      }
    },
    async editBadge({ commit }, {
      id, parentId, badgeTypeId, languageId, name, document,
    }) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.editBadge,
          variables: {
            input: {
              id,
              parentId,
              badgeTypeId,
              languageId,
              name,
              document: {
                id: document.id,
                key: document.key,
                url: document.url,
                fileName: document.fileName,
                fileType: document.fileType,
                fileSize: document.fileSize,
              },
            },
          },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const badge = results && results.data && results.data.editBadge ? results.data.editBadge : null;
      if (badge) {
        commit('updateBadge', badge);
      } else {
        commit('setLoading', false);
      }
    },
    async deleteBadge({ commit }, { id }) {
      commit('setLoading', true);
      const results = await apolloClient
        .mutate({
          mutation: GRAPHQL.mutations.deleteBadge,
          variables: { input: { id } },
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const deleted = results && results.data && results.data.deleteBadge;
      if (deleted) {
        commit('removeBadge', id);
      } else {
        commit('setLoading', false);
      }
    },
  },
};
