<template>
  <div class="uploadStaticDialog">
    <el-dialog
      :close-on-click-modal="false"
      :before-close="closeUploadStaticDialog"
      title="Upload static"
      :visible.sync="uploadStaticDialogVisible"
      width="90%"
    >
      <el-form
        class="form"
        ref="model"
        :model="staticModel"
        @submit.native.prevent="submitStaticForm"
      >
        <el-form-item v-if="staticFormErrors.length">
          <el-alert
            v-for="(error, index) in staticFormErrors"
            :key="index"
            :title="error"
            :closable="false"
            type="error"
            class="error"
          ></el-alert>
        </el-form-item>
        <el-form-item class="element-add">
          <el-autocomplete
            class="game-autocomplete"
            v-model="selectedGameName"
            placeholder="Game"
            :fetch-suggestions="onSearchGame"
            :clearable="true"
            @select="onSelectGame"
            style="margin-right: 10px;"
          ></el-autocomplete>
          <el-autocomplete
            class="network-autocomplete"
            v-model="selectedNetworkName"
            placeholder="Network"
            :fetch-suggestions="onSearchNetwork"
            :clearable="true"
            @select="onSelectNetwork"
            style="margin-right: 10px;"
          ></el-autocomplete>
          <el-button @click="addStaticElementModel">Add</el-button>
        </el-form-item>
        <el-form-item v-for="(element, elementIndex) in staticModel.elements" :key="elementIndex">
          <el-card shadow="hover">
            <div slot="header" class="card-header">
              <b>{{ element.game.name }} - {{ element.network.name }}</b>
              <span class="right">
                <el-button @click="removeStaticElementModel(elementIndex)">
                  <i class="el-icon-close"></i>
                </el-button>
              </span>
            </div>
            <div class="element-formats">
              <b>Valid formats:</b>
              <span
                class="element-formats-item"
                v-for="(staticConfig, staticConfigIndex) in element.staticConfigs" :key="staticConfigIndex">
                {{ staticConfig.format.name }}
              </span>
              <br>
              <b>Valid static name exemple:</b>
              <span class="element-formats-item">
                {{ element.game.code }}_{{ element.staticConfigs[0].format.name }}_EN.jpg
                |
                {{ element.staticConfigs[0].format.name }}_EN.jpg
                |
                {{ element.game.code }}_{{ element.network.name }}_{{ element.staticConfigs[0].format.name }}_EN.jpg
                |
                {{ element.network.name }}_{{ element.staticConfigs[0].format.name }}_EN.jpg
              </span>
            </div>
            <vd-upload-multiple
              uploadPath="documents/statics/upload"
              @upload="onChangeUpload($event, elementIndex)"
            ></vd-upload-multiple>
            <div v-if="element.items.length" class="element-bloc">
              <div
                v-for="(item, itemIndex) in element.items"
                :key="itemIndex"
                class="element-name"
                :class="{ 'element-green': item.valid, 'element-red': !item.valid }"
              >
                {{ item.document.fileName.replace('.jpg', '') }}
                <i v-if="item.valid" class="el-icon-check"></i>
                <i v-if="!item.valid">({{ item.message }})</i>
              </div>
            </div>
          </el-card>
        </el-form-item>
        <el-form-item class="submit">
          <el-button @click="closeUploadStaticDialog">Cancel</el-button>
          <el-button type="primary" native-type="submit" :disabled="staticFormSubmit">Upload</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import VdUploadMultiple from '@/components/vd-upload-multiple/VdUploadMultiple.vue';
import { mapState, mapActions } from 'vuex';
import languageAlias from '../../utils/language-alias';

export default {
  name: 'static-dialog',
  data() {
    return {
      uploadStaticDialogVisible: false,
      staticFormSubmit: false,
      staticFormSubmitTimeout: null,
      staticFormErrors: [],
      staticModel: {
        elements: {},
      },
      selectedGameId: null,
      selectedGameName: '',
      selectedNetworkId: null,
      selectedNetworkName: '',
    };
  },
  async mounted() {
    await this.init();
  },
  beforeDestroy() {
    clearTimeout(this.staticFormSubmitTimeout);
  },
  components: {
    VdUploadMultiple,
  },
  computed: {
    ...mapState('game', { gameElements: 'elements' }),
    ...mapState('network', { networkElements: 'elements' }),
    ...mapState('language', { languageElements: 'elements' }),
    ...mapState('staticConfig', { staticConfigElements: 'elements' }),
  },
  methods: {
    ...mapActions('game', ['fetchGames']),
    ...mapActions('network', ['fetchNetworks']),
    ...mapActions('language', ['fetchLanguages']),
    ...mapActions('staticConfig', ['fetchStaticConfigs']),
    ...mapActions('static', ['uploadStatics']),
    async init() {
      await Promise.all([
        this.fetchGames(),
        this.fetchNetworks(),
        this.fetchLanguages(),
        this.fetchStaticConfigs(),
      ]);
    },
    async openUploadStaticDialog({ gameId = null }) {
      await this.init();
      this.resetStaticModel({ gameId });
      this.uploadStaticDialogVisible = true;
    },
    resetStaticModel({ gameId = null }) {
      this.staticFormErrors = [];
      const game = this.gameElements.find(g => g.id === +gameId) || null;
      this.selectedGameId = (game && game.id) || null;
      this.selectedGameName = (game && game.name) || '';
      this.selectedNetworkId = null;
      this.selectedNetworkName = '';
      this.staticModel = { elements: {} };
    },
    addStaticElementModel() {
      if (!this.selectedGameId || !this.selectedNetworkId) {
        this.$notify({ type: 'warning', message: this.$createElement('b', 'Select game and network') });
        return;
      }
      const game = this.gameElements.find(g => g.id === this.selectedGameId);
      const network = this.networkElements.find(n => n.id === this.selectedNetworkId);
      const staticConfigs = this.staticConfigElements.filter(sc => sc.network.id === this.selectedNetworkId);
      const key = `${game.id}-${network.id}`;

      if (!this.staticModel.elements[key] && staticConfigs.length) {
        this.staticModel.elements[key] = {
          game, network, staticConfigs, items: [],
        };
        this.staticModel = JSON.parse(JSON.stringify(this.staticModel));
      }
    },
    removeStaticElementModel(key) {
      delete this.staticModel.elements[key];
      this.staticModel = JSON.parse(JSON.stringify(this.staticModel));
    },
    onSearchElement(elements = [], key, queryString, cb) {
      elements.sort((a, b) => a[key].length - b[key].length);
      if (queryString) {
        elements = elements.filter(element => element[key].toLowerCase().includes(queryString.toLowerCase()));
      }
      cb(elements.map(element => ({ ...element, id: element.id, value: element[key] })));
    },
    onSearchGame(queryString, cb) {
      this.onSearchElement([...this.gameElements], 'name', queryString, cb);
    },
    onSelectGame(game) {
      this.selectedGameId = game.id;
    },
    onSearchNetwork(queryString, cb) {
      this.onSearchElement([...this.networkElements], 'name', queryString, cb);
    },
    onSelectNetwork(network) {
      this.selectedNetworkId = network.id;
    },
    onChangeUpload(documents, key) {
      const element = this.staticModel.elements[key];
      element.items = [];
      setTimeout(async () => {
        element.items = [];
        for (const document of documents) {
          let valid = true;
          let message = '';
          let staticElement = null;
          const fileName = document.fileName.replace('.jpg', '').split('/').pop().toLowerCase();
          const parts = fileName.replace(`${element.game.code.toLowerCase()}_`, '').split('_');
          if (parts.length === 2 || parts.length === 3) {
            const networkName = parts.length === 3 ? parts[0] : element.network.name.toLowerCase();
            const formatName = parts[parts.length === 3 ? 1 : 0];
            const languageName = parts[parts.length === 3 ? 2 : 1];
            const staticConfig = element.staticConfigs.find(sc => sc.format.name.toLowerCase() === formatName);
            // Migration to iso languages codes, check also old languages codes
            const language = (
              this.languageElements.find(l => l.name.toLowerCase() === languageName) ||
              this.languageElements.find(l => l.name.toLowerCase() === languageAlias[languageName])
            );

            if (element.network.name.toLowerCase() === networkName) {
              if (staticConfig && language) {
                const imageFormat = `${document.infos.size.width}x${document.infos.size.height}`;
                if (staticConfig.format.name === imageFormat) {
                  staticElement = {
                    gameId: element.game.id,
                    networkId: element.network.id,
                    languageId: language.id,
                    staticConfigId: staticConfig.id,
                  };
                } else {
                  message = `invalid format ${imageFormat} must be ${staticConfig.format.name}`;
                  valid = false;
                }
              } else {
                valid = false;
                message = `invalid name ${!staticConfig ? 'format' : ''}${
                  !language ? `${!staticConfig ? ' and ' : ''}language` : ''
                } not recognized`;
              }
            } else {
              valid = false;
              message = `invalid network ${networkName} must be ${element.network.name}`;
            }
          } else {
            valid = false;
            message = 'invalid name must contain format and language or network, format and language';
          }
          element.items.push({
            static: staticElement, document, valid, message,
          });
        }
      }, 250);
    },
    closeUploadStaticDialog() {
      this.resetStaticModel({});
      this.uploadStaticDialogVisible = false;
    },
    staticFormValidation() {
      let valid = true;
      this.staticFormErrors = [];
      let nbDocuments = 0;
      Object.values(this.staticModel.elements).forEach((element) => {
        nbDocuments += element.items.length;
      });
      if (!nbDocuments) {
        this.staticFormErrors.push('You must upload statics');
        valid = false;
      }
      return valid;
    },
    async submitStaticForm() {
      this.staticFormSubmit = true;
      this.staticFormSubmitTimeout = setTimeout(async () => {
        if (this.staticFormValidation()) {
          const data = { statics: [] };
          Object.values(this.staticModel.elements).forEach((element) => {
            element.items.forEach((item) => {
              if (item.valid) {
                data.statics.push({
                  static: item.static,
                  staticDocument: { key: item.document.key, url: item.document.url },
                });
              }
            });
          });
          const response = this.uploadStatics(data);
          if (response) {
            this.$notify({ type: 'success', message: this.$createElement('b', 'Statics are uploaded') });
            this.closeUploadStaticDialog();
          } else {
            this.$notify({ type: 'error', message: this.$createElement('b', 'An error has occurred') });
          }
        }
        this.staticFormSubmit = false;
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.uploadStaticDialog {
  display: block;

  .form {
    .error {
      margin-bottom: 10px;
    }

    .submit {
      text-align: right;
      margin: 0;
    }
  }

  .right {
    float: right;
  }

  .element-warning {
    font-size: 12px;
    color: #ffc04c;
    line-height: 1;
    padding-bottom: 10px;
  }

  .element-formats {
    line-height: 20px;
    font-size: 12px;
    color: #333;

    .element-formats-item {
      margin-left: 10px;
    }
  }

  .element-bloc {
    margin-top: 10px;
    padding: 10px;
    border: solid 1px #eee;
    line-height: 20px;

    .element-name {
      margin-right: 10px;
      font-size: 11px;
      color: #333;

      &.element-green {
        color: green;
      }

      &.element-red {
        color: red;
      }
    }
  }

  /deep/.el-dialog {
    .el-dialog__header {
      margin: 0;
      padding: 15px;
      border-bottom: 1px solid #ebeef5;
    }

    .el-dialog__body {
      padding: 15px;
    }
  }
}
</style>

