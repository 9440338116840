<template>
  <div class="mechanicDialog">
    <el-dialog
      :close-on-click-modal="false"
      :before-close="closeMechanicDialog"
      title="Mechanic"
      :visible.sync="mechanicDialogVisible"
      width="50%"
    >
      <el-form
        class="form"
        ref="mechanicModel"
        label-width="120px"
        :model="mechanicModel"
        @submit.native.prevent="submitMechanicForm"
      >
        <el-form-item v-if="mechanicFormErrors.length">
          <el-alert
            v-for="(error, index) in mechanicFormErrors"
            :key="index"
            :title="error"
            :closable="false"
            type="error"
            class="error"
          ></el-alert>
        </el-form-item>
        <el-form-item label="Name">
          <el-input placeholder="Name" v-model="mechanicModel.name"></el-input>
        </el-form-item>
        <el-form-item class="submit">
          <el-button @click="closeMechanicDialog">Cancel</el-button>
          <el-button type="primary" native-type="submit" :disabled="mechanicFormSubmit">Save</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'mechanic-dialog',
  data() {
    return {
      mechanicDialogVisible: false,
      mechanicFormSubmit: false,
      mechanicFormSubmitTimeout: null,
      mechanicFormErrors: [],
      mechanicModel: {
        id: null,
        name: null,
      },
    };
  },
  beforeDestroy() {
    clearTimeout(this.mechanicFormSubmitTimeout);
  },
  methods: {
    ...mapActions('mechanic', ['addMechanic', 'editMechanic']),
    openMechanicDialog(mechanic = null) {
      this.resetMechanicModel(mechanic);
      this.mechanicDialogVisible = true;
    },
    resetMechanicModel(mechanic = null) {
      this.mechanicFormErrors = [];
      if (mechanic) {
        this.mechanicModel = { ...mechanic };
      } else {
        this.mechanicModel = {
          id: null,
          name: null,
        };
      }
    },
    closeMechanicDialog() {
      this.resetMechanicModel();
      this.mechanicDialogVisible = false;
    },
    mechanicFormValidation() {
      let valid = true;
      this.mechanicFormErrors = [];
      if (!this.mechanicModel.name) {
        this.mechanicFormErrors.push('Name required');
        valid = false;
      }
      return valid;
    },
    async submitMechanicForm() {
      this.mechanicFormSubmit = true;
      this.mechanicFormSubmitTimeout = setTimeout(async () => {
        if (this.mechanicFormValidation()) {
          if (this.mechanicModel.id) {
            await this.editMechanic(this.mechanicModel);
          } else {
            await this.addMechanic(this.mechanicModel);
          }
          this.closeMechanicDialog();
        }
        this.mechanicFormSubmit = false;
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.mechanicDialog {
  display: block;

  .form {
    .error {
      margin-bottom: 10px;
    }

    .submit {
      text-align: right;
      margin: 0;
    }
  }

  /deep/.el-dialog {
    .el-dialog__header {
      margin: 0;
      padding: 15px;
      border-bottom: 1px solid #ebeef5;
    }

    .el-dialog__body {
      padding: 15px;
    }
  }
}
</style>

