<template>
  <div class="classificationList">
    <vd-listing-header :title="title" :actions="headerActions"></vd-listing-header>
    <div class="content">
      <vd-paged-table
        tableKey="classificationList"
        :loading="loading"
        :data="elements"
        :totalDataSize="total"
        :paging="fetchClassificationsByPage"
        :actions="actions"
        :columns="columns"
        :configVisible="configVisible"
        :configClose="closeConfig"
      ></vd-paged-table>
    </div>
    <classification-dialog ref="classificationDialog"></classification-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import ClassificationDialog from '@/views/classification/ClassificationDialog.vue';
import VdPagedTable from '@/components/vd-paged-table/VdPagedTable';
import VdListingHeader from '@/components/vd-listing-header/VdListingHeader';

export default {
  name: 'classificationList',
  data() {
    return {
      title: 'Classification list',
      headerActions: [
        {
          callback: this.openClassificationDialog,
          title: 'Add',
          icon: 'circle-plus-outline',
          key: 'add',
        },
        {
          callback: this.openConfig,
          title: 'Configuration',
          icon: 'setting',
          key: 'setting',
        },
      ],
      actions: [
        { callback: this.editItem, icon: 'edit', key: 'edit' },
        { callback: this.deleteItem, icon: 'delete', key: 'delete' },
      ],
      columns: [
        {
          key: 'name',
          prop: scope => scope.row.name,
          label: 'Classification',
        },
      ],
      configVisible: false,
    };
  },
  components: {
    ClassificationDialog,
    VdPagedTable,
    VdListingHeader,
  },
  computed: {
    ...mapState('classification', ['loading', 'elements', 'total']),
  },
  methods: {
    ...mapActions('classification', [
      'fetchClassificationsByPage',
      'deleteClassification',
    ]),
    openClassificationDialog() {
      this.$refs.classificationDialog.openClassificationDialog();
    },
    editItem(element) {
      this.$refs.classificationDialog.openClassificationDialog(element);
    },
    deleteItem(element) {
      this.$confirm('Do you really want to delete this classification ?')
        .then(async () => {
          await this.deleteClassification(element);
        })
        .catch(() => {});
    },
    openConfig() {
      this.configVisible = true;
    },
    closeConfig() {
      this.configVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.classificationList {
  display: block;

  .content {
    .table {
      width: 100%;
    }
  }
}
</style>

