import Vue from 'vue';
import Vuex from 'vuex';
import gql from 'graphql-tag';
import { apolloClient } from '../../utils/vue-apollo';

Vue.use(Vuex);

const GRAPHQL_RENDER_TREE_PROPS = `
renderTree {
  key
  title
  type
  dataSource {
    id
    label
    description
    children {
      id
      label
      description
    }
  }
  withSearch
}
`;

const GRAPHQL = {
  queries: {
    fetchRenderTree: gql`
      query($input: RenderTreeInput!) {
        renderTree(input: $input) {
          ${GRAPHQL_RENDER_TREE_PROPS}
        }
      }
    `,
  },
};

export default {
  namespaced: true,
  state: {
    renderTree: [],
    loading: false,
  },
  mutations: {
    setRenderTree(state, { renderTree }) {
      state.renderTree = renderTree;
      state.loading = false;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
  },
  actions: {
    async fetchRenderTree({ commit }, { step, templateTypeId, gameId } = {}) {
      commit('setLoading', true);
      const results = await apolloClient
        .query({
          query: GRAPHQL.queries.fetchRenderTree,
          variables: {
            input: { step, templateTypeId, gameId },
          },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          console.error(err.toString());
          return null;
        });
      const renderTree = results && results.data && results.data.renderTree ? results.data.renderTree : [];
      commit('setRenderTree', renderTree);
    },
  },
};
