import Vue from 'vue';
import Vuex from 'vuex';

import AppServiceStore from './modules/appService-store';
import AuthStore from './modules/auth-store';
import NetworkServiceStore from './modules/networkService-store';
import GameStore from './modules/game-store';
import TemplateStore from './modules/template-store';
import TemplateTypeStore from './modules/templateType-store';
import RushStore from './modules/rush-store';
import NetworkStore from './modules/network-store';
import FormatStore from './modules/format-store';
import FormatTypeStore from './modules/formatType-store';
import RatioStore from './modules/ratio-store';
import OrientationStore from './modules/orientation-store';
import MechanicStore from './modules/mechanic-store';
import MetadataStore from './modules/metadata-store';
import ClassificationStore from './modules/classification-store';
import DeviceStore from './modules/device-store';
import WordingStore from './modules/wording-store';
import WordingTypeStore from './modules/wordingType-store';
import LanguageStore from './modules/language-store';
import RenderStore from './modules/render-store';
import AutoCMRenderStore from './modules/autoCMRender-store';
import RenderTreeStore from './modules/renderTree-store';
import RenderPresetStore from './modules/renderPreset-store';
import RenderRequestStore from './modules/renderRequest-store';
import StaticStore from './modules/static-store';
import BadgeTypeStore from './modules/badgeType-store';
import BadgeStore from './modules/badge-store';
import FontStore from './modules/font-store';
import StaticConfigStore from './modules/staticConfig-store';
import EndcardTemplateStore from './modules/endcardTemplate-store';
import EndcardRenderStore from './modules/endcardRender-store';
import ManualRenderStore from './modules/manualRender-store';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    auth: AuthStore,
    appService: AppServiceStore,
    networkService: NetworkServiceStore,
    game: GameStore,
    template: TemplateStore,
    templateType: TemplateTypeStore,
    rush: RushStore,
    network: NetworkStore,
    format: FormatStore,
    formatType: FormatTypeStore,
    ratio: RatioStore,
    orientation: OrientationStore,
    mechanic: MechanicStore,
    metadata: MetadataStore,
    classification: ClassificationStore,
    device: DeviceStore,
    wording: WordingStore,
    wordingType: WordingTypeStore,
    language: LanguageStore,
    render: RenderStore,
    autoCMRender: AutoCMRenderStore,
    renderTree: RenderTreeStore,
    renderPreset: RenderPresetStore,
    renderRequest: RenderRequestStore,
    static: StaticStore,
    badgeType: BadgeTypeStore,
    badge: BadgeStore,
    font: FontStore,
    staticConfig: StaticConfigStore,
    endcardTemplate: EndcardTemplateStore,
    endcardRender: EndcardRenderStore,
    manualRender: ManualRenderStore,
  },
});
