<template>
  <div class="game-view" v-loading="loading">
    <el-container class="game-view-container" v-if="dashboardGame">
      <el-aside width="15%" class="sidebar">
        <div class="title clickable" @click="$router.go(-1)">
          <i class="el-icon-back"></i>
          {{`${dashboardGame.game.code} page`}}
        </div>
        <div class="sidebar-content interesting">
          <img :src="dashboardGame.game.static.icon" width="50%" class="sidebar-icon" />
          <div class="sidebar-label">Marketing code</div>
          <div class="sidebar-property">{{dashboardGame.game.code}}</div>
          <div class="sidebar-label">Name</div>
          <div class="sidebar-property game-name">{{dashboardGame.game.name}}</div>
          <div class="sidebar-label">UA Email</div>
          <div class="sidebar-property">{{dashboardGame.game.uaEmail }}</div>
          <div class="sidebar-label">Orientation</div>
          <div class="sidebar-property">{{dashboardGame.game.orientation.name}}</div>
          <div class="sidebar-label">Mechanic</div>
          <div class="sidebar-property">{{dashboardGame.game.mechanic.name}}</div>
          <el-button class="edit-profile-button" @click="viewGame">Edit profile</el-button>
        </div>
      </el-aside>
      <el-main class="game-view-dam">
        <div class="title">All Assets</div>
        <el-row class="dam-top-row interesting">
          <el-col :span="24" class="top-row-col">
            <div class="dam-renders">
              <el-row style="margin-bottom: 16px;">
                <el-col :span="12">
                  <span>Rendered videos</span>
                  <el-button
                    style="margin-left: 16px; padding: 4px;"
                    @click="$router.push({path: '/generate'})"
                  >
                    <i class="el-icon-plus"></i>
                  </el-button>
                </el-col>
                <el-col :span="12">
                  <router-link
                    :to="`/renders?gameId=${dashboardGame.game.id}`"
                    class="el-button--text"
                    style="float: right;"
                  >See all ({{dashboardGame.renderCount}})</router-link>
                </el-col>
              </el-row>
              <el-row class="line">
                <vd-digital-asset-viewer
                  v-for="render of dashboardGame.renders.slice(0, 19)"
                  v-bind:key="`render-${render.id}`"
                  :url="render.renderDocument.url"
                  type="video"
                  :prev="null"
                  :next="null"
                  :title="render.name"
                  class="asset"
                ></vd-digital-asset-viewer>
              </el-row>
            </div>
          </el-col>
        </el-row>
        <el-row class="dam-bottom-row" :gutter="16">
          <el-col :span="10" class="dam-bottom-left">
            <el-row class="dam-rushes-row interesting">
              <div class="dam-rushes">
                <el-row style="margin-bottom: 16px;">
                  <el-col :span="12">
                    <span>Uploaded rushes</span>
                    <el-button
                      style="margin-left: 16px; padding: 4px;"
                      @click="$refs.rushDialog.openRushDialog({gameId: dashboardGame.game.id})"
                    >
                      <i class="el-icon-plus"></i>
                    </el-button>
                  </el-col>
                  <el-col :span="12">
                    <router-link
                      :to="`/rushes?gameId=${dashboardGame.game.id}`"
                      class="el-button--text"
                      style="float: right;"
                    >See all ({{dashboardGame.rushCount}})</router-link>
                  </el-col>
                </el-row>
                <el-row class="line">
                  <vd-digital-asset-viewer
                    v-for="rush of dashboardGame.rushes.slice(0, 9)"
                    v-bind:key="`rush-${rush.id}`"
                    :url="rush.document.url"
                    type="video"
                    :prev="null"
                    :next="null"
                    :title="rush.name"
                    class="asset"
                  ></vd-digital-asset-viewer>
                </el-row>
              </div>
            </el-row>
            <el-row class="dam-endcards-row interesting">
              <div class="dam-endcards">
                <el-row style="margin-bottom: 16px;">
                  <el-col :span="12">
                    <span>Endcards</span>
                    <el-button
                      style="margin-left: 16px; padding: 4px;"
                      @click="$refs.endcardDialog.openEndcardDialog(dashboardGame.game)"
                    >
                      <i class="el-icon-plus"></i>
                    </el-button>
                  </el-col>
                  <el-col :span="12">
                    <router-link
                      :to="`/endcard-renders?gameId=${dashboardGame.game.id}`"
                      class="el-button--text"
                      style="float: right;"
                    >See all ({{dashboardGame.endcardCount}})</router-link>
                  </el-col>
                </el-row>
                <el-row class="line">
                  <vd-digital-asset-viewer
                    v-for="endcard of dashboardGame.endcards.slice(0, 19)"
                    v-bind:key="`endcard-${endcard.id}`"
                    :url="endcard.document.url"
                    type="endcard"
                    :title="endcard.name"
                    :prev="null"
                    :next="null"
                    class="asset"
                  ></vd-digital-asset-viewer>
                </el-row>
              </div>
            </el-row>
          </el-col>
          <el-col :span="14" class="dam-bottom-right">
            <div class="dam-statics interesting" v-viewer>
              <el-row style="margin-bottom: 16px;">
                <el-col :span="12">
                  <span>Statics</span>
                  <el-button
                    style="margin-left: 16px; padding: 4px;"
                    @click="$refs.staticDialog.openStaticDialog({gameId: dashboardGame.game.id})"
                  >
                    <i class="el-icon-plus"></i>
                  </el-button>
                </el-col>
                <el-col :span="12">
                  <router-link
                    :to="`/statics?gameId=${dashboardGame.game.id}`"
                    class="el-button--text"
                    style="float: right;"
                  >See all ({{dashboardGame.staticCount}})</router-link>
                </el-col>
              </el-row>
              <el-image
                lazy
                v-for="staticE of dashboardGame.statics.slice(0, 49)"
                v-bind:key="`static-${staticE.id}`"
                width="100%"
                height="100%"
                class="images asset"
                fit="contain"
                :src="staticE.document.url"
              />
            </div>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
    <game-dialog ref="gameDialog"></game-dialog>
    <static-dialog ref="staticDialog"></static-dialog>
    <endcard-dialog ref="endcardDialog"></endcard-dialog>
    <rush-dialog ref="rushDialog"></rush-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import 'viewerjs/dist/viewer.css';

import { mapState, mapActions } from 'vuex';

import GameDialog from '@/views/game/GameDialog.vue';
import StaticDialog from '@/views/game/StaticDialog.vue';
import EndcardDialog from '@/views/game/EndcardDialog.vue';
import RushDialog from '@/views/rush/RushDialog.vue';
import VdDigitalAssetViewer from '@/components/vd-digital-asset-viewer/VdDigitalAssetViewer';

export default {
  name: 'game-view',
  data() {
    return {
      renderIndex: null,
    };
  },
  components: {
    VdDigitalAssetViewer,
    GameDialog,
    StaticDialog,
    EndcardDialog,
    RushDialog,
  },
  computed: {
    ...mapState('game', ['loading', 'dashboardGame']),
  },
  methods: {
    ...mapActions('game', ['fetchDashboardGame']),
    viewGame() {
      this.$refs.gameDialog.openGameDialog(this.dashboardGame.game);
    },
  },
  async mounted() {
    await this.fetchDashboardGame({ id: +this.$route.params.id });
  },
};
</script>

<style lang="scss" scoped>
.game-view {
  display: block;
  height: calc(100vh - 156px);
  overflow: hidden;

  .game-view-container {
    height: 100%;

    .title {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 16px;
    }

    .sidebar {
      height: 100%;
      min-width: 128px;
      margin-right: 32px;

      .sidebar-content {
        height: calc(100% - 2 * 8px - 16px - 22px);
        width: calc(100% - 2 * 8px);

        .sidebar-icon {
          display: block;
          margin-left: auto;
          margin-right: auto;
          border-radius: 24px;
          margin-bottom: 32px;
        }

        .sidebar-label {
          margin-top: 16px;
          margin-left: 16px;
          font-size: 12px;
          color: gray;
        }

        .sidebar-property {
          font-size: 14px;
          margin-left: 16px;
        }

        .sidebar-game-name {
          font-size: 16px;
          font-weight: 600;
        }

        .edit-profile-button {
          margin-top: 32px;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    .game-view-dam {
      height: 100%;
      padding: 0 8px;
      overflow: auto;
      min-width: 768px;

      .dam-top-row {
        height: calc(33% - 16px - 2 * 8px);
        margin-bottom: 16px;
        min-height: 192px;

        .top-row-col {
          height: 100%;

          .dam-renders {
            height: 100%;
          }
        }
      }

      .dam-bottom-row {
        height: calc(67% - 9px);

        .dam-bottom-left {
          height: 100%;

          .dam-rushes-row {
            height: calc(50% - 2 * 8px);
            margin-bottom: 16px;

            .dam-rushes {
              height: 100%;
            }
          }

          .dam-endcards-row {
            height: calc(50% - 2 * 8px);

            .dam-endcards {
              height: 100%;
            }
          }
        }

        .dam-bottom-right {
          height: 97%;

          .dam-statics {
            height: calc(100% - 2 * 8px);
          }
        }
      }
    }
  }
}

.interesting {
  background: #fff;
  border-radius: 5px;
  padding: 8px;
  overflow: auto;
}

.clickable {
  cursor: pointer;
}

.asset {
  max-width: 256px;
  max-height: 256px;
  height: 100%;
  margin: 8px;
}

.line {
  white-space: nowrap;
  height: calc(100% - 48px);
}

.fit-square {
  object-fit: contain;
}
</style>

